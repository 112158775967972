import React, { useState } from "react"
import axios from "axios"
import { useEffect } from "react"
import "./chat.css"

const Chat = () => {
  const [showIframe, setShowIframe] = useState(false)
  const [showChatIcon, setShowChatIcon] = useState(true)
  const iframe = document.getElementById("chat-iframe")

  const handleClick = async () => {
    const email = JSON.parse(localStorage.getItem("authUser"))?.Email
    // console.log("email", email)
    const password = "q"

    if (email) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CHAT_URL_BACKEND,
          // "http://localhost:9000/api/users/login",
          {
            Email: email,
            password: password,
          }
        )
        // console.log("API Response:", response.data)

        const authUser = response.data
        setTimeout(() => {
          const iframe = document.getElementById("chat-iframe")
          // console.log("iframe", iframe);  // Check if the iframe is correctly selected
          if (iframe && authUser) {
            // console.log("Sending message to iframe...");
            iframe.contentWindow.postMessage(
              { type: "LOGIN_SUCCESS", authUser },
              process.env.REACT_APP_CHAT_URL_FRONTEND
            )
            // console.log("Message posted to iframe");
          }
        }, 1000)

        setShowIframe(true)
        setShowChatIcon(!showChatIcon)
      } catch (error) {
        console.error("API Error:", error)
      }
    } else {
      console.error("Email not found in localStorage")
    }
  }

  useEffect(() => {
    if (!showIframe) {
      setShowChatIcon(true)
    }
  }, [showIframe])

  return (
    <div>
      {showIframe && (
        <div id="cutomchat-iframe">
          <div
            style={{
              color: "red",
              position: "absolute",
              top: "0",
              right: "3%",
              padding: "0% 0.5% 0% 0.5%",
              fontWeight: "bolder",
              margin: "2%",
              fontSize: "2.2rem",
              cursor: "pointer",
            }}
            onClick={() => setShowIframe(false)}
          >
            &#10540;
          </div>
          <iframe
            id="chat-iframe"
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid rgb(234, 234, 234)",
              borderRadius: "20px",
            }}
            src={process.env.REACT_APP_CHAT_URL_FRONTEND}
            frameBorder="0"
          />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "5%",
          right: "2%",
          zIndex: "9999",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {showChatIcon && (
          <img loading="lazy"
            src="https://img.icons8.com/?size=100&id=eqmc1ZdQ5Azg&format=png&color=000000"
            alt="Chat Icon"
          />
        )}
      </div>
    </div>
  )
}

export default Chat
