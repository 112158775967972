import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import io from "socket.io-client"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import Page from "pages"

// import Alldata from "./assets/localdata.json"

// Activating fake backend
// fakeBackend()
const Alldata = JSON.parse(localStorage.getItem("dataJ"))
import Notifications from "pages/UIcomponents/Notification"
import { onMessageListener } from "./firebaseInit"
import FirebaseNotifications from "pages/notifications/firebaseNotification"
import AddToHomeScreenPrompt from "components/AddToHome"

// analytics.logEvent('select_content', {
//   content_type: 'image',
//   content_id: 'P12453',
//   items: [{ name: 'Kittens' }]
// });
const App = props => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: "", body: "" })

  // console.log("testerlknkjbkjb", show, notification)

  onMessageListener()
    .then(payload => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      })
      setShow(true)
      console.log("payload", payload)
    })
    .catch(err => console.log("failed: ", err))

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_ADMIN)

    socket.on("notificationChannel", message => {
      const {redirect } =
        message
      // console.log("from app.js", message)

      if (message?.redirect !== null && message?.redirect !== "") {
        window.location.href = message?.redirect
        return
      }
    })

    return () => socket.disconnect()
  }, [])

  const Layout = getLayout()
  return (
    <React.Fragment>
      {/* <AddToHomeScreenPrompt/> */}

      {show ? (
        <Notifications
          toastType="success"
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <Router>
        <Switch>
          {Alldata
            ? authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  objct={route.objct}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))
            : location.reload()}

          {Alldata
            ? userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  objct={route.objct}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            : location.reload()}

          {Alldata
            ? Object.keys(Alldata.data).map((route, idx) => (
                <Authmiddleware
                  path={"/page/" + route}
                  layout={Layout}
                  objct={Alldata.data[route]}
                  component={Page}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            : location.reload()}
        </Switch>
      </Router>
      <FirebaseNotifications />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
