import * as React from "react"
import PropTypes from 'prop-types'

const SvgComponent4 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 300"
      {...props}
    >
      <style>
        {
          ".s0{fill:#0d5265}.s1{fill:#fff}.s3{fill:#000}.s7{fill:#00cdb3}.s9{fill:#ff8300}"
        }
      </style>
      <g id="OBJECTS">
        <path id="Layer" className="s0" d="M0 0h600v300H0z" />
        <path
          id="Layer"
          fillRule="evenodd"
          className="s1"
          d="M30 30h33.3v9.6H30zm31.2 2H32.1v5.5h29.1z"
        />
        <path id="Layer" className="s1" d="M420 30h150v240H420z" />
        <path id="Layer" d="M0 0h600v300H0z" fill="none" />
        <path
          id="Layer"
          fillRule="evenodd"
          className="s1"
          d="M282.1 227.2l-2.5-6.1h-8.1l-2.4 6.1h-1.5l7.3-18.4h1.4l7.2 18.4zm-6.5-16.5l-3.5 9h7.1z"
        />
        <path
          id="Layer"
          className="s1"
          d="M301.8 223h-.1l-7-11.3v15.5h-1.5v-18.4h1.4l7.2 11.7 7.2-11.7h1.2v18.4h-1.5v-15.5zM316.5 208.8v18.4H315v-18.4zM321.3 227.2v-18.4h1.5v17h10v1.4zM336.4 227.2v-18.4h1.5v17h10v1.4zM352.9 208.8v18.4h-1.5v-18.4z"
        />
        <path
          id="Layer"
          fillRule="evenodd"
          className="s1"
          d="M372.8 218q0 .9-.1 1.8-.1 1-.4 1.8-.3.9-.8 1.7-.4.8-1 1.5c-.8.9-1.7 1.5-2.7 2-1 .4-2 .7-3.1.7-1.2 0-2.2-.3-3.2-.7-1-.5-1.9-1.1-2.7-2q-1.1-1.4-1.7-3.2-.6-1.7-.6-3.6 0-1.9.6-3.6.6-1.8 1.7-3.2c.8-.9 1.7-1.5 2.7-2 1-.4 2-.7 3.2-.7 1.1 0 2.1.3 3.1.7 1 .5 1.9 1.1 2.7 2q.6.7 1 1.5.5.8.8 1.7.3.8.4 1.8.1.9.1 1.8zm-1.6 0q.1-.8 0-1.6-.1-.7-.4-1.5-.2-.7-.6-1.4-.3-.7-.8-1.3c-.6-.7-1.3-1.3-2.1-1.7-.8-.4-1.7-.6-2.6-.6-1 0-1.9.2-2.7.6-.8.4-1.5 1-2.1 1.7q-.9 1.3-1.4 2.8-.4 1.4-.4 3t.4 3q.5 1.5 1.4 2.8c.6.7 1.3 1.3 2.1 1.7.8.4 1.7.6 2.7.6.9 0 1.8-.2 2.6-.6.8-.4 1.5-1 2.1-1.7q.5-.6.8-1.3.4-.7.6-1.4.3-.8.4-1.5.1-.8 0-1.6z"
        />
        <path
          id="Layer"
          className="s1"
          d="M390.1 208.8v18.4h-1l-11.3-16v16h-1.5v-18.4h1.5l10.8 15.3v-15.3zM244.1 238.5v-6.3h21.1v6.3h-6.9v17.1H251v-17.1zM281.8 232.3l7.3-.1v23.4h-7.3v-8.7h-7.5v8.7H267v-23.4h7.4v8.4h7.4z"
        />
        <path
          id="Layer"
          fillRule="evenodd"
          className="s1"
          d="M308.3 255.6l-1.3-3.4h-8.2l-1.2 3.4h-7.2l8.8-23.3h7.6l8.9 23.3zm-5.4-14.7l-1.9 5.3h3.9z"
        />
        <path
          id="Layer"
          className="s1"
          d="M338.6 232.3v23.3h-6l-8.5-11.7v11.7H317v-23.4h6.9l7.7 10.9v-10.9zM348.5 232.3v9.7l6.3-9.8h8.4l-7.5 11.2 7.8 12.2H355l-6.5-10.3v10.3h-7.4v-23.4zM364.1 254.1v-6.5q.4.3.9.5l.8.4q.5.2.9.4l1 .4q.4.1.9.3l1 .2q.5.1 1 .1.5.1 1.1.1.2 0 .5-.1h1q.3-.1.6-.1 1-.2 1-1v-.2l-.1-.2q0-.1-.1-.2l-.2-.2q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1-.2 0-.3-.1l-3.6-.9q-.6-.1-1.3-.4-.7-.2-1.3-.5-.6-.3-1.2-.8l-1-.8q-.4-.5-.8-1.1-.3-.5-.5-1.1-.2-.6-.3-1.2-.1-.6 0-1.2-.1-.9.1-1.7.2-.8.5-1.5.4-.8.9-1.4.5-.6 1.2-1.1.7-.5 1.5-.9t1.7-.7q.8-.2 1.7-.3.9-.2 1.8-.1h1.1q.5 0 1.1.1.5 0 1 .1.6.1 1.1.2.4.2.9.3.4.1.8.3.4.1.8.3.4.2.8.3v6.6q-.4-.3-.9-.5-.4-.3-.8-.5t-.9-.4q-.4-.1-.9-.3-.4-.1-.9-.2t-.9-.2q-.5-.1-1-.1-.5-.1-.9-.1h-.5q-.2 0-.4.1-.3 0-.5.1-.2 0-.4.1-.7.2-.7.8v.2q0 .1.1.2 0 .1.1.2l.1.1q.1.1.2.1.2.1.3.1.1.1.2.1.1.1.3.1l3.3.9 1.4.4q.7.3 1.4.7.6.3 1.2.8.6.4 1.1.9l.8 1q.3.5.5 1.1.2.6.3 1.2.1.6.1 1.3 0 .8-.1 1.7-.2.9-.6 1.6-.4.8-1 1.5-.6.6-1.4 1.1-.8.4-1.7.8-.9.3-1.8.5-.9.3-1.8.4-.9.1-1.9.1-.5 0-1.1-.1-.5 0-1.1-.1-.5 0-1.1-.1-.5-.1-1.1-.3-.4-.1-.9-.2-.4-.2-.8-.3-.4-.2-.9-.4l-.8-.4zM384.6 247.5l-1.6-15.3 8.8.1-1.5 15.2zm-1.3 4.6q0-.4.1-.8t.2-.7q.2-.4.4-.7.2-.3.5-.6.6-.5 1.4-.8.7-.2 1.5-.3.8.1 1.6.3.7.3 1.3.8.6.6.9 1.3.3.7.3 1.4 0 .8-.3 1.5t-.9 1.3q-.6.5-1.3.8-.8.2-1.6.2t-1.5-.2q-.8-.3-1.4-.8-.3-.3-.5-.6t-.4-.7q-.1-.3-.2-.7-.1-.4-.1-.7z"
        />
        <g id="Layer" opacity={0.2}>
          <g id="Layer">
            <path
              id="Layer"
              className="s1"
              d="M265.4 122.8v-4.9H282v5h-5.4v13.4h-5.8v-13.5zM295.1 117.9h5.8v18.4h-5.8v-6.8h-5.8v6.8h-5.9v-18.4h5.9v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M316 136.3l-1-2.7h-6.5l-1 2.7h-5.7l7-18.4h6l7 18.4zm-4.2-11.6l-1.5 4.2h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M339.9 117.9v18.4h-4.7l-6.7-9.2v9.2h-5.7v-18.4h5.5l6 8.6v-8.6zM347.7 117.9v7.7l5-7.7h6.6l-5.9 8.8 6.2 9.6h-6.8l-5.1-8.1v8.1h-5.8v-18.4zM360 135.2V130q.3.2.7.4.3.2.7.3.3.2.7.3.3.2.7.3l.8.2.8.2q.4 0 .8.1h1.6q.2 0 .4-.1h.4q.8-.2.8-.8v-.2q0-.1-.1-.2v-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2l-2.8-.7q-.5-.2-1.1-.3-.5-.2-1-.5-.5-.2-.9-.6-.5-.3-.9-.6-.3-.4-.5-.8-.3-.5-.4-.9-.2-.5-.3-1v-1q0-.6.1-1.3.1-.6.4-1.2.3-.6.7-1.1.4-.5.9-.8.6-.5 1.2-.8.7-.3 1.3-.5.7-.2 1.4-.2.7-.1 1.4-.1h.9q.4 0 .8.1.4 0 .9.1l.8.2q.4.1.7.2l.6.2q.3.1.7.2.3.2.6.3v5.2q-.3-.2-.7-.4l-.6-.4q-.4-.1-.7-.3-.4-.1-.7-.2-.4-.2-.8-.2-.3-.1-.7-.2-.4 0-.8-.1H366.5q-.2.1-.4.1t-.3.1q-.6.2-.6.6v.2q0 .1.1.1 0 .1.1.2l.1.1q.1 0 .2.1.1 0 .1.1h.2q.1.1.2.1l2.7.7q.5.2 1.1.4.5.2 1 .5.5.2 1 .6.5.3.9.7.3.4.5.8.3.4.5.9.1.5.2.9.1.5.1 1 0 .7-.1 1.4-.2.7-.5 1.3-.3.6-.8 1.1-.5.5-1.1.9-.6.3-1.3.6-.7.3-1.4.5l-1.4.2q-.8.1-1.5.1h-.9q-.4-.1-.9-.1l-.8-.2q-.5 0-.9-.1-.3-.1-.7-.2-.3-.1-.6-.3-.3-.1-.7-.2-.3-.2-.6-.3zM376.2 129.9l-1.3-12h7l-1.2 12zm-1 3.6v-.6l.2-.6q.1-.2.3-.5l.4-.4q.5-.4 1.1-.7.6-.2 1.2-.2.7 0 1.2.2.6.3 1.1.7.5.4.7.9.3.6.3 1.2t-.3 1.2q-.2.5-.7.9-.5.5-1.1.7-.5.2-1.2.2-.6 0-1.2-.2t-1.1-.7q-.2-.1-.4-.4-.2-.2-.3-.5l-.2-.6v-.6zM139.4 122.8v-4.9h16.7v5h-5.4v13.4h-5.8v-13.5zM169.2 117.9h5.8v18.4h-5.8v-6.8h-5.8v6.8h-5.9v-18.4h5.9v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M190 136.3l-1-2.7h-6.4l-1 2.7H176l7-18.4h6l7 18.4zm-4.2-11.6l-1.5 4.2h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M214 117.9v18.4h-4.8l-6.7-9.2v9.2h-5.6v-18.4h5.4l6.1 8.6v-8.6zM221.8 117.9v7.7l4.9-7.7h6.7l-5.9 8.8 6.1 9.6h-6.7l-5.1-8.1v8.1H216v-18.4zM234.1 135.2V130l.6.4q.4.2.7.3.4.2.8.3.3.2.7.3l.8.2q.3.1.7.2.4 0 .8.1H240.9q.2 0 .4-.1h.4q.8-.2.8-.8v-.2q-.1-.1-.1-.2l-.1-.1q0-.1-.1-.1-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-.2l-2.8-.7q-.6-.2-1.1-.3-.5-.2-1-.5-.5-.2-.9-.6-.5-.3-.9-.6l-.6-.8q-.2-.5-.4-.9l-.2-1q-.1-.5 0-1-.1-.6.1-1.2.1-.7.4-1.3.3-.6.7-1.1.4-.5.9-.8.6-.5 1.2-.8.7-.3 1.3-.5.7-.2 1.4-.2.7-.1 1.4-.1h.9q.4 0 .8.1.5 0 .9.1l.8.2q.4.1.7.2l.6.2q.4.1.7.2.3.2.6.3v5.2q-.3-.2-.7-.4l-.6-.4q-.4-.1-.7-.3-.4-.1-.7-.2l-.8-.2q-.3-.1-.7-.2-.4 0-.8-.1H240.6q-.2.1-.4.1t-.3.1q-.6.2-.6.6v.2q0 .1.1.1 0 .1.1.2 0 .1.1.1.1.1.2.1 0 .1.1.1t.2.1h.2l2.7.7q.5.2 1.1.4.5.2 1 .5.5.2 1 .6.4.3.9.7.3.4.5.8.3.4.5.9.1.5.2.9.1.5.1 1 0 .7-.1 1.4-.2.7-.5 1.3-.3.6-.8 1.1-.5.5-1.1.9-.6.3-1.3.6-.7.3-1.4.5l-1.4.2q-.8.1-1.5.1h-.9q-.4-.1-.9-.1-.4-.1-.8-.1-.5-.1-.9-.2-.3-.1-.7-.2-.3-.1-.6-.3-.4-.1-.7-.2-.3-.2-.6-.3zM250.2 129.9l-1.2-12h7l-1.2 12zm-1 3.6v-.6l.2-.6q.1-.2.3-.5l.4-.4q.5-.4 1.1-.7.6-.2 1.2-.2t1.2.2q.6.3 1.1.7.4.4.7.9.2.6.2 1.2t-.2 1.2q-.3.5-.7.9-.5.5-1.1.7-.6.2-1.2.2t-1.2-.2q-.6-.2-1.1-.7l-.4-.4q-.2-.2-.3-.5l-.2-.6v-.6zM265.9 180.1v-5.5h18.6v5.5h-6v15H272v-15zM299.1 174.6h6.4v20.5h-6.4v-7.7h-6.6v7.7h-6.4v-20.5h6.4v7.3h6.6z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M322.3 195.1l-1-3h-7.2l-1.1 3h-6.4l7.8-20.5h6.7l7.8 20.5zm-4.7-13l-1.6 4.8h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M349 174.6v20.5h-5.3l-7.5-10.3v10.3H330v-20.5h6l6.8 9.5v-9.5zM357.7 174.6v8.5l5.5-8.5h7.4l-6.5 9.8 6.8 10.7h-7.5l-5.7-9.1v9.1h-6.5v-20.5zM371.4 193.8l.1-5.7q.3.2.7.4l.8.4q.4.2.8.3.4.2.8.3.4.1.8.3.5.1.9.2.4 0 .9.1h2.3q.3-.1.5-.1.8-.2.9-.9 0-.1-.1-.2v-.2q0-.1-.1-.1-.1-.1-.1-.2-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-3.2-.8q-.6-.1-1.2-.3-.6-.2-1.1-.5-.5-.3-1-.7-.5-.3-1-.8l-.6-.8q-.3-.5-.5-1-.1-.5-.2-1.1-.1-.5-.1-1.1 0-.7.1-1.4.2-.7.5-1.4.3-.6.8-1.2.4-.5 1-1 .7-.4 1.4-.7.7-.4 1.4-.6.8-.2 1.5-.3.8-.1 1.6-.1h.9q.5 0 1 .1.5 0 1 .1.4.1.9.2.4.1.7.2.4.1.8.3.3.1.7.3.3.1.7.3v5.7l-.8-.4q-.3-.2-.7-.4l-.8-.4q-.3-.1-.7-.2-.4-.2-.8-.3-.5-.1-.9-.1-.4-.1-.8-.1-.4-.1-.8-.1h-.4q-.2.1-.4.1h-.4q-.2.1-.4.1-.6.2-.6.7v.4q.1.1.1.2.1 0 .2.1t.2.1.2.1.2.1h.2l3 .8 1.2.4 1.2.6q.5.3 1.1.7.5.3 1 .8.3.4.6.9.3.4.5 1l.2 1q.1.6.1 1.1.1.8-.1 1.5-.2.8-.5 1.5-.4.7-.9 1.2-.5.6-1.2 1t-1.5.7q-.8.3-1.6.5t-1.6.3q-.8.1-1.6.1h-1q-.5-.1-1-.1-.5-.1-.9-.2l-1-.2-.8-.2q-.4-.2-.8-.3-.3-.2-.7-.3-.4-.2-.7-.4zM389.4 188l-1.3-13.4h7.7l-1.3 13.4zm-1.1 4q-.1-.3 0-.7l.2-.6q.1-.3.3-.6.2-.3.5-.5.5-.4 1.2-.7.6-.2 1.3-.2.7 0 1.4.2.6.3 1.2.7.5.5.7 1.1.3.6.3 1.3 0 .7-.3 1.3-.2.6-.7 1.1-.6.4-1.2.7-.7.2-1.4.2t-1.3-.2q-.7-.3-1.2-.7-.3-.2-.5-.5t-.3-.6l-.2-.6q-.1-.4 0-.7zM133.5 180.1v-5.5h18.6v5.5h-6v15h-6.5v-15zM166.6 174.6h6.5v20.5h-6.5v-7.7h-6.5v7.7h-6.5v-20.5h6.5v7.3h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M189.9 195.1l-1.1-3h-7.2l-1 3h-6.4l7.8-20.5h6.7l7.8 20.5zm-4.7-13l-1.7 4.8h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M216.6 174.6v20.5h-5.3l-7.5-10.3v10.3h-6.2v-20.5h6l6.7 9.5v-9.5zM225.3 174.6v8.5l5.5-8.5h7.4l-6.5 9.8 6.8 10.7H231l-5.7-9.1v9.1h-6.5v-20.5zM239 193.8v-5.7q.4.2.7.4l.8.4q.4.2.8.3.4.2.8.3.4.1.9.3.4.1.9.2.4 0 .9.1h1.8q.2 0 .4-.1.3 0 .5-.1.9-.1.8-.9.1 0 0-.1v-.2q0-.1-.1-.2l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.3-.1l-3.1-.8q-.6-.1-1.2-.3-.5-.2-1.1-.5l-1-.6-1-.8q-.3-.4-.6-.9t-.5-1l-.2-1q-.1-.6-.1-1.1 0-.7.1-1.4.2-.8.5-1.4.3-.7.8-1.2.4-.6 1-1 .7-.5 1.4-.8l1.4-.6q.8-.2 1.5-.3.8-.1 1.6-.1.5 0 .9.1h1l1 .2q.4.1.9.2.4.1.7.2l.8.2q.3.2.7.3.3.2.7.3v5.8q-.4-.3-.8-.5-.3-.2-.7-.4-.4-.1-.8-.3-.3-.2-.7-.3l-.8-.2q-.5-.1-.9-.2-.4 0-.8-.1h-1.6q-.2.1-.4.1t-.4.1q-.6.2-.6.7v.2q0 .1.1.2 0 .1.1.1 0 .1.1.1l.2.2q.1 0 .2.1h.2q.1.1.2.1l3 .8q.6.1 1.2.4.6.2 1.2.5.5.3 1.1.7l1 .8q.3.5.6.9.3.5.5 1t.2 1.1q.1.5.1 1 .1.8-.1 1.6-.1.7-.5 1.4t-.9 1.3q-.5.5-1.2.9t-1.5.8q-.7.3-1.5.5t-1.7.3h-2.6q-.5 0-1-.1-.4 0-.9-.1t-1-.3l-.8-.2q-.3-.1-.7-.2l-.8-.4q-.3-.1-.7-.3zM257 188l-1.4-13.4h7.8L262 188zm-1.1 4q0-.3.1-.7 0-.3.2-.6.1-.3.3-.6.2-.3.4-.5.6-.4 1.2-.7.7-.2 1.4-.2t1.4.2q.6.3 1.2.7.5.5.7 1.1.3.6.3 1.3 0 .7-.3 1.3-.2.6-.7 1.1-.6.4-1.2.7-.7.2-1.4.2t-1.4-.2q-.6-.3-1.2-.7-.2-.2-.4-.5t-.3-.6q-.2-.3-.2-.6-.1-.4-.1-.7zM138.3 190.3v-.5h4.3v.5h-1.8v4.6h-.7v-4.6zM147 189.8h.7v5.1h-.7v-2.4h-2.9v2.4h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M152.4 194.9l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M157.8 189.8v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM159.7 189.8v2.2l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7v-5.1zM163.3 194.5v-.7q.1.1.2.1.1.1.2.1.1.1.2.1.1.1.2.1.1.1.2.1h.2q.1.1.2.1h.5q.1 0 .2-.1h.2q.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.2q.1 0 .1-.1v-.2q-.1 0-.1-.1t-.1-.1q0-.1-.1-.2l-.1-.1h-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.2-.2-.3-.4-.1-.3-.1-.5l.2-.6q.1-.2.3-.4.2-.1.3-.1.1-.1.3-.1.1-.1.3-.1H165.6q.1.1.2.1h.2q0 .1.1.1.1.1.2.1t.1.1v.7q0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1l-.1.1q-.1 0-.2.1l-.1.1v.6l.1.1q0 .1.1.1 0 .1.1.1.1.1.2.1l.1.1h.1l.9.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.5q-.1.2-.1.3-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2-.1 0-.3.1h-.8q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1-.2-.1-.2-.1zM167.4 194.5v-.1q0-.1.1-.1v-.1h.1q0-.1.1-.1.1-.1.2-.1t.1.1h.2v.1l.1.1v.4h-.1v.1q-.1.1-.2.1H167.7q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM126.4 184.5v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM135.1 183.9h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M140.4 189l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M145.9 183.9v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM147.8 183.9v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM151.4 188.6v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.1-.2l-.1-.1q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.2q-.1-.1-.3-.1l-.2-.2q-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2q.1-.2.2-.2.1-.1.3-.2.1-.1.2-.1.2 0 .3-.1h.8q.1.1.2.1h.1q.1.1.2.1t.2.1h.2q0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1t-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1 0 .2-.1 0 0 .1v.1q0 .1.1.2v.1q.1 0 .2.1h.1q.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q0 .2.1.3v.2q0 .1-.1.3 0 .1-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H152.8q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1zM155.5 188.6v-.2q.1 0 .1-.1.1-.1.2-.1h.4l.2.2v.3q0 .1-.1.2l-.1.1h-.1l-.1.1h-.1l-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM126.4 177.6v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM135.1 177h.6v5.1h-.6v-2.3h-2.9v2.3h-.7V177h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M140.4 182.1l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.7 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M145.9 177v5.1h-.5l-2.9-4v4h-.6V177h.6l2.7 3.9V177zM147.8 177v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7V177zM151.4 181.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2q.1-.2.2-.2.1-.1.3-.2.1-.1.2-.1.2 0 .3-.1h.8q.1.1.2.1h.1q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1 0-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.1v.2h.2q0 .1.1.1.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2v.3q.1.1.1.2t-.1.3q0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H152.8q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1zM155.5 181.7v-.1-.1h.1v-.1q.1 0 .1-.1h.5q0 .1.1.1v.1h.1v.4q-.1 0-.1.1l-.1.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1-.1v-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM138.3 169.4v-.6h4.3v.6h-1.8v4.6h-.7v-4.5zM147 168.8h.7v5.2h-.7v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M152.4 174l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M157.8 168.8v5.2h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM159.7 168.8v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM163.3 173.6v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1 0 .2.1h.5q.1-.1.2-.1t.2-.1.2-.1l.1-.1.1-.1v-.2q.1-.1.1-.2v-.1l-.1-.1q0-.1-.1-.2l-.1-.1-.1-.1q-.1 0-.1-.1h-.2q-.1-.1-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2-.2-.2q0-.1-.1-.2 0-.1-.1-.2v-.3q0-.1.1-.3 0-.1.1-.2 0-.2.1-.3l.2-.2q.2-.1.3-.1.1-.1.3-.1.1-.1.3-.1H165.6q.1.1.2.1h.2q0 .1.1.1.1.1.2.1t.1.1v.7q0-.1-.1-.1-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1h-1q-.1 0-.2.1l-.1.1q-.1 0-.2.1l-.1.1v.6l.1.1q0 .1.1.1 0 .1.1.1.1.1.2.1l.1.1h.1l.9.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.5q-.1.2-.1.3-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2H164.3q-.2-.1-.3-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1-.1-.1-.2-.1zM167.4 173.6v-.1q0-.1.1-.1v-.1-.1h.1q.1 0 .1-.1H168l.1.1h.1v.1q.1 0 .1.1V173.7q0 .1-.1.1v.1h-.1q0 .1-.1.1H167.7l-.1-.1h-.1v-.1-.1q-.1 0-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM118.3 163.6v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM127 163h.7v5.1h-.7v-2.3h-2.9v2.3h-.7V163h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M132.4 168.1l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M137.8 163v5.1h-.5l-2.8-4.1v4.1h-.7V163h.7l2.7 3.9V163zM139.7 163v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7V163zM143.4 167.7v-.7q0 .1.1.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H145.4q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1.1-.2v-.2q-.1 0-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.3v-.2q-.1-.1-.1-.2 0-.2.1-.3 0-.2.1-.3 0-.1.1-.3.1-.1.2-.1.2-.1.3-.2.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.1q.1.1.2.1l.1.1.1.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1l-.1.1q-.1 0-.2.1 0 .1-.1.1v.6l.1.1q0 .1.1.1l.1.1q.1.1.2.1 0 .1.1.1h.2l.8.4q.1 0 .2.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.3v.5q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3 0-.1.1-.3 0h-.4q-.2 0-.3-.1h-.1q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM147.4 167.7v-.1l.1-.1v-.1h.1v-.1h.1l.1-.1h.2v.1h.1q.1 0 .1.1l.1.1v.4l-.1.1q0 .1-.1.1h-.4-.1v-.1h-.1v-.1l-.1-.1v-.1zm.3-1.2l-.2-3.5h.7l-.1 3.5z"
            />
            <path
              id="Layer"
              className="s3"
              d="M179.2 182.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM184.5 182.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M187.8 185.4l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M191.2 182.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM192.4 182.2v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM194.6 185.1v-.4l.2.2q.2 0 .3.1h.3q.1.1.2.1h.2l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1-.1-.2-.1-.3-.1-.2-.1-.3 0-.2.1-.4l.2-.2q.1-.1.2-.1.1-.1.2-.1H196.1l.1.1h.1q.1 0 .2.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.2q0 .1-.1.1v.1q-.1 0-.1.1V183.2h.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H195.3h-.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM197.2 185.1v-.1-.1h.2v-.1q0 .1.1.1h.1q0 .1.1.1V185.3h-.1v.1H197.3l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM173.6 179.3v-.3h2.7v.4h-1.2v2.8h-.4v-2.8zM179 179h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V179h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M182.3 182.1l-.4-.9h-1.3l-.3.9h-.4l1.2-3.1h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M185.7 179v3.2h-.3l-1.8-2.5v2.5h-.4V179h.4l1.7 2.4V179zM186.8 179v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V179zM189.1 181.9v-.5l.1.1.1.1q.1 0 .1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H190.2h-.1H189.8l-.1.1q-.1 0-.1.1h-.1v.4q0 .1.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1-.1.1-.2.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1l-.1-.1zM191.6 181.9v-.1q0-.1.1-.1v-.1H192l.1.1v.1q.1 0 .1.1l-.1.1v.1h-.1l-.1.1h-.1v-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM169.1 130.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM174.4 130.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M177.8 133.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M181.1 130.1v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.3l1.7 2.4v-2.4zM182.3 130.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM184.5 133.1v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1.1.2.1h.5l.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1-.1-.1h-.1-.1l-.5-.2-.1-.1q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2q.1 0 .1-.1.1-.1.2-.1t.2-.1h.7q.1 0 .1.1H186.3q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.2l-.1-.1q-.1 0-.2.1h-.1-.1l-.1.1h-.1v.1l-.1.1v.2l.1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0 0 .1-.1 0h-.2-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM187.1 133.1v-.1-.1l.1-.1H187.5v.1h.1V133.2l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM177.9 124.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM183.2 124h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V124h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M186.5 127.1l-.3-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M189.9 124v3.2h-.3l-1.8-2.5v2.5h-.4V124h.4l1.7 2.4V124zM191.1 124v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V124zM193.3 126.9v-.5l.1.1q.1.1.2.1l.1.1h.1q.1 0 .1.1H194.6l.1-.1h.1q.1-.1.1-.2.1-.1.1-.2v-.2q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.1q.1-.1.1-.2t.1-.2l.1-.1q.1 0 .2-.1.1 0 .2-.1h.7l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.1-.1q-.1 0-.1.1h-.1v.1l-.1.1V124.9q.1 0 .1.1l.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM195.8 126.9l.1-.1v-.1q0-.1.1-.1H196.3v.1h.1V127q-.1 0-.1.1h-.4v-.1-.1h-.1zm.2-.8l-.1-2.2h.4v2.2zM158 126.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM163.4 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M166.7 129.1l-.4-1H165l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M170.1 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM171.2 125.9v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM173.5 128.8v-.4l.1.1.1.1h.1l.1.1H174.2l.1.1H174.6q.1 0 .1-.1h.2l.1-.1.1-.1v-.1l.1-.1-.1-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1q0-.1-.1-.2v-.2l.1-.1v-.2q0-.1.1-.1.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H175.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2l.1.1v.1l.1.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q0 .1-.1.1h-.3-.2q-.1 0-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1-.1 0-.1-.1zM176 128.8v-.1l.1-.1h.1q0-.1.1 0 0-.1 0 0h.2v.4q-.1 0-.1.1H176.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM158 119.9v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM163.4 119.5h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M166.7 122.7l-.4-1H165l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M170.1 119.5v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM171.2 119.5v1.5l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM173.5 122.5v-.5l.1.1.1.1h.1l.1.1H174.2l.1.1H174.6q.1 0 .1-.1h.2l.1-.1.1-.1v-.2q.1-.1 0-.2 0-.1-.1-.1 0-.1-.1-.1l-.1-.1h-.1v-.1l-.5-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1h.9q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1H174.7q0-.1-.1-.1h-.2q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.2.1v.4l.1.1.1.1h.1v.1h.1l.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1-.1.1-.2.1H174.3 174h-.1l-.1-.1h-.1l-.1-.1h-.1zM176 122.5v-.1-.1q.1 0 .1-.1h.4v.1q.1.1.1.2h-.1v.2h-.1H176.1q0-.1-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM158 133.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM163.4 133.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M166.7 136.2l-.4-.9H165l-.4.9h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M170.1 133.1v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM171.2 133.1v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM173.5 136v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.8q0-.1.1-.1v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1l-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2.1-.1h.2q.1 0 .2-.1h.4q.1.1.2.1h.2q0 .1.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H174.3q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1v.4q.1 0 .1.1h.1q0 .1.1.1t.1.1l.5.1q.1.1.2.1t.1.1q.1 0 .2.1l.1.1q.1.1.1.3.1.1 0 .3 0 .2-.1.3 0 .2-.2.3l-.1.1h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM176 136v-.1-.1h.1l.1-.1h.2q0 .1.1.1v.1q.1 0 .1.1l-.1.1v.1h-.1v.1h-.2l-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM180.8 117.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM186.1 117.4h.4v3.2h-.4v-1.4h-1.8l.1 1.4h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M189.5 120.6l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M192.8 117.4v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM194 117.4v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM196.3 120.4v-.5q0 .1.1.1l.1.1h.1l.1.1h.2q0 .1.1.1h.5l.1-.1h.1l.2-.2v-.2-.2q0-.1-.1-.1 0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2.1 0 .1-.1.1-.1.2-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1l.1.1v.4l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.1q-.1-.1-.1 0-.1-.1-.1 0h-.2-.1l-.1.1-.1.1v.2q-.1.1 0 .2 0 .1.1.1 0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1h.1q.1.1.1.2.1 0 .1.1.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.2 0-.1.1-.2 0H196.8h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM198.8 120.3v-.1h.1v-.1h.4v.4l-.1.1h-.1-.1-.1l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM198 126.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM203.4 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M206.7 129.1l-.3-1H205l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M210.1 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM211.3 125.9l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM213.5 128.8v-.4q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1q-.1 0-.2-.1h-.1q-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1l.1-.1H215l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2l-.1-.1h-.2-.1l-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V128.4q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H214.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM216 128.8v-.1l.1-.1h.1q.1-.1.1 0 0-.1 0 0h.2v.4l-.1.1h-.1-.2v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM198 133.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM203.4 133.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M206.7 136.3l-.3-1H205l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M210.1 133.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM211.3 133.1l-.1 1.4 1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM213.5 136.1v-.5l.2.2q.1 0 .3.1.1 0 .2.1H214.8q0-.1.1-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1t.1-.1H214.9q.1 0 .1.1h.2l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H214.7q-.1-.1-.2-.1l-.1.1h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1.1.1.1.2.1 0 .1.1v.1l.1.1v.4l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1t-.1-.1h-.5q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1zM216 136.1v-.1-.1q.1 0 .1-.1h.4v.2q.1 0 0 0v.2l-.1.1h-.1-.2v-.1h-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM206.2 119.9v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM211.5 119.5h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M214.9 122.7l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.2 3.2zm-1-2.7l-.5 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M218.2 119.5v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.3l1.7 2.5v-2.5zM219.4 119.5v1.5l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM221.7 122.5v-.5l.2.2q.1 0 .2.1h.3q.1.1.3.1h.1l.1-.1h.1q.1 0 .1-.1.1 0 .2-.1v-.2-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1t-.1-.1v-.2q0-.1.1-.2v-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H223.2q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.2-.1q0-.1-.1-.1H222.5l-.1.1h-.1l-.1.1h-.1v.1l-.1.1v.2l.1.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.2.1v.2q.1 0 .1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.9l-.1-.1h-.1q-.1 0-.2-.1zM224.2 122.5v-.1-.1h.1v-.1H224.6v.1h.1V122.6l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM230.2 117.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM235.6 117.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M238.9 120.6l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M242.3 117.4v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM243.4 117.4v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM245.7 120.4v-.5l.2.2q.1.1.3.1.1 0 .2.1h.5q.1-.1.2-.1h.1q.1-.1.1-.2.1-.1.1-.2t-.1-.2q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1l.1-.1H247.1l.1.1h.2l.1.1q.1 0 .1.1v.4q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1 0 0-.1-.1 0h-.2q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1h.2q0 .1.1.2l.1.1v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0h-.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM248.2 120.3v-.1l.1-.1h.4v.1l.1.1v.1l-.1.1q0 .1-.1.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM219.2 126.3v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM224.6 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M227.9 129.1l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M231.3 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM232.4 125.9v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM234.7 128.8v-.4q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H236.2l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1h-.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V128.4q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H235.3q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM237.2 128.8v-.1h.1v-.1h.1q.1-.1.1 0 0-.1.1 0h.1q0 .1.1.1v.2l-.1.1v.1h-.2-.1l-.1-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM219.2 132.4v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM224.6 132h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V132h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M227.9 135.2l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M231.3 132v3.2h-.3l-1.8-2.6v2.6h-.4V132h.4l1.7 2.4V132zM232.4 132v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V132zM234.7 134.9v-.4q.1 0 .1.1h.1q.1.1.2.1l.1.1h.4q0 .1.1 0 .1.1.1 0H236.1l.1-.1h.1v-.1l.1-.1v-.2-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q-.1-.2-.2-.3v-.4q0-.1.1-.3l.2-.2q.1-.1.2-.1l.1-.1h.2q.1-.1.2 0h.5q.1 0 .1.1h.1q.1 0 .1.1h.1v.4h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.2l-.1.1h-.1l-.1.1-.1.1v.4h.1v.1h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1q.1.1.2.1 0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V134.5q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1 0-.2.1-.1 0-.2.1H235.3q-.1 0-.2-.1h-.1l-.1-.1h-.1l-.1-.1zM237.2 134.9v-.1h.1v-.1h.1v-.1h.2v.1h.1v.1h.1v.2l-.1.1q0 .1-.1.1h-.1-.1l-.1-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM240.8 126.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM246.1 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M249.5 129.1l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M252.8 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM254 125.9v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM256.2 128.8v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H257q0 .1.1.1H257.4l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1-.1-.1h-.2q0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1.1-.1.2-.1H257.7l.1.1h.2l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1h-.1-.1l-.1-.1H257.1l-.1.1h-.1l-.1.1h-.1v.1q-.1 0-.1.1v.2l.1.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2h.1q.1.1.2.1l.1.1.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1.1-.2.1t-.2.1h-.4H256.9h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1zM258.8 128.8v-.1-.1h.2q0-.1 0 0 .1-.1.1 0h.1l.1.1v.2q0 .1-.1.1v.1h-.1-.1-.1q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM249.6 117.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM254.9 117.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M258.2 120.6l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M261.6 117.4v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM262.8 117.4v1.4l1.4-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.4v1.4h-.4v-3.1zM265 120.4v-.5l.1.1q.1 0 .1.1h.1q.1.1.2.1h.1l.1.1H266.3q0-.1.1-.1h.1q.1-.1.1-.2.1-.1.1-.2t-.1-.2q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1l.1-.1H266.4l.1.1h.2l.1.1q.1 0 .1.1v.4q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1 0 0-.1-.1 0h-.2q-.1 0-.1.1-.1 0-.2.1v.4q0 .1.1.1l.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1h.2q0 .1.1.2l.1.1v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.5l-.1-.1h-.1q-.1 0-.1-.1h-.1zM267.6 120.3q-.1 0 0 0v-.1q0-.1.1-.1H268l.1.1V120.5h-.1v.1h-.4v-.1-.1q-.1 0-.1-.1zm.2-.7l-.1-2.2h.4v2.2zM261.8 124.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM267.1 124h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V124h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M270.5 127.1l-.4-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M273.8 124v3.2h-.3l-1.8-2.5v2.5h-.3V124h.3l1.7 2.4V124zM275 124v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.6v1.5h-.4V124zM277.2 126.9v-.5q.1.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2v-.2-.2l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.2-.1v-.2q-.1 0-.1-.1v-.2-.1q.1-.1.1-.2t.1-.2q.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7l.1.1H279q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.2q0-.1-.1-.1h-.4-.1-.1-.1l-.1.1h-.1v.1q0 .1-.1.1v.2q0 .1.1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM279.8 126.9v-.1-.1l.1-.1H280.2v.1h.1V127l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM263.1 130.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM268.4 130.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M271.8 133.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M275.1 130.1v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM276.3 130.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM278.5 133.1v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1.1.2.1h.5l.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1l-.1-.1h-.1l-.1-.1q-.2-.1-.2-.3-.1-.2-.1-.3.1-.2.1-.3.1-.2.2-.3.1-.1.2-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.2l-.1-.1q-.1 0-.2.1h-.1-.1l-.1.1q-.1 0-.1.1-.1.1-.1.2t.1.2q0 .1.1.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0 0 .1-.1 0h-.2-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM281.1 133.1v-.1-.1l.1-.1H281.5v.1h.1V133.2l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM283.6 124.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM288.9 124h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V124h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M292.3 127.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M295.6 124v3.2h-.3l-1.7-2.5v2.5h-.4V124h.3l1.7 2.4V124zM296.8 124v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V124zM299.1 126.9l-.1-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2v-.2-.2l-.1-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.1v-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1q.1 0 .2-.1h.7q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1l-.1.1h-.1v.1q0 .1-.1.1v.2q0 .1.1.1v.1h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1l-.1.1q-.1 0-.2.1h-.3-.1q-.1 0-.1-.1H299.5q-.1 0-.1-.1h-.2l-.1-.1h-.1zM301.6 126.9v-.1-.1h.1v-.1H302v.1q.1 0 .1.1v.2l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM270.9 117.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM276.2 117.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M279.6 120.6l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M282.9 117.4v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM284.1 117.4v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM286.4 120.4v-.5q0 .1.1.1l.1.1h.1l.1.1h.2q0 .1.1.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2.1 0 .2-.1 0-.1.1-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1l.1.1v.4l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.1q-.1-.1-.1 0-.1-.1-.1 0h-.2-.1l-.1.1h-.1v.2q-.1.1 0 .1-.1.1 0 .1v.2h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1h.1l.2.2v.1q.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.2 0-.1.1-.2 0h-.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM288.9 120.3v-.1h.1v-.1H289.3l.1.1V120.5l-.1.1h-.2-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM292.1 117.8l-.1-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM297.4 117.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M300.7 120.6l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M304.1 117.4v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM305.3 117.4l-.1 1.4 1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.4v1.4h-.4v-3.1zM307.5 120.4v-.5l.1.1q.1 0 .1.1h.2q0 .1.1.1h.1l.1.1H308.8q0-.1.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.1-.1H308.9q.1 0 .1.1h.2l.1.1q.1 0 .1.1v.4q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H308.7q-.1-.1-.2 0 0-.1-.1 0h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4q.1 0 .1.1l.1.1h.1q0 .1.1.1h.1l.5.2q.1 0 .1.1.1 0 .2.1h.1q.1.1.1.2.1 0 .1.1v.1l.1.1v.4l-.1.1q0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.5l-.1-.1h-.1l-.1-.1h-.1zM310 120.3v-.1h.1q0-.1.1-.1H310.5v.2l.1.1h-.1v.1q0 .1-.1.1H310.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM304.4 124.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM309.8 124h.4v3.2h-.4v-1.5H308v1.5h-.4V124h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M313.1 127.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M316.5 124v3.2h-.3l-1.8-2.5v2.5h-.4V124h.4l1.7 2.4V124zM317.6 124v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4V124zM319.9 126.9v-.5q0 .1.1.1l.1.1q.1 0 .1.1H320.5l.1.1h.5q.1 0 .2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1q-.1 0-.1-.1v-.1h-.1q0-.1-.1-.1h-.1v-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.1q0-.1.1-.2v-.2q.1 0 .2-.1.1 0 .1-.1h.2q.1-.1.2-.1H321.3q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1V126.2q.1 0 .1.1t-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4l-.1-.1h-.2-.1l-.1-.1h-.1q-.1 0-.1-.1h-.1zM322.4 126.9v-.1q0-.1.1-.1v-.1H322.8q.1 0 .1.1v.1h.1v.1l-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM283.6 130.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM288.9 130.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M292.3 133.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M295.6 130.1v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.3l1.7 2.4v-2.4zM296.8 130.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM299.1 133.1l-.1-.5q.1.1.2.1l.1.1h.1l.1.1h.1q.1.1.2.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1-.1l-.4-.2q-.1 0-.2-.1l-.1-.1h-.1l-.1-.1q-.2-.1-.2-.3-.1-.2-.1-.3.1-.2.1-.3.1-.2.3-.3 0-.1.1-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.1l-.1-.1q-.1 0-.1.1h-.2-.1l-.1.1q-.1 0-.1.1-.1.1-.1.2t.1.2q0 .1.1.1 0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2.1 0 .1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.1q-.1.1-.3 0 0 .1-.1 0h-.4q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1zM301.6 133.1v-.1-.1l.1-.1H302v.1h.1V133.2l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM304.4 133.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM309.8 133.1h.4v3.2h-.4v-1.5H308v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M313.1 136.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M316.5 133.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM317.6 133.1v1.4l1.4-1.4h.6l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM319.9 136.1v-.5q0 .1.1.1t.1.1h.1q.1.1.2.1h.1q.1 0 .1.1h.5q.1-.1.2-.1h.1q0-.1.1-.1v-.1q0-.1.1-.1v-.1-.1l-.1-.1v-.1h-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.2-.1q.1-.1.1-.2.1 0 .2-.1l.1-.1q.1 0 .2-.1h.9q0 .1.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H321.1h-.1q0-.1-.1-.1t-.1.1h-.2q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1.1.2t-.1.2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0H320.4h-.1q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.1zM322.4 136.1v-.1-.1h.1v-.1h.4v.1q0 .1.1.1v.1q-.1 0-.1.1t-.1.1h-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM325.1 133.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM330.4 133.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M333.8 136.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M337.1 133.1v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM338.3 133.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM340.6 136.1v-.5q0 .1.1.1l.1.1h.1l.1.1h.2l.1.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.2-.2-.2-.3-.1-.2 0-.3v-.3q.1-.2.3-.3 0-.1.1-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.1l-.1-.1q-.1 0-.1.1h-.2-.1l-.1.1-.1.1v.1l-.1.1q0 .1.1.1v.2h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.1.2.1 0 .1.1.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.3.1l-.1-.1h-.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM343.1 136.1v-.1-.1h.1v-.1H343.5v.1h.1V136.2h-.1v.1h-.1-.1-.1v-.1h-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM314 119.9v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM319.4 119.5h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M322.7 122.7l-.4-1H321l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M326.1 119.5v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM327.2 119.5v1.5l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM329.5 122.5v-.5l.1.1q.1 0 .1.1h.1q.1.1.2.1h.2q.1.1.2.1h.2q.1 0 .1-.1h.2l.1-.1q.1 0 .1-.1t.1-.2q0-.1-.1-.2 0-.1-.1-.1 0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.1-.2l-.1-.1v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1l.1-.1q.1-.1.2-.1h.9q0 .1.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H330.7q0-.1-.1-.1h-.2q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.2.1v.4l.1.1.1.1h.1l.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1.1.2t-.1.2v.1q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1-.1.1-.2.1H330.3h-.4q0-.1-.1-.1h-.1l-.1-.1h-.1zM332 122.5v-.1-.1h.1v-.1h.4v.1q.1.1 0 .2.1 0 0 0v.2h-.1H332.1q0-.1-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM323.1 126.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM328.5 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M331.8 129.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M335.2 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM336.3 125.9v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM338.6 128.8v-.4q.1 0 .1.1.1 0 .1.1h.1q.1.1.2.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1l-.1-.1h-.1q-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1 0-.1.1-.1H340.1l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.2.1v.4q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V128.4q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H339.2q-.1 0-.2-.1h-.1-.1l-.1-.1q-.1 0-.1-.1zM341.1 128.8v-.1h.1v-.1h.1q.1-.1.1 0 0-.1.1 0h.1v.1l.1.1q0 .1-.1.1v.1l-.1.1h-.1-.1-.1v-.1l-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM335.5 117.8v-.4h2.6v.4H337v2.8h-.4v-2.8zM340.9 117.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M344.2 120.6l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M347.6 117.4l-.1 3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM348.7 117.4v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM351 120.4v-.5l.1.1q.1 0 .1.1h.1q.1.1.2.1h.1l.1.1h.5q.1-.1.2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1v-.1h-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1l.1-.1H352.4l.1.1h.2l.1.1q.1 0 .1.1v.4q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1 0 0-.1-.1 0h-.2q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1h.2q0 .1.1.2l.1.1v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0h-.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM353.5 120.3v-.1l.1-.1h.4V120.4q.1 0 0 0v.1l-.1.1H353.6v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM342.3 126.3v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM347.7 125.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M351 129.1l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M354.4 125.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM355.6 125.9l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM357.8 128.8v-.4q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1l.1-.1H359.3l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V128.4q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H358.4q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM360.3 128.8v-.1h.1v-.1h.1q.1-.1.1 0 0-.1.1 0h.1q0 .1.1.1v.2l-.1.1v.1h-.2-.1l-.1-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM342.3 132.4v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM347.7 132h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V132h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M351 135.2l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M354.4 132v3.2h-.3l-1.8-2.6v2.6h-.4V132h.4l1.7 2.4V132zM355.6 132l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V132zM357.8 134.9v-.4q.1 0 .1.1h.1q.1.1.2.1l.1.1h.4q0 .1.1.1t.1-.1H359.2l.1-.1h.1v-.1l.1-.1v-.2-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q0-.1-.1-.2l-.1-.1v-.2-.1-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1l.1-.1h.2q.1-.1.2-.1l.1.1h.4q.1 0 .1.1h.1q.1 0 .1.1h.1v.4h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.2l-.1.1h-.1l-.1.1-.1.1v.4h.1v.1h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V134.5q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1 0-.2.1-.1 0-.2.1H358.4q-.1 0-.2-.1h-.1l-.1-.1h-.1l-.1-.1zM360.3 134.9v-.1h.1v-.1h.1v-.1h.2v.1h.1v.1h.1v.2l-.1.1q0 .1-.1.1h-.1-.1l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM240.8 132.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM246.1 132h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V132h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M249.5 135.2l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M252.8 132v3.2h-.3l-1.8-2.6v2.6h-.4V132h.4l1.7 2.4V132zM254 132v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4V132zM256.2 134.9v-.4q.1 0 .1.1h.2q0 .1.1.1l.1.1h.4l.1.1q.1 0 .2-.1h.2q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.2-.1q0-.1-.1-.1v-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.5-.2h-.1q-.1 0-.1-.1-.1 0-.1-.1h-.2q-.1-.2-.1-.3-.1-.2-.1-.4 0-.1.1-.3l.2-.2q.1-.1.2-.1.1-.1.2-.1h.2q.1-.1.2 0h.4q.1 0 .1.1h.2l.1.1h.1v.4h-.1l-.1-.1h-.2q0-.1-.1-.1h-.1l-.1-.1h-.4-.1q0 .1-.1.1h-.1q0 .1-.1.1v.1q-.1 0-.1.1v.2l.1.1q0 .1.1.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1 0-.2.1-.1 0-.2.1h-.4H256.9h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1l-.1-.1zM258.8 134.9v-.1-.1h.1q0-.1.1-.1h.1v.1h.1l.1.1v.2q0 .1-.1.1 0 .1-.1.1h-.1-.1q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM158 184.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM163.4 183.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M166.7 187.1l-.4-1H165l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M170.1 183.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM171.2 183.9v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM173.5 186.8v-.4q0 .1.1.1l.1.1h.1l.1.1h.2l.1.1h.5q.1-.1.2-.1l.1-.1.1-.1v-.1l.1-.1-.1-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1q0-.1-.1-.2v-.2l.1-.1v-.2q0-.1.1-.1.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H175.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2l.1.1v.1l.1.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q0 .1-.1.1h-.3-.2q-.1 0-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1-.1 0-.1-.1zM176 186.8v-.1q.1 0 .1-.1h.4v.4l-.1.1H176.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM193.4 179.3v-.3h2.7v.4H195v2.8h-.4v-2.8zM198.8 179h.4v3.2h-.4v-1.5H197v1.5h-.4V179h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M202.1 182.1l-.3-.9h-1.4l-.3.9h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M205.5 179v3.2h-.3l-1.8-2.5v2.5h-.4V179h.4l1.7 2.4V179zM206.7 179v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4V179zM208.9 181.9v-.5l.1.1.1.1q.1 0 .2.1h.1q0 .1.1.1H210.3q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2l-.1.1-.1.1q-.1.1-.1.2t.1.2.1.2h.1v.1h.2l.5.2q.1.1.2.1t.1.1.1.1q.1 0 .2.1v.1l.1.1v.7l-.1.1q-.1.1-.2.1l-.2.2h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.1q0-.1-.1-.1zM211.4 181.9v-.1l.1-.1.1-.1H211.9v.1l.1.1v.2q-.1 0-.1.1h-.1v.1h-.1l-.1-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM194.7 186.2v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM200.1 185.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M203.4 189l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M206.8 185.8v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM207.9 185.8v1.5l1.5-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM210.2 188.8v-.5q0 .1.1.1l.1.1q.1 0 .1.1h.2l.1.1h.6q.1 0 .2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H211.3h-.1H210.9l-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H210.6l-.1-.1h-.1q-.1 0-.1-.1h-.1zM212.7 188.8v-.1q0-.1.1-.1v-.1H213.1q.1 0 .1.1v.1h.1v.1q0 .1-.1.1v.1h-.1-.1q-.1.1-.1 0h-.1l-.1-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM219.2 186.2v-.3h2.7v.3h-1.1v2.8h-.5v-2.8zM224.6 185.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M227.9 189l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M231.3 185.8v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM232.4 185.8v1.5l1.5-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM234.7 188.8v-.5l.1.1.1.1q.1 0 .2.1h.1l.1.1h.7l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1-.1.2-.1t.1-.1H236.2l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.5q0-.1-.1-.1h-.1l-.1-.1h-.1zM237.2 188.8v-.1l.1-.1v-.1h.4v.1l.1.1v.2q-.1 0-.1.1h-.1q0 .1-.1 0h-.2v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM242.5 187.9v-.4h2.6v.4H244v2.8h-.4v-2.8zM247.8 187.6h.4v3.1h-.4v-1.4H246v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M251.2 190.7l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M254.5 187.6v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM255.7 187.6v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM258 190.5v-.5q.1 0 .1.1.1 0 .1.1h.1q.1.1.2.1h.2q.1.1.2.1h.2q.1 0 .1-.1h.2l.1-.1h.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H259.5l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1-.1.1v.4h.1v.1l.1.1h.1l.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H258.8h-.2q-.1 0-.2-.1h-.2q-.1 0-.1-.1h-.1zM260.5 190.5v-.1-.1l.1-.1H260.9v.1h.1V190.6l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM240.8 181.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM246.1 180.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M249.5 183.9l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M252.8 180.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM254 180.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM256.2 183.7v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1h.5l.1-.1h.1l.2-.2v-.2-.2q0-.1-.1-.1l-.1-.1-.1-.1h-.1-.1l-.5-.2q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H257.7l.1.1h.2l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1-.1l-.1-.1H257.1q0 .1-.1.1h-.1l-.1.1q-.1 0-.1.1-.1.1-.1.2t.1.2q0 .1.1.1v.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.4H256.9h-.2q-.1-.1-.2-.1h-.1q0-.1-.1-.1h-.1zM258.8 183.6v-.1-.1h.4l.1.1V183.8h-.1v.1h-.1-.1-.1l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM265.6 181.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM271 180.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M274.3 183.9l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M277.7 180.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM278.8 180.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM281.1 183.7v-.5l.2.2q.1 0 .2.1.2 0 .3.1h.5q.1-.1.2-.1h.1q0-.1.1-.2v-.2q.1-.1 0-.2 0-.1-.1-.1 0-.1-.1-.1l-.1-.1h-.2l-.4-.2q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1l.1-.1q.1 0 .2-.1h.9q0 .1.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H282.3q0-.1-.1-.1h-.2l-.1.1h-.1q-.1 0-.2.1l-.1.1v.5h.1l.1.1h.1v.1h.1l.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1 0 .2.1.1 0 .2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1H281.9 281.6h-.1l-.1-.1h-.1l-.1-.1h-.1zM283.6 183.6v-.1q.1 0 .1-.1h.4v.2h.1q0 .1-.1.1v.1l-.1.1H283.7v-.1h-.1v-.1-.1zm.1-.7v-2.2h.4l-.1 2.2zM230.2 175.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM235.6 175.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M238.9 178.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M242.3 175.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM243.4 175.1v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM245.7 178.1v-.5l.2.2q.1 0 .3.1.1 0 .2.1h.5q.1-.1.2-.1h.1q0-.1.1-.1v-.1q0-.1.1-.1v-.1-.1l-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1l.1-.1H247.2l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0h-.4q0-.1-.1-.1h-.1l-.1-.1h-.1zM248.2 178.1v-.1-.1h.1v-.1H248.6q.1 0 .1.1l.1.1v.1l-.1.1q0 .1-.1.1h-.1-.1-.1v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM211.5 175.5v-.4h2.6v.4H213v2.8h-.4v-2.8zM216.8 175.1h.4v3.2h-.4v-1.5H215v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M220.2 178.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M223.5 175.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM224.7 175.1v1.4l1.4-1.4h.5l-1.6 1.6 1.7 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM226.9 178.1v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2q.1 0 .1-.1.1-.1.2-.1t.2-.1h.7q.1 0 .1.1H228.7q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1-.2l-.1-.1q-.1 0-.2.1h-.1-.1l-.1.1h-.1v.1l-.1.1V176.1q.1 0 .1.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2l.1.1q.1.1.1.2.1 0 .1.1l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1l-.1-.1h-.2H227.3q0-.1-.1-.1h-.1l-.1-.1h-.1zM229.4 178.1q0-.1.1-.1v-.1q0-.1.1-.1H229.9v.1h.1V178.2q-.1 0-.1.1h-.1-.1-.1-.1v-.1l-.1-.1zm.2-.8l-.1-2.2h.5l-.1 2.2zM258.4 175.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM263.7 175.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M267.1 178.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M270.4 175.1v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.4l1.7 2.4v-2.4zM271.6 175.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.6l-1.5-1.5v1.5h-.4v-3.2zM273.8 178.1v-.5l.2.2q.2 0 .3.1.1 0 .3.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1q.1-.1.1-.2h.2q.1-.1.2-.1t.2-.1h.4q0 .1.1.1h.2l.1.1h.2v.5l-.1-.1q-.1 0-.2-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.2l.1.1v.1h.1l.1.1h.1q.1 0 .1.1l.5.2h.1q.1.1.2.1t.1.1.1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H274.5h-.1q-.1-.1-.2-.1h-.1q0-.1-.1-.1l-.1-.1h-.1zM276.4 178.1q-.1-.1 0-.1v-.1q0-.1.1-.1H276.8v.1h.1V178.2h-.1v.1h-.1-.2q-.1 0-.1-.1v-.1q-.1 0 0 0zm.1-.8l-.1-2.2h.5l-.1 2.2zM287.4 181.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM292.7 180.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M296 183.9l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M299.4 180.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM300.6 180.7v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM302.8 183.7v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.2l-.5-.2q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1v-.2l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2 0-.1.1-.1.1-.1.2-.1t.2-.1H304.3l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1-.1l-.1-.1h-.2-.1q0 .1-.1.1h-.1q-.1 0-.1.1h-.1v.1l-.1.1V181.7q.1 0 .1.1l.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.7H303.2q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1zM305.3 183.6l.1-.1v-.1h.4v.1h.1V183.8h-.1v.1H305.5q-.1 0-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4v2.2zM305.5 176.4v-.4h2.6v.4H307v2.8h-.4v-2.8zM310.8 176h.4v3.2h-.4v-1.5H309v1.5h-.4V176h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M314.2 179.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M317.5 176v3.2h-.3l-1.8-2.6v2.6h-.3V176h.3l1.7 2.4V176zM318.7 176v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4V176zM321 179v-.4h.1l.1.1.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1h-.1v-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.4-.1H321.7q-.1 0-.1.1-.1 0-.2.1v.4q0 .1.1.1 0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1h-.2q0-.1-.1-.1H321.4l-.1-.1h-.2l-.1-.1zM323.5 179v-.1-.1q.1 0 .1-.1h.2l.1.1h.1v.4h-.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM305.5 184.3v-.4h2.6v.4H307v2.8h-.4v-2.8zM310.8 183.9h.4v3.2h-.4v-1.5H309v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M314.2 187.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M317.5 183.9v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM318.7 183.9v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM321 186.8v-.4l.2.2q.1 0 .3.1.1 0 .2.1H322.3q0-.1.1-.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1l.1-.1H322.5l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1l-.1.1h-.1q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-1q0-.1-.1-.1h-.1l-.1-.1q-.1 0-.1-.1zM323.5 186.8v-.1-.1h.4l.1.1v.2q0 .1-.1.1v.1h-.1-.1-.1l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM309.7 193.9v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM315.1 193.5h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M318.4 196.7l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M321.8 193.5v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM322.9 193.5v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM325.2 196.4v-.4q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H326.7l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2q-.1.1-.1.2-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H325.8q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM327.7 196.4v-.1q.1 0 .1-.1h.2q.1-.1.1 0h.1q0 .1.1.1v.2l-.1.1v.1h-.2-.1l-.1-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM325.1 186.2v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM330.4 185.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M333.8 189l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M337.1 185.8v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM338.3 185.8v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM340.6 188.8v-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1 0 .2.1h.6q.1 0 .1-.1h.1l.1-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.1v-.2q.1-.1.1-.2.1 0 .1-.1.1 0 .2-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1l-.1.1h-.1v.2q-.1.1 0 .1-.1.1 0 .1v.2h.1q0 .1.1.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1t-.2.1h-.5q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM343.1 188.8v-.1-.1h.1v-.1H343.5v.1h.1V188.9l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM325.1 181.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM330.4 180.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M333.8 183.9l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M337.1 180.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM338.3 180.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM340.6 183.7v-.5q0 .1.1.1l.1.1h.1l.1.1h.2l.1.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1-.1l-.4-.2q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H342.1q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.2q-.1.1-.2.1h-.1l-.1.1h-.1v.2q-.1.1 0 .1-.1.1 0 .1v.2h.1l.1.1.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.2.1v.2q.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1H341l-.1-.1h-.1l-.1-.1h-.1zM343.1 183.6v-.1l.1-.1H343.5l.1.1V183.8h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM346 179.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM351.3 179h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V179h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M354.7 182.1l-.4-.9H353l-.4.9h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M358 179v3.2h-.3l-1.7-2.6v2.5h-.4V179h.4l1.7 2.4V179zM359.2 179v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4V179zM361.4 181.9l.1-.5q0 .1.1.1 0 .1.1.1l.1.1h.1l.1.1H362.8l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2q-.1-.1-.1-.2l.1-.1v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H363.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1v.1h-.1v.1l-.1.1q0 .1.1.1v.2l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM364 181.9v-.1-.1h.1v-.1H364.4l.1.1v.1l.1.1q-.1 0-.1.1v.1h-.1l-.1.1h-.1v-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM344.7 190.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM350.1 189.7h.3v3.2h-.4v-1.5h-1.7v1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M353.4 192.9l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M356.7 189.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM357.9 189.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM360.2 192.6v-.4l.1.1.1.1h.1l.1.1H360.9l.1.1H361.3q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1-.1-.1h-.1q-.1-.2-.2-.3v-.3q0-.2.1-.4 0-.1.2-.2 0-.1.1-.1.1-.1.2-.1H361.7l.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1H361.4q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2-.1v.1q-.1 0-.1.1v.4q0 .1.1.1l.1.1q.1 0 .1.1h.1l.5.2h.2q0 .1.1.1l.1.1q.1.1.2.1v.1q.1.1.1.2V192.4q-.1.1-.1.2-.1 0-.2.1l-.1.1q-.1 0-.2.1H360.7l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1zM362.7 192.6v-.1q0-.1.1-.1h.1v-.1h.1q0 .1.1.1h.1v.4l-.1.1H362.8v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM346 186.2v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM351.3 185.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M354.7 189l-.4-1H353l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M358 185.8v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM359.2 185.8v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM361.4 188.8l.1-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1 0 .1.1h.7q.1 0 .1-.1h.1l.1-.1q.1 0 .1-.1v-.1-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.1-.1-.3.1-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1h-.1v.1q0 .1-.1.1v.2l.1.1v.1h.1v.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1l-.1.1h-.4q-.1 0-.1-.1H361.9q-.1 0-.1-.1h-.2l-.1-.1zM364 188.8v-.1-.1h.1v-.1H364.4q.1 0 .1.1v.1q.1 0 .1.1l-.1.1v.1h-.1q-.1.1-.1 0h-.2l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM285 187.9v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM290.4 187.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M293.7 190.7l-.4-1H292l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M297.1 187.6l-.1 3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM298.2 187.6v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM300.5 190.5v-.5l.2.2q.1 0 .2.1h.3q.1.1.3.1h.1q.1 0 .1-.1h.2l.1-.1.1-.1v-.2q.1-.1 0-.2 0-.1-.1-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1l-.5-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1-.2-.2-.2q.1 0 .1-.1.1-.1.2-.1l.1-.1q.1-.1.2-.1h.9q0 .1.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1h-.1q0-.1-.1-.1h-.2l-.1-.1h-.2H301.3l-.1.1h-.1l-.1.1q-.1 0-.1.1v.4l.1.1h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1l.1.1q.1.1.2.1 0 .1.1.1 0 .1.1.1V189.8q.1.1.1.2l-.1.1v.2q-.1.1-.1.2-.1 0-.1.1-.1 0-.2.1-.1 0-.2.1H301.3h-.2q-.1 0-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM303 190.5v-.1-.1h.1v-.1H303.4l.1.1v.2q.1 0 0 0v.2h-.1H303.1q0-.1-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM265.1 187.9v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM270.5 187.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M273.8 190.7l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M277.2 187.6v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM278.3 187.6v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM280.6 190.5v-.5l.1.1q.1 0 .1.1h.1q.1.1.2.1h.2l.1.1H281.7q.1 0 .1-.1h.2l.1-.1h.1v-.1q0-.1.1-.1v-.1-.1l-.1-.1v-.1h-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1v-.2q.1-.1.2-.1l.1-.1q.1-.1.2-.1h.9q0 .1.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H281.8q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2l-.1.1-.1.1v.4l.1.1.1.1h.1l.1.1.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1 0 .2.1.1 0 .2v.1q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1-.1.1-.2.1H281.4 281.1l-.1-.1h-.2q-.1 0-.1-.1h-.1zM283.1 190.5v-.1q0-.1.1-.1v-.1H283.5q.1 0 .1.1v.1h.1v.1l-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM217.5 182.6v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM222.9 182.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M226.2 185.4l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M229.6 182.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM230.7 182.2v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM233 185.1v-.4l.2.2q.1 0 .3.1h.2q.2.1.3.1h.1q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1 0-.1.1-.1H234.5l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H233.6q-.1 0-.2-.1h-.1-.1l-.1-.1q-.1 0-.1-.1zM235.5 185.1v-.1h.1v-.1h.4v.1h.1v.1q0 .1-.1.1v.1q0 .1-.1.1h-.1-.1-.1v-.1l-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM171.5 187.9v-.4h2.6v.4H173v2.8h-.4v-2.8zM176.8 187.6h.4v3.1h-.4v-1.4H175v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M180.2 190.7l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M183.5 187.6v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM184.7 187.6v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM187 190.5v-.5q.1 0 .1.1.1 0 .1.1h.1q.1.1.2.1h.2q.1.1.2.1h.2q.1 0 .1-.1h.2l.1-.1h.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H188.5l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H187.8h-.2q-.1 0-.2-.1h-.1-.1q-.1 0-.1-.1h-.1zM189.5 190.5v-.1-.1h.1v-.1H189.9v.1h.1v.4h-.2-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1z"
            />
            <path
              id="Layer"
              className="s1"
              d="M128.5 154.9v-.6h4.3v.6H131v4.5h-.7v-4.5zM137.2 154.3h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M142.6 159.4l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M148 154.3v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.8 3.9v-3.9zM149.9 154.3v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM153.6 159v-.7q0 .1.1.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H155.6q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1.1-.2v-.2q-.1 0-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.3 0-.1-.1-.2v-.2q0-.2.1-.3 0-.2.1-.3 0-.1.1-.3l.2-.2q.2 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.1q.1.1.2.1l.1.1.1.7q-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1t-.2-.1h-1q-.1 0-.1.1-.1 0-.2.1-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.4l.1.1q0 .1.1.1l.1.1q.1.1.2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3-.1.1-.2.3l-.2.2q-.1 0-.3.1-.1.1-.3.1-.1.1-.3.1h-1q-.1-.1-.3-.1-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1zM157.6 159v-.1l.1-.1v-.1h.1v-.1h.2q0-.1.1 0 0-.1.1 0h.1q.1 0 .1.1l.1.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.2l-.1-.1h-.1v-.1h-.1v-.1l-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM117.4 147.2v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM126 146.7h.7v5.1h-.7v-2.3h-2.8v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M131.4 151.8l-.5-1.6h-2.2l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M136.9 146.7v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM138.8 146.7v2.2l2.3-2.2h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM142.4 151.4v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.4q.2 0 .3-.1h.1q.1-.1.2-.1.2-.1.2-.3.1-.1.1-.3v-.3q-.1-.1-.2-.3l-.1-.1h-.2q0-.1-.1-.1-.1-.1-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.2-.2-.3-.4-.1-.3-.1-.6 0-.2.2-.5.1-.2.3-.4.1-.1.3-.1.1-.1.2-.1.2-.1.3-.1h1q.1.1.2.1h.1q.1.1.2.1.1.1.2.1l.1.1v.7l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-1q-.1 0-.2.1-.1 0-.2.1l-.1.1-.1.1v.2q-.1 0-.1.1t.1.2v.1q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1 0 .2.1h.1l.8.3q.2.1.3.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2t.1.2v.3q0 .1-.1.2 0 .2-.1.3 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-1.4q-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1-.1-.1-.2-.1zM146.5 151.4v-.1-.1l.1-.1.1-.1.1-.1H147.1l.1.1.1.1q0 .1.1.1V151.5l-.1.1v.1q-.1 0-.1.1h-.1-.1H146.7q0-.1-.1-.1v-.1q-.1 0-.1-.1v-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM133.5 139.8v-.5h4.2v.5h-1.8v4.6h-.7v-4.6zM142.1 139.3h.7v5.1h-.7V142h-2.9v2.4h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M147.5 144.4l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M153 139.3v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM154.8 139.3v2.2l2.3-2.2h.9l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM158.5 144v-.7q.1.1.2.1.1.1.2.1 0 .1.1.2h.2q.2.1.3.1.1 0 .2.1H160.3q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.2-.3v-.2-.3-.3q0-.1.1-.2.1-.2.2-.3 0-.1.2-.2.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-.2q-.1-.1-.2-.1H159.8q-.1 0-.2.1-.1 0-.2.1l-.2.2q-.1.1-.1.3 0 .1.1.3 0 .1.1.2t.2.1q0 .1.1.1t.1.1h.2l.8.4q.1 0 .2.1.1 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2-.1.2-.2.3 0 .1-.2.2-.1.1-.2.1-.2.1-.3.2h-1.4q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1zM162.6 144v-.1-.1-.1h.1q0-.1.1-.1t.1-.1h.2q0 .1.1.1h.1v.1h.1v.1q0 .1.1.1v.2q-.1 0-.1.1v.1h-.1v.1h-.2-.1q-.1.1-.2 0l-.1-.1q-.1 0-.1-.1v-.2zm.2-1.2l-.2-3.5h.8l-.2 3.5zM125 131.7v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM133.7 131.1h.7v5.1h-.7v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M139.1 136.2l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M144.5 131.1v5.1h-.4l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM146.4 131.1v2.3l2.3-2.3h.9l-2.6 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM150.1 135.8v-.7q.1.1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.3.1h.8q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2l.1-.1v-.2-.1q0-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1q-.1 0-.1-.1l-.8-.3h-.2l-.2-.2q-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.2v-.3q-.1-.1 0-.2v-.3q0-.2.1-.3 0-.1.1-.2l.2-.2q.2-.1.3-.2.2 0 .3-.1.1 0 .3-.1h.7q.1.1.2.1h.2q.1.1.2.1t.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H151.5q-.1 0-.2.1h-.1q-.1.1-.2.1t-.1.1l-.1.1q-.1.1-.1.2v.4q.1.1.1.2l.1.1q.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1.2.1.3.2.1 0 .1.1l.2.2v.2q.1.2.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.5q-.2 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM154.2 135.8q-.1 0 0-.1v-.1q0-.1.1-.1v-.1h.5l.1.1.1.1v.5h-.1q0 .1-.1.1l-.1.1h-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1 0-.2zm.2-1.2l-.2-3.5h.8l-.1 3.5zM126.4 124.5v-.5h4.2v.6h-1.8v4.5h-.6v-4.5zM135.1 124h.6v5.1h-.6v-2.3h-2.9v2.3h-.7V124h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M140.4 129.1l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.7 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M145.9 124v5.1h-.5l-2.9-4.1v4.1h-.6V124h.6l2.7 3.9V124zM147.8 124v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7V124zM151.4 128.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2q.1-.2.2-.2.1-.1.3-.2.1-.1.2-.1.2 0 .3-.1h.8q.1.1.2.1h.1q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1 0-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.1v.2h.2q0 .1.1.1.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q0 .1.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H152.8q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1zM155.5 128.7v-.1-.1l.1-.1.1-.1.1-.1H156.1l.1.1.1.1q0 .1.1.1V128.8l-.1.1v.1q-.1 0-.1.1h-.1-.1H155.7q0-.1-.1-.1v-.1q-.1 0-.1-.1v-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM128.5 118.2v-.6h4.3v.6H131v4.5h-.7v-4.5zM137.2 117.6h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M142.6 122.7l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M148 117.6v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM149.9 117.6v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.2zM153.6 122.3v-.7q0 .1.1.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1H155.6q.1-.1.2-.1t.2-.1l.2-.2q.1-.2.1-.3v-.3q-.1-.2-.2-.3l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3h-.2l-.2-.2q-.1 0-.2-.1t-.2-.1q-.2-.2-.2-.5-.1-.2-.1-.5t.1-.5q.2-.3.4-.4l.2-.2q.2-.1.3-.1.2 0 .3-.1h.7q.1.1.2.1h.2q.1.1.2.1t.2.1h.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H155h-.1q-.1.1-.2.1t-.2.1l-.2.2q-.1.1-.1.3 0 .2.1.3 0 .2.1.3.1 0 .2.1h.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1l.2.2q.1 0 .2.1 0 .1.1.2 0 .1.1.2v.8q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2 0-.3.1h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM157.6 122.3v-.1l.1-.1v-.1h.1v-.1h.1l.1-.1h.2v.1h.1q.1 0 .1.1l.1.1v.4l-.1.1q0 .1-.1.1h-.4-.1v-.1h-.1v-.1l-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM361.4 118.2v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM370.1 117.6h.6v5.1h-.7v-2.3h-2.8v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M375.4 122.7l-.6-1.5h-2.1l-.5 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M380.9 117.6v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM382.8 117.6v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.2zM386.4 122.3v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1h.8q.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.2-.3q.1-.2.1-.3.1-.1.2-.3.1-.1.2-.1.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1h.8q.1 0 .2.1h.2l.1.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.1 0 .1.1.2h.1q0 .1.1.1.1.1.2.1l.1.1.8.3q.1 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2t.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-.3q-.2.1-.4.1-.1 0-.2-.1h-.5q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1zM390.5 122.3v-.1-.1l.1-.1.1-.1h.1l.1-.1h.1l.1.1h.1v.1h.1q0 .1.1.1v.4q-.1 0-.1.1h-.1v.1h-.1-.1q0 .1-.1 0 0 .1 0 0h-.2l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM362.7 124.5v-.5h4.2v.6h-1.8v4.5h-.6v-4.5zM371.3 124h.7v5.1h-.7v-2.3h-2.9v2.3h-.6V124h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M376.7 129.1l-.6-1.6H374l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M382.1 124v5.1h-.4l-2.9-4.1v4.1h-.6V124h.6l2.7 3.9V124zM384 124v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7V124zM387.7 128.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1l.1-.1q.1-.1.1-.2.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.2-.5-.1-.3-.1-.5 0-.3.1-.5.2-.3.4-.4l.2-.2q.2-.1.3-.1.2 0 .3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H389q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q-.1.1-.1.2V125.4q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1zM391.8 128.7v-.1-.1-.1h.1q0-.1.1-.1v-.1h.4q0 .1.1.1v.1h.1v.1l.1.1v.2h-.1v.1q0 .1-.1.1v.1h-.2-.1-.1-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM361.4 131.7v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM370.1 131.1h.6v5.1h-.7v-2.3h-2.8v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M375.4 136.2l-.6-1.5h-2.1l-.5 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.7 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M380.9 131.1v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.8 3.9v-3.9zM382.8 131.1v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM386.4 135.8v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.1-.2l-.1-.1q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.7-.3h-.3l-.2-.2q-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2.2-.2q.1-.1.3-.2.1-.1.2-.1.2 0 .3-.1h.8q.1.1.2.1h.2q0 .1.1.1t.2.1h.2q0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1 0-.2-.1H387.9q-.1 0-.2.1h-.2q-.1 0-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.2l.1.1.1.1h.1q.1.1.2.1l.1.1.8.3q.1 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2v.3q.1.1.1.2t-.1.3-.1.3q0 .1-.1.2l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H387.8q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1zM390.5 135.8v-.1-.1h.1v-.1q.1 0 .1-.1h.5v.1q.1 0 .1.1h.1v.4q-.1 0-.1.1h-.1v.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM378.5 139.8v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM387.1 139.3h.7v5.1h-.7V142h-2.9v2.4h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M392.5 144.4l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M398 139.3v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM399.8 139.3v2.2l2.4-2.2h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM403.5 144v-.7q.1.1.2.1.1.1.2.1l.2.2h.2q.1.1.2.1t.2.1H405.3q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.1 0-.1-.1-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.3q0-.1.1-.2.1-.2.2-.3l.2-.2q.1 0 .3-.1.1-.1.2-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1h-.5H404.8q-.1 0-.2.1-.1 0-.2.1l-.2.2q0 .1-.1.3 0 .1.1.3 0 .1.1.2t.2.1q0 .1.1.1t.2.1h.1l.8.4q.1 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.2.2.5.1.2 0 .5 0 .3-.1.5t-.3.4q-.2.1-.3.2-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1zM407.6 144v-.1-.1l.1-.1q0-.1.1-.1h.1v-.1h.2l.1.1h.1v.1h.1v.1l.1.1v.2l-.1.1v.1h-.1v.1h-.1-.1-.1-.2q-.1 0-.1-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM358.1 148.8v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM366.7 148.2h.7v5.1h-.7V151h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M372.1 153.3l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M377.6 148.2v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM379.4 148.2v2.3l2.4-2.3h.8l-2.5 2.4 2.5 2.7h-.8l-2.4-2.4v2.4h-.6v-5.1zM383.1 152.9v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1l.1-.1q.1-.1.1-.2.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.2-.2-.5t-.1-.5q0-.3.1-.5.2-.3.4-.4l.2-.2q.2-.1.3-.1.2 0 .3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.7q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q0 .1-.1.2V149.6q0 .1.1.1v.1q.1.1.1.2h.2q0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1l.2.2q.1 0 .2.1 0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2 0 .2-.1.3-.1.1-.3.2l-.2.2q-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM387.2 152.9v-.1-.1l.1-.1q0-.1.1-.1t.1-.1h.1q.1 0 .1.1h.2v.1h.1v.1l.1.1v.2l-.1.1v.1h-.1v.1h-.1-.1q0 .1-.1.1l-.1-.1h-.1q-.1 0-.1-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM380 156.2v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM388.7 155.6h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M394.1 160.7l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M399.5 155.6v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM401.4 155.6v2.3l2.3-2.3h.8L402 158l2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.2zM405 160.3v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.8q.1-.1.2-.1.1-.1.2-.1l.1-.1q.1-.1.1-.2.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.2-.5-.1-.3-.1-.5 0-.3.1-.5.2-.3.4-.5.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.7q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q0 .1-.1.2V157q0 .1.1.1v.1q.1.1.1.2h.2q0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2 0 .2-.1.3-.1.1-.3.2l-.2.2q-.2 0-.3.1h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM409.1 160.3v-.1-.1q.1 0 .1-.1l.1-.1h.1l.1-.1h.1l.1.1h.1l.1.1.1.1v.4l-.1.1-.1.1h-.1-.1q0 .1-.1 0 0 .1 0 0h-.2l-.1-.1q0-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM359.4 163.6v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM368 163h.7v5.1h-.7v-2.3h-2.9v2.3h-.7V163h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M373.4 168.1l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M378.8 163v5.1h-.5l-2.8-4.1v4.1h-.6V163h.6l2.7 3.9V163zM380.7 163v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7V163zM384.4 167.7v-.7q.1.1.2.1 0 .1.1.2.1 0 .2.1h.2q.1.1.2.1.2 0 .3.1h.8q.1-.1.2-.1.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.2-.3v-.2-.2-.3q0-.2.1-.3 0-.1.1-.3.1-.1.3-.1l.2-.2q.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q0 .1.1.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q0 .1-.1.1t-.2.1l-.2.2q-.1.1-.1.3v.3l.2.2.1.1q.1.1.2.1 0 .1.1.1h.2l.8.4q.1 0 .2.1.1 0 .2.1.2.1.3.1.1.1.1.2.2.2.3.5v.5q0 .3-.1.5l-.4.4q-.1.1-.3.2-.1.1-.3.1-.1 0-.3.1h-.8q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1-.2-.1l-.1-.1zM388.4 167.7q.1 0 .1-.1v-.1-.1h.1v-.1h.1q.1 0 .1-.1h.2q0 .1.1.1t.1.1h.1v.2q.1.1 0 .1.1 0 0 .1v.2h-.1q0 .1-.1.1h-.2q-.1.1-.2 0-.1 0-.1-.1-.1 0-.1-.1-.1-.1 0-.2zm.2-1.2l-.2-3.5h.8l-.2 3.5zM362.7 181.4v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM371.3 180.8h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M376.7 185.9l-.6-1.5H374l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M382.1 180.8v5.1h-.4l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM384 180.8v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM387.7 185.5v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2.1 0 .1-.1v-.2-.1-.2q-.1 0-.1-.1t-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.2-.5-.1-.3-.1-.5 0-.3.1-.5.2-.3.4-.4l.2-.2q.2-.1.3-.1.2 0 .3-.1h.7q.1.1.2.1h.2q.1 0 .2.1.1 0 .2.1h.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H389.1h-.1q-.1.1-.2.1t-.2.1l-.2.2q-.1.1-.1.3 0 .2.1.3 0 .2.1.3.1 0 .2.1h.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1l.2.2q.1 0 .2.1 0 .1.1.2 0 .1.1.2v.8q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2 0-.3.1h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM391.8 185.5v-.1-.1l.1-.1q0-.1.1-.1h.1l.1-.1q.1 0 .1.1h.2v.1q.1 0 .1.1t.1.1v.2q-.1 0-.1.1v.1h-.1v.1h-.1q-.1 0-.1.1h-.1-.1q0-.1-.1-.1t-.1-.1h-.1v-.1-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM362.7 188.3v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM371.3 187.8h.7v5.1h-.7v-2.4h-2.9v2.4h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M376.7 192.9l-.6-1.6H374l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M382.1 187.8v5.1h-.4l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.8v-3.8zM384 187.8v2.2l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.3v2.4h-.7v-5.1zM387.7 192.5v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2v-.1-.2-.1l-.1-.1q0-.1-.1-.2-.1 0-.1-.1-.1 0-.2-.1h-.2q0-.1-.1-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.2-.2-.2-.4-.1-.3-.1-.6 0-.2.1-.5l.4-.4q.1-.1.2-.1.2-.1.3-.1.2-.1.3-.1H389.9q.1.1.2.1h.2q.1.1.2.1l.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.9q-.1 0-.2.1-.1 0-.2.1-.1 0-.1.1-.1 0-.1.1t-.1.2V189.2l.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1t.1.1h.2l.8.3q.1.1.2.1.2.1.3.2.1 0 .2.1l.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3-.1.2-.1.3-.1.1-.3.2-.1.1-.2.1-.2.1-.3.2h-1.4q-.1-.1-.2-.1t-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1zM391.8 192.5v-.1-.1-.1h.1q0-.1.1-.1v-.1h.4q0 .1.1.1v.1h.1v.1l.1.1v.2h-.1v.1q0 .1-.1.1l-.1.1h-.1-.1-.1-.1v-.1h-.1l-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM374.2 173.8v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM382.9 173.2h.6v5.1h-.6V176H380v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M388.3 178.3l-.6-1.5h-2.2l-.5 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M393.7 173.2v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM395.6 173.2v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM399.2 177.9v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1q.1-.1.1-.2t-.1-.2v-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.3q.1-.1.1-.2.1-.2.2-.3l.2-.2q.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.2.1-.2.2-.1.1-.1.3v.3l.2.2q0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.6q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.4.1-.1 0-.2-.1h-.5q-.1 0-.2-.1h-.2q0-.1-.1-.1t-.2-.1-.2-.1zM403.3 177.9v-.1-.1q.1 0 .1-.1l.1-.1h.1q0-.1.1-.1h.1l.1.1h.1l.1.1q.1.1.1.2v.3l-.2.2h-.5q0-.1-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5z"
            />
          </g>
        </g>
        <g id="Layer" opacity={0.2}>
          <g id="Layer">
            <path
              id="Layer"
              className="s1"
              d="M197.2 85v-4.9h16.6V85h-5.4v13.5h-5.8V85zM226.9 80.1h5.8v18.4h-5.8v-6.9H221v6.9h-5.8V80.1h5.8v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M247.8 98.5l-1-2.7h-6.5l-1 2.7h-5.7l7-18.4h6l7 18.4zm-4.2-11.6l-1.6 4.2h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M271.7 80.1v18.4h-4.8l-6.7-9.2v9.2h-5.6V80.1h5.5l6 8.5v-8.5zM279.5 80.1v7.7l4.9-7.7h6.7l-5.9 8.8 6.2 9.6h-6.8l-5.1-8.2v8.2h-5.8V80.1zM291.8 97.3v-5.1q.3.2.7.4.3.2.7.3.3.2.7.3.3.2.7.3l.8.2.8.2q.4.1.8.1h1.6q.2 0 .4-.1h.4q.8-.2.8-.8v-.2-.2q-.1 0-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.2l-2.8-.7q-.5-.2-1.1-.3-.5-.2-1-.5-.5-.2-.9-.5-.4-.4-.9-.7-.3-.4-.5-.8-.3-.5-.4-.9-.2-.5-.3-1v-.9q0-.7.1-1.3.1-.7.4-1.3.3-.6.7-1.1.4-.5.9-.8.6-.4 1.3-.7.6-.3 1.2-.5.7-.2 1.4-.3.7-.1 1.4-.1h.9q.4 0 .8.1.5 0 .9.1l.8.2q.4.1.7.2l.6.2q.4.1.7.2.3.2.6.3v5.2l-.6-.4q-.4-.2-.7-.4-.4-.1-.7-.3-.4-.1-.7-.2l-.8-.2q-.3-.1-.7-.2-.4 0-.8-.1H298.3q-.2.1-.4.1t-.3.1q-.6.2-.6.6v.2l.1.1q0 .1.1.2l.1.1q.1.1.2.1.1.1.2.1l.1.1h.2l2.7.7q.5.2 1.1.4.5.2 1 .5.5.2 1 .6.5.3.9.7l.6.8q.2.4.4.9.1.5.2.9.1.5.1 1 0 .7-.1 1.4-.2.7-.5 1.3-.3.6-.8 1.1-.4.5-1 .9-.7.3-1.3.6-.7.3-1.4.4-.7.2-1.5.3-.7.1-1.4.1-.4 0-.9-.1h-.8q-.5-.1-.9-.2t-.9-.2q-.3-.1-.7-.2-.3-.1-.7-.2-.3-.1-.6-.3-.4-.1-.7-.3zM308 92.1l-1.2-12h6.9l-1.2 12zm-1 3.6q0-.3.1-.6 0-.3.1-.6.2-.3.3-.5.2-.3.4-.5.5-.4 1.1-.6.6-.2 1.2-.2.7 0 1.3.2.6.2 1 .6.5.5.7 1 .3.6.3 1.2t-.3 1.2q-.2.5-.7.9-.4.4-1 .7-.6.2-1.3.2-.6 0-1.2-.2-.6-.3-1.1-.7l-.4-.4q-.1-.3-.3-.5-.1-.3-.1-.6-.1-.3-.1-.6zM71.2 85v-4.9h16.7V85h-5.5v13.5h-5.8V85zM100.9 80.1h5.8v18.4h-5.8v-6.9h-5.8v6.9h-5.8V80.1h5.8v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M121.8 98.5l-1-2.7h-6.5l-1 2.7h-5.6l7-18.4h6l7 18.4zm-4.2-11.6l-1.6 4.2h3.1z"
            />
            <path
              id="Layer"
              className="s1"
              d="M145.8 80.1l-.1 18.4H141l-6.7-9.2v9.2h-5.6V80.1h5.4l6.1 8.5v-8.5zM153.5 80.1v7.7l5-7.7h6.6l-5.8 8.8 6.1 9.6h-6.7l-5.2-8.2v8.2h-5.8V80.1zM165.8 97.3v-5.1q.4.2.7.4.4.2.7.3.4.2.7.3.4.2.8.3.3.1.7.2l.8.2q.4.1.8.1H172.7q.2 0 .4-.1h.4q.7-.2.7-.8v-.2-.2l-.1-.1q0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.1-.1h-.2l-2.9-.7q-.5-.2-1-.3-.5-.2-1-.5-.5-.2-1-.5-.4-.4-.8-.7l-.6-.8q-.2-.5-.4-.9l-.2-1q-.1-.5-.1-1 0-.6.1-1.2.2-.7.4-1.3.3-.6.7-1.1.5-.5 1-.8.6-.4 1.2-.7.6-.3 1.3-.5.7-.2 1.4-.3.7-.1 1.3-.1h.9q.4 0 .9.1.4 0 .8.1.5.1.9.2l.6.2q.4.1.7.2l.6.2q.3.2.6.3v5.2l-.6-.4q-.3-.2-.7-.4-.3-.1-.7-.3-.3-.1-.7-.2-.4-.1-.7-.2l-.8-.2q-.3 0-.7-.1h-1.5q-.2.1-.3.1-.2 0-.4.1-.5.2-.5.6V85.6q.1.1.1.2.1 0 .1.1.1.1.2.1.1.1.2.1t.2.1h.2l2.6.7q.6.2 1.1.4.6.2 1.1.5.5.2.9.6.5.3.9.7l.6.8q.2.4.4.9t.3.9v1q.1.7-.1 1.4-.1.7-.4 1.3-.4.6-.8 1.1-.5.5-1.1.9-.6.3-1.3.6-.7.3-1.4.4-.7.2-1.4.3-.8.1-1.5.1-.4 0-.9-.1h-.8q-.5-.1-.9-.2t-.9-.2q-.3-.1-.7-.2l-.6-.2q-.4-.1-.7-.3-.3-.1-.7-.3zM182 92.1l-1.2-12h6.9l-1.2 12zm-1 3.6q0-.3.1-.6 0-.3.1-.6.2-.3.3-.5.2-.3.4-.5.5-.4 1.1-.6.6-.2 1.2-.2.7 0 1.3.2.5.2 1 .6.5.5.7 1 .3.6.3 1.2t-.3 1.2q-.2.5-.7.9-.5.4-1 .7-.6.2-1.3.2-.6 0-1.2-.2-.6-.3-1.1-.7l-.4-.4q-.1-.3-.3-.5-.1-.3-.1-.6-.1-.3-.1-.6zM197.7 142.2v-5.5h18.6v5.5h-6.1v15h-6.4v-15zM230.8 136.7h6.5v20.5h-6.5v-7.6h-6.5v7.6h-6.4v-20.5h6.4v7.4h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M254.1 157.2l-1-3h-7.3l-1 3h-6.4l7.8-20.5h6.7l7.8 20.5zm-4.7-12.9l-1.7 4.7h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M280.8 136.7v20.5h-5.3L268 147v10.3h-6.3v-20.6h6.1l6.8 9.6v-9.6zM289.5 136.7v8.6l5.5-8.6h7.4l-6.5 9.8 6.8 10.7h-7.5l-5.7-9v9H283v-20.5zM303.2 156v-5.7l.8.4q.4.2.7.4.4.2.8.3.4.2.9.3.4.2.8.3.4.1.9.2.4 0 .9.1h1.8q.2 0 .5-.1h.4q.9-.2.9-.9v-.2q0-.1-.1-.2 0-.1-.1-.2 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2l-3.2-.8q-.6-.2-1.1-.4-.6-.2-1.2-.5l-1-.6-1-.8q-.3-.4-.6-.9t-.4-1q-.2-.5-.3-1-.1-.6 0-1.1-.1-.7.1-1.4.1-.7.4-1.4.3-.7.8-1.2.5-.6 1.1-1 .6-.4 1.3-.8.7-.3 1.4-.5.8-.3 1.6-.4.7-.1 1.5 0h1.9l1 .2q.5.1.9.2l.8.2q.3.1.7.2.3.2.7.3.3.2.7.3v5.8q-.4-.3-.7-.5l-.8-.4q-.3-.1-.7-.3-.4-.1-.8-.3l-.8-.2-.8-.2q-.5 0-.9-.1h-1.6q-.2.1-.4.1t-.4.1q-.6.2-.6.7v.2q0 .1.1.2 0 .1.1.1 0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.3l2.9.8 1.2.4q.6.2 1.2.5t1.1.7q.5.4 1 .9l.6.8q.3.5.5 1t.3 1.1v1.1q.1.7-.1 1.5-.1.8-.5 1.4-.3.7-.9 1.3-.5.6-1.2.9-.7.5-1.5.8-.7.3-1.5.5t-1.6.3q-.9.1-1.7 0h-1q-.5 0-.9-.1-.5 0-1-.1t-.9-.3q-.4 0-.8-.2l-.8-.2q-.4-.2-.8-.3-.3-.2-.7-.4zM321.2 150.1l-1.3-13.4h7.7l-1.4 13.4zm-1.1 4q0-.3.1-.7l.2-.6q.1-.3.3-.6l.5-.5q.5-.4 1.2-.7.6-.2 1.3-.2.7 0 1.4.2.7.3 1.2.7.5.5.8 1.1.3.6.3 1.3 0 .7-.3 1.3-.3.6-.8 1.1-.5.5-1.2.7-.7.3-1.4.3t-1.3-.3q-.7-.2-1.2-.7-.3-.2-.5-.5t-.3-.6l-.2-.6q-.1-.3-.1-.7zM65.3 142.2v-5.5h18.5v5.5h-6v15h-6.5v-15zM98.4 136.7h6.5v20.5h-6.5v-7.6h-6.5v7.6h-6.5v-20.5h6.5v7.4h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M121.7 157.2l-1.1-3h-7.2l-1.1 3H106l7.7-20.5h6.8l7.7 20.5zm-4.7-12.9l-1.7 4.7h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M148.4 136.7v20.5h-5.3l-7.5-10.2v10.3h-6.3v-20.6h6l6.8 9.6v-9.6zM157.1 136.7v8.6l5.5-8.6h7.4l-6.6 9.8 6.9 10.7h-7.5l-5.7-9v9h-6.5v-20.5zM170.8 156v-5.7q.4.2.7.4l.8.4q.4.2.8.3.4.2.8.3.4.2.9.3.4.1.9.2.4 0 .9.1h1.8q.2 0 .4-.1h.5q.9-.2.8-.9.1-.1 0-.2v-.2q0-.1-.1-.2-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.3l-3.1-.8-1.2-.4q-.5-.2-1.1-.5l-1-.6-1-.8q-.3-.4-.6-.9t-.5-1l-.2-1q-.1-.6-.1-1.1 0-.7.1-1.4.2-.7.5-1.4t.8-1.2q.4-.6 1-1l1.4-.8q.7-.3 1.4-.5.8-.3 1.5-.4.8-.1 1.6 0h1.9l1 .2q.4.1.9.2.4.1.7.2l.8.2q.3.2.7.3.3.2.7.3v5.8q-.4-.3-.8-.5-.3-.2-.7-.4-.4-.1-.8-.3-.3-.1-.7-.3l-.8-.2q-.5-.1-.9-.2-.4 0-.8-.1h-1.6q-.2.1-.4.1t-.4.1q-.6.2-.6.7v.2q0 .1.1.2 0 .1.1.1 0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2l3 .8 1.2.4q.6.2 1.2.5.5.3 1.1.7.5.4 1 .9l.6.8q.3.5.5 1t.2 1.1q.1.5.1 1.1.1.7-.1 1.5-.2.7-.5 1.4-.4.7-.9 1.2-.5.6-1.2 1t-1.5.7q-.7.3-1.5.5t-1.7.3q-.8.1-1.6.1h-1q-.5-.1-1-.1-.4-.1-.9-.2l-1-.2-.8-.2q-.3-.1-.7-.3-.4-.1-.8-.3-.3-.1-.7-.3zM188.8 150.1l-1.4-13.4h7.8l-1.4 13.4zm-1.1 4q0-.3.1-.7 0-.3.2-.6.1-.3.3-.6l.5-.5q.5-.4 1.2-.7.6-.2 1.3-.2.7 0 1.4.2.6.3 1.2.7.5.5.8 1.1.2.6.2 1.3 0 .7-.2 1.3-.3.6-.8 1.1-.6.5-1.2.7-.7.3-1.4.3t-1.3-.3q-.7-.2-1.2-.7-.3-.2-.5-.5t-.3-.6q-.2-.3-.2-.6-.1-.3-.1-.7zM70.1 152.5v-.6h4.2v.6h-1.7v4.6h-.7v-4.6zM78.8 151.9h.6v5.2h-.6v-2.4h-2.9v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M84.2 157.1l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.6l2 5.2zm-1.6-4.3l-.9 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M89.6 151.9v5.2h-.5l-2.9-4.1v4.1h-.6V152h.6l2.8 3.9V152zM91.5 151.9v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.9l-2.3-2.5v2.5h-.7v-5.2zM95.1 156.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H97.2q.1-.1.2-.1l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.2 0-.3-.1-.1 0-.1-.1-.1-.1-.2-.1-.2-.2-.3-.5-.1-.3-.1-.5 0-.3.2-.5.1-.3.3-.4.1-.1.3-.2.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2l.1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.1.1q-.1 0-.2.1 0 .1-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2t.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-.3q-.2.1-.4 0-.1.1-.2 0H96q-.1 0-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1zM99.2 156.7v-.1-.1l.1-.1v-.1h.1q0-.1.1-.1H99.8q.1 0 .1.1h.1v.1l.1.1V156.8l-.1.1v.1h-.1l-.1.1h-.1-.2l-.1-.1h-.1v-.1l-.1-.1v-.1zm.2-1.2l-.1-3.6h.7l-.2 3.6zM58.2 146.7v-.6h4.2v.6h-1.8v4.5H60v-4.5zM66.8 146.1h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M72.2 151.2l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M77.7 146.1v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6L77 150v-3.9zM79.5 146.1v2.2l2.4-2.2h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM83.2 150.8v-.7q.1.1.2.1.1.1.2.1l.2.2h.2q.1.1.2.1h.2q.1.1.2.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2.1-.1.1-.2v-.1-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.1 0-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2v-.3-.3q0-.1.1-.2.1-.2.2-.3l.2-.2q.1-.1.2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-.2q-.1-.1-.2-.1h-.7q-.1 0-.2.1-.1 0-.2.1l-.1.1q-.1 0-.1.1t-.1.2V147.5l.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1t.1.1h.2l.8.4h.2q.2.1.3.2.1 0 .2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-1.4q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1zM87.3 150.8v-.1-.1-.1h.1q.1-.2.3-.2.2 0 .3.2.2.1.2.3 0 .2-.2.3-.1.1-.2.1-.1.1-.3 0l-.2-.2v-.2zm.2-1.2l-.2-3.5h.8l-.2 3.5zM58.2 139.8v-.6h4.2v.6h-1.8v4.5H60v-4.5zM66.8 139.2h.7v5.1h-.7V142h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M72.2 144.3l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M77.7 139.2v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM79.5 139.2v2.3l2.4-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM83.2 143.9v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H85q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.2-.3q0-.2.1-.3.1-.1.2-.3.1-.1.2-.1l.2-.2q.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.7q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q0 .1-.1.2V140.6q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM87.3 143.9v-.1-.1-.1h.1l.1-.1q.1-.1.2-.1t.2.1q.1 0 .1.1h.1v.1l.1.1v.2l-.1.1q0 .1-.1.1 0 .1-.1.1h-.4l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM70.1 131.6v-.6h4.2v.6h-1.7v4.5h-.7v-4.5zM78.8 131h.6v5.1h-.6v-2.3h-2.9v2.3h-.7V131h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M84.2 136.1l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M89.6 131v5.1h-.5l-2.9-4v4h-.6V131h.6l2.8 3.9V131zM91.5 131v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7V131zM95.1 135.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1H97.2q.1-.1.2-.1l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1h-.1q-.1-.1-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.2t-.1-.2-.1-.2v-.3-.3q.1-.1.1-.2.1-.2.2-.3l.2-.2q.1-.1.3-.1.1-.1.2-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1h-.8q-.1 0-.2.1-.1 0-.2.1l-.1.1q-.1 0-.1.1v.2l-.1.1v.2q.1 0 .1.1t.1.1v.1q.1.1.2.1 0 .1.1.1t.2.1h.1l.8.4h.3l.2.2q.1 0 .2.1l.2.2q0 .1.1.2t.1.2v.2q.1.2 0 .3.1.1 0 .3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2H97q-.1.1-.3.1-.1 0-.2-.1H96q-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1l-.2-.2zM99.2 135.7v-.1l.1-.1v-.1h.1q0-.1.1-.1H99.8q.1 0 .1.1h.1v.1l.1.1V135.9q-.1 0-.1.1l-.1.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.2 3.5zM50.1 125.7v-.5h4.3v.5h-1.8v4.6h-.7v-4.6zM58.8 125.2h.6v5.1h-.6v-2.4h-2.9v2.4h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M64.2 130.3l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.6l2 5.2zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M69.6 125.2v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM71.5 125.2v2.2l2.3-2.2h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM75.1 129.9v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.5q.1 0 .2-.1h.2l.1-.1.2-.2.1-.1v-.2-.1-.2-.1q0-.1-.1-.1 0-.1-.1-.2l-.1-.1q-.1 0-.2-.1h-.1q-.1-.1-.2-.1l-.7-.3q-.1 0-.2-.1-.2 0-.3-.1l-.1-.1-.2-.2-.2-.2q-.1-.1-.1-.2t-.1-.2v-.3-.3q.1-.1.1-.2.1-.2.2-.3l.2-.2q.1-.1.3-.1.1-.1.3-.1.1-.1.3-.1H77.4q.1.1.2.1h.1q.1.1.2.1t.2.1q.1 0 .1.1v.7q0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1h-1q-.1 0-.2.1h-.1l-.2.2-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1 0 .2.1h.1l.8.3q.2.1.3.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2t.1.2v.2q0 .2-.1.3 0 .2-.1.3 0 .1-.1.3-.1.1-.2.1-.2.1-.3.2-.1.1-.3.1-.2.1-.3.1h-.8q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.1q-.1-.1-.2-.1-.1-.1-.2-.1zM79.2 129.9v-.1-.1l.1-.1v-.1h.1l.1-.1H79.8l.1.1h.1v.1q.1 0 .1.1V130q0 .1-.1.1v.1h-.1q0 .1-.1.1h-.1-.1-.1q-.1 0-.2-.1 0-.1-.1-.2v-.1zm.2-1.2l-.1-3.6h.7l-.2 3.6z"
            />
            <path
              id="Layer"
              className="s3"
              d="M110.9 144.8v-.4h2.7v.3h-1.2v2.9h-.4v-2.9zM116.3 144.4h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M119.6 147.6l-.3-1H118l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M123 144.4v3.2h-.3l-1.8-2.6v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM124.2 144.4v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.5v-3.2zM126.4 147.3v-.4q.1.1.2.1.1.1.3.1.1.1.2.1H127.8q0-.1.1-.1l.1-.1q.1-.1.1-.2v-.2q-.1-.1-.1-.2h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2h.2l.1-.1h.2q.1-.1.2-.1h.1q.1.1.2.1H128l.1.1h.1l.1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H127.5h-.1H127.1l-.1.1q-.1 0-.1.1h-.1v.4q0 .1.1.1l.1.1q.1 0 .1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V146.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.5l-.1-.1h-.2-.1q0-.1-.1-.1h-.1q0-.1-.1-.1zM128.9 147.3v-.1h.1v-.1h.1v-.1h.2q0 .1.1.1l.1.1v.2l-.1.1-.1.1h-.1-.1l-.1-.1q0-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM105.4 141.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM110.8 141.1h.4v3.2h-.4v-1.4H109v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M114.1 144.3l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M117.5 141.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM118.6 141.1v1.4l1.4-1.4h.6l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM120.9 144.1v-.5q0 .1.1.1l.1.1h.1l.1.1h.2q0 .1.1.1h.5l.1-.1h.2v-.1l.1-.1v-.1q.1-.1 0-.1.1-.1 0-.1v-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1t-.1-.3-.1-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1h.2q.1 0 .2.1h.1v.4h-.1q-.1 0-.2-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.1q0 .1.1.1v.1l.1.1.1.1h.2l.5.2.1.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H121.5l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM123.4 144.1v-.1-.1h.1v-.1H123.8q.1 0 .1.1t.1.2q-.1 0-.1.1v.1h-.1H123.5q0-.1-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM100.8 92.7v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM106.2 92.3h.4v3.2h-.4V94h-1.8v1.4h-.4v-3.1h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M109.5 95.5l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M112.9 92.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM114.1 92.3v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM116.3 95.2v-.4q.1 0 .1.1h.1q.1.1.2.1l.1.1H117.1l.1.1h.2q.1-.1.2-.1h.1q.1 0 .1-.1h.1v-.1l.1-.1v-.1-.1-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H117.4h-.1H117l-.1.1q-.1 0-.1.1h-.1v.4l.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.5l-.1-.1h-.2l-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM118.8 95.2q0-.1.1-.1V95h.1q0-.1.1-.1h.1v.1h.1q0 .1.1.1v.2l-.1.1v.1H119l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM109.6 86.5v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM115 86.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M118.3 89.3l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M121.7 86.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM122.8 86.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM125.1 89v-.4q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1h.2q.1.1.2.1h.2q.1 0 .2-.1h.1l.1-.1q.1 0 .1-.1.1-.1.1-.2v-.2l-.1-.1-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H126.6l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1h-1q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1zM127.6 89v-.1q.1 0 .1-.1h.1v-.1h.2l.1.1.1.1v.2q-.1 0-.1.1l-.1.1h-.1-.1l-.1-.1q0-.1-.1-.1V89zm.2-.7l-.1-2.2h.4l-.1 2.2zM89.8 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM95.1 88.1h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M98.5 91.3l-.4-1h-1.3l-.4 1H96l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M101.8 88.1v3.2h-.3l-1.7-2.6v2.6h-.4v-3.2h.4l1.6 2.4v-2.4zM103 88.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM105.3 91v-.4h.1q0 .1.1.1 0 .1.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1t-.1-.1-.1-.1-.1-.1l-.1-.1V89q-.1-.1 0-.1-.1-.1 0-.2v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1H106.9l.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.5l-.1.1h-.1v.1q-.1 0-.1.1v.1q-.1 0 0 .1-.1 0 0 0v.2l.1.1h.1v.1h.1q.1 0 .1.1l.5.2h.2l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2v.1l-.2.2q-.1 0-.2.1h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM107.8 91v-.1-.1h.1v-.1H108.2v.1h.1v.4h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM89.8 82.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM95.1 81.7h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M98.5 84.9l-.4-1h-1.3l-.4 1H96l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M101.8 81.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM103 81.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM105.3 84.6v-.4l.1.1.1.1h.1q.1 0 .1.1H106l.1.1H106.4l.1-.1h.1q.1 0 .1-.1.1 0 .2-.1v-.2-.2q0-.1-.1-.2h-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.2q-.1 0 0-.1-.1-.1 0-.2v-.2q.1 0 .1-.1l.1-.1q.1-.1.2-.1.1-.1.2-.1H106.8l.1.1h.1l.1.1h.1v.5l-.1-.1-.1-.1h-.1l-.1-.1h-.2l-.1-.1h-.5q0 .1-.1.1h-.1q0 .1-.1.2v.2q-.1.1 0 .1 0 .1.1.2l.1.1q.1 0 .1.1h.1l.5.2h.2q0 .1.1.1l.1.1q.1.1.2.1v.1q.1.1.1.2v.4q0 .1-.1.2v.2q-.1 0-.2.1t-.2.1l-.1.1H105.8l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1zM107.8 84.6v-.1q0-.1.1-.1t.1-.1q.1 0 .2.1h.1v.4h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM89.8 95.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM95.1 95.2h.4v3.2h-.4V97h-1.8l.1 1.4h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M98.5 98.4l-.4-1h-1.3l-.4 1H96l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M101.8 95.2v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM103 95.2v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM105.3 98.2v-.5q0 .1.1.1l.1.1h.1l.1.1h.2l.1.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1-.1l-.4-.2q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1h.7q.1 0 .2.1h.2l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.2q-.1.1-.2.1h-.1l-.1.1-.1.1v.1q-.1.1 0 .1-.1.1 0 .1v.2h.1l.1.1.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.2.2v.1q.1 0 .1.1v.5l-.1.1q0 .1-.1.2 0 .1-.1.1-.1.1-.2.1 0 .1-.1.1H105.8h-.1l-.1-.1h-.1l-.1-.1h-.1zM107.8 98.1V98l.1-.1h.4v.4l-.1.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM112.6 80v-.4h2.6v.4h-1.1v2.8h-.4V80zM117.9 79.6h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4V81h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M121.3 82.8l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M124.6 79.6v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM125.8 79.6V81l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM128 82.5v-.4q.1 0 .1.1.1 0 .1.1h.1q.1 0 .2.1h.2q.1.1.2.1h.2q.1-.1.2-.1h.1l.1-.1q.1 0 .1-.1.1-.1.1-.2t-.1-.2v-.2h-.1l-.1-.1h-.1l-.1-.1-.5-.1-.1-.1q-.1 0-.2-.1l-.1-.1h-.1q0-.1-.1-.2l-.1-.1v-.2-.1-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H129.5q.1 0 .1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1 0-.1-.1-.1h-.1q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.2l-.1.1h-.1l-.2.2V80.5q0 .1.1.2.1 0 .1.1h.1l.1.1h.1l.5.2h.1l.1.1q.1.1.2.1 0 .1.1.1 0 .1.1.1v.2l.1.1v.2q0 .1-.1.1v.2q-.1.1-.1.2-.1 0-.1.1-.1.1-.2.1t-.2.1H128.6q-.1 0-.2-.1h-.1-.1l-.1-.1q-.1 0-.1-.1zM130.6 82.5v-.1-.1h.1q.1 0 .1-.1.1 0 .1.1h.1l.1.1V82.7h-.1v.1h-.1-.1-.1q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM129.8 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM135.2 88.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M138.5 91.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M141.9 88.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM143 88.1v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM145.3 91v-.4l.1.1h.1l.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1q0-.1-.1-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1t-.1-.1V89v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1h.1q.1 0 .1.1h.4l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H146.4h-.1-.2q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1V89q0 .1.1.1v.1h.1q0 .1.1.1h.1l.1.1.5.2h.1l.1.1q.1 0 .2.1l.1.1q0 .1.1.1v.2l.1.1v.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM147.8 91v-.1l.1-.1.1-.1h.2q0 .1.1.1v.1q.1 0 .1.1v.1h-.1v.1h-.1v.1h-.1-.1q0-.1-.1-.1l-.1-.1V91zm.1-.7v-2.2h.4l-.1 2.2zM129.8 95.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM135.2 95.3h.4v3.2h-.4V97h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M138.5 98.5l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M141.9 95.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM143 95.3v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5L143 97v1.5h-.4v-3.2zM145.3 98.2v-.4q.1.1.2.1.1.1.3.2h.2q.2.1.3.1.1 0 .1-.1H146.7l.1-.1.1-.1q0-.1.1-.1v-.1-.1q0-.1-.1-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1l-.1-.1v-.2-.1-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1l.1.1h.4l.1.1h.1q.1 0 .1.1h.1v.4h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.2l-.1.1h-.1l-.1.1q-.1 0-.1.1v.4l.1.1h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1l.1.1q.1.1.2.1 0 .1.1.1 0 .1.1.1v.2l.1.1v.2l-.1.1v.2q-.1.1-.1.2-.1 0-.2.1l-.1.1q-.1 0-.2.1H146.1h-.2q-.1 0-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1zM147.8 98.2v-.1h.1V98h.1v-.1h.2q0 .1.1.1v.1l.1.1v.1h-.1v.1h-.1v.1h-.1-.1l-.1-.1-.1-.1v-.1zm.1-.7v-2.2h.4l-.1 2.2zM138 82.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM143.3 81.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M146.7 84.9l-.4-1H145l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M150 81.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM151.2 81.7v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM153.4 84.6v-.4l.2.2q.2 0 .3.1h.3q.1.1.2.1h.2q0-.1.1-.1h.1q.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.2q-.1-.1-.1-.2h-.1l-.1-.1h-.1q0-.1-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1-.1-.1h-.2q0-.1-.1-.2v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2l.1-.1.1-.1q.1-.1.2-.1.1-.1.2-.1H154.9q.1 0 .1.1h.1q.1 0 .2.1h.1v.5l-.1-.1-.1-.1h-.2q0-.1-.1-.1h-.1l-.1-.1H154.4h-.1-.1q0 .1-.1.1h-.1q0 .1-.1.1v.1q-.1 0-.1.1V82.7h.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1.1-.2.1t-.2.1h-.4H154.1h-.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM156 84.6v-.1l.1-.1h.1v-.1h.1l.1.1h.1v.2h.1l-.1.1v.1l-.1.1H156.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM162 80v-.4h2.6v.4h-1.1v2.8h-.4V80zM167.3 79.6h.5v3.2h-.5v-1.5h-1.7v1.5h-.5v-3.2h.5V81h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M170.7 82.8l-.4-1H169l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M174.1 79.6v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM175.2 79.6V81l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM177.5 82.5v-.4l.2.2q.1 0 .2.1h.3q.1.1.3.1h.1q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1V82q.1-.1 0-.2 0-.1-.1-.2h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.4-.1q-.1-.1-.2-.1l-.1-.1-.1-.1h-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.2-.1-.2-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1h.9l.1.1h.1l.1.1h.1v.5l-.1-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.4-.1q0 .1-.1.1l-.1.1v.1h-.1V80.5l.1.1v.1h.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q-.1.1-.2.1h-.2H178.1l-.1-.1h-.2l-.1-.1h-.1q-.1 0-.1-.1zM180 82.5v-.1l.1-.1h.1q0-.1.1-.1l.1.1h.1v.2h.1l-.1.1v.1l-.1.1h-.1-.2v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM151 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM156.4 88.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M159.7 91.3l-.4-1H158l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M163.1 88.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM164.2 88.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM166.5 91v-.4l.1.1h.1l.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1q0-.1-.1-.1v-.1q0-.1-.1-.1h-.1V90h-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1t-.1-.1V89v-.1-.2-.2l.1-.1q.1-.1.2-.1l.1-.1q.1-.1.2-.1h.2q.1-.1.2-.1h.1q.1 0 .1.1h.4l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H167.3q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1v.4q.1 0 .1.1h.1q0 .1.1.1h.1l.1.1.5.2h.1l.1.1q.1 0 .2.1l.1.1q0 .1.1.1V90.3l.1.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM169 91v-.1-.1h.1q0-.1.1-.1h.2v.1h.1v.1l.1.1q0 .1-.1.1v.1q-.1 0-.1.1h-.2l-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM151 94.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM156.4 94.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M159.7 97.3l-.4-1H158l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M163.1 94.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM164.2 94.2v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM166.5 97.1v-.4h.1l.1.1q.1 0 .1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1-.1q-.1 0-.1-.1t-.1-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2q.1 0 .1-.1h.2q.1-.1.2-.1h.4q.1 0 .2.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.4l-.1.1q-.1 0-.1.1-.1 0-.1.1v.4q.1 0 .1.1h.1l.1.1h.1l.6.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1V96.4q.1 0 .1.1t-.1.2v.2l-.1.1q-.1.1-.2.1 0 .1-.1.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1l-.1-.1h-.1l-.1-.1zM169 97.1V97v-.1h.1q0-.1.1-.1h.2v.1h.1v.1l.1.1-.1.1v.1q-.1 0-.1.1h-.2v-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM172.6 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM177.9 88.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M181.2 91.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M184.6 88.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM185.8 88.1l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM188 91v-.4q.1 0 .1.1h.1l.1.1h.2q0 .1.1.1H189.4q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1q0-.1-.1-.1v-.1-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1-.1-.1q0-.1-.1-.1V89v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1l.2-.2h.1q.1-.1.2-.1H189.4l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.4.2q.1 0 .2.1.1 0 .1.1.1 0 .2.1l.1.1.1.1v.2q.1.1 0 .2.1.1 0 .2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.2q-.1.1-.2.1h-.2l-.1-.1H188.4l-.1-.1h-.2l-.1-.1zM190.5 91v-.1h.1v-.1h.1v-.1h.2l.1.1.1.1v.2q0 .1-.1.1l-.1.1h-.2v-.1h-.1v-.1h-.1V91zm.2-.7l-.1-2.2h.4v2.2zM181.3 80v-.4h2.7v.4h-1.1v2.8h-.4V80zM186.7 79.6h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4V81h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M190 82.8l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M193.4 79.6v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4L193 82v-2.4zM194.5 79.6V81l1.5-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.5-1.5v1.5h-.4v-3.2zM196.8 82.5v-.4q.1 0 .1.1.1 0 .1.1h.2l.1.1h.2q.1.1.2.1h.2q.1-.1.2-.1h.1l.1-.1q.1 0 .1-.1.1-.1.1-.2v-.2q-.1-.1-.1-.2h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1.1 0 .1-.1h.2q.1-.1.2-.1H198.2q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4l-.1.1q-.1 0-.2.1v.4q0 .1.1.2h.1l.1.1h.2l.4.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V81.9q.1 0 0 .1.1.1 0 .2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4-.1l-.1-.1h-.2l-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM199.3 82.5v-.1h.1v-.1h.1v-.1h.1q.1 0 .1.1h.1q0 .1.1.1v.2l-.1.1v.1h-.2-.1-.1v-.1l-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM193.6 86.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM198.9 86.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M202.2 89.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M205.6 86.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM206.8 86.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.5v-3.2zM209 89v-.4l.2.2q.1 0 .3.1h.2q.2.1.3.1h.1q.1 0 .2-.1h.1l.1-.1q.1 0 .1-.1.1-.1.1-.2t-.1-.2v-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1 0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H210.5l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1h-.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.1 0 .1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1h-1q0-.1-.1-.1t-.1-.1h-.1l-.1-.1zM211.5 89v-.1h.1v-.1h.1v-.1h.2q0 .1.1.1l.1.1v.2q0 .1-.1.1l-.1.1h-.2v-.1h-.1v-.1h-.1V89zm.2-.7l-.1-2.2h.4l-.1 2.2zM194.9 92.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM200.2 92.3h.4v3.2h-.4V94h-1.8v1.4h-.4v-3.1h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M203.5 95.5l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M206.9 92.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM208.1 92.3v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM210.3 95.2v-.4q.1 0 .1.1h.1q.1.1.2.1l.1.1H211.1l.1.1h.2q.1-.1.2-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H211.4h-.1H211l-.1.1q-.1.1-.2.1v.4q.1.1.2.1v.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2H211q0-.1-.1-.1h-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1zM212.8 95.2q0-.1.1-.1V95h.1q0-.1.1-.1h.1v.1h.1l.1.1v.2q0 .1-.1.1v.1H213l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4v2.2zM215.4 86.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM220.7 86.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M224.1 89.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M227.4 86.1v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.4l1.6 2.4v-2.4zM228.6 86.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.5-1.5v1.5h-.4v-3.2zM230.8 89v-.4l.2.2q.2 0 .3.1h.3q.1.1.2.1h.2l.1-.1h.1q.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.2l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1-.1-.2-.1-.3-.1-.2-.1-.3 0-.2.1-.4l.2-.2q.1-.1.2-.1.1-.1.2-.1H232.3l.1.1h.1q.1 0 .2.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1h-.1-.1l-.1-.1H231.7l-.1.1h-.1l-.1.1h-.1v.1q-.1 0-.1.1V87.1h.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H231.5h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1q0-.1-.1-.1zM233.3 89q.1 0 .1-.1v-.1h.1v-.1h.2l.1.1h.1v.4h-.1q0 .1-.1.1h-.2v-.1h-.1v-.1l-.1-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM202.7 80v-.4h2.6v.4h-1.1v2.8h-.4V80zM208 79.6h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4V81h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M211.3 82.8l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M214.7 79.6v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM215.9 79.6V81l1.4-1.4h.5l-1.6 1.5 1.7 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM218.1 82.5v-.4q.1 0 .1.1.1 0 .2.1h.1l.1.1H218.9l.1.1H219.3l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1q0-.1-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1-.1-.1h-.2q0-.1-.1-.2v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2l.1-.1.1-.1q.1-.1.2-.1.1-.1.2-.1H219.6q.1 0 .1.1h.2l.1.1h.1v.5l-.1-.1-.1-.1h-.2q0-.1-.1-.1h-.1l-.1-.1h-.4-.1q0 .1-.1.1h-.1q0 .1-.1.1v.1q-.1 0-.1.1V80.6h.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2h.1q.1.1.2.1l.1.1.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1.1-.2.1t-.2.1h-.4H218.8h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1zM220.7 82.5v-.1-.1h.1l.1-.1h.1v.1h.1l.1.1V82.7h-.1v.1h-.1-.2q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM223.8 80v-.4h2.6v.4h-1.1v2.8h-.4V80zM229.2 79.6h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4V81h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M232.5 82.8l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M235.9 79.6v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM237 79.6V81l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM239.3 82.5v-.4q.1 0 .1.1.1 0 .1.1h.1q.1 0 .2.1h.2q.1 0 .2.1h.2q.1 0 .1-.1h.2l.1-.1h.1v-.1q.1 0 .1-.1V82v-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.1q.1-.1.1-.2t.1-.2l.1-.1q.1 0 .2-.1.1 0 .2-.1h.7l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.1-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.2l.1.1.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1H240.2q-.1 0-.2-.1H239.7l-.1-.1h-.1q0-.1-.1-.1t-.1-.1zM241.8 82.5v-.1q.1 0 .1-.1h.1q0-.1.1-.1l.1.1h.1q0 .1.1.1v.2l-.1.1-.1.1h-.1-.1-.1v-.1q-.1 0-.1-.1v-.1zm.1-.7v-2.2h.4l-.1 2.2zM236.2 86.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM241.5 86.1h.5v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M244.9 89.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M248.2 86.1v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM249.4 86.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM251.7 89v-.4l.1.1.1.1h.1l.1.1H252.4l.1.1H252.8q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H253.2q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1H252.9q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2l-.1.1-.1.1v.4l.1.1.1.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.2.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1l-.1.1q-.1.1-.2.1H252.2l-.1-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM254.2 89v-.1q0-.1.1-.1l.1-.1h.2q0 .1.1.1v.4h-.1v.1h-.2q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM215.4 92.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM220.7 92.3h.4v3.2h-.4V94h-1.8v1.4h-.4v-3.1h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M224.1 95.5l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M227.4 92.3v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.4l1.6 2.4v-2.4zM228.6 92.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM230.8 95.2v-.4q.1 0 .1.1h.2q0 .1.1.1l.1.1H231.6l.1.1H232q0-.1.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1q0-.1-.1-.1l-.5-.2h-.1q-.1 0-.1-.1-.1 0-.1-.1h-.2q0-.1-.1-.2v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2l.1-.1.1-.1q.1-.1.2-.1.1-.1.2-.1H232.3q.1 0 .1.1h.1q.1 0 .2.1l.1.5q-.1 0-.1-.1h-.1q-.1-.1-.2-.1h-.1q0-.1-.1-.1h-.5-.1-.1q0 .1-.1.1h-.1v.1l-.1.1V93.3q.1 0 .1.1l.1.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2H231.5q0-.1-.1-.1h-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1zM233.3 95.2h.1v-.1-.1h.1q0-.1.1-.1h.1v.1h.1l.1.1V95.4h-.1v.1h-.1-.2l-.1-.1v-.1q0-.1-.1-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM236.2 95.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM241.5 95.3h.5v3.2h-.4V97h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M244.9 98.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M248.2 95.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM249.4 95.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM251.7 98.2v-.4l.1.1h.1l.1.1q.1 0 .1.1h.4q.1.1.2.1l.1-.1h.2q.1 0 .2-.1l.1-.1v-.1-.1-.1-.1-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.4-.2h-.2l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1v-.2-.1-.2-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1h.2q.1-.1.2-.1 0 .1.1.1h.4l.1.1h.1l.1.1h.1v.4h-.1l-.1-.1q-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.4q-.1.1-.2.1h-.1v.1q-.1 0-.1.1v.4q0 .1.1.1h.1v.1h.1l.1.1.5.2h.2l.1.1.1.1q.1.1.2.1v.1q.1.1.1.2V98q-.1.1-.1.2-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1H252.2l-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1zM254.2 98.2v-.1q0-.1.1-.1h.1v-.1h.1l.1.1h.1v.4h-.1v.1h-.2q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM256.9 95.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM262.2 95.3h.4v3.2h-.4V97h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M265.6 98.5l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M268.9 95.3v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM270.1 95.3v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM272.3 98.2v-.4q.1 0 .1.1h.2q0 .1.1.1l.1.1h.4l.1.1q.1 0 .2-.1h.2q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1q0-.1-.1-.1l-.5-.2h-.1q-.1 0-.1-.1-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2l.1-.1.1-.1q.1-.1.2-.1.1-.1.2-.1h.2q0-.1.1-.1.1.1.2.1H273.8q.1 0 .1.1h.2l.1.1h.1v.4h-.1l-.1-.1h-.2q0-.1-.1-.1h-.1l-.1-.1h-.4-.1q0 .1-.1.1h-.1q0 .1-.1.1v.1l-.1.1v.2h.1v.1q0 .1.1.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1 0-.2.1-.1 0-.2.1h-.4H273h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1zM274.9 98.2v-.1-.1h.1l.1-.1h.1v.1h.1l.1.1V98.4h-.1q0 .1-.1.1h-.2v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM245.8 82.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM251.2 81.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M254.5 84.9l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M257.8 81.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM259 81.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM261.3 84.6v-.4l.1.1.1.1h.1q.1 0 .1.1H262l.1.1H262.4l.1-.1h.1q.1 0 .2-.1l.1-.1v-.1-.1-.1-.1-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.4-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.2-.1-.2-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H262.8l.1.1h.1l.1.1h.1v.5l-.1-.1-.1-.1h-.1l-.1-.1h-.1q-.1 0-.2-.1h-.4q-.1.1-.2.1h-.1q0 .1-.1.2V82.6q0 .1.1.2l.1.1q.1 0 .1.1h.1l.5.2h.2q0 .1.1.1l.1.1q.1.1.2.1v.1q.1.1.1.2V84.4q-.1.1-.1.2-.1 0-.2.1l-.1.1q-.1 0-.2.1H261.8l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1zM263.8 84.6v-.1q0-.1.1-.1h.1v-.1h.1l.1.1h.1v.4q-.1 0-.1.1H263.9v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM254.9 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM260.3 88.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M263.6 91.3l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M267 88.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM268.1 88.1v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM270.4 91v-.4h.1q0 .1.1.1l.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1-.1q.1 0 .1-.1v-.1h-.1v-.1q0-.1-.1-.1l-.1-.1h-.1v-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3 0-.2.2-.3.1 0 .1-.1h.2q.1 0 .2-.1h.4q.1.1.2.1h.2l.1.1h.1l.1.1v.4l-.1-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H271.2q-.1 0-.1.1h-.1q-.1.1-.2.1v.4q0 .1.1.2h.1q0 .1.1.1h.1l.1.1.5.2h.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1V90.3q.1 0 .1.1t-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM272.9 91v-.1-.1h.1q0-.1.1-.1h.2v.1h.1v.1q.1 0 .1.1t-.1.1v.1h-.1v.1h-.1-.1l-.1-.1h-.1v-.1-.1zm.1-.7v-2.2h.5l-.1 2.2zM267.3 80v-.4h2.6v.4h-1.1v2.8h-.4V80zM272.6 79.6h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4V81h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M276 82.8l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M279.3 79.6v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM280.5 79.6V81l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM282.8 82.5l-.1-.4q.1 0 .2.1l.1.1h.1q.1 0 .1.1H283.5l.1.1H283.9l.1-.1h.1q.1 0 .1-.1h.1l.1-.1v-.1-.1-.1-.1-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.4-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.2q-.1 0 0-.1-.1-.1 0-.2V80q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H284.3l.1.1h.1l.1.1h.1v.5l-.1-.1-.1-.1h-.1l-.1-.1h-.2l-.1-.1h-.5q0 .1-.1.1h-.1q0 .1-.1.1v.1l-.1.1v.2l.1.1q0 .1.1.1l.1.1q.1 0 .1.1h.1l.5.2h.2q0 .1.1.1l.1.1q.1.1.2.1v.1q.1.1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1.1-.2.1t-.1.1H283.3q-.1 0-.1-.1h-.2q-.1-.1-.2-.1l-.1-.1zM285.3 82.5v-.1-.1h.1l.1-.1h.1q0 .1.1.1t.1.1V82.7h-.1v.1h-.1-.1-.1q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM274.1 88.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM279.5 88.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M282.8 91.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M286.2 88.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM287.3 88.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM289.6 91v-.4l.1.1h.1l.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1q0-.1-.1-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1t-.1-.1V89v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1h.1q.1 0 .1.1h.4l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H290.7 290.4q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1V89q0 .1.1.1v.1h.1q0 .1.1.1h.1v.1l.5.2h.2l.1.1q.1 0 .2.1l.1.1q0 .1.1.1v.2l.1.1v.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM292.1 91v-.1l.1-.1.1-.1h.2v.1h.1v.1h.1v.1q0 .1-.1.1v.1q-.1 0-.1.1h-.1-.1q0-.1-.1-.1l-.1-.1V91zm.1-.7v-2.2h.4l-.1 2.2zM274.1 94.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM279.5 94.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M282.8 97.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M286.2 94.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM287.3 94.2v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM289.6 97.1v-.4q.1 0 .2.1t.3.1q.1.1.2.1h.7q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1-.1h-.1v-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1h.4q.1 0 .2.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H290.7h-.4l-.1.1q-.1 0-.1.1-.1 0-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V96.7q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H290.2q-.1 0-.2-.1h-.1l-.1-.1h-.1l-.1-.1zM292.1 97.1V97l.1-.1.1-.1h.2v.1h.1v.1h.1v.1l-.1.1v.1q-.1 0-.1.1h-.1-.1v-.1h-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM172.6 94.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM177.9 94.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M181.2 97.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M184.6 94.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM185.8 94.2l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM188 97.1v-.4h.1l.1.1q.1 0 .1.1h.2q0 .1.1.1h.7l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1v-.1q-.1-.1-.1-.2V95v-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H189.5q.1 0 .1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V96.7q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H188.6q-.1 0-.2-.1h-.1l-.1-.1h-.1l-.1-.1zM190.5 97.1V97h.1v-.1h.1v-.1h.2l.1.1.1.1v.2q0 .1-.1.1l-.1.1h-.1q-.1 0-.1-.1h-.1v-.1q-.1 0-.1-.1zm.2-.8l-.1-2.1h.4v2.1zM89.8 146.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM95.1 146.1h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M98.5 149.3l-.4-1h-1.3l-.4 1H96l1.2-3.2h.4l1.2 3.2zm-1-2.7L97 148h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M101.8 146.1v3.2h-.3l-1.7-2.6v2.6h-.4v-3.2h.4l1.6 2.4v-2.4zM103 146.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM105.3 149v-.4h.1q0 .1.1.1 0 .1.1.1h.1q.1.1.2.1h.7l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1t-.1-.1-.1-.1-.1-.1l-.1-.1v-.2q-.1-.1 0-.1-.1-.1 0-.2v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1H106.9l.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.5l-.1.1h-.1v.1q-.1 0-.1.1v.1q-.1 0 0 .1-.1 0 0 0v.2l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM107.8 149v-.1-.1h.1v-.1H108.2v.1h.1v.4h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM125.2 141.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM130.6 141.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M133.9 144.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M137.3 141.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM138.4 141.1v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM140.7 144.1v-.5q0 .1.1.1t.1.1h.1q.1.1.2.1h.1l.1.1h.5q.1-.1.2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1v-.1h-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1-.1.2-.1l.1-.1H142.1l.1.1h.2l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1-.1 0-.2.1v.4q.1.1.2.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0 0 .1-.1 0h-.2-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM143.2 144.1v-.1-.1h.1v-.1H143.6q.1 0 .1.1.1.1.1.2l-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM126.5 148.4v-.4h2.6v.4H128v2.8h-.4v-2.8zM131.8 148h.5v3.2h-.5v-1.5h-1.7v1.5h-.5V148h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M135.2 151.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M138.6 148v3.2h-.3l-1.8-2.6v2.6h-.4V148h.4l1.7 2.4V148zM139.7 148v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V148zM142 150.9v-.4q.1 0 .1.1.1 0 .1.1h.1q.1.1.2.1h.1q.1 0 .1.1h.4q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H143.4q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H143.1h-.1H142.7q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H142.4l-.1-.1h-.1q-.1 0-.1-.1-.1 0-.1-.1zM144.5 150.9v-.1q.1 0 .1-.1h.1l.1-.1v.1h.2v.4l-.1.1h-.2q-.1 0-.1-.1-.1 0-.1-.1v-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM151 148.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM156.4 148h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V148h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M159.7 151.2l-.4-1H158l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M163.1 148v3.2h-.3l-1.8-2.5v2.5h-.4V148h.4l1.7 2.4V148zM164.2 148v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V148zM166.5 150.9v-.4l.1.1q.1 0 .1.1h.1q.1.1.2.1h.1q.1 0 .1.1h.4q.1 0 .1-.1h.2l.1-.1h.1v-.1q0-.1.1-.1v-.1-.1l-.1-.1v-.1h-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2.1-.1q.1 0 .2-.1h.9q0 .1.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H167.7q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2l-.1.1-.1.1v.4l.1.1.1.1h.1l.1.1.6.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1.1.2t-.1.2v.1q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1-.1.1-.2.1H167.3h-.2q-.1 0-.2-.1h-.2l-.1-.1q-.1 0-.1-.1zM169 150.9v-.1q.1 0 .1-.1h.4v.2h.1q0 .1-.1.1v.1l-.1.1H169.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM174.3 150.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM179.6 149.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M182.9 152.9l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M186.3 149.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM187.5 149.7v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM189.7 152.6v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H190.5q0 .1.1.1h.2q.1 0 .2-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2 0-.1.1-.1.1-.1.2-.1.1-.1.2-.1H191.2l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.2l-.1.1v.1q-.1 0-.1.1V150.7h.1v.1q.1 0 .1.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.7-.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM192.2 152.6l.1-.1v-.1h.1q.1-.1.1 0h.2v.1h.1v.2q0 .1-.1.1v.1H192.4q-.1 0-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM172.6 143.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM177.9 142.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M181.2 146.1l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M184.6 142.9v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM185.8 142.9l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM188 145.8v-.4q.1 0 .1.1h.1l.1.1q.1 0 .2.1h.4q0 .1.1 0 .1.1.1 0H189.4l.1-.1q.1 0 .1-.1.1-.1.1-.2t-.1-.2v-.2h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.2-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1t.1.1h.4q.1 0 .1.1h.2l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.2l-.1-.1h-.2-.1-.2l-.1.1h-.1l-.2.2V143.8q0 .1.1.2h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1l.1.1q.1.1.2.1 0 .1.1.1.1.2.1.3.1.2.1.3-.1.2-.1.3-.1.2-.2.3-.1 0-.2.1-.1 0-.2.1H188.6q-.1 0-.2-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1zM190.5 145.8q0-.1.1-.1v-.1h.1q0-.1.1-.1h.1l.1.1q.1 0 .1.1v.2l-.1.1-.1.1h-.2v-.1h-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM197.4 143.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM202.7 142.9h.5v3.2h-.5v-1.5H201v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M206.1 146.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M209.4 142.9v3.2h-.3l-1.7-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM210.6 142.9v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM212.9 145.8v-.4q.1.1.2.1l.2.2h.3q.1.1.3 0 0 .1.1 0h.2q.1 0 .2-.1l.1-.1v-.2-.2q0-.1-.1-.2h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.4-.2h-.2l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1v-.2q-.1 0 0-.1-.1-.1 0-.2v-.2l.1-.1q.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1h.2q.1-.1.2-.1l.1.1h.4l.1.1h.2q0 .1.1.1v.4h-.1l-.1-.1h-.1l-.1-.1h-.2q0-.1-.1-.1h-.5q0 .1-.1.1h-.1v.1q-.1 0-.1.1v.1q-.1 0 0 .1-.1 0 0 .1v.1q0 .1.1.1h.1v.1h.1l.1.1.5.2h.2q0 .1.1.1l.1.1q.1.1.2.1v.1q.1.1.1.2V145.6q-.1.1-.1.2-.1 0-.2.1-.1 0-.2.1l-.1.1H213.4l-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1zM215.4 145.8v-.1q0-.1.1-.1l.1-.1h.2q0 .1.1.1v.4h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM162 137.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM167.3 137.3h.5v3.2h-.5V139h-1.7v1.4h-.5v-3.1h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M170.7 140.5l-.4-1H169l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M174.1 137.3v3.2h-.3L172 138v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM175.2 137.3v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM177.5 140.2v-.4q.1.1.2.1l.2.2h.3q.1.1.3.1l.1-.1H178.9q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.1.2-.3h.2l.1-.1q.1 0 .2-.1h.5q0 .1.1.1h.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1l-.1.1V138.2q0 .1.1.1v.1h.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1q.1 0 .1.1l.1.1v.2q.1 0 .1.1V139.8q-.1.1-.1.2t-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5q-.1 0-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1zM180 140.2v-.1l.1-.1h.1v-.1h.1q.1 0 .1.1h.1v.2q.1 0 0 0v.2q-.1 0-.1.1h-.1-.1q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM143.3 137.7l-.1-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM148.6 137.3h.4v3.2h-.4V139h-1.8v1.4h-.4v-3.1h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M151.9 140.5l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M155.3 137.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM156.4 137.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM158.7 140.2v-.4q.1.1.2.1.1.1.3.2h.2q.2.1.3.1.1 0 .1-.1H160.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1-.1-.1-.1-.1v-.2-.1-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1l.1-.1h.2q.1-.1.2-.1l.1.1h.4q.1 0 .1.1h.1q.1 0 .1.1h.1v.5l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V139.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1H159.6q-.1 0-.2-.1H159.1l-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM161.2 140.2q0-.1.1-.1v-.1h.1q0-.1.1-.1h.1v.1h.1v.1h.1v.2q0 .1-.1.1l-.1.1h-.1-.1l-.1-.1v-.1q-.1 0-.1-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM190.1 137.7v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM195.5 137.3h.4v3.2h-.4V139h-1.8v1.4h-.4v-3.1h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M198.8 140.5l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M202.2 137.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM203.3 137.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM205.6 140.2v-.4q.1.1.2.1.1.1.3.2h.2q.2.1.3.1.1 0 .1-.1H207l.1-.1h.1v-.1l.1-.1v-.1-.1-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1-.1-.1-.1-.1v-.2-.1-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1l.1-.1h.2q.1-.1.2-.1l.1.1h.4q.1 0 .1.1h.1q.1 0 .1.1h.1v.5l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V139.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1H206.5q-.1 0-.2-.1H206l-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM208.1 140.2q0-.1.1-.1v-.1h.1v-.1h.2l.1.1q0 .1.1.1v.2l-.1.1-.1.1h-.1-.1l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM219.1 143.3v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM224.5 142.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M227.8 146.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M231.2 142.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM232.3 142.9v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM234.6 145.8v-.4q.1 0 .1.1h.1q.1.1.2.1l.1.1h.4q0 .1.1 0 .1.1.1 0H236l.1-.1h.1v-.1l.1-.1v-.2-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1 0-.2-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1t.1.1h.4q.1 0 .1.1h.2l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1-.1l-.1-.1h-.2-.1-.2l-.1.1h-.1l-.1.1-.1.1v.4h.1v.1h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1q.1.1.2.1 0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V145.4q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1 0-.2.1-.1 0-.2.1H235.4h-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1q0-.1-.1-.1zM237.1 145.8v-.1l.1-.1.1-.1h.2v.1h.1v.1h.1v.2q-.1 0-.1.1h-.1v.1h-.1-.1l-.1-.1q0-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM237.3 138.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM242.6 138.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M245.9 141.4l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M249.3 138.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM250.5 138.2v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM252.7 141.1v-.4q.1 0 .1.1.1 0 .2.1h.1l.1.1H253.5l.1.1h.2q.1 0 .2-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1.1-.1.2-.1H254.2q.1 0 .1.1h.1q.1 0 .1.1h.1v.4h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H253.7h-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1V139.2h.1v.1q.1 0 .1.1h.1q.1 0 .1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1 0-.2.1-.1 0-.2.1h-.7-.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1l-.1-.1zM255.2 141.1l.1-.1v-.1h.1l.1-.1h.1v.1h.1v.1h.1v.2q0 .1-.1.1v.1H255.4l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM237.3 146.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM242.6 146.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M245.9 149.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M249.3 146.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM250.5 146.1v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM252.7 149v-.4q.1.1.2.1.2.1.3.1.1.1.3.1h.6l.1-.1q.1 0 .1-.1h.1v-.2-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1t-.1-.3-.1-.3q0-.1.1-.3.1-.1.2-.3h.2q.1-.1.2-.1h.2q0-.1.1-.1h.2l.1.1H254.3q.1.1.2.1h.1q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.2-.1h-.1l-.1-.1h-.5-.1l-.1.1h-.1q-.1.1-.1.2l-.1.1q0 .1.1.2 0 .1.1.2h.1q0 .1.1.1h.1l.1.1.5.2h.1l.1.1q.1 0 .2.1l.1.1q0 .1.1.1V148.3l.1.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4H253.3q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1zM255.2 149v-.1h.1v-.1h.1v-.1h.2l.1.1q.1 0 .1.1v.2q0 .1-.1.1t-.1.1h-.2v-.1h-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM241.5 156.1v-.4h2.6v.4H243v2.8h-.4v-2.8zM246.9 155.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M250.2 158.9l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M253.6 155.7v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM254.7 155.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM257 158.6v-.4q.1 0 .1.1h.1l.1.1q.1 0 .2.1h.9q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1q0-.1-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2h.2q0-.1.1-.1h.2q.1-.1.2-.1h.1q.1 0 .2.1H258.6l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H258.1 257.8q-.1 0-.1.1h-.1l-.2.2V156.6q0 .1.1.2h.1q0 .1.1.1h.1q0 .1.1.1l.5.2h.1l.1.1q.1 0 .2.1 0 .1.1.1 0 .1.1.1V157.9l.1.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1q-.1 0-.2.1H257.8h-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM259.5 158.6v-.1l.1-.1q.1 0 .1-.1h.2q0 .1.1.1v.1q.1 0 .1.1v.1h-.1v.1h-.1v.1h-.1-.1q0-.1-.1-.1l-.1-.1v-.1zm.1-.7v-2.2h.4l-.1 2.2zM256.9 148.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM262.2 148h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V148h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M265.6 151.2l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M268.9 148v3.2h-.3l-1.8-2.6v2.6h-.3V148h.3l1.7 2.4V148zM270.1 148v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4V148zM272.3 150.9v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H273.1q0 .1.1.1H273.5l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H273.8l.1.1h.2l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1-.1l-.1-.1H273.2l-.1.1h-.1-.1q0 .1-.1.1v.1l-.1.1v.2l.1.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H273h-.2q-.1-.1-.2-.1h-.1q0-.1-.1-.1l-.1-.1zM274.9 150.9v-.1-.1h.2q0-.1 0 0h.2q0 .1.1.1v.2q0 .1-.1.1 0 .1-.1.1h-.1-.1l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM256.9 143.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM262.2 142.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M265.6 146.1l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M268.9 142.9v3.2h-.3l-1.8-2.6v2.6h-.3v-3.2h.3l1.7 2.4v-2.4zM270.1 142.9v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM272.3 145.8v-.4q.1 0 .1.1h.2q0 .1.1.1l.1.1h.4q.1.1.1 0 .1.1.2 0h.2q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.2-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1q0-.1-.1-.1l-.5-.2h-.1q-.1 0-.1-.1-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1l.1-.1q.1-.1.2-.1.1-.1.2-.1h.2q0-.1.1-.1t.2.1H273.8q.1 0 .1.1H274.2q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.2-.1h-.1-.1q0-.1-.1-.1h-.4-.1q0 .1-.1.1h-.1q0 .1-.1.1v.1q-.1 0-.1.1v.2l.1.1q0 .1.1.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1.1.1.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.2l-.1.1q-.1 0-.2.1-.1 0-.2.1h-.4H273h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1l-.1-.1zM274.9 145.8v-.1-.1h.1l.1-.1h.1l.1.1q.1 0 .1.1V146h-.1q0 .1-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM277.8 141.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM283.1 141.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M286.4 144.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M289.8 141.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM291 141.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM293.2 144.1v-.5l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1.1.2.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2l.1-.1q.1-.1.2-.1t.2-.1h.7q.1 0 .1.1h.2l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1-.2q-.1-.1-.2-.1l-.1.1h-.1-.1q-.1 0-.1.1h-.1v.1l-.1.1V142.1q.1 0 .1.1l.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.2-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM295.8 144.1q-.1-.1 0-.1v-.1q0-.1.1-.1H296.2v.1h.1V144.2l-.1.1h-.4v-.1-.1h-.1zm.2-.8l-.1-2.2h.4v2.2zM276.5 152.2v-.3h2.6v.3H278v2.9h-.4v-2.9zM281.8 151.9h.4v3.1h-.4v-1.4H280v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M285.2 155.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M288.5 151.9v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.3l1.7 2.4v-2.4zM289.7 151.9v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM292 154.8v-.4h.1l.1.1.1.1h.2q0 .1.1.1H293.4q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1h-.1v-.1-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1-.1-.2-.3v-.3q0-.1.1-.3l.2-.2q.1-.1.2-.1 0-.1.1-.1h.2q.1-.1.2-.1h.1q.1 0 .2.1H293.6l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H293.1h-.1-.2q-.1 0-.1.1h-.1q-.1 0-.1.1l-.1.1V152.8q0 .1.1.1v.1h.1q0 .1.1.1h.1l.1.1.5.2h.1l.1.1q.1 0 .2.1l.1.1q0 .1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.6q-.1-.1-.2-.1h-.1l-.1-.1h-.1l-.1-.1zM294.5 154.8v-.1-.1h.1v-.1h.2q.1 0 .1.1h.1V154.9q0 .1-.1.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM277.8 148.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM283.1 148h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V148h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M286.4 151.2l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M289.8 148v3.2h-.3l-1.8-2.6v2.6h-.4V148h.4l1.7 2.4V148zM291 148v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V148zM293.2 150.9v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1H294.3q.1 0 .2-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1-.1-.2-.1-.3-.1-.2-.1-.3 0-.2.1-.4l.2-.2q.1-.1.2-.1t.2-.1H294.7l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.2q0 .1-.1.1v.1l-.1.1V149h.1v.1q.1 0 .1.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.7-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1zM295.8 150.9q-.1 0 0 0v-.1-.1h.2q0-.1 0 0h.2q0 .1.1.1v.2q0 .1-.1.1 0 .1-.1.1h-.1-.1l-.1-.1v-.1q-.1 0-.1-.1zm.2-.7l-.1-2.2h.4v2.2zM216.8 150.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM222.1 149.7h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M225.5 152.9l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M228.8 149.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.4v-2.4zM230 149.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM232.3 152.6v-.4l.2.2q.1 0 .2.1h.3q.1.1.3.1h.1q.1 0 .1-.1h.1q.1 0 .1-.1.1 0 .2-.1v-.2-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H233.8q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.2-.1q0-.1-.1-.1h-.2q-.1 0-.2.1h-.1-.1v.1l-.1.1v.1q-.1 0-.1.1l.1.1v.1q0 .1.1.1l.1.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2.1 0 .1.1v.5l-.1.1v.2q-.1 0-.2.1t-.2.1q0 .1-.1.1H232.8l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1zM234.8 152.6v-.1l.1-.1h.1v-.1l.1.1h.1l.1.1V152.8l-.1.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM196.9 150.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM202.3 149.7h.3v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M205.6 152.9l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M208.9 149.7v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM210.1 149.7v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM212.4 152.6v-.4l.1.1.1.1h.1l.1.1H213.1l.1.1H213.5q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H213.9q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1H213.6q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2-.1q0 .1-.1.2v.4l.1.1.1.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.2.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1l-.1.1q-.1.1-.2.1H212.9l-.1-.1h-.2l-.1-.1q-.1 0-.1-.1zM214.9 152.6v-.1l.1-.1h.2q0-.1.1 0h.1v.4l-.1.1H215v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM149.3 144.8v-.4h2.6v.3h-1.1v2.9h-.4v-2.9zM154.7 144.4h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M158 147.6l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M161.4 144.4v3.2h-.3l-1.8-2.6v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM162.5 144.4v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM164.8 147.3v-.4q.1.1.2.1.1.1.2.1.2.1.3.1h.7q0-.1.1-.1t.1-.1q0-.1.1-.2 0-.1-.1-.2 0-.1-.1-.2h-.1q0-.1-.1-.1l-.1-.1-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2h.1q.1-.1.2-.1h.2q.1-.1.2-.1h.1l.1.1h.4l.1.1h.1l.1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4l-.1.1q-.1 0-.1.1h-.1v.5h.1q0 .1.1.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V146.6q.1 0 0 .1.1.1 0 .2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.4-.1q-.1-.1-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM167.3 147.3v-.1-.1h.1l.1-.1h.2q0 .1.1.1v.1q.1 0 .1.1l-.1.1v.1h-.1v.1h-.1-.1q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM103.3 150.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM108.6 149.7h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M112 152.9l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M115.3 149.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM116.5 149.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4v-3.2zM118.7 152.6v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H119.5q0 .1.1.1H119.9l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1.1-.1.2-.1H120.2l.1.1h.2l.1.1v.4q-.1 0-.1-.1h-.2q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.1-.1q0 .1-.1.1v.1l-.1.1v.2l.1.1v.1q.1 0 .1.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2l.1.1.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H119.4h-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1zM121.3 152.6v-.1-.1h.2q0-.1 0 0h.2q0 .1.1.1V152.8h-.1v.1h-.1-.2l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2z"
            />
            <path
              id="Layer"
              className="s1"
              d="M60.3 117.1v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM69 116.5h.7v5.1H69v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2H69z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M74.4 121.6l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M79.8 116.5v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM81.7 116.5v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7H84l-2.4-2.4v2.4H81v-5.1zM85.3 121.2v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.2 0-.3-.1-.1 0-.1-.1l-.2-.2q-.1 0-.2-.1t-.1-.3q-.1-.1-.1-.2v-.3-.2q.1-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.1q.1.1.2.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8l-.1.1q-.1 0-.2.1-.1 0-.1.1l-.1.1q-.1.1-.1.2v.4l.1.1q0 .1.1.1l.1.1q.1.1.2.1 0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1l.2.2v.2q.1.1.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.6q-.1 0-.2-.1h-.2q-.1 0-.3-.1h-.1q-.1-.1-.2-.1t-.2-.1-.2-.1zM89.4 121.2v-.1-.1q.1 0 .1-.1l.1-.1h.1l.1-.1h.1l.1.1h.1l.1.1.1.1v.4h-.1v.1q-.1 0-.1.1H90h-.1-.1-.1q-.1 0-.2-.1 0-.1-.1-.1v-.2zm.2-1.2l-.1-3.5h.7l-.1 3.5zM49.2 109.4v-.6h4.2v.6h-1.8v4.6H51v-4.5zM57.8 108.8h.7v5.2h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.1h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M63.2 114l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M68.7 108.8v5.2h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.8v-3.9zM70.5 108.8v2.3l2.4-2.3h.8l-2.5 2.5 2.6 2.7h-.9l-2.4-2.5v2.5h-.6v-5.1zM74.2 113.5v-.7l.2.2q.1 0 .2.1t.2.1.2.1.2.1h1q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q.1-.1.1-.2v-.2-.1-.1q-.1-.1-.1-.2l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.1-.1h-.2l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1t-.1-.2-.1-.2q-.1-.1-.1-.3v-.2-.3q0-.1.1-.3l.2-.2.2-.2.2-.2q.2 0 .3-.1h1.2q.1 0 .2.1h.2q.1.1.2.1t.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1H76q-.1-.1-.2-.1t-.1.1h-.4q-.1.1-.2.1l-.1.1q-.1.1-.1.2t-.1.1V110.2q0 .1.1.2v.1l.1.1q.1.1.2.1l.1.1q.1 0 .1.1h.2l.8.3q.1.1.3.1.1.1.2.1l.2.2.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3 0 .1-.1.2-.1.2-.3.2l-.2.2q-.2.1-.3.1-.2.1-.4.1h-.8q-.1 0-.2-.1h-.2q-.1 0-.2-.1-.1 0-.2-.1h-.1l-.2-.2zM78.3 113.5v-.1q0-.1.1-.1v-.1h.1v-.1h.4l.1.1.1.1v.1q.1 0 .1.1v.1q0 .1-.1.1v.1l-.1.1q-.1 0-.1.1h-.1-.1-.1l-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.1 3.5zM65.3 102v-.6h4.2v.6h-1.8v4.5H67V102zM73.9 101.4h.7v5.2h-.7v-2.4H71v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M79.3 106.6l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.7l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M84.7 101.4v5.2h-.5l-2.8-4.1v4.1h-.6v-5.2h.6l2.7 3.9v-3.9zM86.6 101.4v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7H89l-2.4-2.5v2.5H86v-5.2zM90.3 106.2v-.8q.1.1.1.2.1.1.2.1.1.1.2.1.1.1.2.1t.2.1h.5q.1.1.2.1t.2-.1h.2q.1 0 .2-.1.1 0 .2-.1l.1-.1q.1 0 .1-.1t.1-.2v-.2-.1q0-.1-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2-.2-.2q0-.1-.1-.2v-.2q-.1-.2 0-.3-.1-.1 0-.3 0-.1.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1h1.2q.1 0 .2.1h.2l.1.1q.1 0 .2.1.1 0 .2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-.2q-.1 0-.2-.1h-.4q-.1.1-.2.1h-.1q-.1.1-.2.1-.1.1-.2.3-.1.1-.1.2v.3q.1.2.2.3.1 0 .1.1.1 0 .2.1h.1q.1.1.2.1l.8.3q.1.1.2.1.1.1.2.1l.2.2.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1H91q-.1-.1-.2-.1t-.2-.1h-.2l-.1-.1zM94.3 106.2v-.1l.1-.1v-.1l.1-.1.1-.1h.4v.1h.1q0 .1.1.1v.5q-.1 0-.1.1H95v.1H94.7h-.1q0-.1-.1-.1l-.1-.1v-.1l-.1-.1zm.3-1.3l-.2-3.5h.8l-.2 3.5zM56.8 93.8v-.5h4.3v.5h-1.8v4.6h-.7v-4.6zM65.5 93.3h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M70.9 98.4l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M76.3 93.3v5.1h-.5L73 94.3v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM78.2 93.3v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8L78.2 96v2.4h-.7v-5.1zM81.8 98v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H83.9q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1.1-.2v-.2q-.1 0-.1-.1t-.1-.1-.1-.1-.1-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.1 0-.2-.1 0-.1-.1-.3 0-.1-.1-.2v-.3q0-.1.1-.2 0-.2.1-.3 0-.1.1-.3l.2-.2q.2 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.1q.1.1.2.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1l-.1.1q-.1 0-.2.1 0 .1-.1.1v.6l.1.1q0 .1.1.1 0 .1.1.1.1.1.2.1 0 .1.1.1h.2l.8.4q.1 0 .2.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.3v.5q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.2-.1 0-.3.1h-.3q-.2.1-.3.1h-.3q-.1-.1-.2-.1h-.2q-.2 0-.3-.1h-.2q0-.1-.1-.1t-.2-.1-.2-.1zM85.9 98v-.1l.1-.1v-.1h.1v-.1h.2q0-.1.1 0 0-.1.1 0h.1q.1 0 .1.1l.1.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.2l-.1-.1h-.1v-.1H86v-.1l-.1-.1V98zm.2-1.2l-.1-3.5h.7l-.1 3.5zM58.2 86.7v-.6h4.2v.6h-1.8v4.5H60v-4.5zM66.8 86.1h.7v5.2h-.7v-2.4h-2.9v2.4h-.6v-5.2h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M72.2 91.3l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.2h.6l2 5.2zM70.5 87l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M77.7 86.1v5.2h-.5l-2.9-4.1v4.1h-.6v-5.2h.6L77 90v-3.9zM79.5 86.1v2.3l2.4-2.3h.8l-2.5 2.5 2.6 2.7h-.9l-2.4-2.5v2.5h-.6v-5.2zM83.2 90.8v-.7l.2.2q.1 0 .2.1t.2.1.2.1.2.1h1q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q.1-.1.1-.2v-.2-.1-.1q-.1-.1-.1-.2l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.1-.1h-.2l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1t-.1-.2-.1-.2q-.1-.1-.1-.3v-.2-.3q0-.1.1-.3l.2-.2.2-.2.2-.2q.2 0 .3-.1h1.2q.1 0 .2.1h.2q.1.1.2.1t.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1H85q-.1-.1-.2 0-.1-.1-.1 0h-.4q-.1.1-.2.1l-.1.1q-.1.1-.1.2t-.1.1V87.5q0 .1.1.2v.1l.1.1q.1.1.2.1l.1.1q.1 0 .1.1h.2l.8.3q.1.1.2.1.2.1.3.1l.2.2.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3 0 .1-.1.2-.1.2-.2.2-.2.1-.3.2-.2.1-.3.1-.2.1-.4.1h-.8q-.1 0-.2-.1h-.2q-.1 0-.2-.1-.1 0-.2-.1h-.1l-.2-.2zM87.3 90.8v-.1-.1q.1 0 .1-.1.1 0 .1-.1h.4l.1.1.1.1v.1q.1 0 .1.1v.1q0 .1-.1.1v.1l-.1.1q-.1.1-.2.1h-.3l-.2-.2v-.3zm.2-1.2l-.2-3.5h.8l-.2 3.5zM60.3 80.3v-.5h4.3v.5h-1.8v4.6h-.7v-4.6zM69 79.8h.7v5.1H69v-2.4h-2.9v2.4h-.7v-5.1h.7V82H69z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M74.4 84.9l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.6l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M79.8 79.8v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM81.7 79.8V82l2.3-2.2h.8l-2.5 2.4 2.6 2.7H84l-2.4-2.4v2.4H81v-5.1zM85.3 84.5v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.5q.1 0 .2-.1h.2l.1-.1.2-.2.1-.1v-.2-.1-.2-.1q0-.1-.1-.1 0-.1-.1-.2l-.1-.1q-.1 0-.2-.1h-.1q-.1-.1-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.2-.2-.3-.4-.1-.3-.1-.6 0-.2.2-.5.1-.2.3-.4.1-.1.3-.1.1-.1.3-.2h1.2q.1.1.2.1h.2l.1.1q.1 0 .2.1.1 0 .2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-1q-.1 0-.1.1-.1 0-.2.1-.1 0-.2.2-.1.1-.1.3v.3l.2.2.1.1q.1.1.2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3-.1.1-.2.3-.1.1-.2.1-.1.2-.3.2-.1.1-.3.2-.1 0-.3.1h-.8q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1-.2-.1-.2-.1zM89.4 84.5v-.1-.1l.1-.1v-.1h.1l.1-.1H90l.1.1h.1v.1q.1 0 .1.1V84.6q0 .1-.1.1v.1h-.1q0 .1-.1.1h-.1-.2q-.1 0-.1-.1h-.1v-.1l-.1-.1v-.1zm.2-1.2l-.1-3.5.7-.1-.1 3.6zM293.2 80.3v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM301.8 79.8h.7v5.1h-.7v-2.4h-2.9v2.4h-.6v-5.1h.6V82h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M307.2 84.9l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.2h.6l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M312.7 79.8v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM314.5 79.8V82l2.4-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.4-2.4v2.4h-.6v-5zM318.2 84.5v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2v-.1-.2-.1q-.1-.1-.1-.2l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2-.2-.4q-.1-.3-.1-.6 0-.2.1-.5l.4-.4q.1-.1.2-.1.2-.1.3-.1.2-.1.3-.1H320.4q.1.1.2.1h.2q.1.1.2.1t.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1H319.5q-.1 0-.2.1-.1 0-.2.1-.1 0-.2.2-.1.1-.1.3 0 .1.1.3 0 .1.1.2t.2.1q0 .1.1.1t.1.1h.2l.8.3q.1.1.2.1.2.1.3.2.1 0 .2.1l.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3 0 .1-.1.3-.1.1-.3.1l-.2.2q-.2.1-.3.1-.2.1-.4.1h-.8q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1zM322.3 84.5v-.1-.1-.1q.1 0 .1-.1h.1V84h.4q0 .1.1.1l.1.1v.1q.1 0 .1.1v.2h-.1v.1q0 .1-.1.1l-.1.1h-.1H322.5l-.1-.1q-.1-.1-.1-.2v-.1zm.2-1.2l-.1-3.5.7-.1-.2 3.6zM294.4 86.7l.1-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM303.1 86.1h.7v5.2h-.7v-2.4h-2.9v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M308.5 91.3l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.7l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M313.9 86.1v5.2h-.4l-2.9-4.1v4.1h-.6v-5.2h.6l2.8 3.9v-3.9zM315.8 86.1v2.3l2.3-2.3h.9l-2.5 2.5 2.6 2.7h-.9l-2.3-2.5v2.5h-.7v-5.2zM319.5 90.8v-.7l.2.2.1.1q.1.1.2.1.1.1.2.1t.2.1H321.5q.1 0 .2-.1.1 0 .2-.1l.2-.2q.1-.1.1-.3 0-.2-.1-.3 0-.2-.1-.3-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q0-.1-.1-.2v-.3-.2-.3q0-.1.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1h1.2q.1 0 .2.1h.2q.1.1.2.1l.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.4q-.1-.1-.2-.1t-.2.1H320.6q-.1.1-.2.1l-.1.1q-.1.1-.1.2l-.1.1v.5q.1 0 .1.1l.1.1.1.1q.1 0 .2.1l.1.1h.2l.8.3q.1.1.2.1.1.1.2.1.2.1.3.2.1.1.1.2l.2.2q0 .1.1.2v.7q0 .2-.1.3l-.2.2q-.1.2-.2.2-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1q-.1 0-.2-.1h-.2q0-.1-.1-.2zM323.6 90.8q-.1 0 0 0v-.1-.1l.1-.1.1-.1h.4v.1h.1q0 .1.1.1v.5q-.1 0-.1.1h-.1v.1H323.9h-.1q0-.1-.1-.1l-.1-.1V91v-.1q-.1 0-.1-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM293.2 93.8v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM301.8 93.3h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M307.2 98.4l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M312.7 93.3v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM314.5 93.3v2.3l2.4-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM318.2 98v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1h.8q.1-.1.2-.1.1-.1.2-.1l.1-.1q.1-.1.1-.2.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q0-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.7q-.1.1-.2.1t-.2.1q-.1 0-.1.1-.1 0-.1.1t-.1.2V94.7q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1h.2l.8.4q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2 0 .2-.1.3-.1.1-.3.2l-.2.2q-.2 0-.3.1h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM322.3 98v-.1-.1-.1h.1q0-.1.1-.1h.1q.1-.1.1 0 .1-.1.1 0h.2v.1h.1v.1l.1.1v.1q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1-.1.1-.2.1h-.1q0-.1-.1-.1t-.1-.1h-.1v-.1-.1-.1zm.2-1.2l-.1-3.5h.7l-.2 3.5zM310.3 102v-.6h4.2v.6h-1.8v4.5h-.7V102zM318.9 101.4h.7v5.2h-.7v-2.4H316v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M324.3 106.6l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.2h.6l2 5.2zm-1.6-4.3l-.9 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M329.8 101.4l-.1 5.2h-.4l-2.9-4.1v4.1h-.6v-5.2h.6l2.7 3.9v-3.9zM331.6 101.4v2.3l2.3-2.3h.9l-2.6 2.5 2.6 2.7h-.8l-2.4-2.5v2.5h-.6v-5.2zM335.3 106.2v-.8l.2.2q.1.1.2.1 0 .1.1.1.1.1.2.1.2 0 .3.1h.4q.1.1.2.1t.2-.1h.2q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.2-.1q0-.1-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.2-.2-.3-.4v-.6q0-.2.1-.5.1-.2.4-.4l.2-.2q.2 0 .3-.1h1.2q.1 0 .2.1h.2q.1.1.2.1t.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.2q-.1 0-.2-.1h-.4l-.1.1h-.2q-.1.1-.2.1-.1.1-.2.3-.1.1-.1.2 0 .2.1.3 0 .2.1.3.1 0 .2.1l.1.1h.1q.1.1.2.1l.8.3q.1.1.2.1.1.1.3.1l.2.2.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3l-.2.2q0 .1-.2.2l-.2.2q-.2.1-.3.1-.2.1-.4.1h-.8q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1zM339.4 106.2v-.1-.1-.1q.1 0 .1-.1h.1v-.1h.4l.1.1.1.1v.2q.1 0 .1.1h-.1v.2l-.1.1q-.1 0-.1.1h-.2-.1-.1v-.1h-.1q0-.1-.1-.1v-.1-.1zm.2-1.3l-.2-3.5h.8l-.2 3.5zM289.9 110.9v-.5h4.2v.5h-1.8v4.6h-.6v-4.6zM298.5 110.4h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M303.9 115.5l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M309.3 110.4v5.1h-.4l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM311.2 110.4v2.2l2.3-2.2h.9l-2.6 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.6v-5.1zM314.9 115.1v-.7q.1 0 .2.1 0 .1.1.1.1.1.2.1.1.1.2.1.1.1.3.1h.2q.1.1.2.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2v-.1-.2q0-.1-.1-.1v-.1q-.1-.1-.1-.2-.1 0-.2-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2-.2-.2q0-.1-.1-.2v-.2q-.1-.2-.1-.3 0-.1.1-.3 0-.1.1-.2 0-.2.1-.3l.2-.2q.2-.1.3-.1.1-.1.3-.1.1-.1.3-.1H317.1q.1.1.2.1h.2l.1.1q.1.1.2.1t.2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-1q-.1 0-.2.1l-.1.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.4l.1.1q0 .1.1.1l.1.1q.1.1.2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2H315.8q-.1-.1-.3-.1l-.1-.1h-.2q-.1-.1-.2-.1 0-.1-.1-.1zM319 115.1v-.1-.1l.1-.1.1-.1.1-.1H319.6l.1.1.1.1q0 .1.1.1V115.2l-.1.1v.1h-.1q0 .1-.1.1h-.1-.2q-.1 0-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM311.8 118.3v-.5h4.2v.5h-1.8v4.6h-.7v-4.6zM320.4 117.8h.7v5.1h-.7v-2.4h-2.8v2.4h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M325.8 122.9l-.6-1.6h-2.1l-.5 1.6h-.7l2-5.2h.6l2 5.2zm-1.6-4.3l-.9 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M331.3 117.8v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM333.2 117.8v2.2l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM336.8 122.5v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1.1.2.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1l.2-.2v-.1q.1-.1.1-.2v-.1-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2h-.2q0-.1-.1-.1-.1-.1-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.2-.2-.3-.4-.1-.3-.1-.6 0-.2.2-.5.1-.2.3-.4.1-.1.3-.1.1-.1.2-.1.2-.1.3-.1h1q.1.1.2.1h.1q.1.1.2.1.1.1.2.1l.1.1v.7l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1h-1q-.1 0-.2.1-.1 0-.2.1l-.1.1-.1.1v.2q-.1 0-.1.1t.1.2v.1q0 .1.1.1v.1q.1.1.2.1 0 .1.1.1t.2.1h.1l.8.3q.1.1.3.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2t.1.2v.3q0 .1-.1.2 0 .2-.1.3 0 .1-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.1-.2.1-.3.1h-1.1q-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.1-.1-.1-.2-.1zM340.9 122.5v-.1-.1l.1-.1v-.1h.1l.1-.1H341.5q0 .1.1.1h.1v.1l.1.1V122.6l-.1.1q0 .1-.1.1l-.1.1h-.1-.1-.1q-.1 0-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1zm.2-1.2l-.1-3.5.7-.1-.1 3.6zM291.1 125.7v-.5h4.3v.5h-1.8v4.6h-.7v-4.6zM299.8 125.2h.6v5.1h-.6v-2.4h-2.9v2.4h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M305.2 130.3l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.6l2 5.2zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M310.6 125.2v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM312.5 125.2v2.2l2.3-2.2h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM316.2 129.9v-.7l.1.1q.1.1.2.1.1.1.2.1.1.1.2.1.1.1.2.1h.2q.1.1.3.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1 0-.1.1-.2l.1-.1v-.2l.1-.1v-.2l-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.1-.1h-.2l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2-.2-.2q0-.1-.1-.2v-.2q-.1-.2-.1-.3 0-.1.1-.3 0-.1.1-.2 0-.2.1-.3l.2-.2q.2-.1.3-.1.1-.1.3-.1.1-.1.3-.1H318.4q.1.1.2.1h.2l.1.1q.1 0 .2.1.1 0 .2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.2-.1h-1q-.1 0-.2.1h-.1q-.1.1-.2.3-.1.1-.1.3v.3l.2.2.1.1q.1.1.2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.5q-.1.2-.1.3-.1.1-.2.3l-.2.2q-.1 0-.3.1-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3q-.1 0-.1-.1h-.2q-.1-.1-.2-.1l-.1-.1zM320.2 129.9v-.1q0-.1.1-.1v-.1l.1-.1q.1 0 .1-.1H320.8l.1.1h.1v.1q.1 0 .1.1V130q0 .1-.1.1v.1h-.1q0 .1-.1.1H320.5l-.1-.1q-.1 0-.1-.1v-.1h-.1v-.1zm.3-1.2l-.2-3.6h.8l-.2 3.6zM294.5 143.5v-.5h4.2v.6h-1.8v4.5h-.7v-4.5zM303.1 143h.7v5.1h-.7v-2.3h-2.9v2.3h-.7V143h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M308.5 148.1l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M313.9 143v5.1h-.4l-2.9-4.1v4.1h-.6V143h.6l2.8 3.9V143zM315.8 143v2.3l2.3-2.3h.9l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7V143zM319.5 147.7v-.7q.1.1.2.1 0 .1.1.2.1 0 .2.1h.2q.1.1.2.1.2 0 .3.1h.8q.1-.1.2-.1.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.1-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.3-.5-.1-.3 0-.5 0-.3.1-.5.1-.3.4-.5.1 0 .2-.1.2-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q0 .1.1.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8l-.1.1q-.1 0-.2.1-.1 0-.1.1l-.1.1q-.1.1-.1.2v.4l.1.1.1.1.1.1q.1.1.2.1 0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1.2.1.3.1.1.1.1.2l.2.2q0 .1.1.2v.8q0 .1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.1.1-.3.1-.1 0-.2-.1h-.5q-.1 0-.3-.1h-.1q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1zM323.6 147.7q-.1-.1 0-.1v-.1-.1h.1v-.1h.1l.1-.1h.2l.1.1q.1 0 .1.1h.1v.5l-.1.1-.1.1h-.4q-.1 0-.1-.1l-.1-.1q-.1-.1-.1-.2zm.3-1.2l-.2-3.5h.8l-.2 3.5zM294.4 150.5l.1-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM303.1 149.9h.7v5.2h-.7v-2.4h-2.9v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M308.5 155.1l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.7l2 5.2zm-1.7-4.4l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M313.9 149.9v5.2h-.4l-2.9-4.1v4.1h-.6v-5.2h.6l2.8 3.9v-3.9zM315.8 149.9v2.3l2.3-2.3h.9l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM319.5 154.7v-.8l.2.2.1.1q.1.1.2.1.1.1.2.1t.2.1H321.5q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q0-.1.1-.2v-.2-.1q0-.1-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q0-.1-.1-.2v-.3-.2-.3q0-.1.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1h.3q.1-.1.3 0H321.7q.1 0 .2.1h.2q.1 0 .2.1l.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1.1-.2.1h-.1q-.1.1-.2.1l-.1.1q-.1.1-.1.2-.1 0-.1.1v.5q.1 0 .1.1.1 0 .1.1.1 0 .1.1.1 0 .2.1h.1q.1.1.2.1l.8.3q.1.1.2.1.1.1.2.1.2.1.3.2.1.1.1.2l.2.2q0 .1.1.2v.7q0 .2-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3q0-.1-.1-.1t-.2-.1h-.2q0-.1-.1-.1zM323.6 154.6v-.1q0-.1.1-.2l.1-.1h.4v.1h.1q.1.1.1.2V154.8q0 .1-.1.2h-.1v.1H323.9h-.1q0-.1-.1-.1l-.1-.1v-.1-.1q-.1 0-.1-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM306 135.9v-.6h4.2v.6h-1.8v4.6h-.6v-4.6zM314.6 135.3h.7v5.2h-.7v-2.4h-2.9v2.4h-.6v-5.2h.6v2.3h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M320.1 140.5l-.7-1.6h-2.1l-.6 1.6h-.6l2-5.2h.6l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M325.5 135.3v5.2h-.5l-2.9-4.1v4.1h-.6v-5.2h.6l2.8 3.9v-3.9zM327.4 135.3v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.2zM331 140.1v-.7q.1 0 .2.1t.2.1.2.1.2.1.2.1h.2q.1 0 .2.1h.4q.1-.1.2-.1t.2-.1.2-.1q.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.1-.2-.1q-.1-.1-.1-.2l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2v-.3-.3q0-.1.1-.3l.2-.2.2-.2q.1-.1.2-.1.2-.1.3-.1.2-.1.3-.1H333.2q.1.1.2.1h.2q.1.1.2.1t.1.1q.1 0 .2.1v.7q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.4q-.1-.1-.2 0-.1-.1-.1 0h-.2q-.1 0-.2.1h-.2l-.1.1q-.1.1-.1.2t-.1.1V136.7q0 .1.1.2v.1l.1.1q.1.1.2.1l.1.1q.1 0 .1.1h.2l.8.3q.1.1.2.1.2.1.3.2.1 0 .2.1l.2.2q0 .1.1.2 0 .1.1.2v.7q0 .2-.1.3 0 .1-.1.3-.1.1-.3.2-.1 0-.2.1-.2.1-.3.1-.2.1-.4.1h-.8q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1zM335.1 140.1v-.1-.1q0-.1.1-.1v-.1h.1l.1-.1H335.7q.1.1.2.1 0 .1.1.2V140.2q-.1.1-.1.2-.1 0-.2.1H335.4q-.1 0-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1zm.2-1.2l-.1-3.6h.7l-.1 3.6z"
            />
          </g>
        </g>
        <g id="Layer" opacity={0.2}>
          <g id="Layer">
            <path
              id="Layer"
              className="s1"
              d="M228.9 103.1v-5h16.7v5h-5.5v13.4h-5.8v-13.4zM258.6 98.1h5.8v18.4h-5.8v-6.8h-5.8v6.8H247V98.1h5.8v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M279.5 116.5l-1-2.6H272l-1 2.6h-5.7l7-18.4h6.1l6.9 18.4zm-4.2-11.6l-1.5 4.2h3.1z"
            />
            <path
              id="Layer"
              className="s1"
              d="M303.4 98.1l.1 18.4h-4.8l-6.7-9.2v9.2h-5.6V98.1h5.4l6 8.6v-8.6zM311.2 98.1v7.7l5-7.7h6.6l-5.8 8.8 6.1 9.6h-6.7l-5.1-8.1v8.1h-5.8V98.1zM323.6 115.4l-.1-5.2q.4.2.7.4.4.2.7.4.4.1.7.3l.8.2q.3.2.7.3.4.1.8.1.4.1.8.1.4.1.8.1h.4q.2 0 .4-.1h.5q.2 0 .4-.1.7-.1.7-.8v-.1-.2q0-.1-.1-.2-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.2l-.1-.1-2.9-.7q-.5-.1-1-.3l-1-.4-1-.6q-.4-.3-.8-.7l-.6-.8q-.2-.4-.4-.9-.2-.4-.2-.9-.1-.5-.1-1 0-.7.1-1.3l.4-1.2q.3-.6.7-1.1.5-.5 1-.9.6-.4 1.2-.7.6-.3 1.3-.5.7-.2 1.3-.3.7-.1 1.4-.1h.9q.4.1.9.1.4.1.8.1.4.1.9.2l.6.2q.4.1.7.2.3.2.6.3.3.2.6.3v5.2l-.6-.4q-.3-.2-.7-.4-.3-.1-.7-.3-.3-.1-.7-.2-.4-.2-.7-.2l-.8-.2q-.3-.1-.7-.1H330q-.2 0-.3.1-.2 0-.4.1-.5.2-.5.6V103.7q.1.1.1.2.1 0 .1.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1l2.6.7q.6.2 1.1.4.6.2 1.1.4.5.3.9.7.5.3.9.7l.6.8q.3.4.4.9.2.4.3.9v1q.1.7-.1 1.4-.1.7-.4 1.3-.4.6-.8 1.1-.5.5-1.1.9-.7.3-1.3.6-.7.3-1.4.5-.8.1-1.5.2t-1.5.1h-.8q-.5-.1-.9-.1-.4-.1-.9-.2-.4 0-.8-.1t-.7-.3q-.3-.1-.7-.2-.3-.1-.6-.3-.3-.1-.7-.3zM339.7 110.1l-1.2-12h6.9l-1.2 12zm-1 3.6v-.6q.1-.3.2-.5.1-.3.3-.6l.4-.4q.5-.4 1.1-.6.6-.3 1.2-.3.7 0 1.3.3.5.2 1 .6.5.4.7 1 .3.5.3 1.1 0 .6-.3 1.2-.2.6-.7 1-.5.4-1.1.6-.5.2-1.2.2-.6 0-1.2-.2t-1.1-.6q-.2-.2-.4-.5-.1-.2-.3-.5-.1-.3-.1-.6-.1-.3-.1-.6zM102.9 103.1v-5h16.7v5h-5.4v13.4h-5.8v-13.4zM132.7 98.1h5.8v18.4h-5.8v-6.8h-5.9v6.8H121V98.1h5.8v6.6h5.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M153.6 116.5l-1-2.6h-6.5l-1 2.6h-5.7l6.9-18.4h6.1l6.9 18.4zm-4.2-11.6l-1.5 4.2h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M177.5 98.1v18.4h-4.8l-6.7-9.2v9.2h-5.6V98.1h5.4l6 8.6v-8.6zM185.3 98.1v7.7l4.9-7.7h6.7l-5.9 8.8 6.1 9.6h-6.7l-5.1-8.1v8.1h-5.8V98.1zM197.6 115.4v-5.2q.3.2.7.4.3.2.7.4.3.1.7.3.3.1.7.2.4.2.8.3.4.1.7.1.4.1.8.1.4.1.9.1h.4q.2 0 .4-.1h.4q.2 0 .4-.1.8-.1.8-.8v-.1q0-.1-.1-.2 0-.1-.1-.2l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-2.8-.7q-.6-.1-1.1-.3l-1-.4q-.5-.3-.9-.6-.5-.3-.9-.7-.3-.4-.5-.8-.3-.4-.4-.9-.2-.4-.3-.9v-1q-.1-.7.1-1.3.1-.6.4-1.2.3-.6.7-1.1.4-.5.9-.9.6-.4 1.2-.7.7-.3 1.3-.5.7-.2 1.4-.3.7-.1 1.4-.1h.8q.5.1.9.1.4.1.9.1l.8.2q.3.1.7.2l.6.2q.3.2.7.3.3.2.6.3v5.2q-.3-.2-.7-.4l-.6-.4q-.4-.1-.7-.3l-.8-.2q-.3-.2-.7-.2-.4-.1-.7-.2-.4-.1-.8-.1H204q-.1 0-.3.1-.2 0-.3.1-.6.2-.6.6v.2q0 .1.1.1v.2q.1 0 .2.1.1 0 .1.1.1 0 .2.1h.2q.1.1.2.1l2.7.7q.5.2 1.1.4l1 .4q.5.3 1 .7.4.3.9.7.3.4.5.8.3.4.5.9.1.4.2.9t.1 1q0 .7-.1 1.4-.2.7-.5 1.3-.3.6-.8 1.1-.5.5-1.1.9-.6.3-1.3.6-.7.3-1.4.5-.7.1-1.5.2-.7.1-1.4.1h-.9q-.4-.1-.9-.1l-.8-.2q-.5 0-.9-.1-.3-.1-.7-.3l-.6-.2q-.4-.1-.7-.3-.3-.1-.6-.3zM213.7 110.1l-1.2-12h7l-1.2 12zm-1 3.6v-.6q.1-.3.2-.5.2-.3.3-.6l.4-.4q.5-.4 1.1-.6.6-.3 1.2-.3.7 0 1.3.3.5.2 1 .6.5.4.7 1 .3.5.3 1.1 0 .6-.3 1.2-.2.6-.7 1-.5.4-1 .6-.6.2-1.3.2-.6 0-1.2-.2t-1.1-.6q-.2-.2-.4-.5-.1-.2-.3-.5-.1-.3-.1-.6-.1-.3-.1-.6zM229.4 160.3v-5.5H248v5.5h-6v15h-6.5v-15zM262.6 154.8h6.5v20.5h-6.5v-7.6h-6.5v7.6h-6.5v-20.5h6.5v7.3h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M285.9 175.3l-1.1-3h-7.2l-1.1 3h-6.3l7.7-20.5h6.8l7.7 20.5zm-4.7-13l-1.7 4.8h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M312.6 154.8l-.1 20.5h-5.3l-7.4-10.3v10.3h-6.3v-20.5h6.1l6.7 9.5v-9.5zM321.2 154.8v8.6l5.6-8.6h7.4l-6.6 9.8 6.9 10.7H327l-5.7-9.1v9.1h-6.5v-20.5zM335 174v-5.7l.8.4q.3.2.7.4.4.2.8.3.4.2.8.3.5.2.9.3.4.1.9.2.4 0 .9.1h1.8q.2 0 .4-.1h.5q.9-.2.9-.9v-.2q-.1-.1-.1-.2t-.1-.2q-.1 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.3l-3.1-.8-1.2-.4q-.5-.2-1.1-.5l-1-.6-1-.8q-.3-.4-.6-.9-.3-.4-.5-1l-.2-1q-.1-.5-.1-1.1 0-.7.1-1.4.2-.7.5-1.4.3-.6.8-1.2.4-.6 1-1l1.4-.8q.7-.3 1.4-.5.8-.3 1.5-.4.8-.1 1.6 0h1q.4 0 .9.1.5 0 1 .1.4.1.9.2.4.1.7.2l.8.2q.3.2.7.3.3.2.7.3v5.8q-.4-.2-.7-.4-.4-.3-.8-.4-.4-.2-.7-.4-.4-.1-.8-.3l-.8-.2q-.4-.1-.9-.2h-.8q-.4-.1-.8-.1h-.4q-.2 0-.4.1h-.4q-.2.1-.4.1-.6.2-.6.7v.2q0 .1.1.2 0 .1.1.1 0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2l3 .8 1.2.4q.6.2 1.2.5.6.4 1.1.7.5.4 1 .9.3.4.6.9.3.4.5.9.2.6.2 1.1.1.5.1 1.1.1.8-.1 1.5-.1.8-.5 1.5t-.9 1.2q-.5.6-1.2 1t-1.5.7q-.7.3-1.5.5t-1.7.3q-.8.1-1.6.1-.5 0-1-.1-.5 0-1-.1-.4 0-.9-.1l-1-.2-.8-.2q-.3-.2-.7-.3-.4-.2-.8-.3-.3-.2-.7-.4zM353 168.2l-1.4-13.4h7.8l-1.4 13.4zm-1.1 4v-.7q.1-.3.3-.7.1-.3.3-.5.2-.3.4-.5.6-.5 1.2-.7.7-.3 1.4-.3t1.4.3q.6.2 1.2.7.5.4.7 1.1.3.6.3 1.3 0 .6-.3 1.3-.2.6-.7 1.1-.6.4-1.2.7-.7.2-1.4.2t-1.4-.2q-.6-.3-1.2-.7-.2-.3-.4-.5l-.4-.6q-.1-.3-.2-.7v-.6zM97 160.3v-5.5h18.6v5.5h-6v15h-6.5v-15zM130.2 154.8h6.4v20.5h-6.4v-7.6h-6.6v7.6h-6.4v-20.5h6.4v7.3h6.6z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M153.4 175.3l-1-3h-7.3l-1 3h-6.4l7.8-20.5h6.7l7.8 20.5zm-4.7-13l-1.7 4.8h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M180.1 154.8v20.5h-5.3l-7.5-10.3v10.3h-6.2v-20.5h6l6.7 9.5v-9.5zM188.8 154.8v8.6l5.5-8.6h7.4l-6.5 9.8 6.8 10.7h-7.5l-5.7-9.1v9.1h-6.5v-20.5zM202.5 174v-5.7l.8.4.8.4q.4.2.8.3.4.2.8.3.4.2.8.3.5.1.9.2.4 0 .9.1h1.8q.2 0 .5-.1h.4q.9-.2.9-.9v-.2q0-.1-.1-.2 0-.1-.1-.2l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2l-3.1-.8q-.7-.2-1.2-.4-.6-.2-1.2-.5l-1-.6q-.5-.4-.9-.8t-.7-.9q-.2-.4-.4-1-.2-.5-.3-1-.1-.5 0-1.1-.1-.7.1-1.4.1-.7.4-1.4.3-.6.8-1.2t1.1-1q.6-.4 1.3-.8.7-.3 1.4-.5.8-.3 1.6-.4.7-.1 1.5 0h1q.5 0 .9.1.5 0 1 .1t.9.2l.8.2q.3.1.7.2.4.2.7.3.4.2.7.3v5.8q-.3-.2-.7-.4-.4-.3-.8-.4-.3-.2-.7-.4-.4-.1-.8-.3l-.8-.2-.8-.2h-.9q-.4-.1-.8-.1h-.4q-.2 0-.4.1h-.4q-.2.1-.4.1-.6.2-.6.7v.2q0 .1.1.2 0 .1.1.1 0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.3l2.9.8 1.2.4q.6.2 1.2.5.6.4 1.1.7.5.4 1 .9.4.4.6.9.3.4.5.9.2.6.3 1.1.1.5 0 1.1.1.8-.1 1.5-.1.8-.5 1.5-.3.7-.9 1.2-.5.6-1.2 1t-1.4.7q-.8.3-1.6.5t-1.6.3q-.9.1-1.7.1-.5 0-1-.1-.4 0-.9-.1-.5 0-1-.1t-.9-.2l-.8-.2q-.4-.2-.8-.3-.4-.2-.7-.3l-.8-.4zM220.5 168.2l-1.3-13.4h7.7l-1.4 13.4zm-1.1 4q0-.4.1-.7.1-.3.2-.7.1-.3.3-.5.2-.3.5-.5.5-.5 1.2-.7.7-.3 1.4-.3t1.3.3q.7.2 1.2.7.5.4.8 1.1.3.6.3 1.3 0 .6-.3 1.3-.3.6-.8 1-.5.5-1.2.8-.6.2-1.3.2-.7 0-1.4-.2-.7-.3-1.2-.8-.3-.2-.5-.4-.2-.3-.3-.6-.1-.3-.2-.7-.1-.3-.1-.6zM101.8 170.6v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM110.5 170h.6v5.1h-.6v-2.3h-2.9v2.3h-.7V170h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M115.9 175.1l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M121.3 170v5.1h-.5L118 171v4.1h-.6V170h.6l2.7 3.9V170zM123.2 170v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7V170zM126.9 174.7v-.7l.1.1.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1H128.9q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1.1-.1.1-.2l.1-.1v-.2-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.1-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.3v-.2q-.1-.1 0-.2-.1-.2 0-.3 0-.2.1-.3 0-.1.1-.2.1-.2.3-.2l.2-.2q.2-.1.3-.1.1 0 .3-.1h.7q.1.1.2.1h.2q.1.1.2.1t.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1h-.8l-.1.1q-.1 0-.2.1-.1 0-.1.1l-.1.1q-.1.1-.1.2v.4q.1.1.1.2l.1.1q.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1l.2.2v.2q.1.2.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.6q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM130.9 174.7v-.1q.1 0 .1-.1v-.1h.1v-.1h.1q.1 0 .1-.1h.2l.1.1q.1 0 .1.1h.1v.5l-.1.1-.1.1H131.3h-.1-.1v-.1q-.1 0-.1-.1t-.1-.1v-.1zm.3-1.2l-.2-3.5h.7l-.1 3.5zM89.9 164.7v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM98.6 164.1h.6v5.1h-.6v-2.3h-2.9v2.3H95v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M104 169.2l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M109.4 164.1v5.1h-.5l-2.8-4v4h-.7v-5.1h.6l2.7 3.9v-3.9zM111.3 164.1v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM114.9 168.8v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H117q.1 0 .2-.1.1 0 .1-.1.1 0 .2-.1 0-.1.1-.1v-.2-.2-.1-.2l-.1-.1q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1h-.2l-.7-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q-.1-.1-.1-.2t-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2.2-.2q.1-.1.3-.2.1 0 .3-.1h.3q.1-.1.3-.1h.2q.1.1.2.1h.4l.1.1q.1 0 .2.1.1 0 .2.1h.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1 0-.2.1h-.2l-.1.1q-.2.1-.2.2-.1.2-.1.3v.3q.1.2.2.3l.1.1q.1 0 .1.1h.2l.1.1.8.3q.2 0 .3.1.1.1.2.1l.2.2.2.2q.2.2.2.4.1.3.1.5-.1.3-.2.5-.1.3-.3.4-.1.1-.3.2-.1.1-.3.1-.2.1-.3.1h-.8q-.1 0-.3-.1h-.2q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1zM119 168.8v-.1l.1-.1v-.1q.1 0 .1-.1h.5q0 .1.1.1v.1l.1.1V169q-.1 0-.1.1l-.1.1h-.1q0 .1-.1.1h-.1l-.1-.1h-.2q0-.1-.1-.2v-.2zm.2-1.2l-.1-3.5h.7l-.1 3.5zM89.9 157.8v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM98.6 157.2h.6v5.2h-.6V160h-2.9v2.3H95v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M104 162.3l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M109.4 157.2v5.2h-.5l-2.8-4.1v4.1h-.7v-5.2h.6l2.7 3.9v-3.9zM111.3 157.2v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.9l-2.3-2.5v2.5h-.7v-5.2zM114.9 162v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.2-.2-.3-.4-.1-.3-.1-.5 0-.3.2-.6.1-.2.3-.4.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1l-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.6q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3.1-.2 0-.3-.1h-.5q-.1 0-.2-.1h-.2q0-.1-.1-.1t-.2-.1-.2-.1zM119 161.9v-.1q0-.1.1-.1v-.1h.1q0-.1.1-.1H119.6q.1 0 .1.1h.1q.1.1.1.2v.3l-.2.2q-.1.1-.3.1h-.1l-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM101.8 149.7v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM110.5 149.1h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M115.9 154.2l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M121.3 149.1v5.1h-.5l-2.8-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM123.2 149.1v2.2l2.3-2.2h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM126.9 153.8v-.7l.1.1q.1.1.2.1.1.1.2.1.1.1.2.1.1.1.2.1h.2q.2.1.3.1h.4q.1 0 .2-.1h.2q.1-.1.2-.1 0-.1.1-.1.1-.1.1-.2t.1-.2v-.1-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.1 0-.2-.2 0-.1-.1-.2v-.2q-.1-.1 0-.3-.1-.1 0-.3 0-.1.1-.2 0-.2.1-.3.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1.1-.1.3-.1H129.1q.1.1.2.1t.2.1h.1q.1.1.2.1.1.1.2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.1.1-.1 0-.2.1-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.4l.1.1q0 .1.1.1l.1.1q.1.1.2.1l.1.1h.2l.8.4h.2l.2.2q.1 0 .2.1l.2.2q.2.2.3.5v.5q0 .3-.2.5-.1.2-.3.4-.1.1-.3.2-.1 0-.3.1h-.3q-.2.1-.3.1h-.3q-.1-.1-.2-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1zM130.9 153.8v-.1l.1-.1v-.1h.1v-.1h.1l.1-.1h.2l.1.1h.1q0 .1.1.1v.5l-.1.1-.1.1H131.3h-.1-.1v-.1q-.1 0-.1-.1t-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM81.8 143.8v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM90.5 143.2h.7v5.1h-.7V146h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M95.9 148.3l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2H95z"
            />
            <path
              id="Layer"
              className="s1"
              d="M101.3 143.2v5.1h-.5l-2.8-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM103.2 143.2v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.6v-5.1zM106.9 147.9v-.7l.1.1.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H108.9q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2l.1-.1v-.2-.1q0-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.1-.1l-.8-.3h-.2l-.2-.2q-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.2v-.3q-.1-.1 0-.2v-.3q0-.2.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1.1 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1t.1.1q.1 0 .2.1.1 0 .2.1v.6q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1 0-.2.1h-.1q-.1.1-.2.1t-.1.1q-.1.1-.1.2-.1 0-.1.1v.4q.1.1.1.2l.1.1q.1 0 .1.1h.2q0 .1.1.1.1.1.2.1l.8.3q.1 0 .2.1.1 0 .2.1.2.1.3.2.1.1.1.2.1 0 .2.1 0 .1.1.3v.7q0 .1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1-.2-.1q-.1 0-.1-.1zM111 147.9v-.1-.1h.1v-.1q.1-.1.2-.1H111.6q.1 0 .2.1v.1h.1v.4q-.1 0-.1.1-.1.1-.3.2-.1 0-.2-.1-.2 0-.2-.1-.1-.2-.1-.3zm.2-1.2l-.2-3.5h.7l-.1 3.5z"
            />
            <path
              id="Layer"
              className="s3"
              d="M142.7 162.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM148 162.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M151.4 165.6l-.4-.9h-1.3l-.4.9h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M154.7 162.4v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM155.9 162.4v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.4v1.4h-.4v-3.1zM158.2 165.4v-.5q0 .1.1.1l.1.1h.1l.1.1h.1q.1.1.2.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.2v-.1q0-.1.1-.2.1 0 .1-.1.1-.1.2-.1t.2-.1h.7q.1 0 .2.1h.2q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.2l-.1-.1q-.1 0-.2.1h-.1-.1l-.1.1h-.1v.1q0 .1-.1.1v.2q.1 0 .1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.1.2.1 0 .1.1.1 0 .1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.1q-.1.1-.2 0-.1.1-.2 0h-.4q-.1 0-.1-.1h-.1q-.1 0-.2-.1zM160.7 165.3v-.1l.1-.1H161.1l.1.1V165.5h-.1v.1h-.1-.1-.1l-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM137.2 159.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM142.5 159.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M145.8 162.3l-.3-1h-1.4l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M149.2 159.2v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM150.3 159.2v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM152.6 162.1v-.4h.1l.1.1q.1.1.2.1h.1q0 .1.1.1H154q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1l.1-.1q.1 0 .2-.1h.5q0 .1.1.1h.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4-.1-.1q-.1 0-.1.1h-.1q-.1 0-.1.1l-.1.1V160.1q0 .1.1.1v.1h.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1q.1 0 .1.1l.1.1v.2q.1 0 .1.1V161.7q-.1.1-.1.2t-.1.1q0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.6q-.1-.1-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM155.1 162.1v-.1q.1 0 .1-.1h.1v-.1h.2l.1.1.1.1v.2l-.1.1q-.1 0-.1.1h-.1-.1l-.1-.1-.1-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.2zM132.6 110.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM137.9 110.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M141.3 113.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M144.6 110.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.5v-2.5zM145.8 110.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM148.1 113.3v-.5q0 .1.1.1 0 .1.1.1l.1.1H148.7q0 .1.1.1h.5q.1 0 .1-.1h.1l.1-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.1 0-.3v-.3q.1-.2.3-.3l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2l.1.1v.1h.1q0 .1.1.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.5q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM150.6 113.3v-.1-.1h.1v-.1H151l.1.1v.4h-.4l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM141.4 104.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM146.7 104.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M150.1 107.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M153.4 104.2v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.4l1.7 2.4v-2.4zM154.6 104.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.5-1.5v1.5h-.4v-3.1zM156.8 107.1v-.4h.2q0 .1.1.1l.1.1h.1l.1.1h.8l.1-.1q.1 0 .1-.1l.1-.1v-.1-.1-.1-.1l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1t-.1-.3-.1-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1h.2q.1 0 .2.1h.1q0 .1.1.1l-.1.4v-.1h-.1q-.1 0-.2-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.1q0 .1.1.1v.2h.1l.1.1h.1q.1 0 .1.1l.5.2h.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1l.1.1q0 .1.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H157.5l-.1-.1h-.2q-.1-.1-.2-.1h-.1q0-.1-.1-.1zM159.4 107.1v-.1-.1h.1v-.1H159.8v.1q.1 0 .1.1v.2l-.1.1h-.1v.1h-.1q0-.1-.1-.1t-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM121.5 106.5v-.4h2.6v.4H123v2.8h-.4v-2.8zM126.9 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M130.2 109.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M133.6 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM134.7 106.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM137 109v-.4l.1.1q.1 0 .1.1h.1q.1.1.2.1h.1q.1 0 .1.1h.5q.1-.1.2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1h-.2l-.5-.2-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1-.1.2-.1l.1-.1H138.5l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0h-.4q0-.1-.1-.1h-.1q-.1 0-.1-.1-.1 0-.1-.1zM139.5 109v-.1h.1v-.1h.4v.1l.1.1v.1l-.1.1q0 .1-.1.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM121.5 100.1v-.3h2.6v.3H123v2.8h-.4v-2.8zM126.9 99.8h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M130.2 102.9l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M133.6 99.8v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM134.7 99.8v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM137 102.7v-.5l.1.1.1.1q.1 0 .1.1h.2l.1.1h.6q.1 0 .2-.1h.1l.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H138.1h-.1H137.7l-.1.1h-.1l-.1.1v.4l.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4-.1l-.1-.1h-.2-.1q0-.1-.1-.1t-.1-.1h-.1zM139.5 102.7v-.1q0-.1.1-.1v-.1H139.9l.1.1v.1h.1v.1q0 .1-.1.1v.1h-.1H139.6q0-.1-.1-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM121.5 113.6v-.3h2.6v.3H123v2.8h-.4v-2.8zM126.9 113.3h.4v3.1h-.4V115h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M130.2 116.4l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M133.6 113.3v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM134.7 113.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.5h-.4v-3.2zM137 116.2v-.5l.1.1.1.1.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1 0 .2-.1h.5q0 .1.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1H138.1h-.1-.2q-.1 0-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1V114.2q0 .1.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1l-.1-.1zM139.5 116.2v-.1l.1-.1.1-.1h.2v.1h.1v.1h.1v.1q0 .1-.1.1v.1h-.1q0 .1-.1.1t-.1-.1h-.1q0-.1-.1-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM144.3 98v-.4h2.6v.4h-1.1v2.8h-.4V98zM149.7 97.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M153 100.8l-.4-.9h-1.3l-.3.9h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M156.3 97.7v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM157.5 97.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM159.8 100.6v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .2-.1h.1l.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1l-.1-.1h-.1v-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .1-.1h.2q.1-.1.2-.1H161.2q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4l-.1.1q-.1 0-.1.1h-.1v.5q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V99.9q.1 0 0 .1.1.1 0 .2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1h-.2-.2l-.1-.1q-.1 0-.1-.1h-.1zM162.3 100.6v-.1-.1h.1v-.1H162.7q0 .1.1.1v.4h-.2v.1q-.1 0-.1-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM161.6 106.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM166.9 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M170.3 109.3l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M173.6 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM174.8 106.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM177 109v-.4l.2.2q.1 0 .3.1.1 0 .2.1H178.3q0-.1.1-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1v-.1l-.1-.1q-.1 0-.1-.1h-.2l-.5-.2-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.2-.2-.3v-.3q0-.2.1-.4l.2-.2q.1-.1.2-.1l.1-.1H178.5l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.5q0-.1-.1-.1h-.1l-.1-.1q-.1 0-.1-.1zM179.6 109v-.1-.1h.4q0 .1.1.1V109.2h-.1v.1H179.7q-.1 0-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.4v2.2zM161.6 113.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM166.9 113.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M170.3 116.5l-.4-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M173.6 113.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM174.8 113.3v1.5l1.4-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.5v1.5h-.4v-3.2zM177 116.3v-.5l.2.2q.1.1.3.1.1.1.2.1H178.3l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1 0 .2-.1l.1-.1H178.4q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H178.1h-.1H177.7l-.1.1q-.1 0-.1.1h-.1v.4l.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1q.1.1.1.3.1.1.1.3-.1.1-.1.3l-.2.2q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.5l-.1-.1h-.2l-.1-.1h-.1q-.1 0-.1-.1h-.1zM179.6 116.3v-.1-.1q0-.1.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2q-.1 0-.1.1h-.4v-.1-.1q-.1 0 0 0zm.1-.8l-.1-2.1h.4v2.1zM169.7 100.1v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM175.1 99.8h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M178.4 102.9l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M181.7 99.8v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM182.9 99.8v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM185.2 102.7v-.5q0 .1.1.1 0 .1.1.1t.1.1h.1q.1 0 .2.1h.6q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2H185.8q0 .1-.1.1v.1h-.1v.5l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.5q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM187.7 102.7v-.1-.1h.1v-.1H188.1l.1.1v.4h-.2q-.1.1-.1 0h-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM193.7 98v-.4h2.7v.4h-1.1v2.8h-.4V98zM199.1 97.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M202.4 100.8l-.3-.9h-1.4l-.3.9h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M205.8 97.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM207 97.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM209.2 100.6v-.5l.1.1.1.1q.1 0 .2.1h.1l.1.1h.7l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1h.6l.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H210.3h-.1H209.9l-.1.1q-.1 0-.1.1-.1.1-.1.2v.2q.1.1.1.2h.1l.1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V100.2q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.5l-.1-.1h-.2l-.1-.1h-.1l-.1-.1h-.1zM211.7 100.6v-.1l.1-.1v-.1h.4v.1l.1.1v.2q-.1 0-.1.1h-.1q-.1 0-.1.1 0-.1-.1-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM182.8 106.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM188.1 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M191.4 109.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M194.8 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM196 106.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM198.2 109v-.4l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.2l-.5-.2q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1v-.2l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H199.7l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1-.2q-.1-.1-.2-.1l-.1.1h-.2q-.1 0-.1.1h-.1v.1l-.1.1V107.1q.1 0 .1.1l.1.1h.1q0 .1.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.2H198.6q0-.1-.1-.1h-.1l-.1-.1-.1-.1zM200.7 109q.1 0 .1-.1v-.1h.4v.1h.1V109.2h-.1v.1H200.9q-.1 0-.1-.1t-.1-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM182.8 112.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM188.1 112.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M191.4 115.4l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M194.8 112.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM196 112.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM198.2 115.1v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H199q0 .1.1.1h.2q.1 0 .2-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2 0-.1.1-.1.1-.1.2-.1.1-.1.2-.1H199.7l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2t.1.2l.1.1.1.1h.1v.1h.1l.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1t-.2.1H199.1 198.8h-.1l-.1-.1h-.1q-.1-.1-.2-.1l-.1-.1zM200.7 115.1l.1-.1v-.1h.1q.1-.1.1 0h.2v.1h.1v.2q0 .1-.1.1v.1H200.9l-.1-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM204.3 106.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM209.6 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M213 109.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M216.3 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM217.5 106.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM219.8 109v-.4q0 .1.1.1l.1.1h.1l.1.1h.2l.1.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1-.1l-.4-.2q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2.1 0 .1-.1.1-.1.2-.1t.2-.1H221.3q0 .1.1.1h.1q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1-.1l-.1-.1q-.1 0-.1.1h-.2-.1l-.1.1h-.1v.1q0 .1-.1.1v.2q.1 0 .1.1v.1h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.3 0 0 .1-.1 0h-.4l-.1-.1h-.1q-.1 0-.1-.1-.1 0-.1-.1zM222.3 109v-.1l.1-.1H222.7l.1.1V109.2h-.1v.1h-.2-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM213.1 98v-.4h2.6v.4h-1.1v2.8h-.4V98zM218.4 97.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M221.8 100.8l-.4-.9h-1.3l-.4.9h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M225.1 97.7v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM226.3 97.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM228.6 100.6v-.5l.2.2q.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.1 0-.3v-.3q.1-.2.3-.3l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2l.1.1v.1l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1t-.2.1h-.4q-.1 0-.1-.1h-.4l-.1-.1q-.1 0-.2-.1zM231.1 100.6v-.1-.1h.1v-.1H231.5v.1q.1 0 .1.1v.2l-.1.1h-.1l-.1.1v-.1h-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM225.3 104.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM230.6 104.2h.4v3.1h-.4v-1.4h-1.8l.1 1.4h-.5v-3.1h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M234 107.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M237.3 104.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM238.5 104.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM240.8 107.1v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.7l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2l-.1-.1q0-.1.1-.2v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H242.4l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1v.1h-.1v.1q-.1.1-.1.2h.1v.2l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM243.3 107.1v-.1-.1h.1v-.1H243.7v.1q.1 0 .1.1v.2l-.1.1q-.1 0-.1.1h-.1q0-.1-.1-.1t-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM226.6 110.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM231.9 110.3h.4v3.2h-.4v-1.4h-1.8l.1 1.4h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M235.3 113.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M238.6 110.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.6 2.5v-2.5zM239.8 110.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM242.1 113.3v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1 0-.2-.1-.1 0-.1v-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1v.1h-.1v.1q-.1.1 0 .2-.1 0 0 0v.2h.1q0 .1.1.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1h-.5q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM244.6 113.3v-.1-.1h.1v-.1H245l.1.1v.4H244.8h-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM247.1 104.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM252.4 104.2h.5v3.1h-.5v-1.4h-1.7v1.4h-.5v-3.1h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M255.8 107.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M259.1 104.2v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM260.3 104.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM262.6 107.1v-.4h.1q0 .1.1.1t.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1-.1-.1-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1 0-.1-.1-.1t-.1-.1-.1-.1l-.1-.1v-.2-.1-.2-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H264.2l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2H263.2q0 .1-.1.1v.1h-.1v.5l.1.1h.1v.1h.2l.5.2q.1.1.2.1t.1.1.1.1q.1 0 .2.1v.1l.1.1v.7l-.1.1-.2.2q-.1 0-.1.1h-.2q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM265.1 107.1v-.1-.1h.1v-.1H265.5q0 .1.1.1v.4h-.1q-.1 0-.1.1h-.1v-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM234.4 98v-.4h2.6v.4h-1.1v2.8h-.4V98zM239.8 97.7h.4v3.1h-.5v-1.4H238v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M243.1 100.8l-.4-.9h-1.3l-.4.9h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M246.4 97.7v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM247.6 97.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM249.9 100.6v-.5q0 .1.1.1 0 .1.1.1t.1.1h.1q.1 0 .2.1h.6q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1l-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3 0-.2.2-.3l.1-.1h.2q.1-.1.2-.1H251.3q0 .1.1.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2-.1-.5q0 .1-.1.1l-.1.1v.4q0 .1.1.2h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.5q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM252.4 100.6v-.1-.1h.1v-.1H252.8q0 .1.1.1v.4h-.2v.1l-.1-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM255.6 98v-.4h2.6v.4h-1.1v2.8h-.4V98zM260.9 97.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M264.3 100.8l-.4-.9h-1.3l-.4.9h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M267.6 97.7v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM268.8 97.7v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM271 100.6v-.5l.1.1.1.1q.1 0 .1.1h.2l.1.1h.6q.1 0 .2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H272.1h-.1H271.7l-.1.1q-.1 0-.2.1v.4q0 .1.1.2h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H271.4h-.1l-.1-.1q-.1 0-.1-.1h-.1zM273.6 100.6q-.1-.1 0-.1v-.1q0-.1.1-.1H274v.1q.1 0 .1.1v.2l-.1.1h-.1l-.1.1v-.1h-.1-.1v-.1-.1h-.1zm.2-.8l-.1-2.2h.5l-.1 2.2zM267.9 104.5v-.3h2.7v.3h-1.1v2.8h-.5v-2.8zM273.3 104.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M276.6 107.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M280 104.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM281.1 104.2v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM283.4 107.1v-.4q.1 0 .2.1t.3.1q.1.1.2.1H284.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.1-.3-.1-.1-.1-.3 0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2l-.1.1-.1.1q-.1.1-.1.2v.2q.1.1.1.2h.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V106.7q-.1.1-.1.2t-.1.1q0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5l-.1-.1h-.2-.1q0-.1-.1-.1h-.1q0-.1-.1-.1zM285.9 107.1v-.1l.1-.1v-.1h.4v.1l.1.1v.2q-.1 0-.1.1h-.1v.1h-.1-.1v-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM247.1 110.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM252.4 110.3h.5v3.2h-.5v-1.4h-1.7v1.4h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M255.8 113.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M259.1 110.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.5v-2.5zM260.3 110.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM262.6 113.3v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1-.1-.1-.1q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.1-.1-.2-.3-.1-.1 0-.3 0-.2.1-.3 0-.2.2-.3l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2H263.2q0 .1-.1.1v.1h-.1v.5l.1.1h.1v.1h.2l.5.2q.1.1.2.1t.1.1.1.1q.1 0 .2.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.5q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.1-.1h-.1zM265.1 113.3v-.1-.1h.1v-.1H265.5l.1.1v.4h-.4q0-.1-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM267.9 113.7v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM273.3 113.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M276.6 116.5l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M280 113.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM281.1 113.3v1.5l1.5-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM283.4 116.3v-.5l.1.1.1.1q.1 0 .2.1h.2l.1.1H284.7l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1l.1-.1H284.8q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H284.5h-.1H284.1l-.1.1q-.1 0-.2.1v.4q.1.1.1.2h.1l.1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1.1.1.1.1v.1q.1.1.1.2V115.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1H284.3q-.1 0-.2-.1H283.8l-.1-.1h-.1l-.1-.1h-.1zM285.9 116.3v-.1l.1-.1q0-.1.1-.1h.2q.1 0 .1.1l.1.1v.2h-.1v.1h-.2-.1-.1v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM288.6 113.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM294 113.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M297.3 116.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M300.6 113.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM301.8 113.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM304.1 116.3v-.5q0 .1.1.1 0 .1.1.1t.1.1H304.7q0 .1.1.1h.5q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1q.1 0 0-.1.1 0 0-.1v-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1q.1 0 .2-.1h.8q0 .1.1.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2-.1-.5q0 .1-.1.1v.1h-.1v.5q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.4l-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM306.6 116.3v-.1q0-.1.1-.1 0-.1.1-.1h.2l.1.1v.2q.1 0 0 0v.2h-.4q0-.1-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM277.5 100.1v-.3h2.7v.3h-1.1v2.8h-.5v-2.8zM282.9 99.8h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M286.2 102.9l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M289.6 99.8v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM290.7 99.8v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM293 102.7v-.5l.2.2q.1.1.3.1.1.1.2.1H294.3l.1-.1h.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H294.1h-.1H293.7l-.1.1h-.1q0 .1-.1.1v.4l.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1.1.1.1.1v.1q.1.1.1.2V102.3q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1H293.9q-.1 0-.2-.1H293.4h-.1q0-.1-.1-.1t-.1-.1h-.1zM295.5 102.7v-.1l.1-.1v-.1h.4v.1l.1.1v.2h-.1v.1h-.2q-.1.1-.1 0h-.1q0-.1-.1-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM286.6 106.5v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM292 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M295.3 109.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M298.7 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM299.9 106.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM302.1 109v-.4l.2.2q.2 0 .3.1.1 0 .2.1H303.4q0-.1.1-.1h.1l.1-.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.2l-.5-.2q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1v-.2l-.1-.1v-.2-.2q0-.1.1-.1 0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H303.6l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H303.3q-.1-.1-.2-.1l-.1.1h-.2q-.1 0-.1.1h-.1v.1l-.1.1V107.1q.1 0 .1.1l.1.1h.1q0 .1.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.2H302.5q0-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM304.6 109l.1-.1v-.1h.4v.1h.1V109.2h-.1v.1H304.8q-.1 0-.1-.1t-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM299 98v-.4h2.6v.4h-1.1v2.8h-.4V98zM304.4 97.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V99h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M307.7 100.8l-.4-.9H306l-.4.9h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M311.1 97.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM312.2 97.7v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM314.5 100.6v-.5q0 .1.1.1l.1.1q.1 0 .1.1h.2l.1.1h.6q.1 0 .2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1H315.9q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H315.6h-.4l-.1.1q-.1 0-.2.1v.4q0 .1.1.2h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4-.1l-.1-.1h-.2l-.1-.1h-.1q-.1 0-.1-.1h-.1zM317 100.6v-.1-.1h.1v-.1H317.4q.1 0 .1.1v.1l.1.1q-.1 0-.1.1v.1h-.1-.1v.1l-.1-.1h-.1l-.1-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM305.9 106.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM311.2 106.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M314.5 109.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M317.9 106.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM319.1 106.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM321.3 109v-.4l.1.1q.1 0 .2.1h.1q0 .1.1.1h.1q.1 0 .2.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1-.1l-.5-.2q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1H322.8l.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1-.2q-.1-.1-.2-.1l-.1.1h-.1-.1q-.1 0-.1.1h-.1v.1l-.1.1V107.1q.1 0 .1.1l.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.2H321.7q0-.1-.1-.1h-.1l-.1-.1-.1-.1zM323.9 109q-.1 0 0 0v-.1-.1h.4q0 .1.1.1V109.2h-.1v.1H324q-.1 0-.1-.1v-.1q-.1 0 0-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM305.9 112.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM311.2 112.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M314.5 115.4l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M317.9 112.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM319.1 112.2v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM321.3 115.1v-.4q.1 0 .1.1.1 0 .2.1h.1q0 .1.1.1H322.1q0 .1.1.1H322.5l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2 0-.1.1-.1.1-.1.2-.1.1-.1.2-.1H322.8l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.1q-.1 0-.1.1-.1 0-.1.1-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1t-.2.1h-.4H322 321.7q0-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1zM323.9 115.1q-.1 0 0-.1v-.1h.2q.1-.1.1 0h.1l.1.1V115.3h-.1v.1H324l-.1-.1v-.1q-.1 0 0-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM204.3 112.6v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM209.6 112.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M213 115.4l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M216.3 112.2v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM217.5 112.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM219.8 115.1v-.4l.1.1.1.1h.1l.1.1H220.5l.1.1H220.9l.1-.1h.1q.1 0 .1-.1h.1l.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.1q.1-.1.1-.2l.1-.1q.1-.1.2-.1.1-.1.2-.1H221.3q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.2-.1q0-.1-.1-.1h-.2q-.1 0-.2.1h-.1l-.1.1h-.1v.1l-.1.1v.2q.1 0 .1.1t.1.1l.1.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2.1 0 .1.1v.5l-.1.1v.2q-.1 0-.2.1t-.2.1l-.1.1h-.5-.5l-.1-.1h-.1l-.1-.1q-.1 0-.1-.1zM222.3 115.1v-.1l.1-.1H222.7l.1.1V115.3q-.1 0-.1.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM121.5 164.5v-.4h2.6v.4H123v2.8h-.4v-2.8zM126.9 164.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M130.2 167.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M133.6 164.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM134.7 164.1v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM137 167.1v-.5l.1.1q.1 0 .1.1h.1q.1.1.2.1h.1l.1.1h.5q.1 0 .2-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1h-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1l.1-.1H138.4l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.4-.1H137.7q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1h.2q0 .1.1.2l.1.1v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1h-.2l-.1-.1H137.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM139.5 167.1v-.1q0-.1.1-.1v-.1H139.9q.1 0 .1.1l.1.1v.1l-.1.1v.1h-.1H139.6q0-.1-.1-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM157 159.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM162.3 159.2h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M165.7 162.3l-.4-1H164l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.6 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M169 159.2v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM170.2 159.2v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM172.4 162.1v-.4q.1.1.2.1.2.1.3.1.1.1.3.1h.6l.1-.1q.1 0 .1-.1l.1-.1v-.1-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1l.1-.1q.1-.1.2-.1.1-.1.2-.1t.2-.1h.4q0 .1.1.1h.2l.1.1h.2q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.2-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.2l.1.1.1.1.1.1h.1l.1.1.5.2h.1q.1.1.2.1t.1.1.1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H173.1h-.1q-.1-.1-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM175 162.1v-.1-.1h.1q0-.1.1-.1h.2v.1h.1v.1q.1.1 0 .1.1 0 0 .1v.1h-.1v.1h-.2l-.1-.1h-.1v-.1-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM158.2 166.4v-.3h2.7v.3h-1.1v2.8h-.4v-2.8zM163.6 166.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M166.9 169.2l-.3-1h-1.4l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M170.3 166.1v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM171.4 166.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM173.7 169v-.4q.1.1.2.1.1.1.3.1.1.1.2.1H175.1q0-.1.1-.1l.1-.1q.1-.1.1-.2v-.2q-.1-.1-.1-.2-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1 0 .2-.1h.5q0 .1.1.1h.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H174.8h-.1-.2q-.1 0-.1.1h-.1l-.1.1q-.1.1-.1.2v.2q.1.1.1.2h.1q0 .1.1.1h.1l.1.1.5.1q0 .1.1.1t.2.1l.1.1q.1 0 .1.1l.1.1v.2q.1 0 .1.1V168.6q-.1.1-.1.2t-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1q0-.1-.1-.1zM176.2 169v-.1h.1v-.1h.1v-.1h.2l.1.1.1.1v.2q-.1 0-.1.1l-.1.1h-.1-.1l-.1-.1q0-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM182.8 166.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM188.1 166.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M191.4 169.2l-.3-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M194.8 166.1v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM196 166.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM198.2 169v-.4h.1q.1.1.2.1 0 .1.1.1h.1l.1.1H199.6l.1-.1q.1 0 .1-.1l.1-.1v-.1-.1-.1q0-.1-.1-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1q0-.1.1-.2.1 0 .2-.1h.2l.1-.1h.5q0 .1.1.1h.2l.1.1h.1l.1.1v.4l-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.4-.1-.1q-.1 0-.1.1h-.1q-.1.1-.1.2-.1 0-.1.1t.1.2.1.2h.1q0 .1.1.1t.1.1l.5.1q.1.1.2.1t.1.1q.1 0 .2.1l.1.1v.1q.1.1.1.2V168.8l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1zM200.7 169q0-.1.1-.1v-.1q.1 0 .1-.1h.2q0 .1.1.1l.1.1v.2l-.1.1-.1.1h-.2v-.1h-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM206 168.1v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM211.3 167.8h.4v3.1h-.4v-1.4h-1.8l.1 1.4h-.5v-3.1h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M214.7 170.9l-.4-1H213l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M218 167.8v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM219.2 167.8v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM221.5 170.7v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2q-.1-.1-.1-.2l.1-.1v-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H223.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1 0 .1-.1.1 0 .1-.1.2 0 .1.1.2 0 .1.1.2h.1v.1h.2l.5.2q.1.1.2.1t.1.1.1.1q.1 0 .2.1v.1l.1.1v.7l-.1.1q-.1.1-.2.1l-.2.2h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM224 170.7v-.1-.1h.1v-.1H224.4q.1.1.1.2.1.1 0 .1v.2h-.1q0 .1-.1.1h-.1v-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM204.3 161.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM209.6 160.9h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M213 164.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M216.3 160.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM217.5 160.9v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM219.8 163.9v-.5q0 .1.1.1 0 .1.1.1l.1.1H220.4q0 .1.1.1h.5q.1 0 .1-.1h.1l.1-.1q.1 0 .1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.2 0-.3v-.3q.1-.2.2-.3.1 0 .2-.1.1 0 .2-.1h.7q.1 0 .2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1q-.1 0-.1-.1h-.2-.1-.5-.1l-.1.1h-.1v.1q0 .1-.1.1v.2l.1.1v.1h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1.1 0 .1.1v.5q0 .1-.1.1v.2q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.3.1h-.1q-.1 0-.1-.1H220.2q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM222.3 163.9v-.1-.1h.1v-.1H222.7v.1h.1V164l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM229.1 161.3v-.4h2.7v.4h-1.2v2.8h-.4v-2.8zM234.5 160.9h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M237.8 164.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M241.2 160.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM242.3 160.9v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM244.6 163.9v-.5l.1.1.1.1q.1 0 .1.1H245.2l.1.1h.5q.1 0 .2-.1h.1q0-.1.1-.1v-.1l.1-.1v-.1-.1l-.1-.1v-.1h-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1l.1-.1H246l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.4-.1H245.3q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H245l-.1-.1h-.1q-.1 0-.1-.1h-.1zM247.1 163.9v-.1q0-.1.1-.1v-.1H247.5q.1 0 .1.1l.1.1v.2q-.1 0-.1.1h-.4q0-.1-.1-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM193.7 155.7v-.3h2.7v.3h-1.1v2.8h-.4v-2.8zM199.1 155.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M202.4 158.5l-.3-.9h-1.4l-.3.9h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M205.8 155.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.5v-2.5zM207 155.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5L207 157v1.5h-.4v-3.2zM209.2 158.3v-.5l.2.2q.1.1.3.1.1.1.2.1H210.5l.1-.1h.1q.1-.1.1-.2.1-.1.1-.2v-.2q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1 0 .2-.1.1 0 .1-.1H210.6l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H210.3h-.1H209.9l-.1.1q-.1 0-.1.1h-.1v.4q0 .1.1.1l.1.1q.1 0 .1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V157.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.5l-.1-.1h-.2l-.1-.1h-.1l-.1-.1h-.1zM211.7 158.3v-.1h.1v-.1q0-.1.1-.1h.2q.1 0 .1.1l.1.1v.2h-.1v.1h-.1v.1h-.1-.1v-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM175 155.7v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM180.3 155.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M183.7 158.5l-.4-1H182l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M187 155.3v3.2h-.3L185 156v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM188.2 155.3v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM190.4 158.3v-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2v-.2-.2l-.1-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.2v-.1q.1-.1.1-.2.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.4-.1-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2q0 .1.1.1v.1h.1v.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1l-.1.1h-.3-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1zM193 158.3v-.1-.1h.1v-.1H193.4l.1.1v.1q.1 0 .1.1h-.1v.2h-.1H193.1q0-.1-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM221.9 155.7v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM227.2 155.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M230.6 158.5l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M233.9 155.3v3.2h-.3l-1.7-2.5v2.5h-.4v-3.1h.3l1.7 2.4v-2.4zM235.1 155.3v1.5l1.4-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.5v1.5h-.5v-3.2zM237.3 158.3v-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2v-.2-.2l-.1-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.2v-.1q.1-.1.1-.2.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.4-.1-.1q0 .1-.1.1t-.1.1q-.1.1-.1.2t.1.2q0 .1.1.1v.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1l-.1.1h-.3-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1zM239.9 158.3v-.1-.1h.1v-.1h.2q.1 0 .1.1.1 0 .1.1v.2l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM250.9 161.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM256.2 160.9h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M259.6 164.1l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.6l-.6 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M262.9 160.9v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.3l1.7 2.4v-2.4zM264.1 160.9v1.4l1.4-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.5v1.5h-.4v-3.2zM266.3 163.9v-.5l.1.1q.1.1.2.1l.1.1h.2q.1.1.2.1h.5q.1 0 .1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2q.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7q.1 0 .1.1H268.1q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1q-.1 0-.2-.1h-.1-.4-.1-.1-.1l-.1.1h-.1v.1l-.1.1v.2q0 .1.1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM268.9 163.9v-.1-.1l.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM269 156.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM274.3 156.3l.4-.1v3.2h-.4V158h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M277.7 159.4l-.4-.9H276l-.4.9h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M281 156.3v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM282.2 156.3v1.4l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM284.5 159.2v-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1 0 .2.1h.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1 0 .2-.1h.5q0 .1.1.1h.2q.1 0 .1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H285.6h-.1-.2q-.1 0-.1.1h-.1q-.1 0-.1.1l-.1.1V157.2q0 .1.1.1v.1h.1q0 .1.1.1h.1l.1.1.5.2h.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V158.8q-.1.1-.1.2t-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5q-.1 0-.1-.1h-.2-.1l-.1-.1q-.1 0-.1-.1h-.1zM287 159.2v-.1-.1h.1v-.1H287.4l.1.1v.1q.1 0 0 .1.1 0 0 0v.2h-.1-.1q0 .1 0 0h-.2q0-.1-.1-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM269 164.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM274.3 164.1h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M277.7 167.3l-.4-1H276l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M281 164.1v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.5v-2.5zM282.2 164.1v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM284.5 167.1v-.5l.2.2q.1.1.2.1.2 0 .3.1h.5q.1 0 .1-.1h.1l.1-.1q.1 0 .1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1-.1-.2t.1-.2v-.1q.1-.1.1-.2.1 0 .2-.1l.1-.1q.1 0 .2-.1h.7q.1 0 .2.1h.2q0 .1.1.1h.1v.5q-.1 0-.1-.1h-.1l-.1-.1q-.1 0-.1-.1h-.2-.1-.5-.1l-.1.1-.1.1v.1l-.1.1q0 .1.1.1v.2h.1q0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1q.1 0 .1.1v.6q-.1.1-.1.2-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.2.1h-.2q-.1 0-.1-.1H284.9q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM287 167.1v-.1-.1h.1v-.1H287.4l.1.1q.1.1 0 .2.1 0 0 0v.1q0 .1-.1.1H287.1v-.1h-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM273.3 174.1v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM278.6 173.7h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M281.9 176.9l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M285.3 173.7v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM286.5 173.7v1.4l1.4-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.5v1.5h-.4v-3.2zM288.7 176.7v-.5l.2.2q.2.1.3.1.1 0 .3.1h.5q0-.1.1-.1h.1l.1-.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1q-.1 0-.1-.1h-.1-.1l-.5-.2q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1-.1-.1-.1-.3-.1-.2-.1-.3 0-.2.1-.3.1-.2.2-.3.1-.1.2-.1t.2-.1h.7q.1 0 .1.1H290.5q0 .1.1.1l.1.1v.4l-.1-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1-.2l-.1-.1q-.1 0-.2.1h-.1-.1l-.1.1h-.1v.1l-.1.1v.2l.1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2l.1.1q.1.1.1.2.1 0 .1.1l.1.1v.5q0 .1-.1.1 0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0 0 .1-.1 0h-.2-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM291.2 176.7v-.1l.1-.1q0-.1.1-.1H291.7v.1h.1V176.8h-.1v.1h-.2-.1-.1v-.1l-.1-.1zm.2-.8l-.1-2.2h.5l-.1 2.2zM288.6 166.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM294 166.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M297.3 169.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M300.6 166.1v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM301.8 166.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM304.1 169v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1q.1 0 0-.1.1 0 0-.1v-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2.1-.1h.2q.1 0 .2-.1h.4q.1.1.2.1h.2q0 .1.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H304.9q-.1 0-.2.1h-.1v.1q-.1 0-.1.1v.4q.1 0 .1.1h.1q0 .1.1.1t.1.1l.5.1q.1.1.2.1t.1.1q.1 0 .2.1l.1.1v.1q.1.1.1.2V168.8l-.1.1-.2.2q-.1 0-.1.1h-.2q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM306.6 169v-.1q0-.1.1-.1l.1-.1h.2v.1h.1v.2q.1 0 0 .1v.1h-.1v.1h-.2q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM288.6 161.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM294 160.9h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M297.3 164.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M300.6 160.9v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM301.8 160.9v1.4l1.4-1.4h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM304.1 163.9v-.5q0 .1.1.1 0 .1.1.1t.1.1H304.7q0 .1.1.1h.5q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1q.1 0 0-.1.1 0 0-.1v-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3 0-.2.2-.3l.1-.1q.1 0 .2-.1h.8l.1.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1q-.1 0-.1-.1H305.3h-.1-.5l-.1.1-.1.1v.5h.1q0 .1.1.1h.1v.1h.1l.5.2q.1 0 .2.1.1 0 .1.1.1 0 .2.1l.1.1v.1q.1 0 .1.1v.6q-.1.1-.1.2-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.4q-.1 0-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM306.6 163.9v-.1-.1h.1v-.1H307l.1.1v.4h-.4l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM309.5 159.5v-.3h2.6v.3H311v2.8h-.4v-2.8zM314.8 159.2h.4v3.2h-.4v-1.5H313v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M318.2 162.3l-.4-.9h-1.3l-.4.9h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M321.5 159.2v3.2h-.3l-1.7-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM322.7 159.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM325 162.1v-.4h.1l.1.1.1.1q.1 0 .2.1H326.4q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1h-.1v-.1q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1l-.1-.1v-.2-.1-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H326.5q.1 0 .1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1 0 .1.1.1 0 .1.1.1v.2q.1 0 .1.1V161.7q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1H325.6q-.1 0-.2-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM327.5 162.1v-.1-.1h.1q0-.1.1-.1h.2v.1h.1v.4h-.1v.1h-.2l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM308.2 170.3v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM313.6 169.9h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M316.9 173.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M320.3 169.9v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM321.4 169.9v1.4l1.5-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM323.7 172.8v-.4q0 .1.1.1t.1.1h.1q.1.1.2.1h.1q.1 0 .1.1h.5q.1-.1.2-.1l.1-.1h.1v-.1q0-.1.1-.1v-.1-.1l-.1-.1v-.1h-.1q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1l.1-.1q.1 0 .2-.1h.9q0 .1.1.1h.1l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1H324.9q0-.1-.1-.1h-.2l-.1.1h-.1q-.1 0-.1.1-.1 0-.2.1v.4q0 .1.1.1l.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.2q.1.1.1.2t-.1.2v.1q-.1.1-.1.2-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1H324.5 324.2h-.1q0-.1-.1-.1h-.1l-.1-.1q-.1 0-.1-.1zM326.2 172.8v-.1l.1-.1h.4v.1q0 .1.1.1v.1q-.1 0-.1.1t-.1.1h-.1-.1-.1v-.1q-.1 0-.1-.1v-.1zm.1-.7v-2.2h.4l-.1 2.2zM309.5 166.4v-.3h2.6v.3H311v2.8h-.4v-2.8zM314.8 166.1h.4v3.1h-.4v-1.4H313v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M318.2 169.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M321.5 166.1v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM322.7 166.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM325 169v-.4h.1l.1.1.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1h-.1v-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.4-.1H325.7q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H325.4l-.1-.1h-.2l-.1-.1zM327.5 169v-.1q0-.1.1-.1l.1-.1h.2v.1h.1v.4h-.1v.1h-.2q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM248.5 168.1v-.3h2.6v.3H250v2.8h-.4v-2.8zM253.9 167.8h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M257.2 170.9l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M260.6 167.8v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM261.7 167.8v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM264 170.7v-.4h.1l.1.1q.1 0 .1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H265.1h-.1H264.7l-.1.1q-.1 0-.1.1h-.1v.4q0 .1.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1-.1.1-.2.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1l-.1-.1zM266.5 170.7v-.1q0-.1.1-.1v-.1H266.9l.1.1v.1q.1 0 .1.1l-.1.1v.1h-.1l-.1.1h-.1v-.1h-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM228.6 168.1v-.3h2.7v.3h-1.1v2.8h-.5v-2.8zM234 167.8h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M237.3 170.9l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M240.7 167.8v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM241.8 167.8v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM244.1 170.7v-.4q.1 0 .2.1t.3.1q.1.1.2.1H245.5q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.1-.3-.1-.1-.1-.3 0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2l-.1.1q-.1 0-.1.1h-.1v.4q0 .1.1.1v.1h.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V170.3q-.1.1-.1.2t-.1.1-.1.1l-.2.2h-.2q-.1.1-.2.1h-.5l-.1-.1h-.2-.1q0-.1-.1-.1h-.1q0-.1-.1-.1zM246.6 170.7v-.1l.1-.1v-.1h.4v.1l.1.1v.2q-.1 0-.1.1h-.1v.1h-.1-.1v-.1h-.1v-.1h-.1v-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM181.1 162.8v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM186.4 162.4h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M189.7 165.6l-.3-.9h-1.3l-.4.9h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M193.1 162.4v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM194.3 162.4v1.4l1.4-1.4h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.4v1.4h-.5v-3.1zM196.5 165.4v-.5l.2.2q.2.1.3.1.1 0 .2.1H197.8q0-.1.1-.1h.1l.1-.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.2-.1h.7q.1 0 .1.1h.2l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H197.7q-.1-.1-.2-.1l-.1.1h-.2q-.1 0-.1.1h-.1v.1l-.1.1V163.4q.1 0 .1.1l.1.1h.1q0 .1.1.1h.1l.5.2q.1 0 .1.1.1 0 .2.1h.1q.1.1.1.2.1 0 .1.1v.1l.1.1v.4l-.1.1q0 .1-.1.2 0 .1-.1.1-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.2H196.9l-.1-.1h-.1l-.1-.1h-.1zM199 165.3v-.1q.1 0 .1-.1h.4v.4l-.1.1H199.1q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM135 168.1v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM140.3 167.8h.5v3.1h-.5v-1.4h-1.7v1.4h-.5v-3.1h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M143.7 170.9l-.4-1H142l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M147.1 167.8v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM148.2 167.8v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM150.5 170.7v-.4h.1q0 .1.1.1t.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1l-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1 0-.1-.1-.1t-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3 0-.2.2-.3l.1-.1h.2q.1-.1.2-.1h.4q.1 0 .2.1h.2l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2-.1-.5q0 .1-.1.1 0 .1-.1.1v.4q.1.1.1.2h.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q-.1.1-.2.1h-.5l-.1-.1h-.2-.1q0-.1-.1-.1h-.1l-.1-.1zM153 170.7v-.1-.1h.1v-.1H153.4l.1.1v.1q.1 0 .1.1l-.1.1v.1h-.1l-.1.1h-.1v-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1z"
            />
            <path
              id="Layer"
              className="s1"
              d="M92 135.1v-.6h4.3v.6h-1.8v4.6h-.7v-4.6zM100.7 134.5h.7v5.2h-.7v-2.4h-2.9v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M106.1 139.7l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.2h.7l2 5.2zm-1.7-4.4l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M111.5 134.5v5.2h-.5l-2.8-4.1v4.1h-.7v-5.2h.7l2.7 3.9v-3.9zM113.4 134.5v2.3l2.3-2.3h.8L114 137l2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.2zM117.1 139.3v-.8q.1.1.1.2.1 0 .2.1t.2.1.2.1.2.1H119.1q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q0-.1.1-.2v-.2-.1q0-.1-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q0-.1-.1-.2v-.3-.2-.3q0-.1.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1h.3q.1-.1.3 0H119.3q.1 0 .2.1h.2q.1 0 .1.1.1 0 .2.1.1 0 .2.1v.7q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1.1-.2.1h-.1q-.1.1-.2.1l-.1.1q-.1.1-.1.2-.1 0-.1.1v.5q.1 0 .1.1.1 0 .1.1.1 0 .1.1.1 0 .2.1h.1q.1.1.2.1l.8.3q.1.1.2.1.1.1.2.1.2.1.3.2.1.1.1.2l.2.2q0 .1.1.2v.7q0 .2-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1h-.2q0-.1-.1-.2zM121.2 139.2v-.1q0-.1.1-.2l.1-.1h.4v.1h.1q.1.1.1.2V139.4q0 .1-.1.2h-.1v.1H121.5h-.1q0-.1-.1-.1l-.1-.1v-.1-.1q-.1 0 0-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM80.9 127.5v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM89.6 126.9h.6v5.1h-.6v-2.3h-2.9v2.3H86v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M95 132l-.6-1.6h-2.1l-.6 1.6H91l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M100.4 126.9v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM102.3 126.9v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM105.9 131.6v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1q.1-.1 0-.2.1-.1 0-.2v-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.1-.1-.1-.3 0-.1-.1-.2v-.3q0-.1.1-.2v-.3q.1-.2.2-.3l.2-.2q.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1l-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1 0 .2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.3v.5q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3.1-.2 0-.3-.1h-.5q-.1 0-.2-.1h-.2q0-.1-.1-.1-.1-.1-.2-.1t-.2-.1zM110 131.6v-.1-.1l.1-.1.1-.1h.1q0-.1.1-.1h.1q.1 0 .1.1h.1l.1.1q0 .1.1.1v.4h-.1v.1q-.1 0-.1.1h-.1-.1H110.2q0-.1-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM97 120.1v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM105.6 119.5h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M111 124.6l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M116.5 119.5v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM118.3 119.5v2.3l2.4-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM122 124.2v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H123.8q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q0-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H123.4h-.1q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q0 .1-.1.2V120.9q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM126.1 124.2v-.2q0-.1.1-.1.1-.1.2-.1 0-.1.1-.1h.1q.1 0 .1.1h.1v.1h.1v.1l.1.1v.2l-.1.1q0 .1-.1.1v.1h-.1-.1-.1-.2l-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.2 3.5zM88.6 111.9v-.6h4.2v.6H91v4.5h-.7v-4.5zM97.2 111.3h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M102.6 116.4l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M108 111.3v5.1h-.4l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM109.9 111.3v2.3l2.3-2.3h.9l-2.6 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.6v-5.1zM113.6 116v-.7q.1.1.2.1.1.1.1.2.1 0 .2.1.1 0 .2.1h.3q.1 0 .2.1H115.4q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2l-.1-.1v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1l-.2-.2q-.2-.2-.3-.4v-.5q0-.3.1-.6l.4-.4q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1h-.4q-.1-.1-.2-.1t-.2.1h-.2q-.1 0-.1.1h-.2q-.1.1-.2.3-.1.1-.1.3v.3l.2.2.1.1q.1 0 .2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2.2.2v.2q.1.1.1.2v.5q0 .2-.1.3-.1.1-.2.3-.1.1-.2.1-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-1q-.1-.1-.2-.1t-.2-.1-.2-.1-.2-.1l-.1-.1zM117.7 116v-.1-.1-.1h.1q0-.1.1-.1t.1-.1h.1q.1 0 .1.1h.2v.1q.1.1.1.2.1.1 0 .3 0 .1-.1.2h-.3q0 .1-.1 0h-.1q-.1 0-.1-.1h-.1v-.1-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM89.9 104.8v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM98.6 104.2h.6v5.1h-.6V107h-2.9v2.3H95v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M104 109.3l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M109.4 104.2v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.7 3.9v-3.9zM111.3 104.2v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM114.9 108.9v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.3-.3-.5-.1-.3-.1-.5 0-.3.2-.6.1-.2.3-.4.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1l-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.6q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3.1-.2 0-.3-.1h-.5q-.1 0-.2-.1h-.2q0-.1-.1-.1t-.2-.1-.2-.1zM119 108.9v-.1-.1q.1 0 .1-.1l.1-.1h.1q0-.1.1-.1t.2.1.2.1l.1.1v.4h-.1v.1q-.1 0-.1.1h-.1-.1H119.2q0-.1-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM92 98.4v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM100.7 97.8h.7v5.1h-.7v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M106.1 102.9l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M111.5 97.8v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM113.4 97.8v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM117.1 102.5v-.7l.2.2.1.1q.1 0 .2.1.1 0 .2.1h.2q.1.1.3.1h.8q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2l.1-.1v-.2-.1q0-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1t-.1-.1l-.8-.2q-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1t-.2-.1q-.2-.2-.3-.5-.1-.2 0-.5 0-.3.1-.5.1-.3.4-.4l.2-.2q.2 0 .3-.1.1 0 .3-.1h.7q.1.1.2.1h.2q.1.1.2.1t.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1H118.5q-.1 0-.2.1h-.1q-.1 0-.2.1-.1 0-.1.1l-.1.1q-.1.1-.1.2v.4q.1.1.1.2.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1.2.1.3.2.1 0 .1.1l.2.2q0 .1.1.2v.8q0 .1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.5q-.2 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1zM121.2 102.5q-.1 0 0-.1v-.1q0-.1.1-.1v-.1h.2q0-.1.1-.1 0 .1.1.1h.1l.1.1.1.1v.5h-.1q0 .1-.1.1t-.1.1h-.2q0-.1-.1-.1h-.1v-.1h-.1v-.1-.1q-.1 0 0-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM324.9 98.4v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM333.6 97.8h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M339 102.9l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M344.4 97.8v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM346.3 97.8v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM349.9 102.5v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H352q.1-.1.2-.1t.1-.1q.2-.1.2-.2.1-.2.1-.3.1-.2 0-.3-.1-.2-.2-.3 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.2q-.1-.1-.2-.1-.2-.1-.3-.2-.1 0-.1-.1-.1-.1-.2-.1l-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2.2-.2q.1-.1.3-.2.1-.1.3-.1.1 0 .3-.1h.7q.1.1.2.1h.2l.1.1q.1 0 .2.1h.2l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1t-.2-.1h-.8q-.1.1-.2.1t-.1.1q-.1 0-.2.1 0 .1-.1.1v.6q0 .1.1.2l.1.1.1.1h.1q.1.1.2.1t.1.1l.8.3q.2 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q.1.2.1.3v.2q0 .1-.1.3v.3l-.2.2-.2.2q-.2.1-.3.2-.1 0-.3.1-.2 0-.3.1h-.6q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1zM354 102.5v-.1-.1h.1v-.1q.1 0 .1-.1h.2v-.1q.1 0 .1.1h.2q0 .1.1.1v.1h.1v.4q-.1 0-.1.1l-.1.1h-.1l-.1.1h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1v-.2zm.2-1.2l-.1-3.5h.7l-.1 3.5zM326.2 104.8v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM334.8 104.2h.7v5.1h-.7V107h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M340.2 109.3l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M345.7 104.2v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM347.6 104.2l-.1 2.3 2.4-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM351.2 108.9v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H353q.1 0 .2-.1h.2q.1-.1.2-.1.1-.1.2-.1v-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q.1-.2.1-.3.1-.2.2-.3l.2-.2q.1 0 .3-.1.1-.1.2-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.2.1l-.1.1q-.1 0-.1.1v.2l-.1.1v.2q.1 0 .1.1v.1l.1.1q.1.1.2.1 0 .1.1.1.1.1.2.1h.1l.8.4q.1 0 .3.1.1 0 .2.1t.2.1l.2.2q0 .1.1.2t.1.2v.3q.1.1 0 .2.1.1 0 .3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-.4q-.1.1-.3.1-.1 0-.2-.1h-.5q-.1 0-.2-.1h-.2q-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1zM355.3 108.9v-.1-.1l.1-.1q.1-.1.2-.1 0-.1.1-.1t.2.1q.1 0 .1.1h.1v.1l.1.1v.2l-.1.1q0 .1-.1.1 0 .1-.1.1H355.6q-.1 0-.2-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM324.9 111.9v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM333.6 111.3h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M339 116.4l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M344.4 111.3v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM346.3 111.3v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM349.9 116v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2l.1-.1q.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.3-.3-.5-.1-.3-.1-.5 0-.3.2-.5.1-.3.3-.5.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1l-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.3q0 .1-.1.3v.2q-.1.2-.2.3l-.2.2q-.2.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3.1h-.3q-.1-.1-.2-.1h-.3q-.1 0-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1t-.2-.1zM354 116v-.1-.1l.1-.1.1-.1h.2v-.1h.1l.1.1h.1l.1.1.1.1v.4l-.1.1-.1.1h-.1-.1q0 .1-.1.1v-.1h-.2l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM342 120.1v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM350.6 119.5h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M356.1 124.6l-.6-1.5h-2.2l-.5 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M361.5 119.5v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM363.4 119.5v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM367 124.2v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H368.8q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q0-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1H368.3q-.1.1-.2.1t-.2.1q-.1 0-.1.1l-.1.1q0 .1-.1.2V120.9q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM371.1 124.2v-.1-.1l.1-.1.1-.1h.1q0-.1.1-.1h.1q.1 0 .1.1h.1l.1.1q0 .1.1.1v.4h-.1v.1q-.1 0-.1.1h-.1-.1H371.3q0-.1-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM321.6 129v-.6h4.2v.6h-1.7v4.5h-.7V129zM330.3 128.4h.6v5.1h-.7v-2.3h-2.8v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M335.6 133.5l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M341.1 128.4v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM343 128.4v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7v-5.1zM346.6 133.1v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.1-.2l-.1-.1q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1h-.2l-.7-.3q-.1-.1-.3-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.1-.1l-.2-.2q-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q.1-.2.1-.3l.2-.2.2-.2q.1-.1.3-.2.1 0 .2-.1.2 0 .3-.1h.6q.1 0 .2.1H349q.1.1.2.1t.2.1h.2q0 .1.1.2v.7q-.1-.1-.1-.2-.1 0-.2-.1-.1 0-.2-.1h-.2q0-.1-.1-.1t-.2-.1H348.1q-.1 0-.2.1h-.2q-.1.1-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.2v.1q.1 0 .2.1h.1q.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1l.2.2.2.2q.1 0 .1.1.1.1.1.3 0 .1.1.2v.2q0 .2-.1.3 0 .1-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2.1-.3.1-.2 0-.3.1h-.8q-.2-.1-.3-.1h-.2q-.1-.1-.2-.1t-.2-.1l-.1-.1q-.1 0-.2-.1zM350.7 133.1v-.1-.1h.1v-.1q.1 0 .1-.1h.5v.1q.1 0 .1.1h.1v.4q-.1 0-.1.1h-.1v.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM343.5 136.4v-.6h4.3v.6H346v4.5h-.7v-4.5zM352.2 135.8h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M357.6 140.9l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M363 135.8v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM364.9 135.8v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM368.6 140.5v-.7q0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H370.6q.1-.1.2-.1t.2-.1l.1-.1q0-.1.1-.2v-.1q.1-.1.1-.2v-.1q-.1-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-.2l-.7-.4h-.2l-.2-.2q-.1 0-.2-.1t-.2-.1q-.2-.2-.3-.5-.1-.2-.1-.5.1-.3.2-.5.1-.3.3-.4.2-.1.3-.2.1 0 .3-.1.1 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1l.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1H370q-.1 0-.2.1h-.2q0 .1-.1.1t-.2.1q0 .1-.1.1v.6q.1.1.1.2l.1.1q.1 0 .1.1h.2q0 .1.1.1t.1.1l.9.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q.1.2.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.6q-.1 0-.2-.1h-.2q-.2 0-.3-.1h-.1q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1zM372.6 140.5v-.1l.1-.1q0-.1.1-.1v-.1h.5l.1.1v.1h.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2zm.3-1.2l-.2-3.5h.8l-.2 3.5zM322.9 143.8v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM331.5 143.2h.7v5.1h-.7V146h-2.9v2.3h-.6v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M336.9 148.3l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M342.4 143.2v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM344.2 143.2v2.3l2.3-2.3h.9l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM347.9 147.9v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2.1 0 .1-.1v-.2-.1-.2q-.1 0-.1-.1t-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3h-.2l-.2-.2q-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.2-.1-.2-.1-.3v-.2-.3q0-.2.1-.3l.2-.2.2-.2.2-.2q.2 0 .3-.1.2 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1t.2.1q.1 0 .1.1.1 0 .2.1v.6q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1H349.3l-.1.1h-.2q-.1.1-.2.1t-.1.1l-.1.1q0 .1-.1.2V144.6q0 .1.1.2v.1q.1 0 .1.1.1 0 .2.1l.1.1h.1q.1.1.2.1l.8.3q.1 0 .2.1.2 0 .3.1l.2.2.2.2.1.1q0 .1.1.3v.7q0 .1-.1.3-.1.1-.1.2-.1.1-.3.2l-.2.2q-.2.1-.3.1-.2.1-.4.1h-.8q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1-.1 0-.2-.1zM352 147.9v-.2q.1 0 .1-.1.1-.1.2-.1h.1q.1-.1.1 0h.2q0 .1.1.1v.1h.1v.4q-.1 0-.1.1l-.1.1h-.1l-.1.1h-.1l-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM326.2 161.6v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM334.8 161h.7v5.1h-.7v-2.3h-2.9v2.3h-.6V161h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M340.2 166.1l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M345.7 161v5.1h-.5l-2.9-4v4h-.6V161h.6l2.7 3.9V161zM347.6 161l-.1 2.3 2.4-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.4-2.4v2.4h-.6V161zM351.2 165.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1h.8q.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1v-.2q.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.2-.3q.1-.2.1-.3.1-.1.2-.3.1-.1.2-.1.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1-.1.1v.2q-.1.1-.1.2v.1l.1.1v.1q.1.1.1.2h.2q0 .1.1.1.1.1.2.1l.1.1.8.3q.1 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1 0 .1.1.2t.1.2v.3q.1.1 0 .2.1.1 0 .3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1zM355.3 165.7v-.1-.1h.1v-.1l.1-.1h.5v.1q.1 0 .1.1l.1.1v.2q0 .1-.1.1v.1h-.1v.1h-.1q0 .1-.1.1h-.1-.1v-.1h-.1q-.1 0-.1-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM326.2 168.6v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM334.8 168h.7v5.1h-.7v-2.3h-2.9v2.3h-.6V168h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M340.2 173.1l-.6-1.6h-2.1l-.6 1.6h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M345.7 168v5.1h-.5l-2.9-4.1v4.1h-.6V168h.6l2.7 3.9V168zM347.6 168l-.1 2.3 2.4-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.4-2.4v2.4h-.6V168zM351.2 172.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.8q.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1v-.2q.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1q-.2-.2-.3-.5-.1-.3-.1-.5 0-.3.2-.5.1-.3.3-.4.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1-.1.1v.2q-.1.1-.1.2v.1l.1.1q0 .1.1.1v.2h.2q0 .1.1.1.1.1.2.1l.1.1.8.3q.1 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2v.3q.1.1 0 .2.1.1 0 .3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.1-.2.1-.3.2h-.4q-.1.1-.3.1-.1 0-.2-.1h-.5q-.1 0-.2-.1h-.2q-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1zM355.3 172.7v-.1-.1l.1-.1q0-.1.1-.1h.1v-.1h.2q.1 0 .1.1h.1v.1h.1v.1l.1.1v.2l-.1.1q0 .1-.1.1v.1h-.1-.1-.1-.2l-.1-.1v-.1h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM337.7 154v-.6h4.3v.6h-1.8v4.6h-.7V154zM346.4 153.4h.6v5.1h-.7v-2.3h-2.8v2.3h-.7v-5.1h.7v2.2h2.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M351.8 158.5l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M357.2 153.4v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM359.1 153.4v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM362.8 158.1v-.7q0 .1.1.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H364.8q.1-.1.2-.1t.2-.1l.1-.1q0-.1.1-.2v-.1q.1-.1.1-.2v-.1q-.1-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.3-.5-.1-.2-.1-.5.1-.3.2-.5.1-.3.3-.4.2-.1.3-.2.1-.1.3-.1.1 0 .3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.1q.1.1.2.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.8q-.1.1-.2.1l-.1.1-.2.2q-.1.1-.1.3v.3q.1.2.2.3.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q.1.1.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1h-.3q-.2.1-.3.1h-.3q-.1-.1-.2-.1h-.2q-.2 0-.3-.1h-.1q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM366.8 158.1v-.1l.1-.1q0-.1.1-.1v-.1h.2q0-.1.1 0 0-.1.1 0h.1q.1 0 .1.1v.1h.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.2l-.1-.1h-.1v-.1h-.1v-.1l-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5z"
            />
          </g>
        </g>
        <path id="Layer" d="M390.8 260.1L30 260" fill="none" stroke="#fff" />
        <path
          id="Layer"
          d="M102.6 224.1c-.4 4.4-2.9 5.7-3.9 5.6-1.1-.1-7.4-2.5-7.6-1.6-.1.9 3.7 3.7 6.4 3.9 0 0-5.1 5.5-8.4 3.9-3.3-1.6-3.6-4.1-5.7-5.1s-3.8-1-3 0c.8 1 4.6 1.9 5.8 4 1.2 2.1 2.3 3.6-1.2 3-3.4-.5-9.1-3.1-9.3-6v-.3V231.8c-.7.1-2.2 1.5-3.3 2.5l-.8.8-.3.3c-2.2 2.5-3.4 7.6-3.4 7.6l-.8-.1q.1-1.2.5-2.4.3-1.2.9-2.3.6-1.1 1.3-2.1.8-1 1.8-1.8.6-.5 1.2-1.1.6-.6 1-1.3.5-.7.9-1.5.4-.7.7-1.5s-4.9-5.7-.3-9.3c0 0 3.4-2.1 6.1-1.9 0 0-3.8 6.5-2.6 7.2 1.3.6 3.8-7.6 3.8-7.6s7.7-.7 10.1.4c0 0-6.5 5.4-5.1 5.7 1.4.3 8.4-4.9 8.4-4.9s7.2 1.3 6.8 5.6z"
          fill="#32dac8"
        />
        <path
          id="Layer"
          d="M56.9 224.8c-1.5 3.1-8.7 3.6-12.8 2.8-4.1-.8-2.3-2-.1-3.8 2.2-1.9 6.7-1.4 8.1-2.1 1.3-.8-.6-1.5-3.3-1.3-2.8.2-4.1 2.9-8.5 3.4-4.4.4-7.7-7.9-7.7-7.9 3 1 8.4-.7 8.7-1.7.2-1.1-7.8-.9-9.1-1.3-1.2-.3-3.4-2.8-2.1-7.8 1.4-5 9.8-3.4 9.8-3.4s5.7 8.8 7.4 9c1.7.2-3.4-8.5-3.4-8.5 3.2-.3 11.4 3.7 11.4 3.7s-.5 10.2 1.2 10c1.6-.2 0-9.1 0-9.1 3 1 6 4.6 6 4.6 3.6 6-4.2 10.3-4.2 10.3z"
          fill="#7ff9e2"
        />
        <path
          id="Layer"
          className="s7"
          d="M61.6 232q.5 1.4.7 2.9.2 1.4 0 2.9-.1 1.5-.5 2.9l-.9-.3s.8-6.1-.6-9.7l-.3-.6q-.4-.7-.5-1.1c-.8-1.6-1.9-3.7-2.7-4.2 0 0 4.9-6.8-22.9-18.1 0 0 21.9 7.1 24.4 15q0 1 .2 2 .1 1 .4 2 .2.9.6 1.8.4 1 .9 1.8.7 1.3 1.2 2.7z"
        />
        <path
          id="Layer"
          d="M70.7 215.7s-10.3-5.8-5.5-13.6c0 0 3.9-4.8 8-6 0 0-2.2 11.7 0 12 2.2.2 1.5-13.2 1.5-13.2s10.9-5.2 15.1-4.8c0 0-6.7 11.4-4.5 11.1 2.2-.3 9.7-11.8 9.7-11.8s11.1-2 12.9 4.5c1.8 6.5-1.1 9.9-2.7 10.3-1.7.4-12.3.2-12 1.6.3 1.4 7.4 3.6 11.4 2.4 0 0-4.4 10.8-10.1 10.2-5.8-.5-7.6-4.1-11.2-4.3-3.5-.3-6.1.6-4.3 1.6 1.7 1 7.8.3 10.7 2.8 2.8 2.4 5.2 4-.2 5-5.3 1.1-15 .4-16.9-3.7z"
          fill="#01a982"
        />
        <path
          id="Layer"
          className="s7"
          d="M72.6 219.8c-1 .6-2.5 3.4-3.5 5.5q-.2.5-.7 1.5l-.3.7c-1.9 4.7-.9 12.9-.9 12.9l-1.1.3q-.6-1.9-.7-3.8-.2-2 0-3.9.3-1.9.9-3.8.7-1.8 1.7-3.5.6-1.1 1.1-2.4.5-1.2.8-2.4.4-1.3.6-2.6.2-1.3.2-2.6c3.3-10.4 32.1-19.8 32.1-19.8-36.6 14.9-30.2 23.9-30.2 23.9z"
        />
        <path
          id="Layer"
          className="s9"
          d="M62.6 257c-2.4-1.1-4.6-2.5-6.5-4.4-1.9-1.9-3.4-4.1-4.4-6.6-1-2.4-1.5-5-1.5-7.7h40.3c0 2.7-.5 5.3-1.5 7.7-1 2.5-2.5 4.7-4.4 6.6-1.9 1.9-4.1 3.3-6.5 4.4-2.5 1-5.1 1.5-7.7 1.5-2.7 0-5.3-.5-7.8-1.5z"
        />
        <path
          id="Layer"
          d="M75.3 258.3q-.3.1-.6.1t-.6.1H72.8c-2.6 0-5.3-.5-7.7-1.5-2.4-1.1-4.7-2.5-6.5-4.4-1.9-1.9-3.4-4.1-4.4-6.6-1-2.4-1.5-5-1.5-7.7h5c0 2.5.4 4.9 1.3 7.2.8 2.2 2.1 4.3 3.7 6.2 1.6 1.8 3.6 3.3 5.7 4.5 2.2 1.1 4.5 1.8 6.9 2.1z"
          fill="#fec901"
        />
        <path
          id="Layer"
          className="s9"
          d="M89.3 257.3q.4.2.8.5.3.4.5.8.2.5.2 1H49.9q0-.5.2-1 .2-.4.5-.8.4-.3.9-.5.4-.2.9-.2h35.9q.5 0 1 .2z"
        />
        <path
          id="Layer"
          d="M90.9 238.4q.1.1.1.2 0 .2-.1.3-.1 0-.2.1H49.8q-.1-.1-.2-.1-.1-.1-.1-.3 0-.1.1-.2t.2-.1h40.9q.1 0 .2.1z"
          fill="#f46700"
        />
        <path id="Layer" className="s0" d="M116.3 119.1h260.1v17.1H116.3z" />
        <g id="Layer" opacity={0.2}>
          <g id="Layer">
            <path
              id="Layer"
              className="s1"
              d="M248.5 66v-5h16.7v5h-5.5v13.4h-5.8V66zM278.2 61h5.8v18.4h-5.8v-6.8h-5.8v6.8h-5.9V61h5.9v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M299.1 79.4l-1-2.7h-6.5l-1 2.7H285l7-18.4h6l7 18.4zm-4.2-11.6l-1.5 4.3h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M323 61v18.4h-4.8l-6.7-9.2v9.2H306V61h5.4l6.1 8.6V61zM330.8 61v7.7l5-7.7h6.6l-5.9 8.8 6.2 9.6h-6.8l-5.1-8.1v8.1H325V61zM343.1 78.3v-5.1q.4.2.7.3.3.2.7.4.4.1.7.3.4.1.7.2.4.2.8.3.4.1.8.1.4.1.8.1.4.1.8.1h.4q.2 0 .4-.1h.4q.3 0 .5-.1.7-.1.7-.8v-.1-.2q-.1-.1-.1-.2-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1l-2.8-.7q-.5-.1-1-.3-.6-.2-1-.4l-1-.6q-.4-.3-.8-.7-.4-.4-.6-.8-.2-.4-.4-.9-.2-.4-.2-.9-.1-.5-.1-1 0-.7.1-1.3.1-.6.4-1.2.3-.6.7-1.1.4-.5 1-.9.5-.4 1.2-.7.6-.3 1.3-.5.6-.2 1.3-.3.7-.1 1.4-.1h.9q.4.1.8.1.5 0 .9.1l.8.2q.4.1.7.2l.6.2q.4.1.7.2.3.2.6.3v5.2q-.3-.3-.6-.4-.4-.2-.7-.4-.4-.2-.7-.3-.4-.1-.7-.3l-.8-.2q-.3 0-.7-.1-.4-.1-.8-.1H349.6q-.2 0-.4.1-.2 0-.3.1-.6.1-.6.6v.2l.1.1q0 .1.1.2l.1.1q.1 0 .2.1.1 0 .2.1h.1q.1.1.2.1l2.7.7q.5.1 1.1.4l1 .4q.5.3 1 .7.5.3.9.7l.6.8q.2.4.4.9.1.4.2.9t.1 1q0 .7-.1 1.4-.2.7-.5 1.3-.3.6-.8 1.1-.5.5-1.1.9-.6.3-1.3.6-.7.3-1.4.4-.7.2-1.4.3-.8.1-1.5.1h-.9q-.4-.1-.9-.1l-.8-.2q-.4 0-.9-.2-.3 0-.6-.1-.4-.1-.7-.3l-.6-.2q-.4-.2-.7-.3zM359.3 73l-1.2-12h6.9l-1.2 12zm-1 3.6V76l.2-.6q.1-.3.3-.5.2-.3.4-.4.5-.5 1.1-.7.6-.2 1.2-.2t1.2.2q.6.2 1.1.7.4.4.7.9.2.6.2 1.2t-.2 1.2q-.3.5-.7.9-.5.4-1.1.7-.6.2-1.2.2t-1.2-.2q-.6-.3-1.1-.7l-.4-.4q-.2-.2-.3-.5l-.2-.6v-.6zM122.5 66v-5h16.7v5h-5.4v13.4H128V66zM152.3 61h5.8v18.4h-5.9v-6.8h-5.8v6.8h-5.8V61h5.8v6.6h5.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M173.1 79.4l-.9-2.7h-6.5l-1 2.7H159l7-18.4h6.1l6.9 18.4zm-4.2-11.6l-1.5 4.3h3z"
            />
            <path
              id="Layer"
              className="s1"
              d="M197.1 61v18.4h-4.8l-6.7-9.2v9.2H180V61h5.4l6.1 8.6V61zM204.9 61v7.7l4.9-7.7h6.7l-5.9 8.8 6.1 9.6H210l-5.1-8.1v8.1h-5.8V61zM217.2 78.3v-5.1q.3.2.7.3l.6.4q.4.1.8.3.3.1.7.2.4.2.8.3.3.1.7.1.4.1.8.1.4.1.8.1h.5q.2 0 .4-.1h.4q.2 0 .4-.1.8-.1.8-.8v-.1q-.1-.1-.1-.2t-.1-.2l-.1-.1q-.1 0-.2-.1l-.1-.1h-.2q-.1 0-.2-.1l-2.8-.7q-.6-.1-1.1-.3l-1-.4q-.5-.3-.9-.6-.5-.3-.9-.7l-.6-.8q-.2-.4-.4-.9-.1-.4-.2-.9t0-1q-.1-.7.1-1.3.1-.6.4-1.2.3-.6.7-1.1.4-.5.9-.9.6-.4 1.2-.7.6-.3 1.3-.5.7-.2 1.4-.3.7-.1 1.4-.1.4 0 .8.1h.9q.4.1.9.2.4 0 .8.1.3.1.7.2l.6.2q.3.2.6.3.4.1.7.3v5.1q-.4-.2-.7-.4-.3-.2-.7-.3-.3-.2-.7-.3-.3-.2-.7-.3-.3-.1-.7-.2-.4-.1-.8-.1-.3-.1-.7-.1h-1.5q-.2 0-.3.1h-.4q-.5.2-.5.7V66.6l.1.1.2.2.1.1h.2q.1.1.2.1t.2.1l2.6.7q.6.1 1.1.3.6.2 1.1.5l1 .6.8.8q.4.3.6.8.3.4.4.9.2.4.3.9.1.5 0 1 .1.7 0 1.3-.2.7-.5 1.3-.3.6-.8 1.1-.4.5-1 .9t-1.3.6q-.7.3-1.4.5-.8.2-1.5.3h-2.3q-.5 0-.9-.1-.4 0-.9-.1l-.8-.2q-.4-.1-.7-.2-.4-.1-.7-.2-.3-.2-.7-.3-.3-.2-.6-.3zM233.3 73l-1.2-12h7l-1.2 12zm-1 3.6V76l.2-.6q.1-.3.3-.5.2-.3.4-.4.5-.5 1.1-.7.6-.2 1.2-.2t1.2.2q.6.2 1.1.7.4.4.7.9.2.6.2 1.2t-.2 1.2q-.3.5-.7.9-.5.4-1.1.7-.6.2-1.2.2t-1.2-.2q-.6-.3-1.1-.7l-.4-.4q-.2-.2-.3-.5l-.2-.6v-.6zM249 123.2v-5.5h18.6v5.5h-6v15h-6.5v-15zM282.2 117.7h6.4v20.5h-6.4v-7.6h-6.5v7.6h-6.5v-20.5h6.5v7.3h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M305.4 138.2l-1-3h-7.2l-1.1 3h-6.3l7.7-20.5h6.7l7.8 20.5zm-4.7-12.9L299 130h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M332.1 117.7v20.5h-5.3l-7.5-10.3v10.3h-6.2v-20.5h6l6.8 9.5v-9.5zM340.8 117.7v8.6l5.5-8.6h7.4l-6.5 9.8 6.8 10.7h-7.5l-5.7-9.1v9.1h-6.5v-20.5zM354.6 136.9l-.1-5.7.8.4.8.4q.4.2.8.3.4.2.8.3.4.2.8.3.5.1.9.2.5.1.9.1H362.1q.3 0 .5-.1h.4q.9-.2.9-.9v-.2q0-.1-.1-.2v-.2q-.1 0-.2-.1t-.2-.1-.2-.1-.2-.1h-.2l-3.1-.8-1.2-.4q-.6-.2-1.1-.5-.6-.2-1.1-.6-.5-.4-.9-.8t-.7-.9q-.2-.4-.4-1-.2-.5-.3-1v-1.1q-.1-.7.1-1.4.1-.7.4-1.4l.8-1.2q.5-.6 1.1-1 .6-.4 1.3-.8.7-.3 1.5-.5.7-.3 1.5-.4.8-.1 1.5 0h1q.5 0 1 .1.4 0 .9.1l1 .2q.3.1.7.2.4.1.7.2.4.1.7.3.4.1.7.3v5.7q-.3-.2-.7-.4-.4-.2-.7-.4-.4-.2-.8-.3-.4-.2-.8-.3l-.8-.2-.8-.2q-.4-.1-.9-.1h-1.6q-.2 0-.4.1-.2 0-.3.1-.7.2-.7.7v.1q.1.1.1.2t.1.2l.1.1q.1.1.2.1.1.1.2.1.1.1.2.1.2 0 .3.1l2.9.8q.6.1 1.2.3.7.3 1.2.6.6.3 1.1.7l1 .8q.4.4.6.9.3.5.5 1t.3 1q.1.6.1 1.1 0 .8-.1 1.6-.2.7-.6 1.4-.3.7-.9 1.2-.5.6-1.1 1-.8.4-1.5.7-.8.3-1.6.5t-1.6.3q-.8.1-1.7.1h-.9q-.5-.1-1-.1l-1-.2q-.4-.1-.9-.2l-.8-.2-.8-.2q-.3-.2-.7-.3l-.8-.4zM372.6 131.1l-1.4-13.4h7.7l-1.3 13.4zm-1.1 4v-.7l.2-.6q.2-.4.4-.6.2-.3.4-.5.5-.5 1.2-.7.7-.3 1.4-.3t1.3.3q.7.2 1.2.7.5.4.8 1.1.3.6.3 1.3 0 .6-.3 1.3-.3.6-.8 1.1-.5.4-1.2.7-.6.2-1.3.2-.7 0-1.4-.2-.7-.3-1.2-.7-.2-.3-.4-.5l-.4-.6q-.1-.3-.2-.7v-.6zM116.6 123.2v-5.5h18.6v5.5h-6v15h-6.5v-15zM149.7 117.7h6.5v20.5h-6.5v-7.6h-6.5v7.6h-6.5v-20.5h6.5v7.3h6.5z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M173 138.2l-1.1-3h-7.2l-1 3h-6.4l7.8-20.5h6.7l7.8 20.5zm-4.7-12.9l-1.7 4.7h3.4z"
            />
            <path
              id="Layer"
              className="s1"
              d="M199.7 117.7v20.5h-5.3l-7.5-10.3v10.3h-6.2v-20.5h6l6.7 9.5v-9.5zM208.4 117.7v8.6l5.5-8.6h7.4l-6.5 9.8 6.8 10.7h-7.5l-5.7-9.1v9.1h-6.5v-20.5zM222.1 136.9v-5.7l.8.4q.3.2.7.4.4.2.8.3.4.2.8.3.5.2.9.3.4.1.9.2.4.1.9.1h1.8q.2 0 .5-.1h.4q.9-.2.9-.9v-.2q0-.1-.1-.2 0-.1-.1-.2l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.2l-3.2-.8-1.2-.4q-.5-.2-1.1-.5-.5-.2-1-.6l-1-.8q-.3-.4-.6-.9-.3-.4-.4-1-.2-.5-.3-1-.1-.5 0-1.1-.1-.7.1-1.4.1-.7.4-1.4.3-.6.8-1.2t1-1l1.4-.8q.7-.3 1.4-.5.8-.3 1.5-.4.8-.1 1.6 0h1q.4 0 .9.1.5 0 1 .1t.9.2l.8.2q.3.1.7.2.3.1.7.3.3.1.7.3v5.7q-.4-.2-.7-.4l-.8-.4q-.3-.2-.7-.3-.4-.2-.8-.3l-.8-.2-.8-.2q-.5-.1-.9-.1h-1.6q-.2 0-.4.1-.2 0-.4.1-.6.2-.6.7v.1q0 .1.1.2 0 .1.1.2 0 .1.1.1.1.1.2.1.1.1.2.1.1.1.2.1t.2.1l3 .8q.6.1 1.2.3l1.2.6q.6.3 1.1.7l1 .8q.3.4.6.9t.5 1q.2.5.3 1v1.1q.1.8-.1 1.6-.1.7-.5 1.4-.3.7-.9 1.3-.5.5-1.2.9t-1.5.7q-.7.3-1.5.5t-1.6.3q-.9.1-1.7.1h-1q-.5-.1-.9-.1l-1-.2q-.5-.1-.9-.2l-.8-.2-.8-.2q-.4-.2-.7-.3l-.8-.4zM240.1 131.1l-1.3-13.4h7.7l-1.4 13.4zm-1.1 4q0-.4.1-.7l.2-.6q.1-.4.3-.6.2-.3.5-.5.2-.3.5-.4.3-.2.7-.3.3-.2.6-.2.4-.1.7-.1.4 0 .7.1.4 0 .7.2.3.1.6.3.3.1.6.4.5.4.8 1.1.3.6.3 1.3 0 .6-.3 1.3-.3.6-.8 1-.3.3-.6.5t-.6.3q-.3.1-.7.2-.3.1-.7.1-.3 0-.7-.1l-.6-.2q-.4-.1-.7-.3-.3-.2-.5-.4l-.5-.5q-.2-.3-.3-.6-.2-.3-.2-.7-.1-.3-.1-.6zM121.4 133.5v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM130.1 132.9h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M135.5 138l-.6-1.6h-2.1l-.6 1.6h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M140.9 132.9v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM142.8 132.9v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM146.5 137.6v-.7q0 .1.1.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H148.5q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1.1-.2v-.2q-.1 0-.1-.1t-.1-.1v-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.3v-.2q-.1-.1-.1-.2 0-.2.1-.3 0-.2.1-.3 0-.1.1-.3.1-.1.2-.1.2-.1.3-.2.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.1q.1.1.2.1.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1l-.1.1q-.1 0-.2.1 0 .1-.1.1v.6l.1.1q0 .1.1.1l.1.1q.1.1.2.1 0 .1.1.1h.2l.8.4q.1 0 .2.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.3v.5q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.3 0-.1.1-.3 0h-.4q-.2 0-.3-.1h-.1q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1zM150.5 137.6v-.1l.1-.1v-.1h.1v-.1h.1l.1-.1h.2v.1h.1q.1 0 .1.1l.1.1v.4l-.1.1q0 .1-.1.1h-.4q-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2zm.2-1.2l-.1-3.5h.8l-.2 3.5zM109.5 127.6v-.6h4.2v.6H112v4.5h-.7v-4.5zM118.2 127h.6v5.1h-.6v-2.3h-3v2.3h-.6V127h.6v2.2h3z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M123.5 132.1l-.5-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M129 127v5.1h-.5l-2.8-4v4h-.7V127h.6l2.8 3.9V127zM130.9 127v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7V127zM134.5 131.7v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H136.6l.1-.1q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.2-.3q.1-.2.1-.3.1-.1.2-.3.1-.1.2-.1.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1h.8q.1.1.2.1h.2l.1.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1 0 .2-.1 0 0 .1v.1q0 .1.1.1 0 .1.1.2h.1l.1.1q.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q0 .1.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1h-.3q-.2.1-.4.1h-.2q-.1-.1-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1zM138.6 131.7v-.1q.1-.1.1-.2.1-.1.2-.1H139.2q.1 0 .1.1h.1q0 .1.1.2V131.9l-.1.1-.1.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM109.5 120.7v-.6h4.2v.6H112v4.5h-.7v-4.5zM118.2 120.1h.6v5.2h-.6v-2.4h-3v2.4h-.6v-5.2h.6v2.2h3z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M123.5 125.3l-.5-1.6h-2.2l-.6 1.6h-.6l2-5.2h.6l2 5.2zm-1.7-4.3l-.8 2.1h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M129 120.1v5.2h-.5l-2.8-4.1v4.1h-.7v-5.2h.6l2.8 3.9v-3.9zM130.9 120.1v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.9l-2.3-2.5v2.5h-.7v-5.2zM134.5 124.8v-.7l.2.2q.1 0 .2.1t.2.1.2.1.2.1h1q.1 0 .2-.1.1 0 .2-.1.1 0 .2-.1l.1-.1v-.2-.2-.1-.1q0-.1-.1-.2l-.1-.1-.1-.1q-.1 0-.2-.1l-.1-.1h-.2l-.7-.3q-.2-.1-.3-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.1-.1-.2-.2-.3-.5-.1-.2-.1-.5t.2-.5q.1-.2.3-.4.1-.1.3-.2.1 0 .2-.1h.3q.2-.1.4-.1.1.1.2.1h.4q.1 0 .1.1h.2q.1 0 .2.1.1 0 .2.1l.1.1v.7l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1h-.2q-.1 0-.2-.1h-.4q-.1.1-.2.1h-.2q-.1.1-.2.1 0 .1-.1.1 0 .1-.1.2v.1q-.1.1-.1.2t.1.1v.2l.1.1.1.1.1.1q.1 0 .1.1h.2q0 .1.1.1l.8.3q.2.1.3.1.1.1.2.1l.2.2.2.2q.1.1.1.2.1.1.1.2t.1.2v.2q0 .2-.1.3 0 .2-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2.1-.3.1-.2.1-.3.1h-.8q-.2 0-.3-.1h-.2q-.1-.1-.2-.1t-.2-.1h-.1l-.2-.2zM138.6 124.8v-.1q0-.1.1-.1v-.1h.1q0-.1.1-.1H139.2q.1.1.2.1 0 .1.1.2V125l-.1.1v.1h-.1l-.1.1h-.1-.2l-.1-.1h-.1v-.1q-.1 0-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM121.4 112.5v-.5h4.3v.6h-1.8v4.5h-.7v-4.5zM130.1 112h.6v5.1h-.6v-2.3h-2.9v2.3h-.7V112h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M135.5 117.1l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M140.9 112v5.1h-.5l-2.8-4.1v4.1h-.7V112h.7l2.7 3.9V112zM142.8 112v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7V112zM146.5 116.7v-.7q0 .1.1.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1H148.5q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1.1-.2v-.2q-.1 0-.1-.1t-.1-.1v-.1q-.1-.1-.2-.1l-.1-.1q-.1-.1-.2-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q0-.1-.1-.3 0-.1-.1-.2v-.2q0-.2.1-.3 0-.2.1-.3 0-.1.1-.2.1-.2.2-.2.2-.1.3-.2.1-.1.3-.1.1 0 .3-.1h.7q.1.1.2.1h.2q.1.1.2.1l.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1H147.9q-.1 0-.2.1h-.2l-.1.1q-.1 0-.2.1 0 .1-.1.1v.6q.1.1.1.2l.1.1q.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q.1.2.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.6q-.1 0-.2-.1h-.2q-.2 0-.3-.1h-.1q-.1-.1-.2-.1t-.2-.1l-.1-.1zM150.5 116.7v-.1q0-.1.1-.1v-.1h.1v-.1h.1q0-.1.1-.1h.2v.1h.1q.1 0 .1.1l.1.1v.4h-.1v.1l-.1.1h-.4-.1v-.1q-.1 0-.1-.1l-.1-.1v-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM101.4 106.7v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM110.1 106.1h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M115.5 111.2l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M120.9 106.1v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM122.8 106.1v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7v-5.1zM126.5 110.8v-.7q0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H128.5q.1-.1.2-.1t.2-.1l.1-.1q0-.1.1-.2v-.1q.1-.1.1-.2v-.1q-.1-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-.2l-.7-.3q-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1-.1 0-.2-.1-.2-.2-.3-.5-.1-.2-.1-.5.1-.3.2-.5.1-.3.3-.4.2-.1.3-.2.1 0 .3-.1.1 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1l.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1H127.9q-.1 0-.2.1h-.2l-.1.1-.2.2q-.1.2-.1.3v.3q.1.2.2.3.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2q.1 0 .2.1t.1.2.1.2q.1.2.1.3v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.6q-.1 0-.2-.1h-.2q-.2 0-.3-.1h-.1q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1zM130.5 110.8v-.1l.1-.1q0-.1.1-.1v-.1h.5l.1.1v.1h.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.2q-.1 0-.1-.1h-.1v-.1h-.1v-.1q-.1 0-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5z"
            />
            <path
              id="Layer"
              className="s3"
              d="M162.3 125.7v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM167.6 125.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M171 128.5l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M174.3 125.3v3.2h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM175.5 125.3v1.5l1.4-1.5h.5l-1.6 1.6 1.7 1.6h-.6l-1.4-1.5v1.5h-.4v-3.2zM177.7 128.3v-.5l.2.2q.2.1.3.1.1 0 .3.1h.5q0-.1.1-.1h.1q.1-.1.1-.2.1-.1.1-.2v-.2l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2q.1 0 .1-.1.1-.1.2-.1t.2-.1h.7q.1 0 .1.1H179.5q0 .1.1.1h.1v.5q-.1 0-.1-.1h-.1q-.1-.1-.2-.1h-.1q0-.1-.1-.1h-.5-.1-.1q0 .1-.1.1l-.1.1-.1.1V126.3l.1.1h.1v.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1-.1.1-.2.1h-.2H178.4q0-.1-.1-.1h-.1q-.1 0-.2-.1h-.1l-.1-.1h-.1zM180.3 128.3v-.1-.1l.1-.1h.2q.1 0 .1.1h.1V128.4h-.1v.1h-.1H180.3v-.1-.1q-.1 0-.1-.1zm.2-.7l-.1-2.2h.5l-.1 2.2zM156.7 122.5v-.4h2.7v.4h-1.2v2.8h-.4v-2.8zM162.1 122.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M165.4 125.3l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M168.8 122.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM169.9 122.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM172.2 125v-.4h.1l.1.1.1.1h.2q0 .1.1.1H173.6q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1h-.1v-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1 0 .2-.1h.5q0 .1.1.1h.2q.1 0 .1.1h.1l.1.1v.4l-.1-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1H173.3h-.1-.2q-.1 0-.1.1h-.1q-.1 0-.1.1l-.1.1V123q0 .1.1.1v.1h.1q0 .1.1.1h.1l.1.1.5.2h.1q.1 0 .1.1.1 0 .2.1l.1.1q0 .1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1l-.1-.1zM174.7 125v-.1q.1 0 .1-.1l.1-.1h.2v.1h.1v.1q.1 0 .1.1t-.1.1v.1h-.1v.1h-.1-.1q0-.1-.1-.1 0-.1-.1-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM152.2 73.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM157.5 73.3h.4v3.1h-.4V75h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M160.9 76.4l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.2 3.2zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M164.2 73.3v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM165.4 73.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM167.6 76.2v-.5l.1.1q.1.1.2.1l.1.1h.1q.1 0 .1.1H168.9q.1 0 .1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1q-.1-.1-.2-.1v-.1q-.1-.1-.1-.2l-.1-.1V74v-.1q.1-.1.1-.2t.1-.2q.1 0 .1-.1.1 0 .2-.1h.2q.1-.1.2-.1h.5l.1.1h.2l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1h-.1v.1l-.1.1V74.2h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V75.7q-.1.1-.1.2t-.1.2q-.1 0-.1.1-.1.1-.2.1t-.2.1h-1q0-.1-.1-.1h-.1l-.1-.1h-.1zM170.2 76.2v-.1-.1q.1 0 .1-.1h.2q.1 0 .1.1.1 0 .1.1v.2q0 .1-.1.1h-.1q0 .1-.1 0h-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM161 67.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM166.3 67.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M169.7 70.2l-.4-1H168l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M173 67.1v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM174.2 67.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.4-1.4v1.4h-.4v-3.1zM176.4 70v-.4q.1.1.2.1.2.1.3.1.1.1.3.1h.6l.1-.1.1-.1q.1-.1.1-.2v-.2q-.1-.1-.1-.2-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1 0 .2-.1h.4q0 .1.1.1h.2l.1.1h.2q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.2-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.2l.1.1.1.1.1.1h.1l.1.1.5.2h.1q.1 0 .2.1l.1.1q.1 0 .1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H177.1l-.1-.1h-.2-.1l-.1-.1h-.1q0-.1-.1-.1zM179 70v-.1-.1h.1l.1-.1h.2v.1h.1v.1l.1.1q0 .1-.1.1v.1q-.1 0-.1.1h-.2q-.1 0-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.1zM141.1 69.4V69h2.6v.4h-1.1v2.8h-.4v-2.8zM146.5 69h.4v3.2h-.4v-1.4h-1.8v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M149.8 72.2l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M153.2 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM154.3 69v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V69zM156.6 72v-.4l.1.1h.1l.1.1h.1q.1.1.2.1h.8q0-.1.1-.1v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1h-.1v-.1-.1l-.1-.1h-.1q0-.1-.1-.1l-.1-.1-.5-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1V70v-.1-.2-.2l.1-.1q.1-.1.2-.1l.1-.1q.1-.1.2-.1h.2q.1-.1.2-.1h.1q.1 0 .1.1h.4q0 .1.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H157.4q-.1 0-.1.1h-.1l-.2.2V70q0 .1.1.2h.1q0 .1.1.1t.1.1l.5.2h.2l.1.1q.1 0 .2.1l.1.1q0 .1.1.1V71.3l.1.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4H157.1q0-.1-.1-.1h-.1q-.1 0-.1-.1h-.1l-.1-.1zM159.1 72v-.1-.1h.1q0-.1.1-.1h.1q.1 0 .1.1.1 0 .1.1.1.1 0 .2v.1h-.1v.1h-.2l-.1-.1h-.1v-.1-.1zm.1-.7V69h.4l-.1 2.2zM141.1 63v-.4h2.6v.4h-1.1v2.8h-.4V63zM146.5 62.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V64h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M149.8 65.8l-.4-1h-1.3l-.3 1h-.5l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M153.2 62.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM154.3 62.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM156.6 65.6v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .2-.1h.1q0-.1.1-.2V65q.1 0 0-.1 0-.1-.1-.2l-.1-.1h-.1v-.1h-.1l-.5-.2q-.1 0-.2-.1h-.1q0-.1-.1-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1.1 0 .1-.1h.2q.1-.1.2-.1H158q0 .1.1.1h.1l.1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4l-.1.1q-.1 0-.1.1h-.1v.5q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V64.9q.1 0 .1.1t-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4-.1q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM159.1 65.6v-.1-.1h.1v-.1H159.5q0 .1.1.1v.1q.1 0 .1.1h-.1v.2h-.1-.1v.1l-.1-.1h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM141.1 76.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM146.5 76.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M149.8 79.3l-.4-1h-1.3l-.3 1h-.5l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M153.2 76.2v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM154.3 76.2v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM156.6 79.1v-.4q.1.1.2.1.1.1.2.1.2.1.3.1h.7q0-.1.1-.1v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1l-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1V78h-.1l-.5-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2q.1 0 .1-.1h.2q.1 0 .2-.1h.4q.1.1.2.1h.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H157.4q-.1 0-.1.1h-.1q-.1.1-.2.1v.4q0 .1.1.2h.1q0 .1.1.1t.1.1l.5.2h.2q.1 0 .1.1.1 0 .2.1l.1.1q0 .1.1.1V78.4l.1.1q0 .1-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM159.1 79.1V79v-.1h.1q0-.1.1-.1h.1q.1 0 .1.1.1 0 .1.1.1.1 0 .1v.2h-.1v.1h-.2v-.1h-.1l-.1-.1v-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM163.9 60.9v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM169.2 60.6h.5v3.1h-.5v-1.4h-1.7v1.4h-.5v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M172.6 63.7l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.6 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M175.9 60.6v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM177.1 60.6V62l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.5v-3.2zM179.4 63.5V63l.2.2q.1 0 .2.1h.3q.1.1.3.1h.1q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1v-.2-.2q0-.1-.1-.2h-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1q-.1-.1 0-.2-.1-.1 0-.2v-.2q.1 0 .1-.1.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1H180.9q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.2-.1q0-.1-.1-.1h-.2q-.1 0-.2.1h-.1-.1v.1l-.1.1v.1q-.1 0 0 .1-.1 0 0 .1v.1q0 .1.1.1l.1.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1q.1.1.2.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1t-.2.1q0 .1-.1.1H179.9l-.1-.1h-.2q-.1 0-.1-.1h-.1zM181.9 63.5v-.1-.1h.1v-.1H182.3v.1h.1V63.6q0 .1-.1.1h-.1-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM181.2 69.4V69h2.6v.4h-1.1v2.8h-.4v-2.8zM186.5 69h.4v3.2h-.4v-1.4h-1.8v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M189.8 72.2l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M193.2 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM194.3 69v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V69zM196.6 72v-.4q.1.1.2.1.2.1.3.1.1.1.2.1H198l.1-.1q.1 0 .1-.1h.1v-.2-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1q-.1-.1-.1-.3-.1-.1-.1-.3 0-.1.1-.3l.2-.2q.1-.1.2-.1.1-.1.2-.1h.1q.1-.1.2-.1h.2l.1.1H198.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4-.1-.1l-.1.1h-.1q-.1 0-.1.1v.1q-.1 0-.1.1v.2l.1.1v.1h.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1q.1 0 .1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.5-.1q-.1-.1-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM199.1 72v-.1q.1 0 .1-.1h.1v-.1h.2l.1.1.1.1v.2l-.1.1q-.1 0-.1.1h-.1-.1l-.1-.1-.1-.1V72zm.2-.7l-.1-2.3h.4v2.2zM181.2 76.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM186.5 76.3h.4v3.1h-.4V78h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M189.8 79.4l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M193.2 76.3v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM194.3 76.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM196.6 79.2v-.5l.1.1.1.1q.1 0 .2.1h.2l.1.1H197.9l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1V77v-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1.1 0 .1-.1H198l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.2q-.1 0-.1.1h-.1v.1l-.1.1V77.2h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-1q0-.1-.1-.1h-.1l-.1-.1h-.1zM199.1 79.2v-.1h.1V79q0-.1.1-.1H199.6v.1q.1 0 .1.1v.2h-.1v.1h-.4v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM189.3 63v-.4h2.6v.4h-1.1v2.8h-.4V63zM194.6 62.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V64h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M198 65.8l-.4-1h-1.3l-.3 1h-.5l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M201.3 62.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM202.5 62.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM204.8 65.6l-.1-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2V65v-.1q0-.1-.1-.2l-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.1v-.2q.1-.1.1-.2.1 0 .2-.1l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2q0 .1.1.1v.1l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1h-.3-.2q-.1-.1-.2-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1zM207.3 65.6v-.1-.1l.1-.1H207.7v.1h.1V65.7q0 .1-.1.1h-.1q0 .1-.1.1v-.1h-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM213.3 60.9v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM218.7 60.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M222 63.7l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M225.4 60.6v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4L225 63v-2.4zM226.5 60.6V62l1.5-1.4h.5l-1.6 1.5 1.7 1.6h-.6l-1.5-1.5v1.5h-.4v-3.1zM228.8 63.5V63q.1 0 .1.1.1 0 .1.1h.1q.1.1.2.1h.2q.1.1.2.1h.2q.1 0 .1-.1h.2l.1-.1h.1v-.1l.1-.1v-.1-.1q0-.1-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1-.1-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1 0-.1.1-.1H230.3l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.2.2v.4l.1.1.1.1h.1l.1.1h.1l.5.2.1.1h.1q.1.1.2.1 0 .1.1.1 0 .1.1.2v.1q0 .1.1.1v.2q0 .1-.1.2v.1q-.1.1-.1.2-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H229.6h-.2q-.1 0-.2-.1h-.2q-.1 0-.1-.1h-.1zM231.3 63.5v-.1q0-.1.1-.1 0-.1.1-.1h.2l.1.1v.1h.1v.1q0 .1-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM202.3 69.4V69h2.7v.4h-1.1v2.8h-.4v-2.8zM207.7 69h.4v3.2h-.4v-1.4h-1.8v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M211 72.2l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M214.4 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM215.6 69v1.4L217 69h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V69zM217.8 72v-.4q.1 0 .1.1h.1q.1.1.2.1h.1q0 .1.1.1H219.2q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1h-.1l-.1-.1h-.1l-.1-.1-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1V70v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1l.1-.1h.2q.1-.1.2-.1h.1q.1 0 .2.1H219.4l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H218.9h-.1-.2q-.1 0-.1.1h-.1q-.1.1-.1.2-.1.1-.1.2l.1.1q0 .1.1.2h.1v.1h.1q.1 0 .1.1l.5.2h.2q0 .1.1.1t.1.1q.1 0 .2.1v.1q.1.1.1.2V71.8l-.1.1-.2.2q-.1 0-.2.1h-.1q-.1.1-.2.1h-.7q0-.1-.1-.1h-.1q-.1 0-.2-.1h-.1q0-.1-.1-.1zM220.3 72v-.1q.1 0 .1-.1l.1-.1h.2l.1.1.1.1v.2l-.1.1q-.1 0-.1.1h-.1-.1v-.1h-.1q0-.1-.1-.1V72zm.2-.7l-.1-2.3h.4l-.1 2.2zM202.3 75.5v-.4h2.7v.4h-1.1v2.8h-.4v-2.8zM207.7 75.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M211 78.3l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M214.4 75.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM215.6 75.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM217.8 78v-.4l.2.2q.1 0 .3.1h.2q.2.1.3.1h.1q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1l.1-.1H219.3l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.2q-.1-.1-.2-.1h-.1-.1q-.1 0-.1.1h-.2l-.1.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2.1.1h.2q0 .1.1.1l.1.1q.1.1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H218.4q-.1 0-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM220.3 78v-.1h.1v-.1h.1v-.1h.2l.1.1q0 .1.1.1v.2l-.1.1-.1.1h-.1-.1q0-.1-.1-.1v-.1h-.1V78zm.2-.8l-.1-2.1h.4l-.1 2.2zM223.9 69.4V69h2.6v.4h-1.1v2.8h-.4v-2.8zM229.2 69h.4v3.2h-.4v-1.4h-1.8v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M232.6 72.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.6 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M235.9 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM237.1 69v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.5V69zM239.3 72v-.4q.1 0 .1.1h.2q0 .1.1.1h.1l.1.1h.8l.1-.1q.1 0 .1-.1l.1-.1v-.1-.1-.1q0-.1-.1-.1v-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1l.1-.1q.1-.1.2-.1.1-.1.2-.1h.2q.1-.1.2-.1h.1l.1.1h.4q0 .1.1.1h.1q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.1-.1h-.2l-.1-.1h-.5-.1l-.1.1h-.1l-.1.1v.1q-.1 0-.1.1v.2h.1v.1q0 .1.1.1l.1.1h.1q.1 0 .1.1l.5.2h.1q.1.1.2.1t.1.1.1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H240h-.1l-.1-.1h-.2l-.1-.1h-.1q0-.1-.1-.1zM241.9 72v-.1-.1h.1v-.1h.2q.1 0 .1.1h.1v.4h-.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.3h.5l-.1 2.2zM232.7 60.9v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM238 60.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M241.3 63.7l-.3-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M244.7 60.6v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM245.9 60.6V62l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM248.1 63.5V63l.2.2q.2 0 .3.1h.3q.1.1.2.1h.2l.1-.1h.1q.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.2q-.1-.1-.1-.2h-.1q-.1 0-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2q.1-.1.1-.2l.1-.1.1-.1q.1-.1.2-.1.1-.1.2-.1H249.6l.1.1h.1q.1 0 .2.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.2q0-.1-.1-.1h-.1-.1l-.1-.1h-.2-.1l-.1.1h-.2q0 .1-.1.1v.1q-.1 0-.1.1V61.5h.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H248.8h-.1q-.1 0-.2-.1h-.1-.1l-.1-.1h-.1zM250.7 63.5v-.1-.1l.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2l-.1.1h-.1q-.1.1-.1 0 0 .1 0 0h-.2v-.1-.1q-.1 0 0 0zm.1-.8l-.1-2.1h.5l-.1 2.1zM244.9 67.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM250.2 67.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M253.6 70.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M256.9 67.1v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.3l1.7 2.4v-2.4zM258.1 67.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.4-1.4v1.4h-.4v-3.1zM260.3 70v-.4q.1.1.3.1.1.1.2.1.1.1.3.1h.6l.1-.1q.1-.1.2-.1v-.2-.2q0-.1-.1-.2-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1-.1 0-.2-.1v-.1q-.1-.1-.1-.2v-.1-.2q.1-.1.1-.2t.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1H262q0 .1.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.5-.1l-.1.1h-.1l-.1.1v.1l-.1.1v.1q0 .1.1.1v.1l.1.1.1.1h.1q.1 0 .1.1l.5.2h.2l.1.1q.1 0 .1.1.1 0 .1.1l.1.1q0 .1.1.2v.4q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2H261l-.1-.1H260.6l-.1-.1h-.1q0-.1-.1-.1zM262.9 70v-.1-.1h.1v-.1h.2l.1.1h.1v.4h-.1q0 .1-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM246.2 73.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM251.5 73.3h.4v3.1h-.4V75h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M254.9 76.4l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M258.2 73.3v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM259.4 73.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM261.6 76.2v-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.1 0-.3v-.3q.1-.2.2-.3.1 0 .2-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1h-.1v.1l-.1.1V74.2h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2h.1q.1.1.2.1 0 .1.1.1l.1.1.1.1v.2q.1 0 .1.1V75.7q-.1.1-.1.2t-.1.2l-.1.1q-.1.1-.2.1t-.2.1h-1q0-.1-.1-.1h-.1l-.1-.1h-.1zM264.2 76.2v-.1-.1h.1v-.1H264.6v.1h.1V76.3q0 .1-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM266.7 67.4v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM272 67.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M275.4 70.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M278.7 67.1v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM279.9 67.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM282.2 70v-.4h.1q0 .1.1.1 0 .1.1.1h.1q.1.1.2.1h.7l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1t-.1-.1-.1-.1-.1-.1l-.1-.1V68q-.1-.1 0-.1-.1-.1 0-.2v-.2l.1-.1.2-.2.1-.1h.2q.1 0 .2-.1h.4l.1.1H283.8q0 .1.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.5l-.1.1h-.1l-.1.1v.1l-.1.1v.1q0 .1.1.1v.1l.1.1h.1v.1h.1q.1 0 .1.1l.5.2h.2l.1.1q.1 0 .1.1.1 0 .2.1v.1q.1.1.1.2v.4q0 .1-.1.2v.1l-.2.2q-.1 0-.2.1h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2q-.1 0-.1-.1h-.1l-.1-.1zM284.7 70v-.1-.1q.1 0 .1-.1H285.1v.1h.1v.4h-.1v.1h-.1-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM254 60.9v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM259.3 60.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M262.7 63.7l-.4-1H261l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M266 60.6v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM267.2 60.6V62l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM269.5 63.5V63l.1.1.1.1h.1l.1.1H270.2l.1.1H270.6q.1 0 .1-.1h.1q.1 0 .1-.1.1 0 .2-.1v-.2-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1q-.1-.1-.1-.2t.1-.2V61q.1-.1.1-.2l.1-.1q.1-.1.2-.1.1-.1.2-.1H271q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1h-.1l-.1-.1h-.2l-.1-.1h-.2-.4-.1q0 .1-.1.1l-.1.1q-.1.1-.1.2v.2q.1.1.1.2h.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q-.1.1-.2.1h-.2H270.1l-.1-.1H269.7q0-.1-.1-.1v-.1h-.1zM272 63.5v-.1-.1h.1v-.1H272.4l.1.1v.1q.1 0 0 .1.1 0 0 0v.2h-.1H272.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM275.1 60.9v-.3h2.7v.3h-1.1v2.8h-.5v-2.8zM280.5 60.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M283.8 63.7l-.3-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M287.2 60.6v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM288.4 60.6V62l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM290.6 63.5V63q.1 0 .1.1.1 0 .1.1h.2q0 .1.1.1H291.4q0 .1.1.1h.2q.1 0 .2-.1h.1q.1 0 .1-.1.1 0 .1-.1.1-.1.1-.2v-.2l-.1-.1-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2v-.1l-.1-.1v-.2-.2q0-.1.1-.2l.1-.1q0-.1.1-.1.1-.1.2-.1.1-.1.2-.1H292.1l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1q0-.1-.1-.1h-.2l-.1-.1h-.2-.1l-.1.1h-.2q-.1.1-.1.2-.1.1-.1.2v.2q.1 0 .1.1.1 0 .1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.7-.1q-.1 0-.2-.1h-.1-.1l-.1-.1h-.1zM293.1 63.5v-.1h.1v-.1l.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2h-.1v.1h-.4v-.1q-.1 0-.1-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM287.5 67.4v-.3h2.6v.3H289v2.8h-.4v-2.8zM292.9 67.1h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M296.2 70.2l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M299.6 67.1v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM300.7 67.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM303 70v-.4h.1l.1.1.1.1h.2q0 .1.1.1H304.4q0-.1.1-.1l.1-.1q.1-.1.1-.2t-.1-.2-.1-.2V69h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1-.1-.1q0-.1-.1-.1V68v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1H304.4q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H304.1h-.4q-.1 0-.1.1-.1 0-.2.1v.4q0 .1.1.1 0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1V69.3q.1 0 .1.1t-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H303.4l-.1-.1h-.2l-.1-.1zM305.5 70v-.1l.1-.1q.1 0 .1-.1h.2q0 .1.1.1v.1q.1 0 .1.1v.1h-.1v.1h-.1v.1h-.1-.1l-.1-.1-.1-.1V70zm.1-.7v-2.2h.4l-.1 2.2zM266.7 73.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM272 73.3h.4v3.1h-.4V75h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M275.4 76.4l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M278.7 73.3v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM279.9 73.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM282.2 76.2v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.1-.1-.2-.3V74q0-.2.1-.3 0-.2.2-.3l.1-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.2-.5-.1q0 .1-.1.1l-.1.1v.1q-.1.1 0 .1-.1.1 0 .1v.2l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.7q-.1 0-.1.1-.1.1-.2.1l-.1.1q-.1.1-.2.1t-.2.1h-.4q-.1 0-.1-.1h-.2-.2l-.1-.1q-.1 0-.1-.1h-.1zM284.7 76.2v-.1-.1h.1v-.1H285.1v.1h.1V76.3q0 .1-.1.1h-.1-.1q0 .1-.1 0h-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM287.5 76.6v-.3h2.6v.3H289v2.8h-.4v-2.8zM292.9 76.3h.4v3.1h-.4V78h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M296.2 79.4l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M299.6 76.3v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM300.7 76.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM303 79.2v-.5l.2.2q.1.1.3.1.1.1.2.1H304.3l.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3V77q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H304.1h-.1H303.7q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1H303.9q-.1 0-.2-.1H303.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM305.5 79.2v-.1q0-.1.1-.1v-.1H305.9q.1 0 .1.1v.1h.1v.1q-.1 0-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM308.2 76.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM313.5 76.3h.4v3.1h-.4V78h-1.8l.1 1.5h-.5v-3.2h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M316.9 79.4l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M320.2 76.3v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM321.4 76.3v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM323.7 79.2v-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1 0 .2.1h.6q.1 0 .1-.1h.2v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1q-.1-.1-.2-.3-.1-.1-.1-.3.1-.2.1-.3.1-.2.3-.3l.1-.1q.1 0 .2-.1h.7q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1l-.1.1-.1.1v.1q-.1.1 0 .1-.1.1 0 .1v.2h.1q0 .1.1.1l.1.1h.1l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1t-.2.1h-.4q-.1 0-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM326.2 79.2v-.1-.1h.1v-.1H326.6v.1h.1V79.3l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM297.1 63v-.4h2.6v.4h-1.1v2.8h-.4V63zM302.5 62.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4V64h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M305.8 65.8l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M309.2 62.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM310.3 62.7v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM312.6 65.6v-.5l.2.2q.1.1.3.1.1.1.2.1h.5q.1 0 .2-.1h.1q.1-.1.1-.2t.1-.2l-.1-.1q0-.1-.1-.2v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1H314q0 .1.1.1h.1q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H313.7h-.4l-.1.1q-.1 0-.1.1h-.1v.5q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4-.1l-.1-.1h-.2l-.1-.1h-.1q-.1 0-.1-.1h-.1zM315.1 65.6v-.1q0-.1.1-.1v-.1H315.5l.1.1v.1q.1 0 .1.1t-.1.1v.1h-.1-.1q0 .1 0 0 0 .1-.1 0h-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM306.2 69.4V69h2.7v.4h-1.1v2.8h-.5v-2.8zM311.6 69h.4v3.2h-.4v-1.4h-1.8v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M314.9 72.2l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M318.3 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM319.4 69v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V69zM321.7 72v-.4q.1.1.2.1.1.1.3.1.1.1.2.1H323.1q0-.1.1-.1l.1-.1q.1-.1.1-.2v-.1-.1l-.1-.1v-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1-.1.2-.1l.1-.1H323.1l.1.1h.2l.1.1q.1 0 .1.1v.4q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1 0 0-.1-.1 0h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2.1.1h.2q0 .1.1.1 0 .1.1.2l.1.1v.1l.1.1v.4l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1t-.1-.1h-.5l-.1-.1h-.1-.1q0-.1-.1-.1zM324.2 72v-.1q.1 0 .1-.1l.1-.1h.2l.1.1.1.1v.2l-.1.1h-.1v.1h-.1-.1q0-.1-.1-.1 0-.1-.1-.1V72zm.2-.7l-.1-2.3h.4l-.1 2.2zM318.6 60.9v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM324 60.6h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M327.3 63.7l-.4-1h-1.3l-.4 1h-.3l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M330.6 60.6v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM331.8 60.6V62l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM334.1 63.5V63l.1.1.1.1h.1l.1.1H334.8l.1.1H335.2q.1 0 .1-.1h.1q.1 0 .2-.1l.1-.1V63q.1-.1 0-.1.1-.1 0-.1v-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1q-.1-.1-.1-.2-.1 0-.1-.1v-.1-.2-.2-.1q.1-.1.1-.2.1-.1.2-.1 0-.1.1-.1.1-.1.2-.1h.9q0 .1.1.1h.1l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1H335.3q0-.1-.1-.1h-.2q-.1 0-.1.1h-.2l-.1.1-.1.1v.4l.1.1.1.1h.1v.1h.1l.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.1v.2q.1 0 .1.1v.6q-.1.1-.1.2-.1 0-.2.1 0 .1-.1.1-.1.1-.2.1H334.9 334.6l-.1-.1h-.2q-.1 0-.1-.1h-.1zM336.6 63.5v-.1q0-.1.1-.1 0-.1.1-.1h.2q0 .1.1.1v.1l.1.1h-.1v.2h-.1-.1q-.1.1-.1 0h-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM325.4 69.4V69h2.7v.4H327v2.8h-.4v-2.8zM330.8 69h.4v3.2h-.4v-1.4H329v1.4h-.4V69h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M334.1 72.2l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M337.5 69v3.2h-.3l-1.8-2.5v2.5h-.4V69h.4l1.7 2.4V69zM338.6 69v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V69zM340.9 72v-.4q.1 0 .1.1h.1q.1.1.2.1h.1q0 .1.1.1H342.3q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1h-.1l-.1-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1 0-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1V70v-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.1.1-.1.2-.1l.1-.1h.2q.1-.1.2-.1h.1q.1 0 .2.1H342.5l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4-.1-.1q-.1 0-.1.1h-.1q-.1.1-.1.2-.1 0-.1.1v.2q.1.1.1.2h.1l.1.1h.1l.1.1.5.2h.1q.1.1.2.1l.1.1q.1 0 .1.1l.1.1v.2q.1 0 .1.1V71.6q-.1.1-.1.2t-.1.1q-.1.1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.6q-.1-.1-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM343.4 72v-.1q.1 0 .1-.1h.1v-.1h.2l.1.1.1.1v.2l-.1.1q-.1 0-.1.1h-.2v-.1h-.1q0-.1-.1-.1V72zm.2-.7l-.1-2.3h.4l-.1 2.2zM325.4 75.5v-.4h2.7v.4H327v2.8h-.4v-2.8zM330.8 75.1h.4v3.2h-.4v-1.5H329v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M334.1 78.3l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M337.5 75.1v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM338.6 75.1v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM340.9 78v-.4l.2.2q.1 0 .3.1h.2q.2.1.3.1h.1q.1 0 .2-.1h.1l.1-.1h.1v-.1l.1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1l-.5-.1q0-.1-.1-.1t-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.2l-.1-.1v-.1-.2-.2q0-.1.1-.1 0-.1.1-.2 0-.1.1-.1.1-.1.2-.1t.1-.1H342.4l.1.1h.1q.1 0 .1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.2l-.1-.1h-.2-.1l-.1.1h-.2l-.1.1q0 .1-.1.1v.4h.1v.1q.1 0 .1.1h.1l.1.1h.1l.5.2q.1 0 .1.1h.2q0 .1.1.1l.1.1q.1.1.1.2v.1l.1.1v.4q-.1 0-.1.1t-.1.2q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1H341.5q-.1 0-.2-.1h-.1l-.1-.1h-.1q0-.1-.1-.1zM343.4 78v-.1h.1v-.1h.1v-.1h.2l.1.1.1.1V78.2h-.1l-.1.1h-.1-.1q0-.1-.1-.1v-.1q-.1 0 0-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM223.9 75.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM229.2 75.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M232.6 78.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7L231 77h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M235.9 75.1v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.4v-2.4zM237.1 75.1v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.5v-3.2zM239.3 78v-.4q.1.1.3.2.1 0 .2.1h.3q.1.1.2.1h.2l.1-.1h.1q.1 0 .1-.1h.1q0-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1-.1-.1h-.1l-.1-.1-.5-.1q0-.1-.1-.1t-.1-.1h-.1l-.1-.1-.2-.2v-.1l-.1-.1v-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1.1-.1.2-.1H240.8l.1.1h.2l.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2-.1l-.1-.1H240.2l-.1.1h-.1-.1q0 .1-.1.1v.1l-.1.1v.2l.1.1q0 .1.1.1v.1h.1q.1 0 .1.1h.1l.5.2q.1 0 .2.1h.1l.1.1.1.1q.1.1.1.2l.1.1v.5l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.4H240h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.1q0-.1-.1-.1zM241.9 78v-.1-.1h.1v-.1h.2l.1.1q.1 0 .1.1V78.2h-.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.8l-.1-2.1h.5l-.1 2.2zM141.1 127.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM146.5 127h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V127h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M149.8 130.2l-.4-1h-1.3l-.3 1h-.5l1.3-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M153.2 127v3.2h-.3l-1.8-2.6v2.6h-.4V127h.4l1.7 2.4V127zM154.3 127v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V127zM156.6 130v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.8q0-.1.1-.1v-.1q.1 0 .1-.1v-.1q.1 0 .1-.1l-.1-.1v-.1q0-.1-.1-.1l-.1-.1h-.1v-.1h-.1l-.5-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2-.1-.2-.2l.1-.1.2-.2q.1 0 .1-.1h.2q.1 0 .2-.1h.4q.1.1.2.1h.2l.1.1h.1l.1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1q-.1 0-.1-.1h-.2-.1H157.4q-.1 0-.1.1h-.1q-.1.1-.2.1v.4q0 .1.1.2h.1q0 .1.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V129.3q.1 0 .1.1t-.1.2v.2l-.1.1-.2.2-.1.1h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM159.1 130v-.1-.1h.1q0-.1.1-.1h.1q.1 0 .1.1.1 0 .1.1.1.1 0 .2v.1h-.1v.1h-.2l-.1-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM176.6 122.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM181.9 122.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M185.2 125.3l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M188.6 122.1v3.2h-.3l-1.8-2.6v2.6h-.4v-3.2h.4l1.7 2.4v-2.4zM189.8 122.1l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM192 125v-.4h.1l.1.1.1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1l.1-.1v-.1-.1h-.1v-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H193.1h-.1H192.7q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1h-.2l-.1-.1H192.4l-.1-.1h-.2l-.1-.1zM194.5 125v-.1h.1v-.1h.1v-.1h.2l.1.1.1.1v.2l-.1.1q-.1 0-.1.1h-.2v-.1h-.1v-.1h-.1v-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM177.8 129.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM183.2 129h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V129h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M186.5 132.1l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M189.9 129v3.2h-.3l-1.8-2.6v2.6h-.4V129h.4l1.7 2.4V129zM191 129v1.4l1.4-1.4h.6l-1.6 1.5 1.7 1.7h-.6l-1.5-1.5v1.4h-.4V129zM193.3 131.9v-.5l.1.1.1.1q.1 0 .1.1h.2q0 .1.1.1h.8q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1l-.1-.1h-.1q-.1 0-.1-.1H194.4h-.1H194l-.1.1q-.1 0-.1.1-.1 0-.1.1V129.9q0 .1.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1-.1.1-.2.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1l-.1-.1h-.1l-.1-.1zM195.8 131.9v-.1l.1-.1.1-.1h.2v.1h.1v.1h.1v.1q0 .1-.1.1v.1h-.1q0 .1-.1.1h-.1l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8V129h.4l-.1 2.1zM202.3 129.3v-.3h2.7v.4h-1.1v2.8h-.4v-2.8zM207.7 129h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V129h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M211 132.1l-.4-1h-1.3l-.3 1h-.5l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M214.4 129v3.2h-.3l-1.8-2.6v2.6h-.4V129h.4l1.7 2.4V129zM215.6 129v1.4l1.4-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V129zM217.8 131.9v-.5l.2.2q.1.1.3.1.1.1.2.1H219.2q0-.1.1-.1l.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1v-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1-.1-.1q0-.1-.1-.1v-.2-.1-.2q0-.1.1-.2 0-.1.1-.1 0-.1.1-.2.1 0 .2-.1h.1q.1-.1.2-.1h.5l.1.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1H218.9h-.1H218.5l-.1.1q-.1 0-.1.1l-.1.1V129.9q0 .1.1.1v.1h.1l.1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1q.1 0 .1.1.1 0 .1.1v.1q.1.1.1.2V131.5q-.1.1-.1.2t-.1.1l-.1.1-.2.2h-.2q-.1.1-.2.1h-.5q-.1 0-.1-.1h-.2-.1q0-.1-.1-.1h-.1q0-.1-.1-.1zM220.3 131.9v-.1q.1 0 .1-.1l.1-.1h.2l.1.1q0 .1.1.1v.2l-.1.1-.1.1h-.1-.1v-.1h-.1v-.1q-.1 0-.1-.1zm.2-.8l-.1-2.1h.4l-.1 2.1zM225.6 131v-.4h2.6v.4h-1.1v2.8h-.4V131zM230.9 130.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M234.3 133.8l-.4-1h-1.3l-.4 1h-.4l1.2-3.1h.4l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M237.6 130.7v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM238.8 130.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.4-1.4v1.4h-.4v-3.1zM241.1 133.6v-.4h.1q0 .1.1.1l.1.1h.1l.1.1h.8l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2l-.1-.1-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1q0-.1-.1-.2v-.2l.1-.1v-.2q0-.1.1-.1.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H242.7l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1l-.1.1q-.1.1-.1.2t.1.2.1.2q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.2q-.1.1-.2.1h-.2-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.1v-.1zM243.6 133.6v-.1-.1l.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2q0 .1-.1.1h-.1v.1h-.1l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM223.9 124.2v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM229.2 123.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M232.6 127l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.6 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M235.9 123.8v3.2h-.3l-1.8-2.5v2.5h-.3v-3.2h.3l1.7 2.5v-2.5zM237.1 123.8v1.5l1.4-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.4-1.5v1.5h-.5v-3.2zM239.3 126.8v-.5q.1.1.3.2.1.1.2.1.1.1.3.1h.5q.1 0 .1-.1h.1l.2-.2v-.2-.2l-.1-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.2-.1v-.2q-.1 0-.1-.1v-.2-.2q.1 0 .1-.1t.1-.2q.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7l.1.1H241.1q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.2q0-.1-.1-.1h-.5-.1-.1l-.1.1h-.1v.1l-.1.1v.2q0 .1.1.1v.1h.1v.1h.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM241.9 126.8v-.1-.1l.1-.1H242.3v.1h.1V126.9l-.1.1h-.1-.1-.1-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM248.7 124.2v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM254.1 123.8h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M257.4 127l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M260.8 123.8v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.5v-2.5zM261.9 123.8v1.5l1.5-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM264.2 126.8v-.5l.2.2q.1.1.2.1.2.1.3.1h.5q.1 0 .2-.1h.1q.1-.1.1-.2t.1-.2q0-.1-.1-.2l-.1-.1q0-.1-.1-.1h-.1v-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1.1 0 .1-.1.1 0 .2-.1h.8q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4q-.1 0-.1.1h-.1l-.1.1v.5h.1q0 .1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .1.1.1 0 .2.1l.1.1.1.1V126.1q.1 0 .1.1t-.1.2v.2q-.1 0-.1.1-.1.1-.2.1 0 .1-.1.1-.1.1-.2.1t-.2.1h-.4l-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM266.7 126.8v-.1q0-.1.1-.1 0-.1.1-.1h.2q.1 0 .1.1v.1q.1 0 0 .1.1 0 0 0v.2h-.1-.1-.2q0-.1-.1-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM213.3 118.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM218.7 118.3h.4v3.1h-.4V120h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M222 121.4l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M225.4 118.3v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM226.5 118.3v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.1zM228.8 121.2v-.5l.1.1.1.1q.1 0 .1.1H229.4l.1.1h.5q.1 0 .2-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H229.9h-.1H229.5q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H229.2l-.1-.1h-.1q-.1 0-.1-.1h-.1zM231.3 121.2v-.1q0-.1.1-.1v-.1H231.7q.1 0 .1.1v.1h.1v.1q0 .1-.1.1v.1h-.1-.1-.1-.1v-.1h-.1v-.1zm.1-.8v-2.2h.4l-.1 2.2zM194.6 118.6v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM199.9 118.3h.4v3.1h-.4V120h-1.8v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M203.3 121.4l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M206.6 118.3v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM207.8 118.3v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM210 121.2v-.5l.1.1.1.1q.1 0 .1.1H210.6l.1.1h.5q.1 0 .2-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1l-.1-.1q0-.1-.1-.1v-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.3v-.3q0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.1q.1-.1.2-.1h.6q0 .1.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H211.1h-.1H210.7q-.1 0-.1.1h-.1l-.1.1v.4l.1.1q0 .1.1.1l.1.1h.2l.5.2q0 .1.1.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2q-.1 0-.1.1l-.1.1q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.4l-.1-.1H210.4l-.1-.1h-.1q-.1 0-.1-.1h-.1zM212.6 121.2v-.1-.1l.1-.1H213v.1h.1V121.3q-.1 0-.1.1h-.1-.2-.1v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM241.5 118.6v-.3h2.6v.3H243v2.8h-.4v-2.8zM246.8 118.3h.4v3.1h-.4V120H245v1.5h-.4v-3.2h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M250.2 121.4l-.4-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M253.5 118.3v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM254.7 118.3v1.4l1.4-1.4h.5l-1.6 1.5 1.7 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM256.9 121.2v-.5l.1.1q.1.1.2.1l.1.1h.2q.1.1.2.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1l-.5-.2-.1-.1h-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.1-.3-.1-.1-.1-.3 0-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.2q.1-.1.2-.1h.5l.1.1H258.7q0 .1.1.1v.1l.1.4q-.1 0-.1-.1h-.1q-.1-.1-.2-.1h-.1q0-.1-.1-.1h-.5-.1-.1l-.1.1h-.1v.1l-.1.1v.2q0 .1.1.1v.1h.1v.1q.1 0 .1.1h.2l.5.2.1.1q.1 0 .2.1.1 0 .1.1.1 0 .1.1.1 0 .1.1t.1.1v.5q0 .1-.1.2l-.1.1q0 .1-.1.1-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM259.4 121.2l.1-.1v-.1l.1-.1H259.9v.1h.1V121.3q-.1 0-.1.1h-.1H259.5v-.1-.1zm.1-.8l-.1-2.2h.5l-.1 2.2zM270.5 124.2v-.4h2.6v.4H272v2.8h-.4v-2.8zM275.8 123.8h.4v3.2h-.4v-1.4H274v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M279.1 127l-.3-1h-1.3l-.4 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M282.5 123.8v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.5v-2.5zM283.7 123.8v1.5l1.4-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM285.9 126.8v-.5l.1.1q.1.1.2.1l.1.1h.2q.1.1.2.1h.5l.1-.1h.1l.1-.1.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1v-.2q-.1 0-.1-.1v-.2-.1q.1-.1.1-.2t.1-.2l.1-.1q.1 0 .2-.1.1 0 .2-.1h.7l.1.1h.2l.1.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.4-.1-.1-.1q-.1 0-.1.1h-.1v.1l-.1.1V124.8q.1 0 .1.1l.1.1h.1l.1.1h.1l.5.2q.1 0 .1.1.1 0 .2.1l.1.1q.1 0 .1.1.1.1.2.3v.3q0 .1-.1.3l-.2.2q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2.1h-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.1l-.1-.1h-.1zM288.4 126.8q0-.1.1-.1v-.1l.1-.1H288.9v.1h.1V126.9q-.1 0-.1.1h-.4v-.1-.1q-.1 0 0 0zm.1-.8l-.1-2.1h.5l-.1 2.1zM288.6 119.5v-.3h2.6v.3h-1.1v2.8h-.4v-2.8zM293.9 119.2h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M297.3 122.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M300.6 119.2v3.1h-.3l-1.8-2.5v2.5h-.3v-3.1h.3l1.7 2.4v-2.4zM301.8 119.2v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.6l-1.4-1.5v1.5h-.4v-3.1zM304.1 122.1l-.1-.5q.1.1.2.1 0 .1.1.1l.1.1h.1q.1 0 .1.1H305.3q.1 0 .1-.1h.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1h-.1v-.1q-.1 0-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2l-.1-.1v-.2q0-.1.1-.1v-.2q0-.1.1-.2.1 0 .1-.1.1 0 .2-.1h.2q.1-.1.2-.1h.5q.1.1.2.1h.1l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.5-.1-.1q0 .1-.1.1l-.1.1q0 .1-.1.1v.2q0 .1.1.1v.1l.1.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1.1.2.3v.3q0 .1-.1.3l-.2.2q-.1.1-.2.1-.1.1-.2.1t-.2.1h-.2-.2q-.1-.1-.2-.1h-.2l-.1-.1q-.1 0-.2-.1h-.1zM306.6 122.1v-.1-.1l.1-.1h.2q.1 0 .1.1.1 0 .1.1v.2q0 .1-.1.1h-.1l-.1.1v-.1h-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM288.6 127.4v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM293.9 127h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V127h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M297.3 130.2l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M300.6 127v3.2h-.3l-1.8-2.6v2.6h-.3V127h.3l1.7 2.4V127zM301.8 127v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.6l-1.4-1.5v1.5h-.4V127zM304.1 130l-.1-.4h.2q0 .1.1.1 0 .1.1.1h.1l.1.1h.8l.1-.1q.1 0 .1-.1l.1-.1v-.1-.1-.1-.1l-.1-.1q-.1 0-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1t-.1-.1-.1-.1q-.2-.1-.2-.3-.1-.1-.1-.3.1-.2.1-.3.1-.2.2-.3.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1H305.7l.1.1h.1q0 .1.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.2l-.1-.1h-.5-.1l-.1.1h-.1l-.1.1v.1l-.1.1v.1l.1.1v.1l.1.1.1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q-.1.1-.2.1h-.2-.2q-.1 0-.2-.1h-.2l-.1-.1h-.2q0-.1-.1-.1zM306.6 130v-.1-.1h.1v-.1h.2q.1 0 .1.1.1 0 .1.1v.2q0 .1-.1.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM292.8 137v-.4h2.7v.4h-1.2v2.8h-.3V137zM298.2 136.6h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M301.5 139.8l-.3-1h-1.4l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M304.9 136.6v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM306.1 136.6l-.1 1.4 1.5-1.4h.5l-1.6 1.5 1.6 1.7h-.5l-1.4-1.5v1.5h-.4v-3.2zM308.3 139.6v-.5l.2.2q.1.1.3.1.1 0 .2.1H309.6q0-.1.1-.1h.1q.1-.1.1-.2.1-.1.1-.2v-.2l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.1q-.1-.1-.2-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1t.1-.1H309.7q.1 0 .1.1h.2l.1.1h.1v.5q0-.1-.1-.1l-.1-.1h-.1l-.1-.1h-.4q0-.1-.1-.1l-.1.1h-.2q-.1 0-.1.1h-.1q0 .1-.1.1v.4l.1.1.1.1h.1q0 .1.1.1h.1l.5.2.1.1q.1 0 .2.1h.1q.1.1.1.2.1 0 .1.1v.1l.1.1v.4l-.1.1q0 .1-.1.2l-.1.1q-.1.1-.2.1-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.1 0h-.5l-.1-.1h-.1l-.1-.1h-.1zM310.8 139.6v-.1l.1-.1q0-.1.1-.1H311.3q0 .1.1.1V139.7h-.1v.1h-.2-.2v-.1h-.1v-.1zm.2-.8l-.1-2.2h.4l-.1 2.2zM308.2 129.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM313.5 129h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5V129h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M316.9 132.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M320.2 129v3.2h-.3l-1.7-2.6v2.6h-.4V129h.4l1.7 2.4V129zM321.4 129v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V129zM323.7 131.9v-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1v-.1q.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1-.1-.2-.3-.1-.1 0-.3v-.3q.1-.2.2-.3.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H325.3l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.5-.1q0 .1-.1.1v.1q-.1 0-.1.1v.1q-.1 0 0 .1-.1 0 0 0v.2l.1.1h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1l-.2.2h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM326.2 131.9v-.1-.1h.1v-.1H326.6v.1h.1v.4q-.1 0-.1.1h-.2l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM308.2 124.2v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM313.5 123.8h.4v3.2h-.4v-1.5h-1.8l.1 1.5h-.5v-3.2h.5v1.4h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M316.9 127l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M320.2 123.8v3.2h-.3l-1.7-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM321.4 123.8v1.5l1.4-1.5h.5l-1.5 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM323.7 126.8v-.5q0 .1.1.1 0 .1.1.1l.1.1h.1q.1 0 .2.1h.6q.1 0 .1-.1h.2q0-.1.1-.2v-.2-.2l-.1-.1q0-.1-.1-.1h-.1q0-.1-.1-.1h-.1l-.4-.2q-.1 0-.2-.1h-.1l-.1-.1-.1-.1-.1-.1q-.1-.1-.1-.2v-.1q-.1-.1-.1-.2t.1-.1v-.2q.1-.1.1-.2.1 0 .1-.1.1 0 .2-.1.1 0 .2-.1h.7q.1.1.2.1h.2q0 .1.1.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1l-.1.1-.1.1v.2q-.1.1 0 .2 0 .1.1.1 0 .1.1.1l.1.1h.1l.5.2q.1 0 .2.1l.1.1q.1 0 .1.1.1 0 .2.1v.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1-.1.1-.2.1 0 .1-.1.1h-.2q-.1.1-.2.1h-.2q-.1 0-.1-.1h-.2-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1h-.1zM326.2 126.8v-.1-.1h.1v-.1H326.6l.1.1V126.9l-.1.1h-.1-.1-.1l-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM329.1 122.5v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM334.4 122.1h.4v3.2h-.4v-1.5h-1.8v1.5h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M337.8 125.3l-.4-1h-1.3l-.4 1h-.4l1.3-3.2h.3l1.3 3.2zm-1-2.7l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M341.1 122.1v3.2h-.3l-1.7-2.6v2.6h-.4v-3.2h.3l1.7 2.4v-2.4zM342.3 122.1v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4v-3.2zM344.6 125l-.1-.4h.2q0 .1.1.1 0 .1.1.1h.1l.1.1h.8l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2h-.1q0-.1-.1-.1t-.1-.1-.1-.1-.1-.1l-.1-.1q0-.1-.1-.2v-.1q0-.1.1-.2v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1 0 .2-.1h.4l.1.1H346.2l.1.1h.1q0 .1.1.1v.4h-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.4-.1l-.1.1h-.1l-.1.1q-.1.1-.1.2t.1.2.1.2l.1.1h.1q.1 0 .1.1l.5.2h.2l.1.1q.1 0 .1.1.1 0 .1.1l.1.1.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.2-.1 0-.2.1h-.2q0 .1-.1.1h-.3-.2q-.1 0-.2-.1h-.2l-.1-.1h-.2q0-.1-.1-.1zM347.1 125v-.1-.1h.1v-.1h.2q.1 0 .1.1.1 0 .1.1v.2q0 .1-.1.1l-.1.1h-.1-.1v-.1h-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM327.8 133.2v-.4h2.6v.4h-1.1v2.8h-.4v-2.8zM333.1 132.8h.5v3.2h-.5v-1.4h-1.7v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M336.5 136l-.4-.9h-1.3l-.4.9h-.4l1.2-3.2h.4l1.3 3.2zm-1-2.7l-.6 1.4h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M339.9 132.8v3.2h-.3l-1.8-2.5v2.5h-.4v-3.2h.4l1.7 2.4v-2.4zM341 132.8v1.4l1.4-1.4h.6l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM343.3 135.8v-.5q0 .1.1.1l.1.1h.1l.1.1h.2q0 .1.1.1h.5l.1-.1h.2v-.1l.1-.1v-.1-.1-.1-.1-.1h-.1q0-.1-.1-.1l-.1-.1h-.1l-.1-.1-.4-.1q-.1-.1-.2-.1l-.1-.1h-.1l-.1-.1-.1-.1q-.1-.1-.1-.2v-.1-.2-.2-.1q.1-.1.1-.2.1 0 .2-.1 0-.1.1-.1t.2-.1H344.8q0 .1.1.1h.1q0 .1.1.1h.1v.5l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.1-.1H344.5h-.1l-.1-.1q-.1 0-.1.1H343.9l-.1.1-.1.1v.5h.1q0 .1.1.1h.1v.1h.1l.5.2q.1 0 .2.1h.1q.1.1.2.1 0 .1.1.2v.1q.1 0 .1.1v.6q-.1.1-.1.2-.1.1-.2.1l-.1.1q-.1.1-.2.1h-.2q-.1.1-.2 0-.1.1-.2 0H343.8h-.1l-.1-.1h-.1l-.1-.1q-.1 0-.1-.1zM345.8 135.8v-.1-.1h.1v-.1H346.2l.1.1V135.9l-.1.1H345.9q0-.1-.1-.1v-.1-.1zm.1-.7l-.1-2.2h.5l-.1 2.2zM329.1 129.3v-.3h2.6v.4h-1.1v2.8h-.4v-2.8zM334.4 129h.4v3.2h-.4v-1.5h-1.8v1.5h-.4V129h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M337.8 132.1l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.6 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M341.1 129v3.2h-.3l-1.7-2.6v2.6h-.4V129h.3l1.7 2.4V129zM342.3 129v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.7h-.5l-1.5-1.5v1.5h-.4V129zM344.6 131.9l-.1-.5q.2.1.3.2.1.1.2.1.1.1.3.1h.6l.1-.1q.1 0 .1-.1.1 0 .1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1l-.5-.2h-.1q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1q0-.1-.1-.2v-.1q0-.1.1-.2v-.2l.1-.1q.1-.1.1-.2.1 0 .2-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H346.2l.1.1h.1l.1.1v.4h-.1l-.1-.1q-.1 0-.1-.1h-.1q-.1 0-.2-.1h-.1-.4-.1-.1q0 .1-.1.1l-.1.1v.1l-.1.1v.1l.1.1v.1l.1.1q.1 0 .1.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2 0 .1-.1.1 0 .1-.1.1l-.2.2h-.1q-.1.1-.2.1h-.3-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.1q0-.1-.1-.1zM347.1 131.9v-.1-.1h.1v-.1h.2q.1 0 .1.1h.1V132q0 .1-.1.1l-.1.1h-.1l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1zM268.1 131v-.4h2.6v.4h-1.1v2.8h-.4V131zM273.5 130.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M276.8 133.8l-.4-1h-1.3l-.3 1h-.5l1.3-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1.1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M280.2 130.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM281.3 130.7v1.4l1.4-1.4h.6l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM283.6 133.6v-.4q.1 0 .2.1t.2.1q.2.1.3.1h.7q0-.1.1-.1 0-.1.1-.2v-.1q.1-.1 0-.2 0-.1-.1-.2l-.1-.1h-.1v-.1h-.2l-.4-.2h-.2q0-.1-.1-.1 0-.1-.1-.1t-.1-.1-.1-.1l-.1-.1v-.2-.2-.1-.2q.1-.1.1-.2.1 0 .2-.1.1 0 .1-.1h.2q.1-.1.2-.1h.4q.1 0 .2.1h.2l.1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1h-.2-.1-.4q-.1.1-.2.1v.1q-.1 0-.1.1v.4q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1V132.9q.1 0 0 .1.1.1 0 .2v.2l-.1.1q-.1.1-.2.1 0 .1-.1.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM286.1 133.6v-.1-.1h.1v-.1H286.5l.1.1v.1l.1.1-.1.1v.1h-.1l-.1.1h-.1v-.1h-.1l-.1-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM248.2 131v-.4h2.6v.4h-1.1v2.8h-.4V131zM253.6 130.7h.4v3.1h-.4v-1.4h-1.8v1.4h-.4v-3.1h.4v1.3h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M256.9 133.8l-.4-1h-1.3l-.3 1h-.4l1.2-3.1h.4l1.2 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M260.3 130.7v3.1h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM261.4 130.7v1.4l1.5-1.4h.5l-1.6 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM263.7 133.6v-.4q.1 0 .2.1t.3.1q.1.1.2.1h.7q0-.1.1-.1.1-.1.1-.2l.1-.1q0-.1-.1-.2 0-.1-.1-.2v-.1h-.1q-.1 0-.1-.1h-.1l-.5-.2h-.1q-.1-.1-.2-.1 0-.1-.1-.1l-.1-.1q-.1 0-.1-.1t-.1-.1v-.2-.2-.1q0-.1.1-.2 0-.1.1-.2l.1-.1q.1 0 .2-.1h.1q.1-.1.2-.1h.4q.1 0 .2.1h.2q.1 0 .1.1h.1q.1 0 .1.1v.4l-.1-.1h-.1q0-.1-.1-.1h-.1l-.1-.1H264.8h-.4l-.1.1q-.1 0-.1.1-.1 0-.1.1v.4q.1 0 .1.1h.1l.1.1h.1l.5.2q.1.1.2.1t.1.1q.1 0 .2.1l.1.1.1.1v.1q0 .1.1.2v.1q0 .1-.1.2v.2l-.1.1q-.1.1-.2.1 0 .1-.1.2h-.2q-.1.1-.2.1h-.4-.1q-.1 0-.1-.1h-.2-.1l-.1-.1h-.1l-.1-.1zM266.2 133.6v-.1q0-.1.1-.1v-.1H266.6l.1.1v.1q.1 0 .1.1t-.1.1v.1h-.1q0 .1-.1.1h-.1v-.1h-.1v-.1h-.1v-.1zm.1-.8v-2.1h.4l-.1 2.1zM200.6 125.7v-.4h2.7v.4h-1.1v2.8h-.5v-2.8zM206 125.3h.4v3.2h-.4v-1.4h-1.8v1.4h-.4v-3.2h.4v1.4h1.8z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M209.3 128.5l-.4-1h-1.3l-.3 1h-.4l1.2-3.2h.4l1.2 3.2zm-1-2.7l-.5 1.4h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M212.7 125.3v3.2h-.3l-1.8-2.5v2.5h-.4v-3.1h.4l1.7 2.4v-2.4zM213.8 125.3v1.5l1.5-1.5h.5l-1.6 1.6 1.6 1.6h-.5l-1.5-1.5v1.5h-.4v-3.2zM216.1 128.3v-.5l.1.1q.1 0 .1.1h.2q0 .1.1.1h.1l.1.1H217.4q0-.1.1-.1h.1q0-.1.1-.1v-.1q.1 0 .1-.1v-.1-.1-.1q-.1 0-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.5-.2q-.1 0-.1-.1h-.2q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.2v-.1-.2-.2l.1-.1q0-.1.1-.2l.1-.1q.1-.1.2-.1l.1-.1H217.5l.1.1h.2l.1.1h.1v.5l-.1-.1h-.1q0-.1-.1-.1h-.1q0-.1-.1-.1H217.2h-.1H216.8l-.1.1q-.1 0-.1.1h-.1v.4l.1.1.1.1.1.1h.2l.5.2q0 .1.1.1t.2.1l.1.1.1.1.1.1v.1q.1.1.1.2V127.9q-.1.1-.1.2l-.1.1-.1.1q-.1.1-.2.1-.1.1-.2.1-.1.1-.2.1h-.5l-.1-.1h-.2-.1q0-.1-.1-.1l-.1-.1h-.1zM218.6 128.3v-.1-.1h.1v-.1H219q.1 0 .1.1h.1V128.4h-.1v.1h-.2-.1-.1v-.1h-.1v-.1-.1zm.2-.7l-.1-2.2h.4l-.1 2.2zM154.6 131v-.4h2.6v.4h-1.1v2.8h-.4V131zM159.9 130.7h.4v3.1h-.4v-1.4h-1.8l.1 1.4h-.5v-3.1h.5v1.3h1.7z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s3"
              d="M163.3 133.8l-.4-1h-1.3l-.4 1h-.4l1.3-3.1h.3l1.3 3.1zm-1-2.6l-.5 1.3h1z"
            />
            <path
              id="Layer"
              className="s3"
              d="M166.6 130.7v3.1h-.3l-1.7-2.5v2.5h-.4v-3.1h.4l1.6 2.4v-2.4zM167.8 130.7v1.4l1.4-1.4h.5l-1.5 1.5 1.6 1.6h-.5l-1.5-1.4v1.4h-.4v-3.1zM170.1 133.6v-.4h.1q0 .1.1.1l.1.1h.1q.1.1.2.1h.7q.1-.1.2-.1 0-.1.1-.2v-.1-.2q0-.1-.1-.2l-.1-.1h-.1l-.1-.1h-.1l-.4-.2h-.2q0-.1-.1-.1l-.1-.1q-.1 0-.1-.1-.1 0-.1-.1l-.1-.1v-.2q-.1-.1 0-.2-.1 0 0-.1v-.2l.1-.1.2-.2.1-.1h.2q.1-.1.2-.1h.4q.1 0 .1.1H171.7l.1.1h.1l.1.1v.4q-.1 0-.1-.1h-.1l-.1-.1h-.1q-.1-.1-.2-.1h-.1-.5-.1q0 .1-.1.1 0 .1-.1.1v.2q-.1.1 0 .2 0 .1.1.2h.1v.1h.2l.5.2q.1.1.2.1l.1.1q.1 0 .1.1.1 0 .1.1.1 0 .1.1l.1.1v.5q0 .1-.1.2v.1q-.1.1-.2.1l-.2.2h-.1q-.1.1-.2.1h-.5q-.1 0-.2-.1h-.2l-.1-.1h-.1l-.1-.1zM172.6 133.6v-.1-.1h.1v-.1H173l.1.1v.4h-.1l-.1.1h-.1l-.1-.1q-.1 0-.1-.1v-.1zm.1-.8l-.1-2.1h.5l-.1 2.1z"
            />
            <path
              id="Layer"
              className="s1"
              d="M111.6 98v-.6h4.3v.6h-1.8v4.5h-.7V98zM120.3 97.4h.6v5.2h-.6v-2.4h-2.9v2.4h-.7v-5.2h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M125.7 102.5l-.6-1.5H123l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M131.1 97.4v5.2h-.5l-2.8-4.1v4.1h-.7v-5.2h.7l2.7 3.9v-3.9zM133 97.4v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.7h-.8l-2.4-2.5v2.4h-.7v-5.1zM136.7 102.2v-.8q.1.2.3.3l.4.2q.2.1.4.1H138.7q.1 0 .2-.1.1 0 .2-.1l.1-.1q.1 0 .1-.1v-.2q.1-.1.1-.2v-.1l-.1-.1v-.2q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1-.2-.2-.3-.5-.1-.2-.1-.5.1-.3.2-.5t.3-.4q.2-.1.3-.2.1 0 .3-.1h.3q.1-.1.3-.1.1.1.2.1h.4q.1 0 .2.1h.2q.1 0 .1.1.1 0 .2.1.1 0 .2.1v.7q-.1-.1-.2-.1-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1h-.2q-.1 0-.2-.1h-.4q-.1.1-.2.1h-.2q0 .1-.1.1l-.1.1q-.1.1-.1.2-.1 0-.1.1v.5q.1 0 .1.1l.1.1q.1 0 .1.1.1 0 .2.1h.1q.1.1.2.1l.8.3q.1.1.2.1.1.1.2.1l.2.2.2.2q.1.1.1.2.1.1.1.2.1.1.1.2v.5q-.1.2-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1q-.1 0-.2-.1h-.2l-.1-.1zM140.7 102.1q0-.1.1-.1v-.1l.1-.1.1-.1H141.3q.1 0 .1.1h.1v.1q.1 0 .1.1V102.3q0 .1-.1.2-.1 0-.1.1h-.4q0-.1-.1-.1l-.1-.1v-.1q-.1 0-.1-.1v-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM100.5 90.4v-.6h4.2v.6H103v4.5h-.7v-4.5zM109.2 89.8h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7V92h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M114.5 94.9l-.5-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M120 89.8v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM121.9 89.8v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM125.5 94.5v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1H127.3q.2 0 .3-.1h.1q.1-.1.2-.1.1-.1.2-.1 0-.1.1-.2v-.1-.2-.2-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1t-.1-.2q-.1 0-.2-.1t-.1-.3q-.1-.1-.1-.2V91v-.2q.1-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1.1.2.1h.2l.1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1-.1-.2-.1h-.8q-.1.1-.2.1t-.1.1q-.1 0-.2.1 0 .1-.1.1v.2q-.1.1 0 .2-.1 0 0 .1v.1q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2t.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H126.9q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1zM129.6 94.5v-.2q0-.1.1-.1l.1-.1q.1-.1.2-.1h.2q0 .1.1.1l.1.1v.2q.1 0 0 .1.1 0 0 0v.2l-.1.1-.1.1h-.2-.1-.1v-.1h-.1q0-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM116.6 83v-.6h4.2v.6H119v4.5h-.7V83zM125.2 82.4h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M130.6 87.5L130 86h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M136.1 82.4v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM137.9 82.4v2.3l2.3-2.3h.9l-2.5 2.4 2.5 2.7h-.8l-2.4-2.4v2.4h-.6v-5.1zM141.6 87.1v-.7q.1.1.2.1l.2.2.1.1h.2q.2.1.3.1.1 0 .2.1h.8q.1-.1.2-.1.1-.1.2-.1l.1-.1q.1-.1.1-.2.1 0 .1-.1v-.2-.2-.1l-.1-.1-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.2-.2-.5-.1-.3-.1-.5 0-.3.1-.5.2-.3.4-.4l.2-.2q.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.4q-.1-.1-.2 0-.1-.1-.2 0h-.2q-.1 0-.2.1h-.1l-.1.1q-.1.1-.1.2l-.1.1v.5q.1 0 .1.1t.1.1l.1.1q.1 0 .2.1l.1.1h.2l.8.3q.1.1.2.1l.2.2q.1 0 .2.1l.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3l-.2.2q-.1.2-.2.2-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1h-.2q-.1-.1-.2-.1 0-.1-.1-.1zM145.7 87.1V87v-.1-.1h.1l.1-.1q.1-.1.2-.1t.2.1q.1 0 .1.1h.1v.1q0 .1.1.1v.1q0 .1-.1.3-.1.1-.2.1-.1.1-.3 0-.1 0-.2-.1l-.1-.1v-.1-.1zm.2-1.2l-.2-3.5h.8l-.2 3.5zM108.2 74.8v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM116.8 74.2h.7v5.1h-.7V77h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M122.2 79.3l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M127.6 74.2v5.1h-.5l-2.8-4v4h-.7v-5.1h.7l2.7 3.9v-3.9zM129.5 74.2v2.3l2.3-2.3h.9l-2.6 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7v-5.1zM133.2 78.9v-.7l.2.2.1.1q.1.1.2.1.1.1.2.1t.2.1H135.2q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q0-.1.1-.2V78v-.1q0-.1-.1-.2v-.1q-.1 0-.1-.1-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1-.2-.2-.3-.5-.1-.2 0-.5 0-.3.1-.5t.3-.4q.2-.1.3-.2.2 0 .3-.1h.3q.1-.1.3-.1h.2q.1.1.2.1h.4q.1.1.2.1t.2.1l.1.1q.1 0 .2.1v.7l-.2-.2q-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1 0-.2.1h-.1q-.1.1-.2.1l-.1.1q-.1.1-.1.2-.1 0-.1.1v.5q.1 0 .1.1t.1.1l.1.1q.1 0 .2.1l.1.1h.2l.8.3q.1.1.2.1.1.1.2.1l.2.2.2.2.2.2v.2q.1.1.1.2v.5q-.1.2-.1.3l-.2.2q-.1.2-.2.2-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1q-.1 0-.2-.1h-.2q0-.1-.1-.2zM137.3 78.9q-.1 0 0-.1v-.1q0-.1.1-.1l.1-.1h.4l.1.1q.1.1.1.2v.4q-.1 0-.1.1h-.1v.1H137.6h-.1q0-.1-.1-.1l-.1-.1v-.1-.1q-.1 0-.1-.1zm.3-1.2l-.2-3.5h.8l-.2 3.5zM109.5 67.7v-.6h4.2v.6H112v4.5h-.7v-4.5zM118.2 67.1h.6v5.1h-.6v-2.3h-3v2.3h-.6v-5.1h.6v2.2h3z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M123.5 72.2l-.5-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M129 67.1v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.6l2.8 3.9v-3.9zM130.9 67.1v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.9l-2.3-2.4v2.4h-.7v-5.1zM134.5 71.8v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H136.3q.2 0 .3-.1h.1q.1-.1.2-.1.2-.1.2-.3.1-.1.1-.3v-.3l-.2-.2q0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1l-.1-.1-.2-.2q-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q.1-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .3-.1.1-.1.2-.1.2-.1.3-.1h.8q.1.1.2.1h.1q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.1-.1-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1h-.2q-.1 0-.2-.1h-.8q-.1.1-.2.1t-.2.1l-.1.1q0 .1-.1.1v.2q-.1.1-.1.2l.1.1v.1q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1l.1.1.8.3q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2t.1.3v.2q0 .1-.1.3 0 .1-.1.2 0 .2-.1.3l-.2.2q-.2.1-.3.2-.2 0-.3.1-.2 0-.3.1H135.9q-.1 0-.2-.1h-.3q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1zM138.6 71.8v-.1-.1l.1-.1.1-.1h.1q0-.1.1-.1h.1q.1 0 .1.1h.1l.1.1q0 .1.1.1v.4h-.1v.1q-.1 0-.1.1h-.1-.1H138.8q0-.1-.1-.1V72h-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM111.6 61.3v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM120.3 60.7h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M125.7 65.8l-.6-1.5H123l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M131.1 60.7v5.1h-.5l-2.8-4.1v4.1h-.7v-5.1h.7l2.7 3.9v-3.9zM133 60.7V63l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.8l-2.4-2.4v2.4h-.7v-5.1zM136.7 65.4v-.7q0 .1.1.2.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H138.7q.1-.1.2-.1t.2-.1l.1-.1q0-.1.1-.2v-.1q.1-.1.1-.2v-.1q-.1-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-.2l-.7-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q0-.1-.1-.2v-.3q-.1-.1-.1-.2 0-.2.1-.3 0-.2.1-.3 0-.1.1-.2l.2-.2q.2-.1.3-.2.1 0 .3-.1.1 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1t.1.1h.2q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.2-.1l-.1-.1h-.2q-.1-.1-.2-.1t-.2-.1H138.1q-.1 0-.2.1h-.2l-.1.1-.2.2q-.1.2-.1.3v.3q.1.2.2.3.1 0 .1.1h.2q0 .1.1.1t.2.1l.8.3q.1 0 .2.1.1 0 .2.1l.2.2.2.2q.2.2.3.4v.5q0 .3-.2.5-.1.3-.3.4-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.1-.1zM140.7 65.4v-.1l.1-.1q0-.1.1-.1V65h.5l.1.1v.1h.1v.4l-.1.1q0 .1-.1.1h-.1v.1h-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2zm.3-1.2l-.2-3.5h.8l-.2 3.5zM344.5 61.3v-.6h4.2v.6H347v4.5h-.7v-4.5zM353.1 60.7h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M358.6 65.8l-.7-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M364 60.7v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM365.9 60.7V63l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM369.5 65.4v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1-.1.2-.1t.2-.1.2-.1q0-.1.1-.2v-.1-.2-.1-.2q-.1 0-.1-.1t-.1-.1l-.1-.1q-.1-.1-.2-.1t-.1-.1h-.2l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1-.1-.2-.2-.5-.1-.2-.1-.5t.2-.5q.1-.2.3-.4.1-.1.3-.2.1 0 .2-.1H371.8q.1 0 .1.1h.2q.1.1.2.1t.2.1l.1.1v.7l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.4q-.1.1-.2.1h-.2q-.1.1-.2.1 0 .1-.1.1 0 .1-.1.2v.1q-.1.1-.1.2t.1.1v.2l.1.1v.1q.1 0 .2.1.1 0 .1.1h.2q0 .1.1.1l.8.3q.2.1.3.1.1.1.2.1l.2.2.2.2q.1.1.1.2.1.1.1.2t.1.2v.2q0 .2-.1.3 0 .2-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2.1-.3.1-.2.1-.3.1h-.8q-.2 0-.3-.1h-.2q-.1-.1-.2-.1t-.2-.1l-.1-.1q-.1 0-.2-.1zM373.6 65.4v-.1-.1h.1v-.1q.1-.1.2-.1H374.2q.1 0 .2.1v.1h.1v.4q-.1 0-.1.1-.1.1-.2.1-.1.1-.2.1l-.1-.1q-.1 0-.2-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM345.8 67.7v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM354.4 67.1h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M359.8 72.2l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M365.3 67.1v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM367.1 67.1v2.3l2.3-2.3h.9l-2.6 2.4 2.7 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM370.8 71.8v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H372.6q.1 0 .2-.1h.2q.1-.1.2-.1.1-.1.2-.3.1-.1.1-.3v-.3l-.2-.2-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q0-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1H372.2h-.1q-.1.1-.2.1t-.2.1q-.1 0-.1.1-.1 0-.1.1t-.1.2V68.5q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2-.1.2-.1.3-.1.1-.3.2-.1.1-.2.1-.2.1-.3.2h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM374.9 71.8v-.1-.1-.1h.1q0-.1.1-.1t.1-.1h.2l.1.1h.1v.1h.1v.1q0 .1.1.1v.2q-.1 0-.1.1t-.1.1v.1h-.2H375.1l-.1-.1-.1-.1v-.2zm.2-1.2l-.1-3.5h.7l-.1 3.5zM344.5 74.8v-.6h4.2v.6H347v4.5h-.7v-4.5zM353.1 74.2h.7v5.1h-.7V77h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M358.6 79.3l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.6z"
            />
            <path
              id="Layer"
              className="s1"
              d="M364 74.2v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.8 3.9v-3.9zM365.9 74.2v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM369.5 78.9v-.7l.2.2q.1 0 .2.1t.2.1.2.1.2.1h1q.1 0 .2-.1.1 0 .2-.1.1 0 .2-.1 0-.1.1-.1v-.2-.2-.1-.2l-.1-.1-.1-.1-.1-.1q-.1 0-.2-.1l-.1-.1h-.2l-.7-.3q-.2-.1-.3-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.1-.1l-.2-.2q-.1-.1-.1-.2-.1-.1-.1-.3v-.2-.3q.1-.1.1-.3l.2-.2.2-.2q.1-.1.3-.2.1 0 .2-.1h.3q.2-.1.3-.1h.3q.1.1.2.1H371.9q.1.1.2.1t.2.1.2.1l.1.1v.7q-.1-.1-.1-.2-.1 0-.2-.1h-.2l-.1-.1q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4q-.1 0-.2.1h-.2q-.1.1-.2.1 0 .1-.1.1 0 .1-.1.2v.1q-.1.1-.1.2t.1.1v.2l.1.1v.1q.1 0 .2.1l.1.1h.2q0 .1.1.1l.8.3q.2.1.3.1.1.1.2.1l.2.2.2.2q.1.1.1.2.1.1.1.2v.2q.1.1.1.2 0 .2-.1.3 0 .1-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2.1-.3.1-.2.1-.3.1h-.8q-.2 0-.3-.1h-.2q-.1-.1-.2-.1t-.2-.1l-.1-.1q-.1 0-.2-.1zM373.6 78.9v-.1q0-.1.1-.1v-.1q.1-.1.2-.1H374.2q.1 0 .2.1v.1l.1.1V79.1q-.1 0-.1.1v.1q-.1.1-.3.1h-.2q-.2-.1-.2-.2-.1-.1-.1-.3zm.2-1.2l-.1-3.5h.7l-.1 3.5zM361.6 83v-.6h4.2v.6H364v4.5h-.7V83zM370.2 82.4h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M375.6 87.5L375 86h-2.1l-.6 1.5h-.7l2-5.1h.7l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M381.1 82.4v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM383 82.4v2.3l2.3-2.3h.8l-2.5 2.4 2.6 2.7h-.8l-2.4-2.4v2.4h-.7v-5.1zM386.6 87.1v-.7q.1.1.2.1l.2.2q.1 0 .2.1.1 0 .2.1h.2q.1 0 .2.1h.8q.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1v-.2q.1 0 .1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.7-.3q-.2 0-.3-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.2-.2-.5t-.1-.5q0-.3.1-.5.2-.3.4-.4.1-.1.3-.2.1-.1.2-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2q.1.1.2.1 0 .1.1.1v.7q-.1 0-.2-.1 0-.1-.1-.1-.1-.1-.2-.1-.1-.1-.2-.1t-.2-.1h-.4q-.1-.1-.2 0-.1-.1-.2 0h-.2q-.1 0-.1.1h-.2l-.1.1q-.1.1-.1.2l-.1.1v.5q.1 0 .1.1t.1.1l.1.1q.1 0 .2.1l.1.1h.2l.8.3q.1.1.2.1.1.1.2.1l.2.2.2.2.2.2v.2q.1.1.1.2v.5q0 .2-.1.3l-.2.2q-.1.2-.2.2-.1.1-.3.2-.1.1-.3.1-.1.1-.3.1h-.8q-.1 0-.2-.1h-.3l-.1-.1h-.2l-.1-.1q-.1-.1-.2-.1zM390.7 87.1V87v-.1l.1-.1q0-.1.1-.1h.1v-.1h.2l.1.1h.1v.1h.1v.1l.1.1v.2l-.1.1v.1h-.1v.1h-.1-.1-.1-.2q-.1 0-.1-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM341.2 91.9v-.6h4.2v.6h-1.8v4.5h-.6v-4.5zM349.8 91.3h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M355.2 96.4l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M360.7 91.3v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM362.5 91.3v2.3l2.4-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.4-2.4v2.4h-.6v-5.1zM366.2 96v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H368q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1l-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2-.1-.1-.1-.3-.1-.1-.1-.2v-.3-.2q0-.2.1-.3.1-.1.2-.3l.2-.2q.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1.1.2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1 0-.2-.1h-.7q-.1.1-.2.1t-.2.1l-.1.1-.1.1q0 .1-.1.2V92.7q0 .1.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .3.1.1 0 .2.1t.2.1l.2.2q0 .1.1.2 0 .1.1.2v.3q.1.1 0 .2.1.1 0 .3 0 .1-.1.3 0 .1-.1.2t-.3.2l-.2.2q-.2 0-.3.1-.2 0-.4.1h-.5q-.1 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.1-.1q-.1 0-.2-.1zM370.3 96v-.2q0-.1.1-.1.1-.1.2-.1 0-.1.1-.1t.1.1h.2v.1h.1v.1l.1.1v.2l-.1.1v.1h-.1v.1h-.1-.1q0 .1-.1 0 0 .1-.1 0h-.1q-.1 0-.1-.1l-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM363.1 99.3v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM371.8 98.7h.6v5.1h-.6v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M377.2 103.8l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.6-4.3l-.9 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M382.6 98.7v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.8 3.9v-3.9zM384.5 98.7v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM388.1 103.4v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1H390.2q.1-.1.2-.1t.1-.1q.1 0 .2-.1 0-.1.1-.2v-.1q.1-.1 0-.2.1-.1 0-.1v-.2l-.1-.1q0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1l-.1-.1h-.2l-.7-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1l-.2-.2q-.1-.1-.1-.2 0-.2-.1-.3v-.2q0-.2.1-.3v-.3l.2-.2.2-.2q.1-.1.3-.2.1 0 .3-.1.1 0 .3-.1h.5q.1 0 .2.1h.4l.1.1q.1 0 .2.1h.2q.1.1.1.2v.7q0-.1-.1-.2-.1 0-.2-.1-.1 0-.2-.1h-.1q-.1-.1-.2-.1t-.2-.1H389.6q-.1 0-.2.1h-.2l-.1.1q-.1 0-.2.1 0 .1-.1.1v.6q0 .1.1.2l.1.1.1.1h.1q.1.1.2.1t.1.1l.8.3q.2 0 .3.1.1 0 .2.1l.2.2.2.2q.1 0 .1.1.1.1.1.3.1.1.1.2v.5q-.1.1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1.1-.3.1-.2 0-.3.1h-.8q-.1-.1-.3-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1zM392.2 103.4v-.1-.1h.1v-.1q.1 0 .1-.1h.4q.1 0 .2.1 0 .1.1.1v.4l-.1.1-.1.1h-.1q0 .1-.1.1h-.1q-.1 0-.1-.1h-.1l-.1-.1q0-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5zM342.5 106.7v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM351.1 106.1h.7v5.1h-.7v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M356.5 111.2l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M361.9 106.1v5.1h-.4l-2.9-4v4h-.6v-5.1h.6l2.8 3.9v-3.9zM363.8 106.1v2.3l2.3-2.3h.9l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM367.5 110.8v-.7l.2.2.1.1q.1 0 .2.1.1 0 .2.1h.2q.2.1.3.1h.8q.1-.1.2-.1t.2-.1q.1 0 .1-.1.1-.1.1-.2.1 0 .1-.1v-.2-.1q0-.1-.1-.2v-.1l-.1-.1q-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1h-.1l-.8-.3q-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1t-.2-.1q-.1-.1-.1-.2l-.2-.2v-.3-.2-.3q0-.2.1-.3 0-.1.1-.2t.3-.2l.2-.2q.2 0 .3-.1.2 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1t.2.1h.1q.1.1.2.1v.7q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1H368.9q-.1 0-.2.1h-.1q-.1.1-.2.1t-.1.1l-.1.1q-.1.1-.1.2V107.5q0 .1.1.1v.2q.1 0 .1.1.1 0 .1.1h.2l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.1 0 .3.1l.2.2q.1 0 .1.1l.2.2q0 .1.1.2v.8q0 .1-.1.3l-.2.2-.2.2q-.1.1-.3.2-.1 0-.3.1-.1 0-.3.1h-.5q-.2 0-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1l-.1-.1zM371.6 110.8v-.1-.1q0-.1.1-.1v-.1h.5l.1.1.1.1v.1q.1.1 0 .1V111.1h-.1q0 .1-.1.1l-.1.1h-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2zm.3-1.2l-.2-3.5h.8l-.2 3.5zM345.8 124.5v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM354.4 123.9h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M359.8 129l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M365.3 123.9v5.1h-.5l-2.9-4v4h-.6v-5.1h.6l2.7 3.9v-3.9zM367.1 123.9v2.3l2.3-2.3h.9l-2.6 2.5 2.7 2.6h-.9l-2.4-2.4v2.4h-.6v-5.1zM370.8 128.6v-.7l.2.2q.1 0 .2.1.1 0 .2.1.1 0 .2.1h.2q.1.1.2.1h.8q.1 0 .2-.1.1 0 .2-.1.1 0 .1-.1l.1-.1q.1-.1.1-.2v-.2-.1-.2q-.1 0-.1-.1l-.1-.1-.1-.1q-.1-.1-.2-.1t-.1-.1h-.2l-.8-.3q-.1-.1-.2-.1-.1-.1-.2-.1l-.2-.2q-.1 0-.2-.1t-.1-.2-.1-.2q-.1-.1-.1-.3v-.2-.3q0-.2.1-.3l.2-.2.2-.2.2-.2q.2 0 .3-.1.2 0 .3-.1h.5q.1 0 .2.1h.4q.1.1.2.1t.2.1q.1 0 .1.1.1 0 .2.1v.7l-.2-.2-.1-.1q-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1h-.4l-.1.1h-.2q-.1.1-.2.1l-.1.1q-.1.1-.1.2l-.1.1V125.3q0 .1.1.2v.1q.1 0 .1.1.1 0 .2.1h.1l.1.1q.1.1.2.1l.8.3q.1 0 .2.1.2 0 .3.1l.2.2q.1.1.2.1 0 .1.1.2 0 .1.1.3v.7q0 .1-.1.3 0 .1-.1.2l-.2.2q-.2.1-.3.2-.2.1-.3.1-.2.1-.4.1h-.8q-.1-.1-.2-.1h-.2q-.1-.1-.2-.1t-.2-.1q-.1 0-.1-.1-.1 0-.2-.1zM374.9 128.6v-.1-.1l.1-.1.1-.1h.5v.1q.1 0 .1.1t.1.1v.2l-.1.1v.1l-.1.1h-.1l-.1.1h-.1-.1-.1v-.1h-.1l-.1-.1v-.1-.1-.1zm.2-1.2l-.1-3.5h.7l-.2 3.5zM345.8 131.5v-.6h4.2v.6h-1.8v4.5h-.7v-4.5zM354.4 130.9h.7v5.1h-.7v-2.3h-2.9v2.3h-.6v-5.1h.6v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M359.8 136l-.6-1.5h-2.1l-.6 1.5h-.6l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M365.3 130.9v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.7 3.9v-3.9zM367.1 130.9v2.3l2.3-2.3h.9l-2.6 2.4 2.7 2.7h-.9l-2.4-2.4v2.4h-.6v-5.1zM370.8 135.6v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1H372.6q.1 0 .2-.1h.2q.1-.1.2-.1l.1-.1q.1-.1.1-.2l.1-.1v-.2-.2-.1l-.1-.1q0-.1-.1-.1l-.1-.1q-.1-.1-.2-.1-.1-.1-.2-.1l-.1-.1-.8-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1q-.2-.3-.2-.5-.1-.3-.1-.5 0-.3.1-.5.2-.3.4-.5.1 0 .2-.1.2-.1.3-.1.2-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .2.1h.2l.1.1q.1.1.2.1v.7q-.1 0-.2-.1l-.1-.1q-.1 0-.2-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1H372.2h-.1q-.1 0-.2.1-.1 0-.2.1-.1 0-.1.1-.1 0-.1.1t-.1.2V132.3l.1.1v.1l.1.1q.1.1.2.1 0 .1.1.1l.1.1q.1 0 .2.1l.8.3q.1 0 .2.1.2 0 .3.1.1.1.2.1l.2.2q0 .1.1.2 0 .1.1.2v.8q0 .1-.1.2-.1.2-.1.3-.1.1-.3.2-.1.1-.2.1-.2.1-.3.2h-.4q-.1.1-.3.1h-.2q-.1-.1-.3-.1h-.2q-.1 0-.2-.1h-.2q-.1-.1-.2-.1l-.1-.1q-.1 0-.2-.1zM374.9 135.6v-.1-.1-.1h.1q0-.1.1-.1t.1-.1h.2l.1.1h.1v.1q.1 0 .1.1.1.1.1.2t-.1.2l-.1.1v.1h-.2-.1-.1-.1l-.1-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.2 3.5zM357.3 116.9v-.6h4.3v.6h-1.8v4.5h-.7v-4.5zM366 116.3h.7v5.1h-.7v-2.3h-2.9v2.3h-.7v-5.1h.7v2.2h2.9z"
            />
            <path
              id="Layer"
              fillRule="evenodd"
              className="s1"
              d="M371.4 121.4l-.6-1.5h-2.1l-.6 1.5h-.7l2-5.1h.6l2 5.1zm-1.7-4.3l-.8 2.2h1.7z"
            />
            <path
              id="Layer"
              className="s1"
              d="M376.8 116.3v5.1h-.5l-2.9-4.1v4.1h-.6v-5.1h.6l2.8 3.9v-3.9zM378.7 116.3v2.3l2.3-2.3h.8l-2.5 2.5 2.6 2.6h-.9l-2.3-2.4v2.4h-.7v-5.1zM382.3 121v-.7q.1.1.2.1l.2.2q.1 0 .2.1h.2q.1.1.2.1t.2.1h.7q.1 0 .2-.1h.2q.1-.1.2-.1 0-.1.1-.1 0-.1.1-.2v-.1q.1-.1 0-.2.1-.1 0-.2v-.1q0-.1-.1-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1-.2-.1 0-.1-.1-.1t-.2-.1l-.7-.3q-.1 0-.2-.1-.1 0-.2-.1-.1 0-.2-.1t-.2-.1l-.2-.2q-.1-.1-.1-.3 0-.1-.1-.2v-.3q0-.1.1-.2v-.3q.1-.2.2-.3l.2-.2q.1 0 .3-.1.1-.1.3-.1.1-.1.3-.1h.7q.1 0 .2.1h.2q.1 0 .1.1h.2q.1.1.2.1l.1.1v.7l-.1-.1q-.1 0-.2-.1-.1 0-.1-.1-.1 0-.2-.1h-.2q-.1-.1-.2-.1h-.8q-.1 0-.2.1-.1 0-.1.1-.1 0-.2.1l-.1.1v.6q0 .1.1.1 0 .1.1.1 0 .1.1.1l.1.1q.1.1.2.1h.1l.8.4q.2 0 .3.1.1 0 .2.1t.2.1l.2.2q.1.1.1.2.1.1.1.2.1.1.1.3v.5q-.1.1-.1.2-.1.2-.2.3l-.2.2q-.1.1-.3.1-.1.1-.3.2h-.3q-.2.1-.4.1-.1 0-.2-.1h-.5q-.1 0-.2-.1h-.2q0-.1-.1-.1-.1-.1-.2-.1t-.2-.1zM386.4 121q0-.1.1-.2.1-.2.2-.2.1-.1.2 0 .2 0 .3.1l.1.1v.4l-.1.1q-.1.1-.2.1H386.7q-.1 0-.2-.1 0-.1-.1-.1v-.1-.1zm.2-1.2l-.1-3.5h.7l-.1 3.5z"
            />
          </g>
        </g>
        <path id="Shape 1" d="M29 19h36v28H29z" fill="#7630ea" />
        <path id="Shape 2" className="s0" d="M22 19h58v29H22z" />
      </g>
      <>
        <text x="425" y="100" style={{ fontSize: "10px", fontWeight: "400" }}>
          {props.templatemessage.substring(0, 24)}
        </text>
        {props.templatemessage.length > 25 ? (
          <text x="425" y="115" style={{ fontSize: "10px", fontWeight: "400" }}>
            {props.templatemessage.substring(24, 48)}
          </text>
        ) : null}
        {props.templatemessage.length > 49 ? (
          <text x="425" y="130" style={{ fontSize: "10px", fontWeight: "400" }}>
            {props.templatemessage.substring(48, 72)}
          </text>
        ) : null}
        {props.templatemessage.length > 73 ? (
          <text x="425" y="145" style={{ fontSize: "10px", fontWeight: "400" }}>
            {props.templatemessage.substring(72, 96)}
          </text>
        ) : null}
        {props.templatemessage.length > 97 ? (
          <text x="425" y="160" style={{ fontSize: "10px", fontWeight: "400" }}>
            {props.templatemessage.substring(96, 120)}
          </text>
        ) : null}
        {props.templatemessage.length > 121 ? (
          <text x="425" y="175" style={{ fontSize: "10px", fontWeight: "400" }}>
            {props.templatemessage.substring(96, 144)}
          </text>
        ) : null}
        <text x="425" y="200" style={{ fontSize: "10px", fontWeight: "400" }}>
          From {props.RecievedName}
        </text>
        <text x="425" y="220" style={{ fontSize: "10px", fontWeight: "400" }}>
          {props.RecievedEmail}
        </text>
      </>
    </svg>
  )
}

SvgComponent4.propTypes = {
  templatemessage: PropTypes.string,
  RecievedName: PropTypes.string,
  RecievedEmail: PropTypes.string,
}

export default SvgComponent4
