// import PropTypes from "prop-types"
// import MetaTags from "react-meta-tags"
// import React, { useEffect, useState } from "react"

// import {
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane,
//   Modal,
// } from "reactstrap"
// import SweetAlert from "react-bootstrap-sweetalert"
// // import Logimg from "../../assets/images/Loginpage.jpg"
// // Redux
// import { connect } from "react-redux"
// import { withRouter } from "react-router-dom"

// // availity-reactstrap-validation
// import { AvForm, AvField } from "availity-reactstrap-validation"
// import LoginLapImag from "../../assets/images/Login PC.png"
// import LoginMobImag from "../../assets/images/Login PC.png"

// // actions
// import { loginUser, apiError, socialLogin } from "../../store/actions"
// import classnames from "classnames"
// import { isMobile } from "react-device-detect"
// // import firebase from "firebase/app"
// // import "firebase/analytics"
// // import "firebase/messaging"

// const Login = props => {
//   const [ms, setMS] = useState(false)

//   const [ActiveTab, setActiveTab] = useState("Individual")
//   const [customAction, setcustomAction] = useState()
//   const [activeTabContent, setactiveTabContent] = useState("1")
//   const [mssgToken, setmssgToken] = useState("")
//   const [showPassword, setShowPassword] = useState(false)
//   const [toggleMssg, setToggleMssg] = useState("")

//   const handleMssgChange = (event) => {
//     setToggleMssg(event.target.value);
//   };

//   const toggle1 = tab => {
//     if (ActiveTab !== tab) {
//       setActiveTab(tab)
//     }
//   }

//   const togglePassword = () => {
//     setShowPassword(!showPassword)
//   }
//   // handleValidSubmit
//   const handleValidSubmit = (event, values) => {
//     props.loginUser(values, props.history)
//   }
//   const [alert, setAlert] = useState(true)
//   const alertToggle = () => {
//     setAlert(!alert)
//   }
//   const hideAlert = () => {
//     setAlert(false)
//   }
//   const loader = document.querySelector(".loader")

//   useEffect(() => {
//     // const firebaseConfig = {
//     //   apiKey: "AIzaSyDhyZp6xSQeZZBvKnPqkE5mwoUTDIiBjA0",
//     //   authDomain: "userposts-64965.firebaseapp.com",
//     //   projectId: "userposts-64965",
//     //   storageBucket: "userposts-64965.appspot.com",
//     //   messagingSenderId: "483796190733",
//     //   appId: "1:483796190733:web:e29eef974a10885c427744",
//     //   measurementId: "G-N8E9G16ELR",
//     // }

//     // // init firebase backend
//     // // initFirebaseBackend(firebaseConfig)

//     // firebase.initializeApp(firebaseConfig)
//     // const analytics = firebase.analytics()
//     // const messaging = firebase.messaging()

//     // // Get registration token. Initially this makes a network call, once retrieved
//     // // subsequent calls to getToken will return from cache.
//     // messaging
//     //   .getToken({
//     //     vapidKey:
//     //       "BJ4DhIruUgaLVh2H8orodqcT0TUQAC7NKuaETeic9b_fnpc33JPb02uVlda_zGaKiwPKVkd4GOlZNCYJ11A_H1g",
//     //   })
//     //   .then(currentToken => {
//     //     if (currentToken) {
//     //       console.log(currentToken)
//     //       setmssgToken(currentToken);
//     //       // Send the token to your server and update the UI if necessary
//     //       // ...
//     //     } else {
//     //       // Show permission request UI
//     //       console.log(
//     //         "No registration token available. Request permission to generate one."
//     //       )
//     //       // ...
//     //     }
//     //   })
//     //   .catch(err => {
//     //     console.log("An error occurred while retrieving token. ", err)
//     //     // ...
//     //   })

//     loader.classList.add("loader--hide")

//     setmssgToken(localStorage.getItem("notificationToken"))
//   }, [])

//   return (
//     <React.Fragment>
//       <MetaTags>
//         <title>Xiaomi - 10 Years of Tomorrow</title>
//       </MetaTags>
//       <Modal
//         size="xl"
//         isOpen={ms}
//         toggle={() => {
//           setMS(!ms)
//         }}
//         centered={true}
//         style={{ width: "auto" }}
//       >
//         <div className="modal-header">
//           <h5 className="modal-title mt-0">Photo Mosaic</h5>
//           <button
//             type="button"
//             onClick={() => {
//               setMS(!ms)
//             }}
//             className="close"
//             data-dismiss="modal"
//             aria-label="Close"
//           >
//             <span aria-hidden="true">&times;</span>
//           </button>
//         </div>
//         <div className="modal-body">
//           <iframe
//             allow="camera; microphone"
//             title="test"
//             className="embed-responsive-item mosaic"
//             src={encodeURI("https://demo-mosaic.herokuapp.com/")}
//           />
//         </div>
//       </Modal>
//       <Container style={{ padding: 0, position: "relative" }} fluid>
//         {isMobile ? (
//           <img loading="lazy"
//             src={LoginMobImag}
//             // src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/MI%2FLogin%20Phone.png?alt=media"
//             style={{ position: "absolute", width: "100%" }}
//           ></img>
//         ) : (
//           <img loading="lazy"
//             src={LoginLapImag}
//             // src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/MI%2FLogin%20PC.png?alt=media"
//             style={{ position: "absolute", width: "100%" }}
//           ></img>
//         )}
//         <div className="account-pages pt-sm-5">
//           <center className="loginCenter">
//             <Card
//               className="overflow-hidden cardLogin"
//               style={{
//                 // boxShadow: "0px -5rem 2rem 0px rgb(18 38 63 / 3%)",
//                 boxShadow: "none",
//                 background: "transparent",
//               }}
//             >
//               <CardBody className="pt-0" style={{ padding: 0 }}>
//                 <div className="p-2" style={{ color: "#fff" }}>
//                   {/* <div style={{fontSize:"0.7rem",textAlign:"left",color:"black",fontWeight:"bold"}} className="mt-1" >I consent and authorize ABB to the use of my data as part of an ABB initiative to celebrate the Technology Day virtually. Please refer to the ABB privacy policy to know more - <a href="https://new.abb.com/privacy-notice/virtual-events">https://new.abb.com/privacy-notice/virtual-events</a></div> */}
//                   <TabContent
//                     activeTab={activeTabContent}
//                     className="p-3 text-muted"
//                   >
//                     <TabPane tabId="1">
//                       <AvForm
//                         className="form-horizontal"
//                         onValidSubmit={(e, v) => {
//                           handleValidSubmit(e, v)
//                         }}
//                       >
//                         {props.error && typeof props.error === "string" ? (
//                           props.error == "Invalid Email!" ||
//                           props.error ==
//                             "Somethiing went wrong, Please try again!" ||
//                           props.error ==
//                             "User Already registered , Please come back again on the event day to log in." ||
//                           props.error ==
//                             "Oops! Your password is incorrect. Please try again using the correct password." ||
//                           props.error ==
//                             "User Already registered , please login" ||
//                           props.error == "User not Found!" ||
//                           props.error ==
//                             "User not Found! Please Register to login" ||
//                           props.error ==
//                             "Your ID has been registered. Please come back again on the event day to log in." ? (
//                             props.error ==
//                             "Your ID has been registered. Please come back again on the event day to log in." ? (
//                               <SweetAlert
//                                 show={alert}
//                                 success
//                                 // showCancel
//                                 title="Registered Successfully"
//                                 cancelBtnBsStyle="danger"
//                                 // confirmBtnText="Photo Mosaic"
//                                 onConfirm={() => {
//                                   // setMS(true);
//                                   hideAlert(false)
//                                 }}
//                                 // onCancel={hideAlert}
//                                 // onClick={console.log("hi")}
//                                 // timeout={4000}
//                               >
//                                 {props.error}
//                               </SweetAlert>
//                             ) : props.error ==
//                               "User Already registered , Please come back again on the event day to log in." ? (
//                               <SweetAlert
//                                 show={alert}
//                                 success
//                                 // showCancel
//                                 title="Already Registered"
//                                 cancelBtnBsStyle="danger"
//                                 // confirmBtnText="Photo Mosaic"
//                                 onConfirm={() => {
//                                   // setMS(true);
//                                   hideAlert(false)
//                                 }}
//                                 // onCancel={hideAlert}
//                                 // timeout={4000}
//                               >
//                                 {props.error} Please click on Photo Mosaic to
//                                 submit picture for RALLY OF INCLUSION.
//                               </SweetAlert>
//                             ) : (
//                               <SweetAlert
//                                 show={alert}
//                                 // warning
//                                 // title="  Failed!"
//                                 onConfirm={hideAlert}
//                                 // timeout={4000}
//                               >
//                                 {props.error}
//                                 {props.error}a
//                               </SweetAlert>
//                             )
//                           ) : (
//                             <SweetAlert
//                               show={alert}
//                               // warning
//                               // title="Failed!"
//                               onConfirm={hideAlert}
//                               // confirmBtnBsStyle="danger"
//                               confirmBtnCssClass="sweetAlertBtn"
//                               // timeout={4000}
//                             >
//                               {props.error}
//                             </SweetAlert>
//                           )
//                         ) : null}
//                         {/* <div className="newlog">
//                       Login
//                     </div> */}
//                         {/* <div className="mb-1">
//                           <AvField
//                             type="select"
//                             name="type"
//                             defaultValue=""
//                             required
//                             onChange={handleMssgChange}
//                           >
//                             <option
//                               value=""
//                               disabled
//                               style={{ color: "#495057" }}
//                             >
//                               Choose Partners/Employee *
//                             </option>
//                             <option value="Partner">Partner</option>
//                             <option value="Employee">Employee</option>
//                           </AvField>
//                         </div> */}

//                         <div className="mb-1">
//                           <AvField
//                             name="Firstname"
//                             // style={{fontSize:"inherit"}}
//                             value=""
//                             className="form-control  "
//                             placeholder="Name *"
//                             type="text"
//                             required
//                           />
//                         </div>

//                         <div className="mb-1">
//                           <AvField
//                             name="Email"
//                             // style={{fontSize:"inherit"}}
//                             value=""
//                             className="form-control  "
//                             placeholder="Email *"
//                             type="Email"
//                             required
//                           />
//                         </div>
//                         <div className="mb-1">
//                           <AvField
//                             name="EmployeeID"
//                             // style={{fontSize:"inherit"}}
//                             value=""
//                             className="form-control  "
//                             placeholder="Employee Code *"
//                             type="text"
//                             required
//                           />
//                         </div>

//                         {/* <div className="mb-1">
//                           <AvField
//                             name="otp"
//                           // style={{fontSize:"inherit"}}
//                             value=""
//                             className="form-control  "
//                             placeholder=" OTP shared on Mail*"
//                             type="number"
//                             // required
//                           />
//                         </div> */}
//                         <div className="mb-1">
//                           <AvField
//                             name="customaction"
//                             value={customAction}
//                             className="form-control  "
//                             placeholder="action *"
//                             type="text"
//                             // required
//                             disabled
//                             hidden
//                           />
//                         </div>
//                         {/* <div className="mb-1">
//                           <AvField
//                             name="Password"
//                             value=""
//                             className="form-control  "
//                             placeholder="Password *"
//                             type="password"
//                             required
//                           />
//                         </div> */}
//                         {/* <div className="mb-2">
//                         <AvCheckboxGroup inline name="one" label=" " required>
//                         <AvCheckbox label="By ticking this box you are agreeing to receive marketing communications from Lenovo and are providing express consent to receive these messages. This includes both email and phone communications. You may unsubscribe at any time by using the link provided in each email or contacting Lenovo at privacy@lenovo.com" value="Bulbasaur" />
//                         </AvCheckboxGroup>
//                         <AvCheckboxGroup inline name="two" label=" " required>
//                         <AvCheckbox label="You are providing consent that Lenovo may process your data in the manner indicated above and described in our Privacy Policy." value="Bulbasaur" />
//                         </AvCheckboxGroup>
//                         </div> */}

//                         <div
//                           className="mt-3 d-flex justify-content-evenly"
//                           style={{ textAlign: "center" }}
//                         >
//                           <button
//                             // onClick={()=>{setcustomAction("Login")}}
//                             style={{
//                               backgroundColor: "rgb(255,255,254)",
//                               color: "white",
//                               borderColor: "white",
//                               // width: "100%",
//                               fontWeight: "bold",
//                               marginRight: "5%",
//                             }}
//                             className="btn btn-primary btn-block "
//                             type="submit"
//                           >
//                             Login
//                           </button>
//                           {/* <button
//                            onClick={()=>{setcustomAction("verify")}}
//                             style={{
//                               backgroundColor: "#24468B",
//                               color: "white",
//                               borderColor: "white",
//                               // width: "100%",
//                               fontWeight:"bold"
//                             }}
//                             className="btn btn-primary btn-block "
//                             type="submit"
//                           >
//                            Verify OTP
//                           </button> */}
//                         </div>

//                         {/* <div style={{ fontSize: "0.7rem", textAlign: "left", color: "black", fontWeight: "bold" }} className="mt-1">Kindly Register for before login. </div> */}
//                       </AvForm>
//                     </TabPane>
//                     {/* <div style={{color:"white",fontSize:"0.6rem",marginTop:"1%"}} > *Please use your official email Id to Login</div> */}
//                   </TabContent>
//                   {toggleMssg==="Employee" ? "Please enter your name and employee ID.":"Please enter your store name and partner ID."}
//                   {/* *Please use your registered email ID to login */}
//                 </div>
//               </CardBody>
//             </Card>
//           </center>
//         </div>
//       </Container>
//     </React.Fragment>
//   )
// }

// const mapStateToProps = state => {
//   const { error } = state.Login
//   return { error }
// }

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
// )

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
//   socialLogin: PropTypes.func,
// }

// -------------------------------------
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  CardBody,
  Card,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import { isMobile } from "react-device-detect"

import LoginLapImag from "../../assets/images/Login PC.png"
import LoginMobImag from "../../assets/images/Login PC.png"

import { loginUser, apiError, socialLogin } from "../../store/actions"

const Login = props => {
  const [modalState, setModalState] = useState(false)
  const [activeTab, setActiveTab] = useState("Individual")
  const [activeTabContent, setActiveTabContent] = useState("1")
  const [showPassword, setShowPassword] = useState(false)
  const [alertVisible, setAlertVisible] = useState(true)
  const [messageToken, setMessageToken] = useState("")
  const [helprc, onhelpOpenRC] = useState(false)
  const [toggleMessage, setToggleMessage] = useState("")
  var tawklink = ""

  useEffect(() => {
    const loader = document.querySelector(".loader")
    loader.classList.add("loader--hide")

    setMessageToken(localStorage.getItem("notificationToken"))
  }, [])
  const toggle1 = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const Alldata = JSON.parse(localStorage.getItem("dataJ"))

  const toggleModal = () => setModalState(!modalState)
  const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const hideAlert = () => setAlertVisible(false)
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }
  const handleTabToggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handleToggleMessage = event => setToggleMessage(event.target.value)

  const renderAlert = () => {
    if (props.error && typeof props.error === "string") {
      let alertTitle = ""
      let alertType = "warning"

      if (
        props.error ===
          "Your ID has been registered. Please come back again on the event day to log in." ||
        props.error ===
          "User Already registered , Please come back again on the event day to log in." ||
        props.error === "Somethiing went wrong, Please try again!"
      ) {
        alertTitle = props.error.includes("already registered")
          ? "Already Registered"
          : "Registered Successfully"
        alertType = "success"
      }

      return (
        <SweetAlert
          style={{ color: "#000" }}
          show={alertVisible}
          type={alertType}
          title={alertTitle}
          onConfirm={hideAlert}
        >
          {props?.error}
        </SweetAlert>
      )
    }
    return null
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>UDAAN Virtual Sales Summit 2024</title>
      </MetaTags>
      <Modal
        size="xl"
        isOpen={modalState}
        toggle={toggleModal}
        centered
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Photo Mosaic</h5>
          <button
            type="button"
            onClick={toggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            allow="camera; microphone"
            title="test"
            className="embed-responsive-item mosaic"
            src={encodeURI("https://demo-mosaic.herokuapp.com/")}
          />
        </div>
      </Modal>
      <Modal
        isOpen={helprc}
        toggle={() => {
          onhelpOpenRC(!helprc)
        }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Help Desk</h5>
          <button
            type="button"
            onClick={() => {
              onhelpOpenRC(!helprc)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {Alldata.data.lobby.data.areas.map((item, i) =>
            (() => {
              switch (item.id) {
                case "help": {
                  ;<div>
                    {item.url ? (tawklink = item.url) : (tawklink = "")}
                  </div>
                }
              }
            })()
          )}
          <iframe
            height="450px"
            width="100%"
            title="test"
            className="embed-responsive-item"
            src={encodeURI(tawklink)}
          />
        </div>
      </Modal>
      <Container fluid style={{ padding: 0, position: "relative" }}>
        <img
          loading="lazy"
          src={
            isMobile
              ? // LoginMobImag
                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FLow%20res%20graphics%2FLogin%20Phone.jpg?alt=media&token=018f7670-9b32-4330-afcf-30fbce9ebac6"
              : "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FLow%20res%20graphics%2FLogin_BG.jpg?alt=media&token=9ea3d4a4-38d5-46e6-b2e2-55094d76e6a6"
            // LoginLapImag
          }
          style={{ position: "absolute", width: "100%" }}
          alt="Login"
        />
        <div className="account-pages pt-sm-5">
          <center className="loginCenter">
            <Card
              className="overflow-hidden cardLogin"
              style={{ boxShadow: "none", background: "transparent" }}
            >
              <CardBody className="pt-0" style={{ padding: 0 }}>
                <div className="p-2" style={{ color: "#fff" }}>
                  <Nav
                    style={{ columnGap: "2%" }}
                    pills
                    className="navtab-bg nav-justified"
                  >
                    <NavItem>
                      {/* <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "Individual",
                        })}
                        onClick={() => {
                          toggle1("Individual")
                          setActiveTabContent("1")
                        }}
                      >
                        Login
                      </NavLink> */}
                      <div id="1"></div>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "Team",
                        })}
                        onClick={() => {
                          toggle1("Team")
                          setActiveTabContent("2")
                        }}
                      >
                        Register
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  {renderAlert()}
                  <TabContent
                    activeTab={activeTabContent}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                      >
                        {/* <div className="mb-1">
                          <AvField
                            name="Firstname"
                            className="form-control"
                            placeholder="Name *"
                            type="text"
                            required
                          />
                        </div> */}
                        <div className="mb-1">
                          <AvField
                            name="Email"
                            className="form-control"
                            placeholder="Email ID*"
                            type="email"
                            required
                          />
                        </div>
                        {/* <div className="mb-1">
                          <AvField
                            name="EmployeeID"
                            className="form-control"
                            placeholder="Employee Code *"
                            type="text"
                            required
                          />
                        </div> */}
                        <div className="mb-3 applebtn">
                          <AvField
                            name="customaction"
                            value="Login"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>
                        <div
                          className="mt-3 d-flex justify-content-evenly"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              // backgroundColor: "rgb(255,255,254)",
                              // color: "white",
                              // borderColor: "white",
                              // fontWeight: "bold",
                              // marginRight: "5%",
                              // background: "rgba(23, 23, 23, 0.66)",
                              borderRadius: "16px",
                              // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                              // backdropFilter: "blur(16.5px)",
                              // webkitBackdropFilter: "blur(16.5px)",
                              outline: "none",
                              border: "none",
                            }}
                            className="btn btn-primary btn-block applebtn"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </AvForm>
                      <p className="mt-3 text-black">
                        Please login using your SEED email ID
                      </p>
                    </TabPane>
                    <TabPane tabId="2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="mb-1">
                          <AvField
                            name="Firstname"
                            className="form-control"
                            placeholder="Full Name *"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-1">
                          <AvField
                            name="EmployerName"
                            className="form-control"
                            placeholder="Employer Name *"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-1">
                          <AvField
                            name="Email"
                            className="form-control"
                            placeholder="Email ID *"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-1">
                          <AvField
                            name="storeName"
                            className="form-control"
                            placeholder="Store Name *"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="customaction"
                            value="Register"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>
                        <div
                          className="mt-3 d-flex justify-content-evenly"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              backgroundColor: "rgb(255,255,254)",
                              color: "white",
                              borderColor: "white",
                              fontWeight: "bold",
                              marginRight: "5%",
                            }}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </AvForm>
                    </TabPane>
                  </TabContent>
                  {/* {toggleMessage === "Employee"
                    ? "Please enter your name and employee ID."
                    : "Please enter your registered email ID to enter"} */}
                </div>
              </CardBody>
            </Card>
          </center>
        </div>
      </Container>
      <div
        style={{
          cursor: "pointer",
          height: "80px",
          width: "80px",
          position: "absolute",
          top: "85%",
          right: "10%",
        }}
      >
        <img width="100px" src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FHELP%20Button.png?alt=media" onClick={()=>{onhelpOpenRC(!helprc)}} />
        {/* <div style={{color:"#fff",fontSize:"1.8rem",textAlign:"center",padding:"2% 5%"}}
        className="applebtn"
        ><span>Help</span><span>?</span></div> */}
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
