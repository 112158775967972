import { OPEN_MODAL, OPEN_RC, OPEN_MS, OPEN_TOUR, OPEN_WELCOME, OPEN_ANN, NOTIFY } from "./actionTypes"

export const openModal = () => ({
  type: OPEN_MODAL,
})

export const openRC = () => ({
  type: OPEN_RC,
})

export const openMS = () => ({
  type: OPEN_MS,
})

export const openTour = () => ({
  type: OPEN_TOUR,
})

export const openWelcome = () => ({
  type: OPEN_WELCOME,
})

export const openAnn = () => ({
  type: OPEN_ANN,
})

export const openNot = () => ({
  type: NOTIFY,
})
