import React from "react"
import PropTypes from "prop-types"
import { Card, CardText, CardHeader, CardBody } from "reactstrap"

const CommnetCard = props => {
  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom text-uppercase">
        {props.cardTitle}
        <br />
        {props.cardExtraTitle}
      </CardHeader>
      <CardBody>
        <blockquote className="card-blockquote mb-0">
          <CardText>{props.cardText}</CardText>
        </blockquote>
      </CardBody>
    </Card>
  )
}

CommnetCard.propTypes = {
  cardTitle: PropTypes.string,
  cardText: PropTypes.string,
  cardExtraTitle: PropTypes.string,
}

export default CommnetCard
