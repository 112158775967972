import React from "react";
import PropTypes from 'prop-types'
import Svg1 from "./1.js"
import Svg2 from "./2.js"
import Svg3 from "./3.js"
import Svg4 from "./4.js"

const GratitudeTemplate = (props) => {
    switch(props.templateURL){
        case "1":
          return (
            <Svg1
                templateURL={props.templateURL}
                RecievedEmail={props.RecievedEmail}
                RecievedName={props.RecievedName}
                templatemessage={props.templatemessage}>
                </Svg1>
            )
            break
        case "2":
            return (
              <Svg2
              templateURL={props.templateURL}
              RecievedEmail={props.RecievedEmail}
              RecievedName={props.RecievedName}
              templatemessage={props.templatemessage}>
              </Svg2>
            )
            break
        case "3":
            return(
              <Svg3
              templateURL={props.templateURL}
              RecievedEmail={props.RecievedEmail}
              RecievedName={props.RecievedName}
              templatemessage={props.templatemessage}>
              </Svg3>
            )
        case "4":
            return(
              <Svg4
              templateURL={props.templateURL}
              RecievedEmail={props.RecievedEmail}
              RecievedName={props.RecievedName}
              templatemessage={props.templatemessage}>
              </Svg4>
            )
        default :
            return(
              "Cannot View this card, Please contact Help from Side Menu Bar and report the issue."
            )
    }
    
}

GratitudeTemplate.propTypes = {
    templateURL: PropTypes.string,
    templatemessage: PropTypes.string,
    RecievedName: PropTypes.string,
    RecievedEmail: PropTypes.string,
}

export default GratitudeTemplate