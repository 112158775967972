import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Button,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "../datatables.scss"

import "./mylib.css"
import classnames from "classnames"
import { doc } from "prettier"

const Mylibrary = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [ActiveTab, setActiveTab] = useState("My Bookmarks")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const loader = document.querySelector(".loader")
  const [moda, setmoda] = useState(false)
  const [docurl, setdocurl] = useState("")
  const [MyResourcesArray, setMyResourcesArray] = useState([])
  console.log(MyResourcesArray)
  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (obj.savedDoc) {
      {
        obj.savedDoc.map((item, i) =>
          //  MyResourcesArray.push(item)
          setMyResourcesArray(MyResourcesArray => [...MyResourcesArray, item])
          // console.log(item.docname)
        )
      }
    }
    loader.classList.add("loader--hide")
  }, [])

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        style={{ backgroundColor: "#3B919A" }}
        onClick={() => {
          // console.log(row.Email);
          setmoda(!moda)
          setdocurl(row.docurl)
          // setrecvName(row.Firstname);
        }}
      >
        View
      </Button>
    )
  }

  const columns = [
    // {
    //   dataField: 'key',
    //   text: 'Key',
    //   sort: false,
    // },
    {
      dataField: "sl.no",
      text: "S.No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1
      },
      sort: false,
    },
    {
      dataField: "docname",
      text: "Document Name",
      sort: false,
    },
    {
      dataField: "docurl",
      text: "Click To View",
      formatter: linkFollow,
      sort: false,
    },
    // {
    //   dataField: 'total',
    //   text: 'Total',
    //   sort: false
    // }
  ]

  const defaultSorted = [
    {
      dataField: "total",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 12,
    totalSize: MyResourcesArray.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "12", value: 12 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: MyResourcesArray.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={moda}
        toggle={() => {
          setmoda(!moda)
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel"></h5>
          <button
            onClick={() => {
              setmoda(!moda)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe src={docurl} height="550px" width="100%"></iframe>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "My Bookmarks",
                })}
                onClick={() => {
                  toggle1("My Bookmarks")
                  setactiveTabContent("1")
                }}
              >
                My Bookmarks
              </NavLink>
              <div id="1"></div>
            </NavItem>
            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "Team",
                })}
                onClick={() => {
                  toggle1("Team")
                  setactiveTabContent("2")
                }}
              >
                Team Score
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTabContent} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#00A982" }}>
                        You can view your saved Resources here
                      </CardTitle>
                      {/* <p className="card-title-desc"> </p> */}

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={MyResourcesArray}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={MyResourcesArray}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light tableHead"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            {/* <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>Team Leaderboard</CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='key'
                        columns={columns}
                        data={TeamScore}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField='key'
                            columns={columns}
                            data={TeamScore}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />

                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                            }
                          </ToolkitProvider>
                        )
                        }</PaginationProvider>

                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </TabPane> */}
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Mylibrary
