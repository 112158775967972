import React, { useState, useEffect } from "react";
import { getToken } from "../../firebaseInit.js";


async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

// FOR BACKGROUND NOTIFICATION AND GET TOKEN
const FirebaseNotifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", data);
        // if(window.location.pathname=="/path"){
          const obj = JSON.parse(localStorage.getItem("authUser"))
          if(obj){
            // alert("start")
            postData(process.env.REACT_APP_ADMIN+"/login/update/fcm",{
              Email:obj.Email,
              Token:data,
            })
          }
          // alert(data)
          
        // }
        if(window.location.pathname=="/pathnew"){
          window.location=data
        }
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

FirebaseNotifications.propTypes = {};

export default FirebaseNotifications;