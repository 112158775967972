import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};
 
firebase.initializeApp(firebaseConfig);
let messaging;
try{
   messaging = firebase.messaging();
}catch(error){
  if(window.location.pathname=="/path"){
  alert(error)
  }
  console.log(error)
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = messaging ?  await messaging.getToken({ vapidKey: publicKey }) : null;
    if (currentToken) {
      setTokenFound(true);
      if(localStorage.getItem("notificationToken") != currentToken){
        console.log("updating firebase token");
      }
      localStorage.setItem("notificationToken",currentToken)
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    // alert("Please grant notification permission for better experience")
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });