import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const CustomButton = props => {
  return (
    <Button
      className="btn mb-1"
      color="primary"
      onClick={() => {
        CometChatWidget.chatWithUser(props.chatUid)
        CometChatWidget.openOrCloseChat(true)
      }}
    >
      {props.customText}
    </Button>
  )
}

CustomButton.propTypes = {
  customText: PropTypes.string,
  chatUid: PropTypes.string,
}

export default CustomButton
