import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  Row,
  Col,
  Modal,
} from "reactstrap"
import { openModal, openRC, openNot, openAnn } from "store/actions"
import { connect } from "react-redux"
import parse from "html-react-parser"
import io from "socket.io-client"
// import Alldata from "../../assets/localdata.json"

const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Speakers = props => {
  const [ActiveTab, setActiveTab] = useState(Alldata.data.agenda.data.day1.name)

  const { modal, onOpenModal } = props
  const { rc, onOpenRC } = props
  const { ann, onOpenAnn } = props
  const loader = document.querySelector(".loader")

  const { noti, onOpenNot } = props

  const [mssg, setMssg] = useState("")
  const [Ann, setAnn] = useState([])

  useEffect(() => {
    loader.classList.add("loader--hide")
    const socket = io.connect(process.env.REACT_APP_ADMIN)
    if (document.getElementById("cometchat__widget")) {
      document.getElementById("cometchat__widget").style.display = "none"
    }
    socket.on("notificationChannel", message => {
      const { title, des, url, img } = message
      setMssg({
        title,
        des,
        url,
        img,
      })
      onOpenNot()
    })
    fetch(process.env.REACT_APP_ADMIN + "/notification/getAll")
      .then(response => response.json())
      .then(data => {
        setAnn(data)
      })
    return () => socket.disconnect()
  }, [])

  // const [bioModal,setBioModal] = useState(false)
  return (
    <React.Fragment>
      <div style={{ padding: 0 }} className="page-content">
        <MetaTags>
          <title>UDAAN Virtual Sales Summit 2024</title>
        </MetaTags>
        <Container style={{ padding: 0, paddingTop: "70px" }} fluid>
          <Modal size="lg" isOpen={ann} toggle={onOpenAnn} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Announcements &nbsp; <i className="bx bxs-bell-ring" />
              </h5>
              <button
                onClick={onOpenAnn}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                backgroundColor: "#1E1E1E",
                border: "1px solid #1E1E1E",
              }}
            >
              <center>
                {Ann.map((a, idx) => (
                  <Card
                    key={idx}
                    className="py-2 px-5"
                    style={{ backgroundColor: "transparent", color: "#00A982" }}
                  >
                    {a.img && (
                      <CardImg
                        top
                        className="img-fluid"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                          encodeURIComponent(a.img) +
                          "?alt=media"
                        }
                        alt="Skote"
                      />
                    )}

                    <CardBody>
                      <center>
                        <CardTitle className="mt-0">{a.title}</CardTitle>
                        <CardText>{a.des}</CardText>
                        {a.url && (
                          <a href={encodeURI(a.url)}>
                            <button
                              className="btn btn-danger"
                              style={{
                                margin: "1rem",
                                color: "#FFFFFF",
                                backgroundColor: "transparent",
                                border: "1px solid #FFFFFF",
                              }}
                            >
                              Visit
                            </button>
                          </a>
                        )}
                      </center>
                    </CardBody>
                  </Card>
                ))}
              </center>
            </div>
          </Modal>

          <Modal
            isOpen={rc}
            toggle={onOpenRC}
            centered={true}
            style={{ width: "auto" }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Help Chat</h5>
              <button
                type="button"
                onClick={onOpenRC}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe
                height="450px"
                width="100%"
                title="test"
                className="embed-responsive-item"
                src={encodeURI(
                  "https://tawk.to/chat/60ca022c7f4b000ac037ef21/1f8ahh0o7"
                )}
              />
            </div>
          </Modal>

          <Modal isOpen={noti} toggle={onOpenNot}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Announcement &nbsp; <i className="bx bxs-bell-ring" />
              </h5>
              <button
                onClick={onOpenNot}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Card>
                {mssg.img && (
                  <CardImg
                    top
                    className="img-fluid"
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      encodeURIComponent(mssg.img) +
                      "?alt=media"
                    }
                    alt="Skote"
                  />
                )}

                <CardBody>
                  <center>
                    <CardTitle className="mt-0">{mssg.title}</CardTitle>
                    <CardText>{mssg.des}</CardText>
                    {mssg.url && (
                      <a href={encodeURI(mssg.url)}>
                        <button className="btn btn-danger">Visit</button>
                      </a>
                    )}
                  </center>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal size="xl" isOpen={modal} toggle={onOpenModal} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Agenda
              </h5>
              <button
                onClick={onOpenModal}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe
                id={Alldata.data.lobby.data.areas[3].name}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                  encodeURIComponent("nipsea/Award Ceremony Agenda.pdf") +
                  "?alt=media"
                }
                height="550px"
                width="100%"
              ></iframe>
              {/*<Card>
              <CardBody>
                <CardTitle className="h4">Agenda</CardTitle>
                 <p className="card-title-desc">Example of custom tabs</p>

                <Nav tabs className="nav-tabs-custom nav-justified">
                  {Object.keys(Alldata.data.agenda.data).map((day, idx) => (
                    <NavItem key={idx}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active:
                            ActiveTab === Alldata.data.agenda.data[day].name,
                        })}
                        onClick={() => {
                          toggleCustom(Alldata.data.agenda.data[day].name)
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-apple-airplay"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          {Alldata.data.agenda.data[day].name}
                        </span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={ActiveTab} className="p-3 text-muted">
                  {Object.keys(Alldata.data.agenda.data).map((day, idx) => (
                    <TabPane
                      key={idx}
                      tabId={Alldata.data.agenda.data[day].name}
                    >
                      {Alldata.data.agenda.data[day].data.map((cardo, idc) => {
                        return (
                          <Row key={idc}>
                            <Col sm="12">
                              <Card>
                                <CardBody>
                                  <Media>
                                    <div className="me-3">
                                      <img
                                      src={avatar}
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    />
                                    </div>
                                    <Media body className="align-self-center">
                                      {cardo.redirect && (
                                        <a href={cardo.redirect}>
                                          <button
                                            className="btn btn-danger"
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                            }}
                                          >
                                            Join
                                          </button>
                                        </a>
                                      )}

                                      <div className="text-muted">
                                        <h5>{cardo.title}</h5>
                                        <h6>{cardo.time}</h6>
                                        {cardo.des && (
                                          <p className="mb-1">
                                            {parse("" + cardo.des)}
                                          </p>
                                        )}
                                      </div>
                                    </Media>
                                  </Media>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )
                      })}
                    </TabPane>
                  ))}
                </TabContent>
              </CardBody>
            </Card> */}
            </div>
          </Modal>

          {/*  */}

          <div
            className="row"
            style={{ marginLeft: "66px", marginRight: "36px" }}
          >
            <div className="col-12 p-3">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h1 className="mb-0 font-size-18 judgecol">Judges</h1>
              </div>
            </div>
          </div>
          <Row style={{ marginLeft: "56px", marginRight: "36px" }}>
            {Alldata.data.speakers.data.day1.data.map((det, idx) => {
              const [vb, setvb] = useState(false)
              const togglevb = () => {
                setvb(!vb)
              }
              return (
                <>
                  <Modal
                    size="xl"
                    isOpen={vb}
                    toggle={togglevb}
                    centered={true}
                    // style={{ height: "50%" }}
                  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                      >
                        Judges Profile
                      </h5>
                      <button
                        onClick={() => {
                          setvb(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body newjudg">
                      <Card style={{ margin: "16px" }}>
                        <CardBody style={{ padding: "2rem" }}>
                          <CardImg
                            top
                            className="img-fluid square"
                            style={{
                              maxHeight: "525px",
                              maxWidth: "525px",
                              objectFit: "cover",
                              objectPosition: "top",
                              float: "left",
                            }}
                            src={
                              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                              encodeURIComponent(det.pic) +
                              "?alt=media"
                            }
                            alt="Skote"
                          />

                          {/* <ul className="list-group list-group-flush"> */}
                          <CardTitle
                            className="mt-0 newFont"
                            style={{
                              color: "black",
                              fontWeight: 600,
                              fontSize: "1.5rem",
                              textAlign: "justify",
                            }}
                          >
                            {det.name}
                          </CardTitle>
                          <CardText
                            style={{
                              paddingRight: "5rem",
                              textAlign: "justify",
                            }}
                          >
                            {det.position}
                          </CardText>
                          {det.des && (
                            <p className="mb-1 newFont">
                              {parse("" + det.des)}
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </Modal>
                  <Col lg={4} md={6} xl={3}>
                    <Card key={idx}>
                      <CardImg
                        top
                        className="img-fluid"
                        style={{
                          // maxHeight: "200px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                          encodeURIComponent(det.pic) +
                          "?alt=media"
                        }
                        alt="Skote"
                      />
                      <CardBody style={{ padding: 0 }}>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <center>
                              <CardTitle className="mt-0">{det.name}</CardTitle>
                              <CardText>{det.position}</CardText>
                            </center>
                          </li>
                          <li className="list-group-item">
                            <a style={{ fontWeight: "500" }} onClick={togglevb}>
                              <center>View Bio</center>
                            </a>
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ agenda }) => ({
  modal: agenda.modal,
  rc: agenda.rc,
  ann: agenda.ann,
  noti: agenda.noti,
})

const mapDispatchToProps = dispatch => ({
  onOpenModal: () => dispatch(openModal()),
  onOpenRC: () => dispatch(openRC()),
  onOpenAnn: () => dispatch(openAnn()),
  onOpenNot: () => dispatch(openNot()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Speakers)
