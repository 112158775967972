// AgendaModal.js

import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { FiClock, FiCalendar, FiSave } from "react-icons/fi"
import axios from "axios"
import "./AgendaModal.css"

const AgendaModal = ({ isOpen, toggle }) => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [sessions, setSessions] = useState({})
  const [activeTab, setActiveTab] = useState("")

  const adjustToIst = (utcDate) => {
    utcDate = new Date(utcDate)
    let adjustedData = new Date(utcDate.getTime() - (5 * 60 + 30) * 60 * 1000)
    return adjustedData
  }

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${process.env.REACT_APP_ADMIN}/sessions/platformsession`)
        .then(async response => {
          if (!response.data || !response.data.length) {
            console.log("Error fetching agenda")
            return
          }
          let lowerCaseTicket_Variant = obj.Ticket_Variant ? obj.Ticket_Variant.toLowerCase() :""
          const formattedSesions = response.data.reduce((acc, session) => {
            session.passType = session.passType ? session.passType.toLowerCase() :""
            acc[session.day] = acc[session.day] || []
            acc[session.day].push({
              _id: session._id,
              time: adjustToIst(session.time),
              end: adjustToIst(session.end),
              title: session.title,
              description: session.description,
              attendees: session.attendees,
              speaker: session.speaker,
              hall: session.hall,
              // validPass: !!(session.passType =="All" || session.passType==lowerCaseTicket_Variant),
              validPass: true,
              // time:session.time,
            })
            return acc
          }, {})

          const sessionsData = {}
          let defaultKey
          const mapSessions = Object.keys(formattedSesions).map(
            (sessionKey, index) => {
              if (index === 0) {
                defaultKey = sessionKey
              }
              sessionsData[sessionKey] = {
                name: `${sessionKey}`,
                data: formattedSesions[sessionKey],
              }
              return
            }
          )
          await Promise.all(mapSessions)
          setSessions(sessionsData)
          setActiveTab(defaultKey)
        })
        .catch(error => {
          console.error("Error fetching sessions:", error)
        })
    }
  }, [isOpen])

  const bookSession = (sessionId,validPass) => {
    const userId = obj?._id;
    // alert("Booking of sessions and add to calendar functionality will be enabled soon.")
    if(validPass){
    axios
      .post(`${process.env.REACT_APP_ADMIN}/sessions/${sessionId}/book`, { userId })
      .then(response => {
        alert("Your session is booked - please show your QR code at the entry of this session")
        $("#"+sessionId).addClass("disabled")
        console.log($("#"+sessionId)[0].innerText)
        $("#"+sessionId)[0].innerText="Registered"
        // const {userDetails} = response?.data;
        // if(userDetails){
        //     localStorage.setItem("authUser", JSON.stringify(userDetails))
        //     setUserDtails(JSON.stringify(userDetails))
        // }
      })
      .catch(error => {
        console.log(error)
        alert(error?.response?.data?.error)
      })
    }else{
      alert("Booking of this sessions is restricted")
    }
    
  }


  const addToCalendar = session => {
    // alert("Booking of sessions and add to calendar functionality will be enabled soon.") 
    // Add to Calendar logic to create a ics file
        const startTime = new Date(session.time)
        const endTime = new Date(
          session.end
          // new Date(session.time).setHours(startTime.getHours() + 1)
        ) // Assuming 1 hour sessions

        const formattedStartTime = startTime.toISOString().replace(/-|:|\.\d+/g, "")
        const formattedEndTime = endTime.toISOString().replace(/-|:|\.\d+/g, "")

        const icsMSG = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${document.URL}
DTSTART:${formattedStartTime}
DTEND:${formattedEndTime}
SUMMARY:${session.title}
DESCRIPTION:${session.description}
LOCATION:Online
END:VEVENT
END:VCALENDAR` //don't format this with space ics file will break

        const blob = new Blob([icsMSG], { type: "text/calendar" })
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", `${session.title}.ics`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="agenda-modal">
      {/* <ModalHeader toggle={toggle} className="agenda-header">Agenda</ModalHeader> */}
      <ModalHeader toggle={toggle} className="">
        Agenda
      </ModalHeader>
      <ModalBody className="agenda-body">
        <Nav tabs className="nav-tabs-custom nav-justified">
          {Object.keys(sessions).map(day => (
            <NavItem key={day}>
              <NavLink
                active={activeTab === day}
                onClick={() => {
                  setActiveTab(day)
                }}
              >
                {/* {day.charAt(0).toUpperCase() + day.slice(1)} */}
                {sessions[day]["name"]}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Nav vertical className="agenda-nav">
          {sessions[activeTab]?.["data"].map((session, idx) => (
            <div key={idx} className="agenda-session" style={{ "--i": idx }}>
              <h4 className="agenda-day">{session.day}</h4>
              <NavItem className="agenda-item">
                <NavLink className="agenda-link">
                  <div className="agenda-time-title">

                    <span className="agenda-title">{session.title}</span>
                  </div>
                  <div style={{ marginBottom: "2%" }}> <FiClock className="agenda-icon" />
                    <strong className="agenda-time">
                      &nbsp;
                      {new Date(session.time).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </strong></div>

                  <div className="agenda-description">
                    {session.description}
                  </div>
                  {session.speaker ? <div className="agenda-description" dangerouslySetInnerHTML={{ __html: `<strong>Speaker: </strong> ` + session.speaker }} /> : null}
                  {session.hall ? <div className="agenda-description" dangerouslySetInnerHTML={{ __html: `<strong>Hall: </strong> ` + session.hall }} /> : null}
                  <div className="agenda-buttons">
                    <Button
                      size="sm"
                      color="Dark"
                      className="agenda-button"
                      onClick={() => addToCalendar(session)}
                    >
                      <FiCalendar className="agenda-button-icon" />
                      Add to Calendar
                    </Button>
                    <Button
                      size="sm"
                      color="Dark"
                      className="agenda-button"
                      id={session._id}
                      disabled={session?.attendees.find((elem) => elem == obj._id)}
                      onClick={() => bookSession(session._id,session.validPass)}
                    >
                      <FiSave className="agenda-button-icon" />
                      {session?.attendees.find((elem) => elem == obj._id) ? "Already Registered" : "Book"}
                    </Button>
                  </div>
                </NavLink>
              </NavItem>
            </div>
          ))}
        </Nav>
      </ModalBody>
    </Modal>
  )
}

export default AgendaModal
