import React, { useEffect, useState } from "react"
import "../Weather/weather.css"
import clear from "./weatherImages/clear.png"
import clouds from "./weatherImages/clouds.png"
import drizzle from "./weatherImages/drizzle.png"
import humidity from "./weatherImages/humidity.png"
import mist from "./weatherImages/mist.png"
import rain from "./weatherImages/rain.png"
import search from "./weatherImages/search.png"
import snow from "./weatherImages/snow.png"
import wind from "./weatherImages/wind.png"

import i01d from "./weatherImagescode/01d@2x.png"
import i01n from "./weatherImagescode/01n@2x.png"
import i02d from "./weatherImagescode/02d@2x.png"
import i02n from "./weatherImagescode/02n@2x.png"
import i03d from "./weatherImagescode/03d@2x.png"
import i04d from "./weatherImagescode/04d@2x.png"
import i09d from "./weatherImagescode/09d@2x.png"
import i10d from "./weatherImagescode/10d@2x.png"
import i10n from "./weatherImagescode/10n@2x.png"
import i11d from "./weatherImagescode/11d@2x.png"
import i13d from "./weatherImagescode/13d@2x.png"
import i50d from "./weatherImagescode/50d@2x.png"


const Weather = props => {
  const [data, setData] = useState();
  const [inputValue, setInputValue] = useState('');
  const [isPgload, setisPgload] = useState(false)

const loader = document.querySelector(".loader")
 
  const apikey = process.env.REACT_APP_WEATHER_API_KEY
  const apiUrl =
    "https://api.openweathermap.org/data/2.5/weather?&units=metric&q=";


  const fetchWeather = async () => {
    console.log(1)
    const resp = await fetch(`${apiUrl}${inputValue ? inputValue : 'Hyderabad'}&appid=${apikey}`);
    const data = await resp.json()
    console.log(data);
    setData(data)
  }


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    fetchWeather();
  }, []); 

  setTimeout(() => {
    loader.classList.add("loader--hide")
    setisPgload(true)
  }, 2000)

   //cometchat
  //  useEffect(() => {
  //   setTimeout(() => {
  //     CometChatWidget.init({
  //       appID: "251003bbff056e50",
  //       appRegion: "in",
  //       authKey: "eb9ec815f482349e775f12fa5b379ca4413a6f82",
  //     }).then(
  //       response => {
  //         /**
  //          * Create user once initialization is successful
  //          */
  //         // const obj1 = JSON.parse(localStorage.getItem("authUser"))
  //         const UID = obj._id
  //         // const USERNAME = obj.Email
  //         const USERNAME = obj.Firstname ? obj.Firstname : obj.Email;

  //         // obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
  //         CometChatWidget.createOrUpdateUser({
  //           uid: UID,
  //           name: USERNAME,
  //         }).then(user => {
  //           console.log("Initialization completed successfully")
  //           //You can now call login function.
  //           CometChatWidget.login({
  //             uid: UID,
  //           }).then(
  //             response => {
  //               CometChatWidget.launch({
  //                 //widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
  //                 widgetID: "40db02c6-d79f-4bf9-a987-9c815c6c41c7",
  //                 docked: "true",
  //                 alignment: "right", //left or right
  //                 roundedCorners: "true",
  //                 height: "calc(100% - 180px)",
  //                 width: "400px",
  //                 defaultID: "", //default UID (user) or GUID (group) to show,
  //                 defaultType: "user", //user or group
  //               })
  //             },
  //             error => {
  //               console.log("User login failed with error:", error)
  //               //Check the reason for error and take appropriate action.
  //             }
  //           )
  //         })
  //       },
  //       error => {
  //         console.log("Initialization failed with error:", error)
  //         //Check the reason for error and take appropriate action.
  //       }
  //     )
  //   }, 1000)
  // },[])


  return (
    <React.Fragment>
      <div className="page-content main-div" style={{backgroundColor:"#792881"}}>
        <div className="container-fluid">
          <div className="weather-card">
            <div className="weather-search" style={{display:"none"}}>
              <input type="text" placeholder="   City" spellCheck="false"  value={inputValue}
        onChange={handleInputChange}/>
              <button onClick={fetchWeather}>
                <img  loading="lazy" src={search} />
              </button>
            </div>
            <div className="weather-data">
            <img 
src={
    data &&
    (
      data.weather[0].id === "200" || "201" || "202" || "210" || "211" || "212" || "221" || "230" || "231" || "232" ? i11d :
      data.weather[0].id === "300" || "301" || "302" || "310" || "311" || "312" || "313" || "314" || "321" ? i09d :
      data.weather[0].id === "500" || "501" || "502" || "503" || "504" || "511" || "520" || "521" || "522" || "531" ? i10d :
      data.weather[0].id === "600" || "601"||"602" || "611" || "612" || "613" || "615" || "616" || "620" || "621" || "622" ? i13d :
      data.weather[0].id === "701" || "711" || "721" || "731" || "741" || "751" || "761" || "762" || "771" || "781" ? i50d :
      data.weather[0].id === "800" ? i01d :
      data.weather[0].id === "801" || "802" || "803" || "804" ? i02d :
      clear 
      )
  }
  
  
  className="weather-icon"
  alt="Weather Icon"

/>

              <h1 className="temp">
              {data && data.main.temp ? (
                        <span>
                        {data.main.temp}
                        <sup style={{ fontSize: "30px" }}>°C</sup>
                      </span>
                      ) : (
                        <sub>Fetching...</sub>
                      )}
              </h1>
              <h2  className="city">{data && data.name ? (
                        `${data.name}`
                      ) : (
                        <sub>Fetching...</sub>
                      )}</h2>

              <div className="details">
                <div className="col">
                  <img src={humidity} />
                  <div>
                    <p className="humidity">
                     
                      {data && data.main.humidity ? (
                        `${data.main.humidity}%`
                      ) : (
                        <sub>Fetching...</sub>
                      )}
                    </p>
                    <p>Humidity</p>
                  </div>
                </div>
                <div className="col">
                  <img  loading="lazy" src={wind} />
                  <div>
                    <p className="wind"> {data && data.wind.speed ? (
                        `${data.wind.speed}Km/h`
                      ) : (
                        <sub>Fetching...</sub>
                      )} </p>
                    <p>Wind speed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Weather
