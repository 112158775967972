import React from "react"
import PropTypes from "prop-types"
import CustomButton from "./customButton"
import BCardButton from "./bCardButton"

const ConnectCard = props => {
  return (
    <div className="media mb-4">
      <img loading="lazy"
        className="d-flex  rounded me-3 avatar-sm"
        src={
          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
          encodeURIComponent(props.connectPicture) +
          "?alt=media"
        }
        alt="Skote"
        height="64"
      />
      <div className="media-body">
        <div className="row">
          <div className="col-5">
            <h5 className="mt-0 font-16">{props.connectName}</h5>
            <p>{props.connectDesignation}</p>
          </div>
          <div className="col-5">
            <BCardButton
              customText={props.connectBCardText}
              recvEmail={props.connectBCardEmail}
            />
          </div>
          <div className="col-1">
            <CustomButton
              customText={props.connectChatText}
              chatUid={props.connectChatUid}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ConnectCard.propTypes = {
  connectName: PropTypes.string,
  connectDesignation: PropTypes.string,
  connectPicture: PropTypes.string,
  connectChatUid: PropTypes.string,
  connectChatText: PropTypes.string,
  connectBCardText: PropTypes.string,
  connectBCardEmail: PropTypes.string,
}

export default ConnectCard
