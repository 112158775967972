import { React, useState } from "react"
import { Redirect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Button } from "reactstrap"

const authError = props => {
  const [alert, setAlert] = useState(false)
  const alertToggle = () => {
    if (!alert) setAlert(true)
    else setAlert(false)
  }
  const [redirect, setredirect] = useState(false)
  const redirectToggle = () => {
    setAlert(false)

    // if(!redirect)
    // window.location.href = "/login"
  }

  return (
    <div>
      {/* {<Button color="primary" disabled={this.state.notChange} onClick={() => this.showAlert('Save changes for client', '¿Are you sure?', () => this.updateCustomer, null) } >Save changes</Button>} */}
      <SweetAlert
        show={alert}
        danger
        title="Woot!"
        onConfirm={redirectToggle}
        timeout={5000}
      >
        You will be redirected to the login page. Please enter your email ID to
        log in.
      </SweetAlert>
      <Button color="primary" onClick={alertToggle}>
        sfb
      </Button>
    </div>
  )
}

export default authError
