import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const BCardButton = props => {
  const sendBCard = () => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("sender", obj.Email)
    urlencoded.append("receiver", props.recvEmail)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_ADMIN + "/bCards/send", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.operationStatus) {
          alert(result.message)
        } else {
          alert(result.message)
        }
      })
      .catch(error => console.log("error", error))
  }
  return (
    <Button
      className="btn mb-1"
      color="primary"
      onClick={() => {
        sendBCard()
      }}
    >
      {props.customText}
    </Button>
  )
}

BCardButton.propTypes = {
  customText: PropTypes.string,
  recvEmail: PropTypes.string,
}

export default BCardButton
