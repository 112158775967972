import PropTypes, { func } from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import user1 from "../../assets/images/user.d1a7045e.png"
import maleAvatar from "../../assets/images/users/male-avatar.png"
import femaleAvatar from "../../assets/images/users/female-avatar.png"
import othersAvatar from "../../assets/images/users/others-avatar.png"
import QRCode from "qrcode";

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

const UserProfile = props => {
  const [prof, setprof] = useState(user1)
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [comp, setcomp] = useState("")
  const [des, setdes] = useState("")
  const [phno, setphno] = useState("")
  const [idx, setidx] = useState(1)
  const [qrImageUrl, setQrImageUrl] = useState()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(
          obj.Firstname
            ? obj.Firstname
            : " " + " " + obj.Lastname
            ? obj.Lastname
            : " "
        )
        setemail(obj.Email)
        setidx(obj._id)
        setcomp(obj.Company)
        setdes(obj.Designation)
        setphno(obj.PhoneNumber)
        if(obj.SelectedAvatar==="maleAvatar")
          setprof(maleAvatar)
        else if(obj.SelectedAvatar==="femaleAvatar")
          setprof(femaleAvatar)
        else if(obj.SelectedAvatar==="othersAvatar")
          setprof(othersAvatar)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      // setTimeout(() => {
      //   props.resetProfileFlag()
      // }, 3000)
    }
  }, [props.success])

  function handleValidSubmit(event, values) {
    props.editProfile(values)
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }

  // Example POST method implementation:
  async function postData2(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
  const user = JSON.parse(localStorage.getItem("authUser"))
  
  postData2(process.env.REACT_APP_ADMIN + "/updateProfile/getprof", {
    Email: user.Email,
  }).then(data => {
    // console.log(data) // JSON data parsed by `data.json()` call
    if (data.Profile) {
      setprof(data.Profile)
    }
  })

  function changeprof(event) {
    const user = JSON.parse(localStorage.getItem("authUser"))
    var fileReader = new FileReader()
    if (event.target.files[0].size > 11000) {
      alert("File is too big!")
    } else {
      fileReader.readAsDataURL(event.target.files[0])
    }

    // Example POST method implementation:
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }
    fileReader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        // console.log(fileReader.result);
        postData(process.env.REACT_APP_ADMIN + "/updateProfile/prof", {
          Email: user.Email,
          Profile: fileReader.result,
        }).then(data => {
          // console.log(data) // JSON data parsed by `data.json()` call
          setprof(data.Profile)
        })
      },
      false
    )
  }

  const loader = document.querySelector(".loader")

  useEffect(async() => {
    loader.classList.add("loader--hide")
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const qrCodeResponse= await QRCode.toDataURL(obj._id)
  setQrImageUrl(qrCodeResponse)

  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="User" breadcrumbItem="Profile" /> */}
          <div className="row" style={{ marginRight: "36px" }}>
            <div className="col-12 p-3">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h1 className="mb-0 font-size-18 judgecol">Profile</h1>
              </div>
            </div>
          </div>

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card className="profCard" style={{paddingLeft:"20px"}}>
                <CardBody>
                  <Media>
                    {/* <div className="ms-3 align-self-center">
                      <img
                        src={prof}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                        // onClick={changeprof()}
                      />
                    </div> */}
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5 style={{ color: "#fff" }}>{name}</h5>
                        <p className="mb-1" style={{ color: "#fff" }}>{email}</p>
                        {/* <p className="mb-0">Id no: #{idx}</p> */}
                      </div>
                    </Media>
                    {qrImageUrl ? <img loading="lazy" className="zoomed-image" src={qrImageUrl} alt="img" />: "NA"}
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <center>
            <h4 className="card-title mb-4" style={{ color: "#fff" }}>Change Profile</h4>
          </center>
          <Row>
            <Col>
              <Card style={{ maxWidth: "80%", left: "10%" }}>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div className="form-group" style={{ padding: "1rem" }}>
                      <AvField
                        name="Name"
                        label="Name"
                        value={name}
                        className="form-control"
                        placeholder="Enter Fullname"
                        type="text"
                        required
                      />
                      {/* <div className="form-group" style={{ paddingTop: "1rem" }}>
                        <AvField disabled value={obj.Teamname} className="form-control" label="Business Units" type="text" name="Teamname" placeholder="Business Units *" required/>
                      </div> */}

                      {/* <div className="form-group" style={{paddingTop:"1rem"}}>
                        <AvField
                          name="Company"
                          label="Company"
                          value={comp}
                          className="form-control"
                          placeholder="Company *"
                          type="text"
                          // disabled
                          required
                        /> 
                        </div>
                      <div className="form-group" style={{paddingTop:"1rem"}}>
                        <AvField
                          name="Designation"
                          label="Designation"
                          value={des}
                          className="form-control"
                          placeholder="Designation *"
                          type="text"
                          // disabled
                          required
                        /> 
                        </div>
                      <div className="form-group" style={{paddingTop:"1rem"}}>
                        <AvField
                          name="PhoneNumber"
                          label="PhoneNumber"
                          value={phno}
                          className="form-control"
                          placeholder="PhoneNumber (Optional)"
                          type="text"
                          // disabled
                          // required
                        /> 
                        </div> */}
                    </div>
                    <div className="form-group" style={{ padding: "1rem" }}>
                      <AvField
                        name="Email"
                        label="Email"
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        type="email"
                        disabled
                      />
                      {/* </div><div className="form-group" style={{paddingTop:"1rem"}}>
                        <AvField
                          name="Company"
                          label="Company"
                          value={comp}
                          className="form-control"
                          placeholder="Company"
                          type="text"
                          disabled
                          required
                        /> */}
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                    <div className="form-group" style={{ padding: "1rem" }}>
                      {/* <AvField
                        name="Profile Picture"
                        label="Profile Picture"
                        className="form-control"
                        type="file"
                        onChange={changeprof}
                      />
                      <small>Image should be less than 10kb.</small> */}
                    </div>
                    <div className="text-center mt-4 mb-4">
                      <Button
                        type="submit"
                        style={{ color: "#1E1E1E" }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
