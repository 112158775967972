import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"
import { isMobile } from "react-device-detect"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import { Container } from "reactstrap"
import localJsonData from "./assets/localdata.json"

const loader = document.querySelector(".loader")

const showLoader = () => loader.classList.remove("loader--hide")
const hideLoader = () => loader.classList.add("loader--hide")

const allDeviceSupport = false

const renderApp = () => {
  const app = (
    <Provider store={store}>
      <BrowserRouter>
        {allDeviceSupport ? (
          <App hideLoader={hideLoader} showLoader={showLoader} />
        ) : (isMobile && (window.location.pathname=="/login" || window.location.pathname=="/home" || (localStorage.getItem("authUser") ? false: window.location.pathname=="/"))) ?  (
          <DeviceOrientation lockOrientation={"portrait"}>
            <Orientation orientation="portrait" alwaysRender={false}>
            
            <App hideLoader={hideLoader} showLoader={showLoader} />
             
            </Orientation>
            <Orientation orientation="landscape" alwaysRender={false}>
            <App hideLoader={hideLoader} showLoader={showLoader} />
          
            </Orientation>
          </DeviceOrientation>
        ) : window.location.pathname === "activityfeed" ? (
          <App hideLoader={hideLoader} showLoader={showLoader} />
        ) : (
          <DeviceOrientation lockOrientation={"landscape"}>
            <Orientation orientation="landscape" alwaysRender={false}>
            <App hideLoader={hideLoader} showLoader={showLoader} />
            </Orientation>
            <Orientation orientation="portrait" alwaysRender={false}>
              <Container style={{ padding: 0, position: "relative" }} fluid>
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  alt="clickable"
                  src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FRotate%20Your%20Phone.gif?alt=media&token=797900cb-990e-466a-9182-c675914732ab"
                  style={{
                    zIndex: 9999999999999999999,
                    position: "fixed",
                    display: "block",
                    backgroundColor: "white",
                  }}
                  onLoad={hideLoader}
                />
              </Container>
            </Orientation>
          </DeviceOrientation>
        )}
      </BrowserRouter>
    </Provider>
  )

  // 

  ReactDOM.render(app, document.getElementById("root"))
}

const fetchAndStoreData = async () => {
  const adminPanel = isMobile
    ? `${process.env.REACT_APP_ADMIN}`
    : `${process.env.REACT_APP_ADMIN_LAPTOP}`

  try {

    const response = await fetch(`${adminPanel}/getJD`)
    const data = await response.json()
    localStorage.removeItem("dataJ")

    localStorage.setItem("dataJ", JSON.stringify(data))
  } catch (error) {
    console.error("Failed to fetch data:", error)
  }
  hideLoader()
  renderApp()
}

showLoader(); 
fetchAndStoreData();

serviceWorker.unregister()
// serviceWorker.register();