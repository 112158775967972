import { React, useState } from "react"
import { sha256 } from "js-sha256"
import SweetAlert from "react-bootstrap-sweetalert"

const loader = document.querySelector(".loader")
const authCheck = props => {
  const params = new URLSearchParams(window.location.search)
  const qtoken = params.get("token")
  const ene = params.get("ene")

  const base64Encode = data => {
    let buff = new Buffer.from(data)
    return buff.toString("base64")
  }

  const base64Decode = data => {
    let buff = new Buffer.from(data, "base64")
    return buff.toString("ascii")
  }

  // const hashstr = sha256.update("abc").update("bcd").update("def").hex();
  // console.log(hashstr);

  const enco = (lEmail, logTime) => {
    const encodedlEmail = base64Encode(lEmail.toString())
    // console.log(encodedlEmail);
    const encodedlogTime = base64Encode(logTime.toString())
    const encodedsecret = base64Encode(process.env.REACT_APP_SECRET.toString())

    const token = sha256
      .update(encodedlEmail)
      .update(encodedlogTime)
      .update(encodedsecret)
      .hex()
    // console.log('http://localhost:9000/login/check/'+token+'/'+encodedlEmail);

    return token
  }

  //for login success alert
  const [successalert, setsuccessAlert] = useState(false)

  // //for login fail alert
  const [alert, setFailAlert] = useState(false)
  const alertToggle = () => {
    setFailAlert(!alert)
  }
  const [redirect, setredirect] = useState(false)
  const redirectToggle = () => {
    if (redirect) window.location.href = "/login"
  }
  setTimeout(() => {
    loader.classList.add("loader--hide")
  }, 1000)

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
  const vlogTime = Date.now()
  const decodedlEmail = base64Decode(ene)

  postData(process.env.REACT_APP_ADMIN + "/login/get/document", {
    Email: decodedlEmail,
    token: process.env.REACT_APP_SECRET,
  }).then(document => {
    //   console.log(document); // JSON data parsed by `data.json()` call
    if (!document.mssg) {
      // if (false) {
      if (document.Timestamp == document.lastLogin) {
      console.log("first")
        // window.location.href = "/login/fail";
        setFailAlert(true)
        setredirect(true)
        console.log("Invalid link , Please generate again")
        //   Noob will do it!
      } else {
        const verifytoken = enco(decodedlEmail, document.Timestamp)
        console.log("email" + decodedlEmail)
        console.log("time" + document.Timestamp)
        console.log(" vf : ", verifytoken)
        console.log(qtoken)
        if (verifytoken == qtoken) {
        // if (true) {
          console.log("verified!")
          const vlogTime = Date.now()
          postData(process.env.REACT_APP_ADMIN + "/login/update/document", {
            Email: decodedlEmail,
            token: process.env.REACT_APP_SECRET,
            vlogTime,
          }).then(doc => {
            if (!document.mssg) {
              localStorage.setItem("authUser", JSON.stringify(doc))
              setsuccessAlert(true)
              window.location.href = "/dashboard"
            } else {
              // Noob will do it!
              // window.location.href = "/login/fail";
              setFailAlert(true)
              setredirect(true)
            }
          })
        } else {
          // window.location.href = "/login/fail";
          setFailAlert(true)
          setredirect(true)
          console.log({ mssg: "Invalid Link, Please TRY AGAIN" })
          // Noob will do it!
        }
      }
    } else {
      console.log("second")
      // window.location.href = "/login/fail";
      setFailAlert(true)
      setredirect(true)
      // console.log(document);
      console.log({ mss: "Something Went wrong, TRY AGAIN! " })
      // Noob will do it!
    }
  })
  return (
    <div>
      <SweetAlert
        show={alert}
        danger
        title="Login Failed!"
        onConfirm={redirectToggle}
        // timeout={4000}
      >
        You will be redirected to the login page. Please enter your email ID to
        log in.
      </SweetAlert>
      {/* <SweetAlert
                show={successalert}
                title={"Uses render props"}
                onConfirm={this.onConfirm}
                type={'controlled'}
                dependencies={[this.state.firstName, this.state.lastName]}
            >
                
                    <form>
                        Your name is: 
                        <hr />
                        <input
                            type={'text'}
                            ref={renderProps.setAutoFocusInputRef}
                            className="form-control"
                            value=""
                            onKeyDown={renderProps.onEnterKeyDownConfirm}
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                            placeholder={'First name'}
                        />
                        <br />
                        <input
                            type={'text'}
                            className="form-control"
                            value=""
                            onKeyDown={renderProps.onEnterKeyDownConfirm}
                            // onChange={(e) => this.setState({ lastName: e.target.value })}
                            placeholder={'Last name'}
                        />
                        <hr />
                    </form>
                
            </SweetAlert> */}
      <SweetAlert
        show={successalert}
        success
        title="Login Success!"
        // onConfirm={redirectToggle}
        // timeout={4000}
      >
        Success
        {/* You will be redirected to the login page. Please enter your email ID to log in. */}
      </SweetAlert>
    </div>
  )
}

export default authCheck
